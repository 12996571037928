import React, { useEffect } from 'react'


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);





export default function CustomChart(props) {

  const { chartData, chartLabel, chartCategory, ratio_type } = props;

  // const ratio_type = "top_bottom";
  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       display: false, 
  //       position: 'top',
  //     },
  //     title: {
  //       display: false, 
  //       text: '',
  //     },
  //   },
  // };
  
const options = {
  indexAxis: 'y',
  scales: {
    // r: {
    //   ticks: {
    //     backdropPadding: {
    //       x: 10,
    //       y: 40
    //     }
    //   }
    // },
    // x: {
    //   ticks: {
    //     display: true
    //   }, grid: {
    //     display: false

    //   },
    // },
    // y: {
    //   ticks: {
    //     display: true,
    //   },
    //   grid: {
    //     display: false
    //   }
    // }
  },
  // elements: {
  //   bar: {
  //     borderWidth: 1,
  //     marginLeft: 20
  //   },
  // },
  // responsive: true,
  // maintainAspectRatio: false,
  // layout: {
  //   padding: {
  //     top: 0,
  //     right: 30,
  //     bottom: 0,
  //     left: 0
  //   }
  // },

  aspectRatio: ratio_type == 'top_bottom' ? 10/2 : 10/5,

  plugins: {
    datalabels: {
      align: function (context) {
        var value = context.dataset.data[context.dataIndex];
        return 'end';
      },
      anchor: function (context) {
        var value = context.dataset.data[context.dataIndex];
        return value > -1 ? 'end' : 'start';
      },
      borderRadius: 4,
      color: 'black',
      // rotation: function(context) {
      //   var value = context.dataset.data[context.dataIndex];
      //   return value > 0 ? 45 : 180 - 45;
      // },
      // backgroundColor: function(context) {
      //   return context.dataset.backgroundColor;
      // },
      // formatter: Math.round,
      display: true,
      padding: function (context) {
        var value = context.dataset.data[context.dataIndex];
        return value > -1 ? '0' : '5';
      },
    },
    legend: {
      display: false,
      position: 'left',
    },
    title: {
      display: false,
      // text: 'Chart.js Horizontal Bar Chart',
    },
  },
};

  useEffect(() => {
    // console.log(props);
  }, [props])
  
  
  const labels = chartLabel.filter((item) => item != 'Total');
  // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  
  
  let lastChartData = chartData.slice(-1);
  let newChartData = chartData.filter((item) => item !== lastChartData[0]);
  
  // console.log(newChartData);
  
  const data = {
    labels,
    legend: {
      display: false
    },
    datasets: [
      {
        // clip: {left: 10, top: 20, right: 30, bottom: 40},
        label: '',
        data: newChartData,
        borderColor: function (context) {
          return context.raw > 0 ? 'rgb(34, 132, 65)' : 'rgba(244, 67, 54)';
        },
        backgroundColor: function (context) {
          return context.raw > 0 ? 'rgba(34, 132, 65, 0.5)' : 'rgba(244, 67, 54, 0.5)';
        },
      },
    ],
  };

  

  return (
    <>
      <Box sx={{
        paddingTop:1,
        // height:200,
       }}>
      <Bar  options={options} data={data} />
      </Box>
    </>
  )
}
