import React from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { Box } from "@mui/system";
import { IconButton, List, ListItem, ListItemText } from "@mui/material";
// import "./styles.css";

export default function Tree() {
  const [items, setItems] = React.useState([
    {
      order_by: 1,
      label: "ram ",
      columns: [
        {
          order_by: 1,
          label: " --- Sub ram"
        },
        {
          order_by: 9,
          label: " --- Sub ram 2"
        }
      ]
    },
    {
      order_by: 2,
      label: "ram 2",
      columns: [
        {
          order_by: 1,
          label: "A"
        },
        {
          order_by: 9,
          label: "I"
        }
      ]
    },
    // {
    //   order_by: 3,
    //   label: "C",
    //   columns: [
    //     {
    //       order_by: 1,
    //       label: "A"
    //     },
    //     {
    //       order_by: 9,
    //       label: "I"
    //     }
    //   ]
    // },
    // {
    //   order_by: 4,
    //   label: "D",
    //   columns: [
    //     {
    //       order_by: 1,
    //       label: "A"
    //     },
    //     {
    //       order_by: 9,
    //       label: "I"
    //     }
    //   ]
    // },
    // {
    //   order_by: 5,
    //   label: "E",
    //   columns: [
    //     {
    //       order_by: 1,
    //       label: "A"
    //     },
    //     {
    //       order_by: 9,
    //       label: "I"
    //     }
    //   ]
    // },
    // {
    //   order_by: 6,
    //   label: "F",
    //   columns: [
    //     {
    //       order_by: 1,
    //       label: "A"
    //     },
    //     {
    //       order_by: 9,
    //       label: "I"
    //     }
    //   ]
    // },
    // {
    //   order_by: 7,
    //   label: "G",
    //   columns: [
    //     {
    //       order_by: 1,
    //       label: "A"
    //     },
    //     {
    //       order_by: 9,
    //       label: "I"
    //     }
    //   ]
    // },
    // {
    //   order_by: 8,
    //   label: "H",
    //   columns: [
    //     {
    //       order_by: 1,
    //       label: "A"
    //     },
    //     {
    //       order_by: 9,
    //       label: "I"
    //     }
    //   ]
    // },
    // {
    //   order_by: 9,
    //   label: "I",
    //   columns: [
    //     {
    //       order_by: 1,
    //       label: "A"
    //     },
    //     {
    //       order_by: 9,
    //       label: "I"
    //     }
    //   ]
    // }

  ]);

  //   const onSortEnd = () => {
  //     setItems((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  //   }

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setItems((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    console.log(items);
  };

  return (
    <>

      <SortableList
        onSortEnd={onSortEnd}
        className="list"
        draggedItemClassName="dragged"
      >
        {items.map((item) => (
          <SortableItem key={item.order_by}>
            <div className="item">
              <div>{item.label}</div>
              <SortableList
                onSortEnd={onSortEnd}
                className="sub-list"
                draggedItemClassName="dragged"
              >
                {item.columns.map((item0) => (
                  <SortableItem key={item0.order_by}>
                    <div className="sub-item">
                      <div>{item0.label}</div>
                    </div>

                  </SortableItem>
                ))}
              </SortableList>
            </div>

          </SortableItem>
        ))}
      </SortableList>


      {/* <Box >
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {items.map((item) => (
            <>
              <ListItem
                key={item.order_by}
                disableGutters
                secondaryAction={
                  <IconButton>
                  </IconButton>
                }
              >
                <ListItemText primary={item.label} />
              </ListItem>
            </>
          ))}
        </List>
      </Box> */}

    </>


  );
}
