import React from 'react'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';




const PriceChartComp = () => {
  
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

// const labels = [
//   "Open (Unit Curr)",
//   "High (Unit Curr)",
//   "Low (Unit Curr)",
//   "Close (Unit Curr)",
//   "Volume (000's)",
//   "No of Trades",
//   "Value",
//   "Market Cap",
//   "TTM PE(x)",
//   "Cons TTM PE(x)",
//   "SENSEX",
//   "P/BV(x)",
//   "Cons P/BV(x)",
//   "EV/EBIDTA(x)",
//   "Cons EV/EBIDTA(x)",
//   "MCAP/Sales(x)",
//   "Cons MCAP/Sales(x)",
// ];
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const data = {
  labels,
  datasets: [
    {
      type: 'line',
      label: 'Dataset 1',
      borderColor: 'rgb(255, 99, 132)',
      borderWidth: 2,
      fill: false,
      data: [200,255,156,800,-600,-300,945],
    },
    {
      type: 'bar',
      label: 'Dataset 2',
      backgroundColor: 'rgb(75, 192, 192)',
      data: [200,255,156,800,-600,-300,945],
      borderColor: 'white',
      borderWidth: 2,
    },
    {
      type: 'line',
      label: 'Dataset 3',
      backgroundColor: 'rgb(53, 162, 235)',
      data: [290,355,556,100,-900,-400,545],
    },
  ],
};

  return (
    <>
        <Chart type='bar' data={data} />
    </>
  )
}

export default PriceChartComp