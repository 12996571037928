export const ALL_USERS_REQUEST = "ALL_USERS_REQUEST";
export const ALL_USERS_SUCCESS = "ALL_USERS_SUCCESS";
export const ALL_USERS_FAIL = "ALL_USERS_FAIL";


export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const SINGLE_COMPANY_NOTE_REQUEST = "SINGLE_COMPANY_NOTE_REQUEST";
export const SINGLE_COMPANY_NOTE_SUCCESS = "SINGLE_COMPANY_NOTE_SUCCESS";
export const SINGLE_COMPANY_NOTE_FAIL = "SINGLE_COMPANY_NOTE_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const UPLOAD_DOCUMENT_REQUEST = "UPLOAD_DOCUMENT_REQUEST";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_FAIL = "UPLOAD_DOCUMENT_FAIL";

export const UPLOAD_DOCUMENT_ANALYST_REQUEST = "UPLOAD_DOCUMENT_ANALYST_REQUEST";
export const UPLOAD_DOCUMENT_ANALYST_SUCCESS = "UPLOAD_DOCUMENT_ANALYST_SUCCESS";
export const UPLOAD_DOCUMENT_ANALYST_FAIL = "UPLOAD_DOCUMENT_ANALYST_FAIL";

export const SEND_NOTIFICATION_REQUEST = "SEND_NOTIFICATION_REQUEST";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAIL = "SEND_NOTIFICATION_FAIL";

export const COMPANY_COMMENTS_REQUEST = "COMPANY_COMMENTS_REQUEST";
export const COMPANY_COMMENTS_SUCCESS = "COMPANY_COMMENTS_SUCCESS";
export const COMPANY_COMMENTS_FAIL = "COMPANY_COMMENTS_FAIL";

export const SINGLE_COMPANY_VALUATION_REQUEST = "SINGLE_COMPANY_VALUATION_REQUEST";
export const SINGLE_COMPANY_VALUATION_FAIL = "SINGLE_COMPANY_VALUATION_FAIL";
export const SINGLE_COMPANY_VALUATION_SUCCESS = "SINGLE_COMPANY_VALUATION_SUCCESS";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";


export const ALL_COMPANY_REQUEST = "ALL_COMPANY_REQUEST";
export const ALL_COMPANY_SUCCESS = "ALL_COMPANY_SUCCESS";
export const ALL_COMPANY_FAIL = "ALL_COMPANY_FAIL";

export const ALL_COMPANY_REQUEST_2 = "ALL_COMPANY_REQUEST_2";
export const ALL_COMPANY_SUCCESS_2 = "ALL_COMPANY_SUCCESS_2";
export const ALL_COMPANY_FAIL_2 = "ALL_COMPANY_FAIL_2";

export const SINGLE_COMPANY_REQUEST = "SINGLE_COMPANY_REQUEST";
export const SINGLE_COMPANY_SUCCESS = "SINGLE_COMPANY_SUCCESS";
export const SINGLE_COMPANY_FAIL = "SINGLE_COMPANY_FAIL";

export const CALENDER_REQUEST = "CALENDER_REQUEST";
export const CALENDER_SUCCESS = "CALENDER_SUCCESS";
export const CALENDER_FAIL = "CALENDER_FAIL";

export const COMPANY_MASTER_REQUEST = "COMPANY_MASTER_REQUEST";
export const COMPANY_MASTER_SUCCESS = "COMPANY_MASTER_SUCCESS";
export const COMPANY_MASTER_FAIL = "COMPANY_MASTER_FAIL";

export const SECTOR_REQUEST = "SECTOR_REQUEST";
export const SECTOR_SUCCESS = "SECTOR_SUCCESS";
export const SECTOR_FAIL = "SECTOR_FAIL";

export const INDUSTRY_REQUEST = "INDUSTRY_REQUEST";
export const INDUSTRY_SUCCESS = "INDUSTRY_SUCCESS";
export const INDUSTRY_FAIL = "INDUSTRY_FAIL";

export const TURN_AROUND_REQUEST = "TURN_AROUND_REQUEST";
export const TURN_AROUND_SUCCESS = "TURN_AROUND_SUCCESS";
export const TURN_AROUND_FAIL = "TURN_AROUND_FAIL";

export const RESULT_DATA_REQUEST = "RESULT_DATA_REQUEST";
export const RESULT_DATA_SUCCESS = "RESULT_DATA_SUCCESS";
export const RESULT_DATA_FAIL = "RESULT_DATA_FAIL";

export const ABOUT_US_UPDATE_REQUEST = "ABOUT_US_UPDATE_REQUEST";
export const ABOUT_US_UPDATE_SUCCESS = "ABOUT_US_UPDATE_SUCCESS";
export const ABOUT_US_UPDATE_FAIL = "ABOUT_US_UPDATE_FAIL";

export const VALUATION_REQUEST = "VALUATION_REQUEST";
export const VALUATION_SUCCESS = "VALUATION_SUCCESS";
export const VALUATION_FAIL = "VALUATION_FAIL";
export const VALUATION_FILTER_REQUEST = "VALUATION_FILTER_REQUEST";
export const VALUATION_FILTER_SUCCESS = "VALUATION_FILTER_SUCCESS";
export const VALUATION_FILTER_FAIL = "VALUATION_FILTER_FAIL";



export const DELIVERY_DATA_REQUEST = "DELIVERY_DATA_REQUEST";
export const DELIVERY_DATA_SUCCESS = "DELIVERY_DATA_SUCCESS";
export const DELIVERY_DATA_FAIL = "DELIVERY_DATA_FAIL";
export const DELIVERY_DATA_FILTER_REQUEST = "DELIVERY_DATA_FILTER_REQUEST";
export const DELIVERY_DATA_FILTER_SUCCESS = "DELIVERY_DATA_FILTER_SUCCESS";
export const DELIVERY_DATA_FILTER_FAIL = "DELIVERY_DATA_FILTER_FAIL";




export const FIIS_MODEL_REQUEST = "FIIS_MODEL_REQUEST";
export const FIIS_MODEL_SUCCESS = "FIIS_MODEL_SUCCESS";
export const FIIS_MODEL_FAIL = "FIIS_MODEL_FAIL";
export const FIIS_SECTORS_REQUEST = "FIIS_SECTORS_REQUEST";
export const FIIS_SECTORS_SUCCESS = "FIIS_SECTORS_SUCCESS";
export const FIIS_SECTORS_FAIL = "FIIS_SECTORS_FAIL";
export const FIIS_TOP_BOTTOM_REQUEST = "FIIS_TOP_BOTTOM_REQUEST";
export const FIIS_TOP_BOTTOM_SUCCESS = "FIIS_TOP_BOTTOM_SUCCESS";
export const FIIS_TOP_BOTTOM_FAIL = "FIIS_TOP_BOTTOM_FAIL";
export const DATE_MASTER_REQUEST = "DATE_MASTER_REQUEST";
export const DATE_MASTER_SUCCESS = "DATE_MASTER_SUCCESS";
export const DATE_MASTER_FAIL = "DATE_MASTER_FAIL";
export const RATIO_TOOLTIP_REQUEST = "RATIO_TOOLTIP_REQUEST";
export const RATIO_TOOLTIP_SUCCESS = "RATIO_TOOLTIP_SUCCESS";
export const RATIO_TOOLTIP_FAIL = "RATIO_TOOLTIP_FAIL";

export const FORENSIC_TAB_SHOW_HIDE_REQUEST = "FORENSIC_TAB_SHOW_HIDE_REQUEST";
export const FORENSIC_TAB_SHOW_HIDE_SUCCESS = "FORENSIC_TAB_SHOW_HIDE_SUCCESS";
export const FORENSIC_TAB_SHOW_HIDE_FAIL = "FORENSIC_TAB_SHOW_HIDE_FAIL";

export const FIIS_MODEL_COMMENT_REQUEST = "FIIS_MODEL_COMMENT_REQUEST";
export const FIIS_MODEL_COMMENT_SUCCESS = "FIIS_MODEL_COMMENT_SUCCESS";
export const FIIS_MODEL_COMMENT_FAIL = "FIIS_MODEL_COMMENT_FAIL";

