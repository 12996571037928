import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useDispatch, useSelector } from "react-redux";
import { TLEOD_OHLCRAction, TLPBAction } from "../../../redux/actions/VdrAction";
import { Box, Typography } from "@mui/material";

const PBChart = () => {
  
  const [OHLC, setOHLC] = useState([]);
  const [VOLUME, setVOLUME] = useState([]);
  const [Options, setOptions] = useState([]);
  const [IsChart, setIsChart] = useState(false);
    
  const dispatch = useDispatch();
  const { data: CompanyNoteData, loading: CompanyNoteLoading } = useSelector(
    (state) => state.singleCompanyNoteReducer
  );

  const {
    EOD_OHLC: { data: EOD_OHLCData, loading: EOD_OHLCLoading },
    PB: { data: PBData, loading: PBLoading },
  } = useSelector((state) => state.TrendlyneDataReducer);
  const [ChartData, setChartData] = useState([]);

  let compSlug = "INFY";
  if(CompanyNoteData?.Data && CompanyNoteData?.Data.length > 0){
    compSlug = (CompanyNoteData?.Data[0].BSEcode || CompanyNoteData?.Data[0].NSEcode);
  }

  useEffect(() => {
    if(CompanyNoteData?.Data && CompanyNoteData?.Data.length > 0){
    if(EOD_OHLCLoading){
        dispatch(TLEOD_OHLCRAction(compSlug));
    }

    if(!EOD_OHLCLoading){
        let a0 = [];
        let v0 = [];
        let a0000 = EOD_OHLCData.slice(0, (EOD_OHLCData.length-1)).sort(function (a, b) {
                      return new Date(a.date) - new Date(b.date);
                  })
          a0000.map((item, i0) => {
            const d = new Date(item?.date);
            let date1 = d.valueOf();
            let date12 = d;
            let a00 = [
                date1,
                item.close_price,
            ]
            a0.push(a00)
            
        })
        setOHLC(a0)
        
    }
    }
    

    // fetchData();
  }, [dispatch, EOD_OHLCLoading, CompanyNoteLoading]);

  useEffect(() => {


    if(CompanyNoteData?.Data && CompanyNoteData?.Data.length > 0){
      if(PBLoading){
          dispatch(TLPBAction(compSlug));
      }
    
      // console.log('CompanyNoteData?.Data.length <<<<< ', PBLoading)
    if(!PBLoading){
        let v0 = [];
        for(let i = PBData.length - 1; i >= 0; i--) {
              const valueAtIndex = PBData[i]
              const d = new Date(valueAtIndex[0]);
              let date1 = d.valueOf();
              let a00 = [
                date1,
                valueAtIndex[1]
              ]
          v0.push(a00)
        }
        setVOLUME(v0)
    }
}


    // fetchData();
  }, [dispatch, PBLoading, CompanyNoteLoading]);


  useEffect(() => {
    if(OHLC.length > 0 &&
      VOLUME.length > 0){
        let options = {
          chart: {
                
            zooming:{
              key:undefined,
              mouseWheel:{
              enabled:false
              }
              
              }

            },
        
          title: {
            text: "",
          },
    
    
          
          
          rangeSelector: {
                        
            buttons: [{
              type: 'month',
              count: 1,
              text: '1m',
              title: 'View 1 month'
          }, {
              type: 'month',
              count: 3,
              text: '3m',
              title: 'View 3 months'
          }, {
              type: 'month',
              count: 6,
              text: '6m',
              title: 'View 6 months'
          }, {
              type: 'ytd',
              text: 'YTD',
              title: 'View year to date'
          }, {
              type: 'year',
              count: 1,
              text: '1y',
              title: 'View 1 year'
          }, {
              type: 'year',
              count: 3,
              text: '3y',
              title: 'View 3 year'
          }, {
              type: 'year',
              count: 5,
              text: '5y',
              title: 'View 5 year'
          }, {
              type: 'all',
              text: 'All',
              title: 'View all'
          }],
                selected: 4
            },
    
          // tooltip: {
          //   pointFormat:
          //     '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.cumulativeSum})<br/>',
          //   changeDecimals: 2,
          //   valueDecimals: 2,
          // },
    
          // xAxis: {
          //   minRange: 3 * 24 * 36e5,
          //   max: Date.UTC(2021, 0, 6),
          // },
          // yAxis: [{
          //   visible: true,
          //   tickPosition: "inside",
          //   offset: 0,
          //   title: {
          //     text: "# tasks",
          //     align: "high",
          //     textAlign: "left",
          //     rotation: 0,
          //     offset: 0,
          //     margin: 0,
          //     y: -5,
          //     x: -7
          //   },
          //   labels: {
          //     align: "right",
          //     y: -5
          //   }
          // }, {
          //   opposite: false,
          //   tickPosition: "inside",
          //   offset: 0,
          //   type: "linear",
          //   title: {
          //     text: "# completed",
          //     textAlign: "right",
              
          //     y: 12,
          //     x: -7
          //   },
          //   labels: {
          //     align: "right",
          //     y: 12
          //   }
          // }],

          yAxis: [{ // left y axis
            opposite:false,
            title: {
                text: "Price"
            },
            // lineWidth: 2,
            
        }, { // right y axis
          opposite:true,

            title: {
                text: 'PB'
            },
            // lineWidth: 2,
        }],
        plotOptions: {
          series: {
              lineWidth: 1.5,
              // lineColor:'#006aff',
              // color:'#817e88', // Volume Color/ Bar Color
          }
      },
          series: [
            {
              data: OHLC,
              name: "Price",
              type: "spline",
              id: "Price_001",
            },
            {
              data: VOLUME,
              name: "PB",
              type: "spline",
              id: "PB_001",
              yAxis: 1,
            },
          ],
        };
        setOptions(options)
        setIsChart(true);
      }

    
  }, [OHLC, VOLUME]);
  return (
    <>
    {
      !IsChart && 
      <Box sx={{ 
        width:'100%',
        textAlign:'center'
       }}>
          <Typography>
            Fetching Data...
          </Typography>
      </Box>
    }
    {
      IsChart &&
        <HighchartsReact
          containerProps={{ style: { height: "100%" } }}
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={Options}
        />
    }
    </>
  );
};

export default PBChart;
