import React, { useEffect, useRef, useState } from 'react'
import { 
    AppBar, 
    Box, 
    Button, 
    CircularProgress, 
    Divider, 
    Grid, 
    IconButton, 
    List, 
    ListItem, 
    ListItemButton, 
    ListItemIcon, 
    ListItemText, 
    Menu, 
    MenuItem, 
    Tab, 
    Tabs, 
    TextField, 
    Typography
 } from '@mui/material';
import { Link } from 'react-router-dom';

import { AiOutlineFilePdf } from "react-icons/ai";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { dateFormat } from '../../constants/helpers';

import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import MailIcon from '@mui/icons-material/Mail';

const ContainerView = (props) => {

    const { toggleDrawer, showForensic, setOpenForm, itemData, title, companyData, deleteFunction, DataRoomDocDetail, setDataRoomDocDetail } = props;


    // console.log(title, ' <<< itemData >>> ', itemData)
    const shotButton = useRef();

    const [arrowIcon, setArrowIcon] = useState(<RiArrowDownSLine fontSize={25} color="#f37b22" />);
    const [ToggleData, setToggleData] = useState(true);
    const [DivHeight, setDivHeight] = useState(300);
    const [ListData, setListData] = useState(itemData);
    const [name, setName] = useState("");
    const [ActiveBtn, setActiveBtn] = useState(0);




    const dispatch = useDispatch(); 
    const { data: UploadDocumentData, loading: UploadDocumentLoading } = useSelector(state => state.UploadDocumentReducer);


    useEffect(() => {
        setListData(itemData);
    }, [itemData])

    useEffect(() => {

    }, [name])

    useEffect(() => {

    }, [ActiveBtn])

    const [KeyUp, setKeyUp] = useState(false);

    const btnCategory = [
        "All Files",
        "Initial Coverage",
        "Quarterly Update",
        "Brief Notes",
        "Others",
    ];


    const sortData = (itemData, type) => {
        let sData;



        if (SortType === 'name') {
            if (ActiveBtn) {
                if (ToggleData) {
                    sData = ListData.sort((a, b) => a.fileName.localeCompare(b.fileName));
                } else {
                    sData = ListData.sort((a, b) => b.fileName.localeCompare(a.fileName));
                }
            } else {
                if (ToggleData) {
                    sData = itemData.sort((a, b) => a.fileName.localeCompare(b.fileName));
                } else {
                    sData = itemData.sort((a, b) => b.fileName.localeCompare(a.fileName));
                }
            }
        } else {
            if (ActiveBtn) {
                if (ToggleData) {
                    sData = ListData.sort((a, b) => {
                        var a1 = moment(a.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
                        var b1 = moment(b.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
                        var dd = new Date(a1) - new Date(b1);
                        return dd;
                    });
                } else {
                    sData = ListData.sort((a, b) => {
                        var a1 = moment(a.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
                        var b1 = moment(b.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
                        var dd = new Date(b1) - new Date(a1);
                        return dd;
                    });
                }

            } else {
                if (ToggleData) {
                    sData = itemData.sort((a, b) => {
                        var a1 = moment(a.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
                        var b1 = moment(b.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
                        var dd = new Date(a1) - new Date(b1);
                        return dd;
                    });
                } else {
                    sData = itemData.sort((a, b) => {
                        var a1 = moment(a.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
                        var b1 = moment(b.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
                        var dd = new Date(b1) - new Date(a1);
                        return dd;
                    });
                }
            }
        }

        setToggleData(!ToggleData);
    }

    const onUpdateHeight = () => {
        if (DivHeight === 300) {
            setArrowIcon(<RiArrowUpSLine fontSize={25} color="#f37b22" />)
            setDivHeight('auto')
        } else {
            setArrowIcon(<RiArrowDownSLine fontSize={25} color="#f37b22" />)
            setDivHeight(300)
        }

    }

    const handleChange = (event, itemData) => {
        // console.log(itemData);
        let arrNew = [];
        itemData.forEach(function (a) {
            // console.log(btnCategory[ActiveBtn]);
            var fName = a.fileName.toLowerCase();
            var nVal = event.target.value.toLowerCase();
            if (fName.indexOf(nVal) > -1) {
                if (btnCategory[ActiveBtn] === a.DocumentType) {
                    arrNew.push(a)
                }
                if (ActiveBtn === 0) {
                    arrNew.push(a)
                }
            }
        });
        setListData(arrNew);
        setName(event.target.value);
    };


    const filterByCat = (event, activeStatus, filterFrom) => {
        // console.warn(activeStatus)
        setActiveBtn(activeStatus);
        let lstData = itemData.filter((item) => item.DocumentType == filterFrom);
        if (activeStatus === 0) {
            setListData(itemData);
        } else {
            setListData(lstData);
        }
    };


    const listContainer = useRef();
    const searchContainer = useRef();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [SortType, setSortType] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    
    return (
        <>
            <Box className="box-container" sx={{
                paddingBottom: 0,
            }}>
                <Box className="header-section">
                    <Box
                        display={'flex'}
                        justifyContent="space-between"
                        alignItems={'center'}
                        sx={{
                            paddingBottom: '0.5rem'
                        }}
                    >
                        <Typography fontSize={16} variant="h6" style={{}}>{title || 'Final Output'}</Typography>
                        <>
                            <IconButton ref={shotButton} size="small"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}

                            // onClick={() => sortData(itemData, (title || 'Final Output'))}
                            >
                                {
                                    ToggleData
                                        ?
                                        <BiSortDown />
                                        :
                                        <BiSortUp />
                                }
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => [sortData(itemData, (title || 'Final Output')), handleClose(), setSortType('date')]}>Sort by Date</MenuItem>
                                <MenuItem onClick={() => [sortData(itemData, (title || 'Final Output')), handleClose(), setSortType('name')]}>Sort by Name</MenuItem>
                            </Menu>
                        </>
                    </Box>
                    <Divider />
                </Box>

                <Box className="list-container"
                    sx={{
                        minHeight: 300,
                        height: DivHeight,
                    }}
                >
                    <Box ref={searchContainer}>
                        {
                            !title ?
                                <>
                                    <Box
                                        sx={{
                                            marginTop: 1,
                                        }}
                                    >
                                        {
                                            btnCategory.map((item, index) => {
                                                return (
                                                    <>
                                                        <Button key={index} size="small"
                                                            className={`${ActiveBtn === index ? 'btn_active' : null}`}
                                                            variant='outlined'
                                                            onClick={(e) => filterByCat(e, index, item)}
                                                            sx={{
                                                                marginLeft: 0.5,
                                                                marginTop: 0.5,
                                                                fontSize:'0.6rem',
                                                                textTransform:"capitalize"
                                                            }} title={item} >
                                                            {item}
                                                        </Button>
                                                    </>
                                                )
                                            })
                                        }
                        
                                    </Box>
                                </>
                                :
                                null
                        }

                        <Box
                            sx={{
                                marginTop: 2,
                            }}
                        >
                            <TextField
                                onChange={(e) => handleChange(e, (itemData && itemData))}
                                sx={{
                                    width: '99%',
                                    padding: 0
                                }}
                                size="small"
                                label="Search Document"
                                id="fullWidth"
                                value={name}
                                InputProps={{
                                    endAdornment: <SearchIcon sx={{ color: 'gray' }} />,
                                }}
                                InputLabelProps={{ style: { fontSize: 12, padding: 0, margin: 0 } }}
                                inputProps={{
                                    style: { padding: '0.4rem', fontSize: 15 }
                                }}
                            />
                        </Box>
                    </Box>

                     {
                        UploadDocumentLoading == 'aa'
                        ?
                        <>
                            <Box sx={{ marginTop:2 }}>
                            <CircularProgress color="inherit" />
                            </Box>
                        </>
                        :
                        <>
                        <List ref={listContainer}>
                        {
                            ListData && ListData.length ?
                                ListData.map((item, index) => {
                                    let fileLink = '';
                                    let fileExtension = '';
                                    fileLink = item.link;
                                    fileExtension = fileLink.split('.').pop();
                                    if (fileExtension.toLowerCase() === 'pdf') {
                                        fileLink = `/MyPdfViewer`;
                                    }
                                    
                                    item = { ...item };
                                    item.title = companyData?.company_name;
                                    {/* console.table(item); */}
                                    
                                    return (
                                        <>
                                            <ListItem disablePadding key={index} className='docContainer'
                                                
                                                secondaryAction={
                                                    showForensic && (
                                                            <>
                                                            <IconButton edge="end" aria-label="delete" onClick={(e)=>{
                                                            toggleDrawer('bottom', true)(e);
                                                            setOpenForm('SendNotification');
                                                            setDataRoomDocDetail({
                                                                ...DataRoomDocDetail,
                                                                docId:item?.FileID,
                                                                dateTime:item?.Date,
                                                                docName:item?.fileName, 
                                                            });
                                                        }} >
                                                            <MailIcon />
                                                        </IconButton>
                                                        <IconButton edge="end" aria-label="delete" onClick={(e)=>deleteFunction(item)} >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                            </>

                                                        )
                                                }
                                            >
                                                {
                                                    fileExtension.toLowerCase() === 'pdf' ?
                                                        <ListItemButton
                                                            sx={{
                                                                // padding: '0 100px 0 0',
                                                                // paddingRight: 100,
                                                                margin: '0',
                                                            }}
                                                            component={Link} target="_blank" to={fileLink} onClick={(e) => {
                                                                console.table(item)
                                                                localStorage.setItem('PDFViewed', window.btoa(item.link));
                                                                localStorage.setItem('itemData1', JSON.stringify(item));
                                                                localStorage.setItem('file_type', title || 'Final Output');
                                                            }}>
                                                            <ListItemIcon sx={{
                                                                padding: '0px',
                                                                minWidth: '2.5rem'
                                                            }}
                                                            >
                                                                <AiOutlineFilePdf size={30} /> 
                                                            </ListItemIcon>
                                                            <ListItemText className='data-room-doc' primary={
                                                                <Typography variant="subtitle2" className='docItem' sx={{
                                                                    color: '#f79044',
                                                                    fontSize: { xs: 10, sm: 10, md: 10, lg: 10, xl: 14 },
                                                                }}>{item.fileName}</Typography>
                                                            } secondary={
                                                                <>
                                                                    <Box display={'flex'}>
                                                                        <Typography fontSize={9} style={{}}>{dateFormat(item.Date, 'DD/MMM/YYYY')}</Typography>
                                                                        {
                                                                            !title ?
                                                                                <>
                                                                                    {
                                                                                        !ActiveBtn ?
                                                                                            <>
                                                                                                <Typography fontSize={9} fontWeight="bold" sx={{ marginLeft: 0.5 }}>{item.DocumentType}</Typography>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                    </Box>
                                                                </>
                                                            } />
                                                            
                                                        </ListItemButton>
                                                        :
                                                        <ListItemButton
                                                            sx={{
                                                                padding: '0',
                                                                margin: '0',
                                                            }}
                                                            rel="noopener noreferrer"
                                                            component={'a'} target="_blank" href={fileLink} onClick={(e) => {
                                                                localStorage.setItem('PDFViewed', window.btoa(item.link));
                                                                localStorage.setItem('file_type', title || 'Final Output');
                                                            }}>
                                                            <ListItemIcon sx={{
                                                                padding: '0px',
                                                                minWidth: '2.5rem'
                                                            }}
                                                            >
                                                                <AiOutlineFilePdf size={30} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={
                                                                <Typography variant="subtitle2" className='docItem' sx={{ color: '#f79044', fontSize: { xs: 10, sm: 10, md: 10, lg: 10, xl: 14 } }}>{item.fileName}</Typography>
                                                            } secondary={
                                                                <>
                                                                    <Box display={'flex'}>
                                                                        <Typography fontSize={9} style={{}}>{dateFormat(item.Date, 'DD/MMM/YYYY')}</Typography>
                                                                        {
                                                                            !title ?
                                                                                <>
                                                                                    {
                                                                                        !ActiveBtn ?
                                                                                            <>
                                                                                                <Typography fontSize={9} fontWeight="bold" sx={{ marginLeft: 0.5 }}>{item.DocumentType}</Typography>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                    </Box>
                                                                </>
                                                            } />
                                                        </ListItemButton>
                                                }
                                            </ListItem>
                                            {
                                                itemData.length - 1 !== index ?
                                                    <Divider />
                                                    :
                                                    null
                                            }
                                        </>
                                    )
                                })
                                :
                                <>
                                    <Typography variant='subtitle1' fontSize={12} fontWeight="500">
                                        {!name ? 'To Be Updated' : 'Data not Found!'}
                                    </Typography>
                                </>
                        }
                    </List>
                        </>
                     }
                    
                </Box>

                <Box className="footer-section"
                    sx={{

                    }}
                >
                    <Divider />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <IconButton size="small" onClick={() => onUpdateHeight()}>
                            {arrowIcon}
                        </IconButton>
                    </div>
                </Box>

            </Box>
        </>
    )
}

export default ContainerView