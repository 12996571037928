import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Box, Typography } from '@mui/material';


const SelectCompanyIndustrySector = (props) => {

    const {
        sectors,
        industry,
        sectorMasterArr,
        filterArr,
        industryMasterArr,
        sectorsKey,
        industryKey,
        resetButton,
        sectorsIds,
        setSectors,
        setIndustry,
        setSectorMasterArr,
        setFilterArr,
        setIndustryMasterArr,
        setSectorsKey,
        setIndustryKey,
        setResetButton,
        setSectorsIds,
        companyMasterLoading,
        sectorMasterLoading,
        industryMasterLoading,
        companyMaster,
        sectorMaster,
        industryMaster,
    } = props;


    useEffect(() => {
        console.log(sectorMaster);
        selectSectors();
        selectIndustry();
    }, [sectorMaster, industryMaster]);


    useEffect(() => {
        if (sectorsIds.length === 0) {
            selectIndustry();
        } else {
            filterSelectIndustry();
        }
    }, [sectorsIds])



    const selectIndustry = () => {

        if (industryMaster.length > 0) {
            var data1 = [];
            industryMaster.map((item) => {
                var d1 = { title: item.Industry, value: item.IndustryID };
                data1.push(d1);
            })
            setIndustryMasterArr(data1);
        }
    }

    const selectSectors = () => {
        if (sectorMaster.length > 0) {
            var data1 = [];
            sectorMaster.map((item) => {
                var d1 = { title: item.Sector, value: item.sectorID };
                data1.push(d1);
            })
            setSectorMasterArr(data1);
        }
    }

    const filterSelectIndustry = () => {
        let industryMasterFilter = [];
        for (var i = 0; i < sectorsIds.length; i++) {
            var industryMasterFilter1 = industryMaster.filter(industry => (sectorsIds[i] == industry.sectorID));
            Array.prototype.push.apply(industryMasterFilter, industryMasterFilter1);
        }
        var data1 = [];
        if (industryMasterFilter.length > 0) {
            industryMasterFilter.map((item) => {
                var d1 = { title: item.Industry, value: item.IndustryID };
                data1.push(d1);
            })
        }
        setIndustryMasterArr(data1);
    }

    return (
        <>

            <Box
                display="flex"
                flexDirection="row"
                alignContent={'center'}
                justifyContent={'space-between'}
            >
                <div style={{width:'100%', margin:'0 0.2rem'}}>
                    
                        <Typography  className='fontSize-07rem'>Sectors</Typography>
                    <Autocomplete
                        multiple
                        limitTags={2}
                        id="multiple-limit-tags"
                        options={sectorMasterArr}
                        getOptionLabel={(option) => option.title}
                        defaultValue={sectors}
                        size="small"
                        renderInput={(params) => (
                            <TextField className='fontSize-12px' {...params} size="small" placeholder="Select Sectors" />
                        )}
                        fullWidth
                        // sx={{ marginX: 1 }}
                        onChange={(event, newInputValue) => {
                            var val1 = [];
                            for (var a = 0; a < newInputValue.length; a++) {
                                val1.push(newInputValue[a].value);
                            }
                            // setInputs({ ...inputs, ['Sectors']: val1 });
                            setSectors(newInputValue);
                            setSectorsIds(val1);
                        }}


                    />
                </div>
                <div style={{width:'100%', margin:'0 0.2rem'}}>
                    <Typography  className='fontSize-07rem'>Industry</Typography>
                    <Autocomplete
                        multiple
                        limitTags={2}
                        id="multiple-limit-tags"
                        options={industryMasterArr}
                        defaultValue={industry}
                        getOptionLabel={(option) => option.title}
                        size="small"
                        renderInput={(params) => (
                            <TextField className='fontSize-12px' {...params} size="small" placeholder="Select Industry" />
                        )}
                        onChange={(event, newInputValue) => {
                            var val1 = [];
                            for (var a = 0; a < newInputValue.length; a++) {
                                val1.push(newInputValue[a].value);
                            }
                            //   setInputs({ ...inputs, ['Industry']: val1 });
                            setIndustry(newInputValue)

                        }}
                        fullWidth
                    />
                </div>
            </Box>


        </>
    )
}

export default SelectCompanyIndustrySector