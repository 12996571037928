import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SingleCompanyValuationAction } from "../../../redux/actions/userAction";
import InCr from "./InCr";
import { BsFillBarChartFill } from "react-icons/bs";
import CrtModal from "../amchart/CrtModal";
import HistoricalTrend from "./HistoricalTrend/Index";

const ValuationComp = (props) => {
  const { companyId, title, isCD } = props;
  const { data: ValuationData, loading: ValuationLoading } = useSelector(state => state.SingleCompanyValuationReducer);


  const [Valuation, setValuation] = useState(null);
  const [ValuationTableData, setValuationTableData] = useState([]);
  
  const [TableColumns, setTableColumns] = useState([]);
  const [TableBody, setTableBody] = useState([]);

  const dispatch = useDispatch();

  const ThemeColorData = useSelector(state=> state.ThemeColorReducer);

  useEffect(()=>{
      
  }, [ThemeColorData])
  useEffect(() => {
    
    if (ValuationLoading) {
      // var prams = [{ "CompanyID": companyId, "userid": "22" }];
      var prams = { "CompanyID": companyId, "userid": "22" };
      dispatch(SingleCompanyValuationAction(prams, 'new'));
    }
    if (!ValuationLoading) {
      setValuation(ValuationData);
      // alert(valuationData);
      const { Data, Header } = ValuationData;
      const newTbData = {
        Header: Header && Header[0],
        Financials: Data && Data[0]?.Financials,
        Valuations: Data && Data[0].ValuationValues,
      };
      // console.log(newTbData.Header)
      if(Header && Header.length){
        let cols = []
        let a0 = Header[0];
        Object.keys(a0).map((item, i)=>{
          if(i > 0){
            // console.log(a0[item])
            let dd = {
                "id": item,
                "width": "",
                "align": "",
                "label": a0[item]
              }
            cols.push(dd);
          }
        })
        setTableColumns(cols)
        // console.log(cols)
      }
      if(Data && Data.length){
        let dataA = []
        // let a00 = Data[0]?.ValuationValues;
        let a00 = Data[0]?.Valuations || Data[0]?.ValuationValues;    
        // console.log('Data[0] >>?> ', Data[0]);
        Object.keys(a00).length > 0 && Object.keys(a00).map((item, i)=>{
          if(i > 0){
            let cData = a00[item];
            let childObj = {}
            Object.keys(cData).map((item0, i0)=>{
              if(i0 > 0){
                childObj = {...childObj, [`Column${i0}`]: cData[item0]}
              }
            })
            dataA.push(childObj)
          }
        })
        console.log(dataA)
        setTableBody(dataA)
      }
      // console.log(Data[0].ValuationValues)


      setValuationTableData(newTbData);
       
    }

  }, [dispatch, ValuationLoading])
  
  const [
    ActiveSecondaryBtn,
    setActiveSecondaryBtn
  ] = useState(1);

  const [IsOpen, setIsOpen] = useState(false);
  const [CrtRow, setCrtRow] = useState(false);
  const showChart = (row)=>{
    // console.log(row);
    
    let newRow = {};
    Object.keys(row).map((item)=>{
        let a0 = {
            bg_color: "#ccdbf5",
            isBold: true,
            isItalic: false,
            label: row[item],
            text_color: "#000",
          
        }
        newRow = {...newRow, [item]: a0}
        
        // newRow.push(a0)
    })
    dispatch({
      type:"Columns_Rows",
      payload:{columns:TableColumns, rows:newRow}
    })
    
    // setIsOpen(true);
    // setCrtRow(newRow);
  }
   
  let primaryBtnArr = [

    {
      "id":1,
      // "label":"Brief",
      "label":"Table",
      "component": ""
    },
    {
      "id":2,
      // "label":"Historical",
      "label":"Charts",
      "component": ""
    },

  ]

  return (
    <>
    
    
    <CrtModal
          isOpen={IsOpen}
          setIsOpen={setIsOpen}
          row={CrtRow}
          columns={TableColumns}
        />
      {/* <button className='btn primary-btn'>Download Image</button> */}
      <Box className="valuation-box">
        <div className="box-head-sec">
          
        <div style={{ 
            display:'flex',
            width:'100%',
            justifyContent:'space-between',
            alignItems:'center',
           }}>
            <div style={{ 
            display:'flex',
            alignItems:'end',
            gap:'.5rem'
           }}>
              {
              isCD !== true && (
                <>
                  <h3>{title}</h3>
                  <InCr />
                </>
                )
              }
            </div>
            <div>
              
            <h6 style={{ textAlign: 'center' }}>Cons Priority {
              isCD === true && ( <>  (In Cr.)</> )}</h6>
            <div className="btn-group">

              
            {
              primaryBtnArr.map((item, i)=>{
                return (
                  <>
                    <button onClick={()=>{
                        setActiveSecondaryBtn(item.id);
                      }}  key={i} className={`btn ${ActiveSecondaryBtn === item.id ? "active" : ""}`}>{item?.label}</button>
                    </>
                )
              })
            }
  </div>
            </div>

          </div>
          <div>
            {/* <button className="btn primary-btn-outline">Edit</button>
            <button className="btn primary-btn">Download Image</button> */}
          </div>
        </div>
        {
          ActiveSecondaryBtn === 2 ?
          <>
            <div className="box-body-sec">
              <HistoricalTrend   themeColor={ThemeColorData.chartColor}  companyId={companyId} />
            </div>
          </>
          :
          <>
          <div className="box-body-sec">
            
            <table>
              <thead>
                <tr>
                  {
                    TableColumns.map((item,i)=>{
                      return (
                        <td key={i}>{item.label}</td>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                    TableBody.map((row,i)=>{
                      return (
                        <tr>
                          {
                            TableColumns.map((item,i)=>{
                              let value = row[item.id]
                              return (
                                <td key={i}>
                                  {/* {value} */}
                                  <div style={{ 
                                          display:'flex',
                                          width:'100%',
                                          justifyContent: (i === 0 ? 'space-between' : 'flex-end'),
                                          alignItems:'center'
                                         }}>
                                          {value || ""}  
                                          <span>
                                            {
                                              i === 0 && 
                                              <IconButton onClick={(e)=>showChart(row)} sx={{ padding:0 }}>
                                                <BsFillBarChartFill size={16} />
                                              </IconButton>
                                            }
                                          </span>
                                        </div>
  
                                </td>
                              )
                            })
                          }
                        </tr>
                      )
                    })
                  }
                
              </tbody>
            </table>
            {/* <table>
              <thead>
                <tr>
                  {
                    tablColumns.map((item,i)=>{
                      return (
                        <td key={i}>{item.label}</td>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                    tablData.map((row,i)=>{
                      return (
                        <tr>
                          {
                            tablColumns.map((item,i)=>{
                              let value = row[item.id]
                              return (
                                <td key={i}>{value}</td>
                              )
                            })
                          }
                        </tr>
                      )
                    })
                  }
                
              </tbody>
            </table> */}
          </div>
          </>
        }
      </Box>
    </>
  );
};

export default ValuationComp;
