import moment from 'moment';
import Moment from 'moment'


export const calenderArr = [
    {
        "UserId": 1,
        "FromDate": Moment().format('MM/DD/YYYY'),
        "ToDate": Moment().add(7, 'days').format('MM/DD/YYYY'),
        "Sector": [],
        "Industry": [],
        "Market_Cap": ["", ""]
    }
];
export const defaultCompanyArr = [
    {
        "$id": "",
        "type": "Share Price (TTM)",
        "sub_type": [
            {
                "Market_Cap": [
                    "",
                    ""
                ],
                "LTP": "",
                "TTM_P_B": "",
                "TTM_P_E": "",
                "ROCE": "",
                "TTMSalesAbs": "",
                "TTMPATAbs": ""
            }
        ]
    },
    {
        "type": "Result Data",
        "sub_type": [
            {
                "$id": "5",
                "Sales_YOY": "",
                "Sales_QOQ": "",
                "EBDITA_YOY": "",
                "EBDITA_QOQ": "",
                "PAT_YOY": "",
                "PAT_QOQ": "",
                "GP_YOY": "",
                "GP_QOQ": ""
            }
        ]
    },
    {
        "type": "Turn Around",
        "sub_type": [
            {
                "EBDITA_TO": "",
                "PAT_TO": "",
                "Gross_Margin": "",
                "Gross_Profit": ""
            }
        ]
    },
    {
        "type": "More Filters",
        "sub_type": [
            {
                "Sector": [

                ],
                "Industry": [

                ],
                "Company": [

                ]
            }
        ]
    },
    {
        "type": "Date",
        "sub_type": [
            {
                "FromDate": moment().add('-1', 'days').format('MM/DD/YYYY'),
                "ToDate": moment().format('MM/DD/YYYY') 
            }
        ]
    },
    {
        "type": "Color",
        "sub_type": [
            {
                "ColorCode": ""
            }
        ]
    }
];
export const defaultValuationArr = [{ "type": "Classification", "sub_type": [{ "Sector": null, "Industry": null, "Company": null }] }, { "type": "Share Price (TTM)", "sub_type": [{ "Market_Cap": null, "LTP": null, "Away52wkHigh": null, "Away52wkLow": null, "TTM_PE": null, "TTM_PBV": null }] }, { "type": "Historical", "sub_type": [{ "Diff_Bw_5yrsAvg_PBV": null, "Diff_Bw_10yrsAvg_PBV": null, "Diff_Bw_5yrsAvg_PE": null, "Diff_Bw_10yrsAvg_PE": null, "Avg_Sales_3yrs": null, "Avg_Sales_5yrs": null, "Avg_Sales_10yrs": null, "Avg_PAT_3yrs": null, "Avg_PAT_5yrs": null, "Avg_PAT_10yrs": null }] }, { "type": "Balance Sheet", "sub_type": [{ "Total_DebtEquity": null, "GrossBlockAdditionin_5yrs": null, "TotalDebtIncreasein_5yrs": null, "ROCE": null, "NetCash": null, "CFO_EBIDTA": null, "Net_Cash_Mcap": null }] }, { "type": "ShareHolding Pattern", "sub_type": [{ "Promoter_Holding": null, "Pledge": null }] }];
export const defaultDeliveryDataArr = [
    {
      "type": "Classification",
      "sub_type": [
        {
          "Sector": null,
          "Industry": null,
          "Company": null,
          "UserID": 1,
          "F_O": false,
          "chkPortfolio": false
        }
      ]
    },
    {
      "type": "Deliverable Volume",
      "sub_type": [
        {
          "LtpVS200DMA": null,
          "Market_Cap": [
            "",
            ""
          ],
          "DelQty": null,
          "DelPer7D": null,
          "Delvalue": null
        }
      ]
    },
    {
      "type": "Time Interval",
      "sub_type": [
        {
          "DelDays": ""
        }
      ]
    },
    {
      "type": "Daily Moving Average",
      "sub_type": [
        {
          "chkbox200DMA": false,
          "chkbox50DMA": false
        }
      ]
    }
  ];

let userDataDetail = localStorage.getItem('user');
if (userDataDetail) {
    userDataDetail = JSON.parse(userDataDetail);
}

export const UserData = userDataDetail;  //{"$id":"1","response_code":200,"status":1,"msg":"success","UserNAme":"admin","UserID":"1"}

export const UserId = UserData?.UserID || 1;
export const UserName = UserData?.UserNAme || 'Demo'; 




export const historicalTrendChooseField = () => {
  return [
      {
          "label": "Share Price",
          "columns": [
              {
                  "FH_Market_Capitalization": "Market Cap (cr.)",
                  "title": "Market Cap (cr.)",
                  "checked_status": 1
              },
              {
                  "FR_DividendYield": "Dividend Yield (%)",
                  "title": "Dividend Yield (%)",
                  "checked_status": 0
              },
              {
                  "FR_Adjusted_PE": "PE (x)",
                  "title": "PE (x)",
                  "checked_status": 0
              },
              {
                  "FR_Price_BY_BookValue": "P/BV (x)",
                  "title": "P/BV (x)",
                  "checked_status": 0
              },
              {
                  "FR_TotalDebt_BY_Equity": "Total Debt/Equity (x)",
                  "title": "Total Debt/Equity (x)",
                  "checked_status": 0
              },
              {
                  "FR_InterestCover": "Interest Cover (x)",
                  "title": "Interest Cover (x)",
                  "checked_status": 0
              },
              {
                  "FR_TotalDebt_BY_Mcap": "Total Debt/Mcap (x)",
                  "title": "Total Debt/Mcap (x)",
                  "checked_status": 0
              }
          ]
      },
      {
          "label": "Balance Sheet",
          "columns": [
              {
                  "Receivable_days": "Receivable Days",
                  "title": "Receivable Days",
                  "checked_status": 0
              },
              {
                  "FR_InventoryDays": "Inventory Days",
                  "title": "Inventory Days",
                  "checked_status": 0
              },
              {
                  "FR_Payabledays": "Payable Days",
                  "title": "Payable Days",
                  "checked_status": 0
              },
              {
                  "CCC_Noofdays": "CCC_No of Days",
                  "title": "CCC_No of Days",
                  "checked_status": 0
              },
              {
                  "Net_Casho": "Net Cash (cr.)",
                  "title": "Net Cash (cr.)",
                  "checked_status": 0
              },
              {
                  "BS_GrossBlock": "Gross Block (cr.)",
                  "title": "Gross Block (cr.)",
                  "checked_status": 0
              },
              {
                  "BS_Capital_WorkinProgress": "CWIP (cr.)",
                  "title": "CWIP (cr.)",
                  "checked_status": 0
              },
              {
                  "BS_Cash_andBank": "Cash & Bank (cr.)",
                  "title": "Cash & Bank (cr.)",
                  "checked_status": 0
              },
              {
                  "FR_Asset_Turnover": "Asset Turnover (x)",
                  "title": "Asset Turnover (x)",
                  "checked_status": 0
              },

          ]
      },
      {
          "label": "P&L Statement",
          "columns": [
              {
                  "PL_NetSales": "Net Sales (cr.)",
                  "title": "Net Sales (cr.)",
                  "checked_status": 1
              },
              {
                  "PL_Consolidated_PAT": "Cons PAT (cr.)",
                  "title": "Cons PAT (cr.)",
                  "checked_status": 1
              },
              {
                  "EBIDTA_Margin": "Gross Profit (cr.)",
                  "title": "Gross Profit (cr.)",
                  "checked_status": 1
              },
              {
                  "EBIDTA_Margin": "Gross Profit Margin (%)",
                  "title": "Gross Profit Margin (%)",
                  "checked_status": 1
              },
              {
                  "EBIDTA": "EBIDTA (cr.)",
                  "title": "EBIDTA (cr.)",
                  "checked_status": 1
              },
              {
                  "EBIDTA_Margin": "EBIDTA Margin (%)",
                  "title": "EBIDTA Margin (%)",
                  "checked_status": 0
              },
              {
                  "PAT_Margin": "PAT Margin (%)",
                  "title": "PAT Margin (%)",
                  "checked_status": 0
              }

          ]
      },
      {
          "label": "Cash Flow",
          "columns": [
              {
                  "FH_CashFlowfrom_Operations": "CFO (cr.)",
                  "title": "CFO (cr.)",
                  "checked_status": 0
              },
              {
                  "FR_ROCE": "ROCE (%)",
                  "title": "ROCE (%)",
                  "checked_status": 0
              }

          ]
      },
      {
          "label": "Valuation Ratio",
          "columns": [
              {
                  "FR_EV_BY_NetSales": "EV/Net Sales (x)",
                  "title": "EV/Net Sales (x)",
                  "checked_status": 0
              },
              {
                  "FR_EV_BY_EBITDA": "EV/EBIDTA (x)",
                  "title": "EV/EBIDTA (x)",
                  "checked_status": 0
              },
              {
                  "FR_Mcap_BY_Sales": "Mcap/Sales (x)",
                  "title": "Mcap/Sales (x)",
                  "checked_status": 0
              },
              {
                  "CFO_BY_EBIDTA": "CFO/EBIDTA (x)",
                  "title": "CFO/EBIDTA (x)",
                  "checked_status": 0
              },
              {
                  "Net_Cash_BY_Mcap": "Net Cash/Mcap (%)",
                  "title": "Net Cash/Mcap (%)",
                  "checked_status": 0
              }

          ]
      }

  ]
}


export const segmentAddJson = {
    "segment_label": "",
    "segment_id": "",
    "segment_position": "",
    "status": true,
    "is_pin": false,
    "Input": 3
}

export const repositoryTestParams = {
    "Date" :[],
    "sectorId":"",
    "IndustryId":"",
    "CompanyId":"",
    "BrokerId":"",
    "ReportType":"",
    "page":1,
    "order":"asc",
    "order_column": "CompanyName",
    "numPerPage":1000,
    "search":""
  }