import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TLEOD_OHLCRAction } from "../../../redux/actions/VdrAction";

import indicatorsAll from "highcharts/indicators/indicators-all";
import annotationsAdvanced from "highcharts/modules/annotations-advanced";
import priceIndicator from "highcharts/modules/price-indicator";
import fullScreen from "highcharts/modules/full-screen";
import stockTools from "highcharts/modules/stock-tools";
import { Box } from "@mui/material";
import PriceChart from "./PriceChart";
import PEChart from "./PEChart";
import EPSChart from "./EPSChart";
import PBChart from "./PBChart";



indicatorsAll(Highcharts);
annotationsAdvanced(Highcharts);
priceIndicator(Highcharts);
fullScreen(Highcharts);
stockTools(Highcharts);

const HighchartsStock = () => {
  const [Options, setOptions] = useState([]);
  const [IsChart, setIsChart] = useState(false);

  // const options = {
  //     title: {
  //       text: 'My stock chart'
  //     },
  //     series: [{
  //       data: [1, 2, 3]
  //     }]
  //   }
  



  let chartBtn = [
    {
      "id":1,
      "label":"Price",
      "component":<PriceChart />,
      "value":"",
    },
    {
      "id":2,
      "label":"PE",
      "component":<PEChart />,
      "value":"",
    },
    // {
    //   "id":3,
    //   "label":"EPS",
    //   "component":<EPSChart />,
    //   "value":"",
    // },
    {
      "id":3,
      "label":"PB",
      "component":<PBChart />,
      "value":"",
    },
  ]
  
  const [ActiveButton, setActiveButton] = useState(1)

  return (
    <>
    
    <Box  className="box-head-sec" >
      <div style={{
                  display:'flex',
                  gap:'10px',
                  justifyContent:'center',
                  marginTop:'1rem'
                }} className="">
                  {
                    chartBtn.map((item, i)=>{
                      return (
                        <button onClick={()=>{
                          setActiveButton(item.id)
                        }
                        } key={i} className={`btn ${item.id === ActiveButton ? "primary-btn" : "secondary-btn-outline"}`}>{item?.label}</button>
                      )
                    })
                  }
                  
                </div>
      </Box>
      <div style={{ 
        height: '80vh'
       }}>
          {chartBtn[(ActiveButton-1)].component}
       </div>
    </>
  );
};

export default HighchartsStock;
