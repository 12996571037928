import React, { useEffect, useState } from "react";
import { SingleCompanyGrid } from "../components";
// import { Carousel } from '@trendyol-js/react-carousel';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button, Divider, Tab, Tabs, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { vdrHomeAction, vdrCompanyAction } from "../../redux/actions/VdrAction";
import { CustomCarousel, HomeSearch } from "../components";
import { wordLimit, checkUser } from "../../constants/helpers";
import { LoadingComponent } from "../../components";
import { Link as SLink } from "react-scroll";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { VDR_COMPANY_REQUEST, VDR_HOME_REQUEST } from "../../constants/VdrConstants";

const CompanyListing = () => {
  const dispatch = useDispatch();
  const { filterData: homeData, loading: homeLoading } = useSelector(
    (state) => state.vdrHomeReducer
  );

  const {
    data: companyData,
    loading: companyLoading,
    totalRows,
  } = useSelector((state) => state.vdrCompanyReducer);

  const [listingData, setListingData] = useState(null);
  const [listingGroup, setListingGroup] = useState(null);

  const [SearchedDates, setSearchedDates] = useState(null);

  const navigation = useNavigate();
  useEffect(() => {

    if (localStorage.getItem("dateRange")) {
      var jSonData = JSON.parse(localStorage.getItem("dateRange"));
      console.log(jSonData);
      setSearchedDates(jSonData); 
      // console.log('SearchedDates2 >>>>>>>>>>>>>>>>>>>>>>>', SearchedDates)
      dispatch({type: VDR_HOME_REQUEST})
      dispatch({type: VDR_COMPANY_REQUEST})
    }
  }, []); 

  const companyDataView = () => {};

  const [companyMasterArr, setCompanyMasterArr] = React.useState([]);
  const [company, setCompany] = React.useState([]);
  const [companyKey, setCompanyKey] = React.useState(false); 
  const [cLoading, setCLoading] = React.useState(false);

  const selectCompany = () => {
    if (totalRows > 0) {
      var data1 = [];
      companyData.Data.map((item) => {
        var d1 = {
          title: item.company_name,
          name: item.company_name,
          value: item.company_id,
        };
        data1.push(d1);
      });

      data1.sort(function (a, b) {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
      const unique = [
        ...new Map(data1.map((item) => [item["value"], item])).values(),
      ];
      // console.log('unique >> '+JSON.stringify(data1));
      setCompanyMasterArr(unique);
      setCLoading(true);
    }
  };

  useEffect(() => {
    if (homeLoading && SearchedDates && SearchedDates.length > 0) {
      let filterOption  = {  };
          filterOption  = { ...filterOption, status: 1 };
        filterOption = { ...filterOption, FromDate:SearchedDates[0], ToDate:SearchedDates[1] }
      dispatch(vdrHomeAction(filterOption, 'filter'));
    }
    if (companyLoading) {
      dispatch(vdrCompanyAction({ status: 1 }));
    }
    if (!homeLoading) {
      setListingData(homeData?.Data);
      const unique = [
        ...new Map(
          homeData?.Data.map((item) => [item["group_id"], item])
        ).values(),
      ]; 
      setListingGroup(unique);
    }
    // console.log(companyLoading);
    if (!companyLoading) {
      selectCompany();
    }
    // console.log('SearchedDates >> ', SearchedDates);
  }, [dispatch, homeLoading, companyLoading, SearchedDates]);

  useEffect(() => {
    checkUser()
  }, [])

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    // console.log(newValue);
    setValue(newValue);
  };

  var group_id;

  if (homeLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Box className="headerContainerFront">
        <Typography
          variant="h1"
          fontSize={"1.5em"}
          fontWeight="500"
          color="#f37b21"
          style={{ zIndex: 1 }}
        >
          Reports Uploaded in Between
        </Typography>
        <Typography
          style={{ zIndex: 2 }}
          fontSize={"2.5em"}
          color="#f37b21"
          fontWeight="700"
        >
          {/* 15-Apr-2022 to 15-Apr-2023 */}
          {SearchedDates ? moment(SearchedDates[0]).format('MMM DD, YYYY') + " to " + moment(SearchedDates[1]).format('MMM DD, YYYY') : null }
          
        </Typography>
      </Box>

      <Box className="bodyContainer">
        {listingGroup &&
          listingGroup.map((groupItem, gIndex) => {
            return (
              <>
                <Box
                  sx={{
                    margin: "20px 0",
                  }}
                  key={gIndex}
                  id={`container${gIndex}`}
                >
                  <Box
                    sx={{
                      display: "flex",
                      padding: "0 0.8rem",
                    }}
                    justifyContent="space-between"
                    alignItems={"center"}
                  >
                    <Typography variant="h6">{groupItem.group_name}</Typography>

                    {groupItem?.company_length > 5 ? (
                      <>
                        <Button variant="text">See &nbsp;More</Button>
                      </>
                    ) : null}
                  </Box>

                  <CustomCarousel
                    groupId={groupItem.group_id}
                    // EnableNumber={groupItem?.enable_number}
                    listingData={listingData}
                  />
                </Box>
                {/* {gIndex == 3 ? (
                  <Divider sx={{ backgroundColor: "#979797", height: 2 }} />
                ) : null} */}
              </>
            );
          })}
      </Box>
    </>
  );
};

export default CompanyListing;
