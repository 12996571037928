import React, { useState, useEffect, Fragment } from "react";
import { FaSortAmountUp, FaSortAmountDown } from "react-icons/fa";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import Divider from "@mui/material/Divider";
import {
  forumAction,
  forumDetailAction,
} from "../../../redux/actions/VdrAction";
import { useDispatch, useSelector } from "react-redux";
import { Box, ListItemButton } from "@mui/material";
import { NEWSLETTER_COMMENT_REQUEST } from "../../../constants/VdrConstants";
import CalenderComp from "./Calender";
import { useParams } from "react-router-dom";
import { crtUserId } from "../../../constants/helpers";



const LeftSection = (props) => {
  const {
    FilteredData, setFilteredData, DatesArr, setDatesArr, ActiveItem, setActiveItem, ActiveVideo
  } = props


  let { blogSlug } = useParams();
  useEffect(() => {
    // console.log('FilteredData >>>> ', FilteredData)
  }, [props])
  
  
  // const [ActiveItem, setActiveItem] = useState("");
  const [BtnAction, setBtnAction] = useState(1);
  
  let cal_btn = [
    {
        "id":1,
        "label":"Latest",
    },
    {
        "id":2,
        "label":"Select Date On Calender",
    }
  ]
    
  let userId = crtUserId();
  const dispatch = useDispatch();
  // const {
  //   data: { data: ForumData },
  //   loading: ForumLoading,
  // } = useSelector((state) => state.ForumReducer);


useEffect(() => {
  if(FilteredData.length > 0){
    let item0 = FilteredData[0];
    if(blogSlug !== undefined && blogSlug !== "" && ActiveVideo === 1){
      item0 = FilteredData.find(item=> item.slug === blogSlug);
      console.log('item0 <<<<><>> ', item0)
    }
    let slug = item0?.slug
    let params = {
        "slug": slug,
        "user_id": userId
    }
    setActiveItem(item0?.id);
    dispatch(forumDetailAction(params));
  }
}, [FilteredData])


  const ActiveItemFun = (item0) => {
    let slug = item0.slug;
    let params = {
        "slug": slug,
        "user_id": userId
    }
    
    setActiveItem(item0.id);
    dispatch({ type: NEWSLETTER_COMMENT_REQUEST });
    dispatch(forumDetailAction(params));

}







  return (
    <div className={`video-left`}>
      
      <Box
        sx={
          {
            display:'flex',
            gap:'.5rem',
            marginBottom:'.5rem'
          }
        }
      >
        {
            cal_btn.map((itm, i)=>{
                return (
                    <Fragment key={itm?.id}>
                        <Button className={`${BtnAction === itm.id ? "btn_contained" : "btn_outlined"}`} sx={{ fontSize:'.7rem' }} size="small" onClick={()=>setBtnAction(itm.id)} variant={`${BtnAction === itm.id ? "contained" : "outlined"}`} >{itm?.label}</Button>
                    </Fragment>
                )
            })
        }

        {/* <Button size="small" variant="contained">Latest</Button>
        <Button size="small" variant="outlined">Choose Calender</Button> */}

      </Box>
      
      {
        BtnAction === 1 ?
        <>
          <Box>
          <List
            sx={{
              width: "100%",
            }}
            className="video-list"
          >
            {FilteredData &&
              FilteredData.length > 0 &&
              FilteredData.map((item, i) => {
                return (
                  <>
                  <ListItem disablePadding  className={ActiveItem === item?.id ? "activeListCls" : ""}>
                      <ListItemButton onClick={()=>{
                        ActiveItemFun(item)
                      }}>
                        <Box sx={{ 
                          display:'flex',
                          justifyContent:'space-between',
                          alignItems:'center',
                          width:'100%'
                          }}>
                          <Box>
                            <Box sx={{ 
                              display:'flex',
                              // justifyContent:'space-between',
                              justifyContent:'flex-start',
                              alignItems:'center',
                              gap:1
                            }}>
                              {/* <div className='vid-icon'>
                                <PlayCircleIcon />
                              </div> */}
                              <div className='vid-title'>
                                  {item?.first_name}
                                <div>
                                  <b><small>{item?.c_type}</small></b>
                                </div>
                              </div>
                            </Box>
                          </Box>
                          {/* <div>
                            <div className='vid-date'>
                                {item?.dateTime}
                            </div>
                          </div> */}
                        </Box>
                      </ListItemButton>
                    </ListItem>
                    
                    {/* <ListItem>
                      <ListItemText onClick={()=>{
                        actionItems(itm)}}
                        primary={itm?.first_name}
                        secondary={itm?.c_type}
                      />
                    </ListItem>
                    <Divider component="li" /> */}
                  </>
                );
              })}
          </List>
      </Box>
        </>
        :
        <>
          <div style={{ 
              marginTop:'1rem',
              paddingRight:'.5rem'
            }}>
              <CalenderComp item={{}} ActiveDateData={FilteredData} />
          </div>
        </>
      }
      

     
    </div>
  );
};

export default LeftSection;
