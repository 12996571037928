import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Avatar, Box, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Tab, Typography } from "@mui/material";
import React from "react";
import './style.scss'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forumAction } from "../../../redux/actions/VdrAction";
import DetailPage from "./DetailPage";
import OtherSection from "./OtherSection";
import { FORUM_DETAIL_REQUEST } from "../../../constants/VdrConstants";
import { useParams, useSearchParams } from "react-router-dom";
const TOTPage = () => {

    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');


// const [Value, setValue] = React.useState(String(code));
const [Value, setValue] = React.useState("1");
const [ActiveItem, setActiveItem] = React.useState(null);

const {
    data:{
        data:ForumData
    },
    loading:ForumLoading,
} = useSelector(state => state.ForumReducer)

useEffect(() => {
    let f = document.querySelector('footer');
    if(f){
      f.remove()
    }
    // console.log('idddd >>>> ', code)
  },[]);
  
const {
    data:{
        data:ForumetailData
    },
    loading:ForumetailLoading,
} = useSelector(state => state.ForumDetailReducer)


const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch({ type: FORUM_DETAIL_REQUEST });
};

const dispatch = useDispatch();
useEffect(() => {
    if(ForumLoading){
        dispatch(forumAction({}))
    }
    if(!ForumLoading){
    //  console.log(ForumData)
    }
}, [dispatch, ForumLoading])

  
  return (
    <>
      <Box
        className="headerContainerFront"
        sx={{ 
          height: "auto",
        }}
      >
        <Typography
          style={{ zIndex: 2 }}
          fontSize={"2.5em"}
          color="#133e5b"
          fontWeight="700"
        >
          Newsletter
        </Typography>
      </Box>
      <Box
        className="videoContainer"
        sx={{
          marginTop: 5,
          marginBottom: 5,
        }}
      >

        
           <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={Value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {
                            ForumData?.newsletter && ForumData?.newsletter.map((item, i)=>{
                                return (
                                    <Tab label={`${item?.name}`} value={String(i+1)} 
                                    wrapped={true} />
                                )
                            })
                        }
                        {/* <Tab label="Presentation/ Company/ Sector Discussion" value={ForumData?.newsletter && ForumData?.newsletter.length+1} 
          wrapped={true} /> */}
                    </TabList>
                    </Box>
                    
                        {
                            ForumData?.newsletter && ForumData?.newsletter.map((item, i)=>{
                                return (
                                    <>
                                        <TabPanel value={String(i+1)}>
                                            <DetailPage item={item} ActiveItem={ActiveItem} setActiveItem={setActiveItem} />
                                        </TabPanel>
                                    </>
                                )
                            })
                        }
                        
                    {/* <TabPanel value={ForumData?.newsletter && ForumData?.newsletter.length+1}>
                        <OtherSection ForumData={ForumData} />
                    </TabPanel> */}
                </TabContext>
            </Box>
      </Box>
    </>
  );
};

export default TOTPage;
