import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QUARTERLY_SEGMENT_RESET } from '../../../../constants/VdrConstants';
import { quarterlySegmentAction } from '../../../../redux/actions/VdrAction';
import { Box, Button, Typography } from '@mui/material';
import InCr from '../InCr';

const QuarterlySegmentComp = (props) => {
    

  const {QuarterlySegmentType, setQuarterlySegmentType, companyId, secondaryBtnArr, setActivePrimaryBtn} = props;

  const { data: quarterlySegmentData, loading: quarterlySegmentLoading, currentType } = useSelector(state => state.quarterlySegmentReducer);
  const [QuarterlySegmentData, setQuarterlySegmentData] = useState({_Headers:[], tableBody:[]});
  const [QuarterlySegmentActiveFilter, setQuarterlySegmentActiveFilter] = useState({});

  const [FirstLoad, setFirstLoad] = useState(true);

  const selectSegmentButtontype = (key, val) => {
    let a0 = QuarterlySegmentActiveFilter;
    a0 = {...a0, [key]:val }
    setQuarterlySegmentActiveFilter(a0);
    console.log(QuarterlySegmentActiveFilter)
  }

  const dispatch = useDispatch();

  useEffect(()=>{
      setQuarterlySegmentActiveFilter({});
      dispatch({ type: QUARTERLY_SEGMENT_RESET });
      setQuarterlySegmentData({_Headers:[], tableBody:[]})
      dispatch(quarterlySegmentAction({companyID:companyId}, QuarterlySegmentType));
  }, [QuarterlySegmentType])
  

  useEffect(() => {
  

    if(!quarterlySegmentLoading){
      if(quarterlySegmentData._Headers && quarterlySegmentData._Headers.length > 0){

        let _Headers = quarterlySegmentData._Headers;
        let qData = quarterlySegmentData.Data;
  
        var mColArr = [];
        var width = 120;
        _Headers.map((head, key)=>{
          var hData = {
            id: head['column'],
            label: head['value'],
            minWidth: width,
            maxWidth: width,
            align: 'canter',
            hideCheck: null,
            sticky: null,
            extra:head
          }
          mColArr.push(hData);
        });
        
        var allRowsData_row = [];
  
        setQuarterlySegmentData({
          _Headers:mColArr,
          tableBody:qData
        })
      }else{
        setFirstLoad(false);
        setQuarterlySegmentType('Standalone')
        let secondBtn = secondaryBtnArr.find(item =>item.value == 'Standalone')
    console.log('secondBtn <><><<>><><> ', secondBtn)
    setActivePrimaryBtn(secondBtn?.id)
      }
    }
  }, [props, quarterlySegmentLoading])
  



  return (
    <>
    <div className="box-body-sec">
         <div>
            <Typography>Quarterly Segment "{QuarterlySegmentType}" &nbsp;
                {
                    quarterlySegmentData._Headers && quarterlySegmentData._Headers.length > 0 ?
                    <>   
                    <small>(showing data from last {quarterlySegmentData._Headers && quarterlySegmentData._Headers.length-1} quarters)</small>
                    </>
                    :
                    ''
                }
            </Typography>
        </div>
            
    <div>
      {
        !quarterlySegmentLoading ?
        
        <>
        
      { quarterlySegmentData._Headers && 
        quarterlySegmentData._Headers && quarterlySegmentData._Headers.length > 0 ? 
        <>
        
        
          <table sticky_Headers aria-label="sticky table" className="qr-table custom-table" id="table-to-xls">
            <thead>
            <tr role="checkbox" tabIndex={-1} >
                {
                    QuarterlySegmentData._Headers.map((column, cKey)=>{
                      let cStyle = {};
                      let valueRow = column.extra;
                      let value = column.label;
                      cStyle = {...cStyle};
                    //   cStyle = {...cStyle, backgroundColor:valueRow?.bgColor, color:valueRow?.textColor};
                      if(cKey !== 0){
                        cStyle = {...cStyle, textAlign:'center'};
                      }
                      return (
                        <td className='text-ellipsis' style={cStyle} key={column.id} align={column.align} >
                          {column?.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </td>
                      )
                    })
                  }
            </tr>
            </thead>
            <tbody>

                  {
                    QuarterlySegmentData.tableBody.map((row, rKey)=>{

                      // console.log(row);
                      let cStyle = {};
                          cStyle = {...cStyle};
                        //   cStyle = {...cStyle, backgroundColor:row.bgColor, color:row.textColor};
                          
                      let rowData = row.data_new;
                      // console.log('nData >>>>>> ', rowData)
                      let nData = {};
                      if(rowData.length > 0){
                        rowData = rowData[0];
                        let getKey = "amount";
                        if(QuarterlySegmentActiveFilter && Object.keys(QuarterlySegmentActiveFilter).length === QuarterlySegmentData?.tableBody.length){
                          getKey = QuarterlySegmentActiveFilter[rKey];
                        }
                        rowData = rowData[getKey];
                        nData = rowData[0]._cell;
                      }
                        nData = rowData[0]._cell;
                        let lastColData = rowData[rowData.length -1]._cell;
                        // console.log('nData >>>>>> ', nData, nData.length, lastColData.slice(0, nData.length))
                        // console.log('nData >>>>>> ', lastColData)
                        let lastRowData = []
                        nData.map((lrd, lrd_index)=>{
                          let lrd_0 = lastColData[lrd_index];

                          let n_val = parseFloat(0);
                          
                          if(lrd_0?.value != null){
                            n_val = (lrd_0.value).replace(',', '');
                            n_val = n_val.replace('%', '');
                            n_val = parseFloat(n_val);
                          }

                          lrd_0 = {...lrd_0, column: lrd.column, new_value: n_val}
                          lastRowData.push(lrd_0)
                          
                        })
                        lastRowData.sort((a, b)=>{ 
                          return b.new_value - a.new_value
                        })
                        let lastRowNegative = Number(0);
                        lastRowData.map((o_l)=>{
                          if(o_l.value == null){
                            let a000000000 = lastRowData[lastRowData.length - 1 ];
                            lastRowNegative = a000000000.new_value
                            o_l.new_value = (lastRowNegative - 1) 
                            console.log(a000000000.value)
                          }
                        })
                        nData = lastRowData;
                        nData.sort((a, b)=>{ 
                            return b.new_value - a.new_value
                        })

                        console.log('lastRowData >>> ', nData);
                      
                      return (
                        <>
                        <tr tabIndex={-1} key={'rKey__'+rKey}>
                          <td colSpan={QuarterlySegmentData._Headers && QuarterlySegmentData._Headers.length} className='text-ellipsis' style={cStyle} key={rKey} >
                            <Box  sx={{ 
                                display:'flex',
                                justifyContent:'space-between',
                                alignItems:'center'
                               }}>
                              <div style={{ 
                                fontSize:17, fontWeight:'500'
                               }}>{row.title}</div>
                              <Box sx={{ 
                                display:'flex',
                                justifyContent:'flex-end'
                               }}>
                                {
                                  row.filter_data.map((fItem, fKey)=>{ 
                                    if(fKey === 0 && !QuarterlySegmentActiveFilter[rKey]){
                                      let a0 = QuarterlySegmentActiveFilter;
                                      a0 = {...a0, [rKey]:fItem.value }
                                      setQuarterlySegmentActiveFilter(a0)
                                    }
                                    return (
                                      <Button onClick={()=>selectSegmentButtontype(rKey,fItem.value)} className={``} variant={`${QuarterlySegmentActiveFilter[rKey] === fItem.value ? 'contained' : 'outlined'}`} color="secondary" size="small" sx={{ margin: '0.2rem', fontSize:10, padding:'2px 8px' }} type="button">{fItem.label}</Button>
                                    )
                                  })
                                }
                                
                              </Box>
                            </Box>
                          </td>
                        </tr>

                        {
                          nData.map((childItem, chKey)=>{
                            
                            // console.log('childItem >>>>>> ', childItem)

                            return (
                              <>
                              <tr>
                                {
                                  QuarterlySegmentData._Headers.map((column, cKey)=>{
                                    // let valueRow = rowData[cKey]._cell[chKey]
                                    let valueRow = rowData[cKey]._cell.find(ff_item=>ff_item.column == childItem.column)
                                    let cStyle_1 = {};
                                    let value = valueRow?.value;
                                    if(cKey > 0 && value != null && QuarterlySegmentActiveFilter[rKey] === 'amount'){
                                        value = value.substring(0, value.indexOf('.'));
                                    }

                                    cStyle_1 = {...cStyle_1};
                                    // cStyle_1 = {...cStyle_1, backgroundColor:valueRow?.bgColor, color:valueRow?.textColor};
                                    if(cKey !== 0){
                                      cStyle_1 = {...cStyle_1, textAlign:'center'};
                                    }
                                    return (
                                      <td className='text-ellipsis' style={cStyle_1} key={column.id} align={column.align} >
                                        {
                                          column?.format && typeof value === 'number'
                                          ? column.format(value)
                                          : value
                                        }
                                      </td>
                                    )
                                  })
                                }
                              </tr>
                              </>
                            )
                          })
                        }
                        
                        </>
                      )
                    })
                  }
                  
            </tbody>
          </table>
          
        </>
        :
        <>
        
          <Typography>
            N.A.
          </Typography>

        </>
      }

        </>
        :
        <>
          <Typography>Loading...</Typography>
        </>
      }
    </div>
        </div>
    </>
  )
}

export default QuarterlySegmentComp