import React, {useState, useEffect, useLayoutEffect} from 'react'
import { Box, Alert, Button, IconButton } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import InputComponent from './../components/Common/InputComponent';
// import { linkBaseUrl } from './../utils/userRequest'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loginAction } from '../redux/actions/userAction';
import { useForm } from 'react-hook-form';




const LoginPage = () => {

  
  const dispatch = useDispatch();
  const { data, loading, error, isLoggedIn } = useSelector((state) => state.loginData);


  const [showPassword, setShowPassword] = React.useState(false);
  const [inputs, setInputs] = React.useState({});
  const [errors, setErrors] = React.useState({});

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  
  const handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs({...inputs, [name]: value});
  }
  

const { control, handleSubmit, register, reset } = useForm({
    defaultValues: {
        username: "",
        password: "",
    }
})

const navigation = useNavigate();

useEffect(() => {
  
  var rootSection = window.location.href.split('/')[3];
  
  if(!loading){

    if(localStorage.getItem('user')){
      if(rootSection === "price"){
        navigation('/list');
      }
      else
      if(rootSection === "calendar"){
        navigation('/assign');
      }
      else
      {
        navigation('/');
      }
    }
  }
}, [dispatch, loading])


const onSubmit = (data) => {

  dispatch(loginAction(data.username, data.password))
  
};



  return (
    <>

      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div className='form'>
          <div className='form_logo'>
            {/* Lo<span>g</span>o */}
            <img src={'./logo192.png'} style={{ width: '13rem' }} />
          </div>
          <div className="w-100">
            {
              error ?
                <Alert fullWidth variant="outlined" severity="error">
                  {error}
                </Alert>
                :
                null
            }
          </div>

          <form method='post' className='form_items'  onSubmit={handleSubmit(onSubmit)} >

            <Box className={'mtb-05rem'} >
              <label>Username</label>
              <InputComponent
                className="formIcons"
                placeholder={'Enter Username'}
                name='username'
                {...register("username")}
                control={control}
                fullWidth
                required
                onChange={handleChangeInput}
                InputProps={{
                  startAdornment: (<Box className='inputIcon'
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    <PersonOutlineOutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5, borderWidth: 1 }} />
                  </Box>),
                }}
              />
              {errors?.username && <div className='text-errors'>{errors?.username}</div>}
            </Box>

            <Box className={'mtb-05rem'} >
              <label>Password</label>
              <InputComponent

                className="formIcons"
                placeholder={'Enter Password'}
                control={control}
                name='password'
                {...register("password")}
                required
                type={showPassword ? 'text' : 'password'}
                onChange={handleChangeInput}
                sx={{ width:'100%' }}
                InputProps={{
                  startAdornment: (<Box className='inputIcon'
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    <LockOutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5, borderWidth: 1 }} />
                  </Box>),
                  endAdornment: (<IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility size="small" /> : <VisibilityOff size="small" />}
                  </IconButton>),
                }}
              />
              {errors?.password && <div className='text-errors'>{errors?.password}</div>}
            </Box>
            <Button type="submit" className='form_button btn-theme w-100'>Log In</Button>
          </form>
          
        </div>
      </Box>

    </>
  )
}

export default LoginPage