import React, { useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

const CompanyOldDetail = () => {
    const { companyName } = useParams();
    const navigate = useNavigate();
    const lnk = `/company/${companyName}`;
    // console.log(navigate('/'))
    useEffect(() => {
        navigate(lnk);
        // companyName
        // /company/:companyName
    }, [])
    
  return (
    <></>
  )
}

export default CompanyOldDetail