import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import InCr from "./InCr";
import { useDispatch, useSelector } from "react-redux";
import { SingleCompanyratiosAction } from "../../../redux/actions/VdrAction";
import { BsFillBarChartFill } from "react-icons/bs";

const RatioComp = (props) => {
  const {
    companyId,
    isCD
  } = props;

  const {
    data,
    loading
  } = useSelector(state=>state.SingleCompanyratiosReducer);


  const [QuarterlySegmentType, setQuarterlySegmentType] = useState([]);
  const [TableColumns, setTableColumns] = useState([]);
  const [TableBody, setTableBody] = useState([]);
  const [ActivePrimaryBtn, setActivePrimaryBtn] = useState(1);
  const [FirstLoad, setFirstLoad] = useState(true);
  const [ActiveSecondaryBtn, setActiveSecondaryBtn] = useState(1);

  const [PlusIcons, setPlusIcons] = useState({});
  

  const showDiv = (e, index) => {
    let a0 = PlusIcons;
    a0 = {
      ...a0,
      [`childTable_${index}`]: (!PlusIcons[`childTable_${index}`])
    }
    
    console.log(PlusIcons, 'index >> ', index, PlusIcons[`childTable_${index}`], (!PlusIcons[`childTable_${index}`]));
    // PlusIcons[`childTable_${r_i}`]

    setPlusIcons(a0);
  }

const dispatch = useDispatch();


const callApi = () => {
    
  let pType = 'con';
   if(QuarterlySegmentType === "Standalone"){
     pType = 'std';
   }
  let params =  {
    "CompanyId":companyId,
    "type":pType
  }
  dispatch(SingleCompanyratiosAction(params))
}

useEffect(() => {
  setTableColumns([])
  setTableBody([])
  callApi()
}, [QuarterlySegmentType])

useEffect(() => {
    if(!loading){
      
      if(data.header && data.header.length > 0){
        let cols = []
        let firstObj = data.header[0];
        let a0 = firstObj.row;
        Object.keys(a0).map((item, i)=>{
          if(i > 0){
            let dd = {
                "id": item,
                "width": "",
                "align": "",
                "bg_color": firstObj?.bg_color,
                "isItalic": firstObj?.isItalic,
                "isBold": firstObj?.isBold,
                "text_color": firstObj?.text_color,
                "label": a0[item]
              }
            cols.push(dd);
          }
        })
        setTableColumns(cols)
      }else{
        if(FirstLoad){
          setFirstLoad(false);
          setActivePrimaryBtn(2);
          setQuarterlySegmentType('Standalone')
        }
      }
      if(data.Data && data.Data.length){
        let dataA = []
        let plusIcon = {};
        let a00 = data.Data;
        a00.map((item, i)=>{
          let cData = item?.row;
          let childObj = {}
          Object.keys(cData).map((item0, i0)=>{
            if(i0 > 0){
              let data = {
                "label":cData[item0],
                "bg_color":item['bg_color'],
                "isItalic":item['isItalic'],
                "isBold":item['isBold'],
                "text_color":item['text_color'],
              };
              childObj = {...childObj, [`Column${i0}`]: data} 
            }
          })
          if(item?.childTable.length > 0){
            plusIcon = {...plusIcon, [`childTable_${i}`]: false}
          }
          childObj = {...childObj, [`childTable`]: (item?.childTable || [])}
          dataA.push(childObj)
        })
        // console.log('dataA >>>>>>>> ', dataA)
        setPlusIcons(plusIcon);
        setTableBody(dataA)
      }
    }
}, [dispatch, loading])
  
let secondaryBtnArr = [
  {
    "id":1,
    "label":"Consolidated",
    "value":"Consolidated",
    "show_in":[1,2,3]
  },
  {
    "id":2,
    "label":"Standalone",
    "value":"Standalone",
    "show_in":[1,2,3]
  },
] 
const showChart = (row)=>{
  console.log(row);
  
  // let newRow = {};
  // Object.keys(row).map((item)=>{
  //     let a0 = {
  //         bg_color: "#ccdbf5",
  //         isBold: true,
  //         isItalic: false,
  //         label: row[item],
  //         text_color: "#000",
        
  //     }
  //     newRow = {...newRow, [item]: a0}
      
  //     // newRow.push(a0)
  // })
  let newRow = row
  dispatch({
    type:"Columns_Rows",
    payload:{columns:TableColumns, rows:newRow}
  })
  
  // setIsOpen(true);
  // setCrtRow(newRow);
}
  return (
    <>
      {/* <button className='btn primary-btn'>Download Image</button> */}
      <Box className="valuation-box">
        
      {
          !isCD && (
        <div className="box-head-sec">
        <div style={{ 
            display:'flex',
            width:'100%',
            justifyContent:'space-between',
            alignItems:'center',
           }}>
            <div style={{ 
            display:'flex',
            alignItems:'end',
            gap:'.5rem'
           }}>
                <h3>Ratios</h3>
                
            </div>

          </div>
          <div>
            {/* <button className="btn primary-btn-outline">Edit</button>
            <button className="btn primary-btn">Download Image</button> */}
          </div>
        </div>
        )}
        <div style={{
          display:'flex',
          gap:'10px',
          marginBottom:'14px'
         }}>
        {
            secondaryBtnArr.map((item, i)=>{
              if(item.show_in.includes(ActiveSecondaryBtn)){
              }
              return (
                <>
              <button onClick={()=>{
                // console.log('item.id >>> ', item.id)
                setQuarterlySegmentType(item?.value)
                setActivePrimaryBtn(item.id);
              }
              } key={i} className={`btn ${ActivePrimaryBtn === item.id ? "primary-btn" : "secondary-btn-outline"}`}>{item?.label}</button>
                </>
              )
            })
          }

          {/* <button className="btn primary-btn-outline">Edit</button>
          <button className="btn primary-btn">Download Image</button> */}
        </div>
        <div className="box-body-sec">
          
        <table>
        <thead>
          <tr>
            {
              TableColumns.map((item,i)=>{
                let cStyle = {
                  
                      backgroundColor:item?.bg_color,
                      fontStyle:item?.isItalic ? "italic" : "",
                      fontWeight:item?.isBold ? "500" : "",
                      color:item?.text_color,
                    }
                return (
                  <td style={cStyle} key={i}>{item.label}</td>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {
              TableBody.map((row,r_i)=>{
                let backgroundColor = "";
                let fontStyle = "";
                let fontWeight = "";
                let color = "";

                return (
                  <>
                    <tr>
                      {
                        TableColumns.map((item,i)=>{
                          let rowData = row[item.id]
                          let value = rowData?.label
                          let cStyle = {
                            backgroundColor:rowData?.bg_color,
                            fontStyle:rowData?.isItalic ? "italic" : "",
                            fontWeight:rowData?.isBold ? "500" : "",
                            color:rowData?.text_color,
                          }
                          if(backgroundColor === "" &&
                            fontStyle === "" &&
                            fontWeight === "" &&
                            color === ""){
                              backgroundColor = rowData?.bg_color;
                              fontStyle = rowData?.isItalic ? "italic" : "";
                              fontWeight = rowData?.isBold ? "500" : "";
                              color = rowData?.text_color;
                          }
                          return (
                            <td style={cStyle} key={i}>
                              <div style={{ 
                                  display:'flex',
                                  width:'100%',
                                  justifyContent: (i === 0 ? 'space-between' : 'flex-end'),
                                  alignItems:'center'
                                  }}>
                                {value || ""}  
                                  <span>
                                    {
                                      i === 0 && 
                                      <IconButton onClick={(e)=>showChart(row)} sx={{ padding:0 }}>
                                        <BsFillBarChartFill size={16} />
                                      </IconButton>
                                    }
                                  </span>
                                </div>

                              {/* <div style={{
                              display:'flex',
                              alignItems:'center',
                              justifyContent: i !==0 ? 'end' : "start",
                              columnGap:'.5rem'
                            }}>
                              <div>{value || ""}</div>
                              {
                                i===0 && row?.childTable && row?.childTable.length > 0 && (
                                  PlusIcons[`childTable_${r_i}`] ?  <AiOutlineMinus onClick={(e)=>showDiv(e, r_i)} className="td-icon"/> : <AiOutlinePlus onClick={(e)=>showDiv(e, r_i)} className="td-icon"/> 
                                )
                              }
                            </div> */}
                            </td>
                          )
                        })
                      }
                    </tr>
                    { 
                       PlusIcons[`childTable_${r_i}`] &&
                      row?.childTable && row?.childTable.length > 0 && (
                        <>
                          {
                            row.childTable.map((child, c)=>{
                              return (
                                <tr key={c} className={r_i%2 !== 0 ? "tr-even" : "tr-odd"}>
                                  {
                                    TableColumns.map((item0,i0)=>{
                                      let c_value = child[item0.id];
                                      let cStyle = {};
                                      if(i0 === 0){
                                        cStyle = {
                                          ...cStyle,
                                          paddingLeft:'2rem'
                                        }
                                      }
                                      cStyle = {
                                        ...cStyle,
                                        backgroundColor:backgroundColor,
                                        fontStyle:fontStyle,
                                        fontWeight:fontWeight,
                                        color:color
                                      }
                                      return (
                                        <>
                                          <td key={i0} style={cStyle}>
                                            {/* {c_value} */}
                                            <div style={{ 
                                          display:'flex',
                                          width:'100%',
                                          justifyContent: (c === 0 ? 'space-between' : 'flex-end'),
                                          alignItems:'center'
                                         }}>
                                        {c_value || ""}  
                                          <span>
                                            {
                                              c === 0 && 
                                              <IconButton onClick={(e)=>showChart(row)} sx={{ padding:0 }}>
                                                <BsFillBarChartFill size={16} />
                                              </IconButton>
                                            }
                                          </span>
                                        </div>
                                          </td>
                                        </>
                                      )
                                    })
                                  }
                                </tr>
                              )
                            })
                          }
                        </>
                      )
                    }
                  </>
                )
              })
            }
          
        </tbody>
      </table>
        </div>
      </Box>
    </>
  );
};

export default RatioComp;
