import React, { useState } from "react";
import "./Modal.css"; // Import the CSS file or define styles inline
import { useEffect } from "react";

import { useRef, useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

const CModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content">
        {children}
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

const CrtModal = ({ isOpen, setIsOpen, row, columns }) => {
  const chartRef = useRef(null);
  const ThemeColorData = useSelector(state=> state.ThemeColorReducer);

  useEffect(()=>{
      
  }, [ThemeColorData])

  const createChart = () => {
    return am5.Root.new("chart");
  };
const [Loading0, setLoading0] = useState(true);
const [ChartTitle, setChartTitle] = useState("");
  useLayoutEffect(() => {
    setChartTitle('')
    setTimeout(() => {
      if (isOpen === true) {
        // console.log("date===",data)
        let root = createChart();

        root.setThemes([am5themes_Animated.new(root)]);

        let chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            // panY: true,
            // layout: root.verticalLayout,
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
          })
        );
        

        // chart.zoomOutButton.set("forceHidden", true);


        let data0 = [];
        columns.map((item, i) => {
          if(i===0){
            setChartTitle(row[item?.id].label);
          }
          if (i > 0) {
            let r_num = row[item?.id].label;
            // console.log('r_num >>> ', r_num)
            if(r_num == ""){
              r_num = Number(0);
            }
            if(r_num){
                r_num = r_num.replaceAll("%", "");
            }
            let myobj = {
              category: item?.label,
              value1: Number(r_num),
            };
            data0.push(myobj);
          }
        });
        // Object.keys(row).map((item)=>{
        //     console.log("use rowww==", columns);
        //     let myobj={
        //       category: 'header',
        //       value1: row[item].label,
        //     }
        //     // data = Object.assign(data, myobj);
        //     data0.push(myobj)
        //     // data.unshift(myobj)
        //     // data =[..data,myobj]
        // })
        // console.log('data0 >>>>>> ', data0)

        let data = data0;
        //   data = [{
        //     category: "Research",
        //     value1: 1000
        //   }, {
        //     category: "Mar1keting",
        //     value1: 1200,
        //     value2: 1800
        //   }, {
        //     category: "Saales",
        //     value1: 850
        //   }, {
        //     category: "Ma0rketing",
        //     value1: 1200
        //   }, {
        //     category: "Sa0les",
        //     value1: 850
        //   }, {
        //     category: "Marketing",
        //     value1: 1200
        //   }, {
        //     category: "Sales",
        //     value1: 850
        //   }, {
        //     category: "M0ar1keting",
        //     value1: 1200,
        //     value2: 1800
        //   }, {
        //     category: "S0aales",
        //     value1: 850
        //   }, {
        //     category: "M0a0rketing",
        //     value1: 1200
        //   }, {
        //     category: "S0a0les",
        //     value1: 850
        //   }, {
        //     category: "M0arketing",
        //     value1: 1200
        //   }, {
        //     category: "S0ales",
        //     value1: 850
        //   }
        // ];
        // console.log("data >>>>>> ", data);

        // Create Y-axis
     
        
        let yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {}),
          })
        );

        // Create X-Axis
        let xAxis = chart.xAxes.push(
          am5xy.CategoryAxis.new(root, {
            renderer: am5xy.AxisRendererX.new(root, {
              minGridDistance: 30,
              tooltip: am5.Tooltip.new(root, {})
            }),
            categoryField: "category",
          })
        );


        xAxis.data.setAll(data);
        // xAxis.labels.template.setAll({
        //   rotation: -90,
        //   centerY: am5.p50,
        //   centerX: 0,
        //   paddingRight: 15
        // });
        // xAxis.zoomToIndexes(10, 20);

        // Create series
        let series1 = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value1",
            categoryXField: "category",
          })
        );

        
          // Rounded corners for columns
          series1.columns.template.setAll({
            cornerRadiusTL: 5,
            cornerRadiusTR: 5,
            strokeOpacity: 0
          });

          // Make each column to be of a different color
          series1.columns.template.adapters.add("fill", function (fill, target) {
            return (ThemeColorData.chartColor || '#c767dc');
          });

          series1.columns.template.adapters.add("stroke", function (stroke, target) {
            return chart.get("colors").getIndex(series1.columns.indexOf(target ));
          });

          // Add Label bullet
          series1.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationY: 1,
              sprite: am5.Label.new(root, {
                text: "{valueYWorking.formatNumber('#.')}",
                fill: root.interfaceColors.get("alternativeText"),
                centerY: 0,
                centerX: am5.p50,
                populateText: true
              })
            });
          });


        series1.data.setAll(data);

        // let series2 = chart.series.push(
        //   am5xy.ColumnSeries.new(root, {
        //     name: "Series",
        //     xAxis: xAxis,
        //     yAxis: yAxis,
        //     valueYField: "value2",
        //     categoryXField: "category"
        //   })
        // );
        //  series2.data.setAll(data);

        // Add legend
        let legend = chart.children.push(am5.Legend.new(root, {
          centerX: am5.percent(50),
          x: am5.percent(50),
        }));
        legend.data.setAll(chart.series.values);

        // Add cursor
        chart.set("cursor", am5xy.XYCursor.new(root, {}));

        setLoading0(false);
        return () => {
          root.dispose();
        };
      }
      
    }, 1000);
  }, [row, isOpen]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Dialog fullWidth maxWidth={"lg"} open={isOpen} onClose={closeModal}>
        <DialogTitle sx={{ 
          textAlign:'center'
         }}>{ChartTitle || "Loading.."}</DialogTitle>
         <Divider />
        <DialogContent>
          {/* {
            Loading0 ?
              <Box>
                <CircularProgress />
              </Box>
            :
            <div id="chart" style={{ width: "100%", height: "460px" }}></div>

          } */}
          <div id="chart" style={{ width: "100%", height: "460px" }}></div>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={closeModal}>Close</Button>
        </DialogActions> */}
      </Dialog>

      {/* <Modal
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div id="chart" style={{ width: "100%", height: "500px" }}></div>

          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal> */}

      {/* <CModal isOpen={isOpen} onClose={closeModal}>
        <div id="chart" style={{ width: "100%", height: "500px" }}></div>
      </CModal> */}
    </div>
  );
};

export default CrtModal;
