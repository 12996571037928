import * as React from 'react';
import {TextField, Box} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { openSingleCompany } from '../../redux/actions/userAction';
import { useNavigate } from 'react-router-dom';
import { convertToSlug, encodeBtoa } from './../../constants/helpers'
import CalendarDialog from './CalendarDialog';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function HomeSearch({ newOptions, ...props }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      //   await sleep(0); // For demo purposes.
      if (active) {
        setOptions([...newOptions]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        width: '80%',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex:10
       }}>
      <CalendarDialog />
        <Autocomplete
          id="HomeSearch-demo"
          {...props}
          open={open}
          onKeyPress={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false); 
          }}
          onChange={(event, newInputValue) => {
            var val1 = newInputValue;
            // encodeBtoa(val1.value)
            localStorage.setItem("single-company-data", encodeBtoa(val1.value))
            navigate(`/company/${encodeBtoa(val1.value)}`);
            // navigate(`/${convertToSlug(val1.name)}`);
            val1['CompanyID'] = val1.value;

          }}

          isOptionEqualToValue={(option, value) => option.title === value.title}
          getOptionLabel={(option) => option.title}
          options={options}
          loading={loading}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}

              placeholder='Search Company'
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>
    </>
  );
}
