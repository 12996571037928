import React, { useEffect } from 'react'
import { Calendar } from 'react-date-range';
import {useState} from 'react'
import { addDays, format, isWeekend } from 'date-fns';
import { useDispatch } from 'react-redux';
import { forumDetailAction } from '../../../redux/actions/VdrAction';
import moment from 'moment';
import { crtUserId } from '../../../constants/helpers';

const CalenderComp = (porps) => {

const {
  item,
  ActiveDateData
} = porps

useEffect(() => {
  
}, [])
const dispatch = useDispatch();


const aaa = (c_date) => {
  let formatted_date = moment(c_date).format('YYYY-MM-DD');
  // console.log(formatted_date)
  if(ActiveDateData.includes(formatted_date)){
    setDate(c_date)
    let params = {
        "slug": item.company_slug,
        "user_id": crtUserId(),
        "date": formatted_date
    }
    dispatch(forumDetailAction(params));
  }
}

const [locale, setLocale] = React.useState('ja');
const [date, setDate] = useState(null);

const customDayContent = (day) => {
  let extraDot = null;
  let f_day = moment(day).format('YYYY-MM-DD')
  // console.log('f_day >>> ', f_day)
  if (ActiveDateData.includes(f_day)) {
    extraDot = (
      <div
        style={{
          height: "98%",
          width: "98%",
          borderRadius: "1.3rem",
          background: "transparent",
          position: "absolute",
          top: 0,
          right: 0,
          border: '2px solid #1c4209'
        }}
      />
    )
  }
  return (
    <div>
      {extraDot}
      <span>{format(day, "d")}</span>
    </div>
  )
}

  return (
    <>
    
      <Calendar 
        onChange={item => aaa(item)}
        dayContentRenderer={customDayContent}
        date={date}
      />
    </>
  )
}

export default CalenderComp