import { AiOutlineArrowUp } from "react-icons/ai"; 
import React, { useEffect, useState } from 'react'
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';

import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Checkbox, CircularProgress, Tab, Tabs } from '@mui/material';
import moment from 'moment';
import CommentList from '../newsletter/comment/List';
import { NewsletterCommentAction, AddToMylistAction,RemoveToMylistAction, SingleNewsletterLikAction, MyBlogListAction } from '../../../redux/actions/VdrAction';
import { BiTrash } from "react-icons/bi";
import { crtUserId } from "../../../constants/helpers";

const RightSection = ({ActiveVideoItem}) => {
  const {
    data:detailData,
    loading:detailLoading
} = useSelector(state=>state.ForumDetailReducer) 


const { data: SingleNewsletterLikeData, loading: SingleNewsletterLikeLoading } = useSelector((state) => state.SingleNewsletterLikeReducer);


const { data: MyBlogListData, loading: MyBlogListLoading } = useSelector((state) => state.MyBlogListReducer);

const dispatch = useDispatch(); 
const [value, setValue] = React.useState(0);
const [ActiveData, setActiveData] = React.useState({});

const handleChange = (event, newValue, data) => {
  setActiveData(data)
  setValue(newValue);
};
let userId = crtUserId();
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const [checked, setChecked] = React.useState(SingleNewsletterLikeData?.is_user_like);
const { data: NewsletterCommentData, loading: NewsletterCommentLoading } = useSelector((state) => state.NewsletterCommentReducer);



const [MyFavorite, setMyFavorite] = useState([])
const myFavFun = () => {
  if(MyBlogListData?.data && MyBlogListData.data.length > 0){
      let a0 = MyBlogListData.data.map((item)=>item?.blogdata[0].blog_id)
      console.log('ActiveVideoItem >>> ', ActiveVideoItem)
      console.log('a0 >>>> ',a0, MyFavorite)
      setMyFavorite(a0)
    }
}



useEffect(() => {
  // console.log('ActiveVideoItem >>>> ', ActiveVideoItem)
  if(!MyBlogListLoading){
    myFavFun();
  }
}, [MyBlogListLoading]);

useEffect(() => {
  if(!SingleNewsletterLikeLoading){
    setChecked(SingleNewsletterLikeData?.is_user_like)
  }
}, [SingleNewsletterLikeLoading]);


const handleChangeCheck = (event) => { 
  
  let crt_check = event.target.checked;
  let like_type = 'like';
  if(!crt_check){
    like_type = 'unlike';
  }
  setChecked(crt_check);
  let vid_params = { blog_id: ActiveData?.id, type: like_type, user_id: userId}
  dispatch(SingleNewsletterLikAction(vid_params));

};
  
const goToTop = () => {
  document.querySelector('.video-right').scroll(0, 0)
}

const [CrtData, setCrtData] = useState([]);
useEffect(() => {
  console.log('detailLoading >>> ', detailLoading)
  if(!detailLoading){
    
    let BlogAddonTot = (detailData?.BlogAddonTot && detailData?.BlogAddonTot.length) > 0 ? detailData?.BlogAddonTot : []
    let aaa = [...[detailData?.blog], ...BlogAddonTot];
    aaa.sort((a,b)=>{
      return new Date(b.created_at) - new Date(a.created_at)
    })
    setValue(Number(0))
    if(aaa.length > 0){
      setActiveData(aaa[0])
      let a0 = aaa[0];
      let vid_params = { blog_id: a0?.id, type: "", user_id: userId}
      dispatch(SingleNewsletterLikAction(vid_params));

      let paramsList = {
        blog_id: a0?.id,
        user_id:userId,
      };
      dispatch(NewsletterCommentAction(paramsList));
    }
    setCrtData(aaa);
  }
}, [detailLoading])
const addtolist = async(type) => {
  if(type ==1)
  {
   let vid_params = { user_id:userId, blog_id: ActiveData?.id,type:"blog"}

   await dispatch(AddToMylistAction(vid_params));
  }
  else{
   let r_params = { user_id:userId, blog_id:ActiveData?.id,type:"blog"}

   await dispatch(RemoveToMylistAction(r_params));

  }
  let prams = { "user_id": userId,"type":"blog"};
  dispatch(MyBlogListAction(prams));

}
  return (
    <div className={`video-right`} style={{ 
      padding:'1rem 0'
     }}>
      <Box sx={{ 
        position:'relative'
       }}>
            {
              detailLoading ?
              <Box sx={{ 
                marginTop:'1rem'
               }}>
                <CircularProgress />
              </Box>
              :
              <>
              <Box sx={{ position:'sticky', top:'-1rem', marginBottom: '.5rem', backgroundColor:'#fff',boxShadow:'0 0 3px 1px #ededed', zIndex:'10' }}>
                <Box sx={{
                  paddingTop:'.5rem'
                }}>
                  <Tabs
                    value={value}
                    // onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    {
                     CrtData && CrtData.length > 0  && CrtData.map((item, i)=>{
                       return (
                        <Tab onClick={(e)=>handleChange(e, Number(i), item)} key={i} label={moment(item?.created_at).format('DD-MMM-YYYY')} />
                       )
                     })
                    }
                  </Tabs>
                </Box>
              </Box>
                <div style={{ padding:'1rem 1.4rem' }} dangerouslySetInnerHTML={{ 
                  __html: ActiveData?.discription
                 }} />

                 
          {
            ActiveData && (
              <>
                <div style={{ 
                  marginTop:'.5rem',
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'space-between',
                  padding: '1rem 1.4rem'
                }}>
                  <Box style={{ 
                      display:'flex',
                      fontSize:'1rem',
                      alignItems:'center'
                    }}
                  >
                    <Checkbox 
                      {...label}
                      onChange={(e)=>handleChangeCheck(e)} 
                      icon={<FavoriteBorder />}
                      checkedIcon={<Favorite sx={{ color: 'red' }} />}
                      checked={checked}
                      sx={{ 
                        padding:'0 .2rem 0 0'
                      }}
                    />
                    <div>
                      {SingleNewsletterLikeData?.likecount || Number(0)} Like
                    </div>
                  </Box>
                  
                {
                  MyFavorite.includes(ActiveData?.id) ?
                    <Button  onClick={()=>addtolist(Number(0))} className='addToList' size="small"><BiTrash style={{ 
                      marginRight:'.2rem'
                    }} />  Remove From List</Button>
                    :
                    <Button  onClick={()=>addtolist(Number(1))} className='addToList' size="small">Add To List</Button>
                }
              {/* <Button  onClick={()=>addtolist(Number(1))} className='addToList' size="small">Add To List</Button>
              <Button  onClick={()=>addtolist(Number(0))} className='addToList' size="small">Remove From List</Button> */}
              
                  {/* <Button className='addToList' size="small">Add To List</Button> */}
                </div>
                <div id='commentContainer' style={{ 
                  padding: '1rem 1.4rem'
                }}>
                  {/* {console.log('ActiveData >>> ', ActiveData)} */}
                  <CommentList ActiveItem={ActiveData} />
                  <Button onClick={()=>goToTop()} variant="contained" sx={{ 
                        borderRadius:'50px',
                        minHeight:'50px',
                        minWidth:'50px',
                        margin:'1rem 0',
                        padding: 0,
                        float:'right',
                        position:'relative',
                        bottom: '2.5rem'
                      }}>
                        <AiOutlineArrowUp size={'1.5rem'}/>
                      </Button>
                </div>

              </>
            )
          }
          
              </>
            }
          </Box>


          
    </div>
  )
}

export default RightSection