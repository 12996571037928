import * as React from 'react';
import addWeeks from 'date-fns/addWeeks';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DateRangePicker from '@mui/lab/DateRangePicker';
import { StaticDateRangePicker } from '@mui/lab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import Moment from 'moment'

import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; //main style file
import 'react-date-range/dist/theme/default.css'; //theme css file




function DateRangePickerNew(date, amount) {
  return date ? addWeeks(date, amount) : undefined;
}

export default function MinMaxDateRangePicker(props) {

  const {value, setValue} = props;

  const [SelectedBtnType, setSelectedBtnType] = React.useState(1);


  const chooseDates = (id) => {
    // console.log(id);
    if (id === 1) {
      setValue([Moment().toDate(), Moment().toDate()]);
    } else
      if (id === 2) {
        setValue([Moment().subtract(1, 'day').toDate(), Moment().subtract(1, 'day').toDate()]);
      } else
        if (id === 3) {
          setValue([Moment().subtract(1, 'week').add(1, 'day').toDate(), Moment().toDate()]);
        } else
          if (id === 4) {
            setValue([Moment().subtract(1, 'month').add(1, 'day').toDate(), Moment().toDate()]);
          }
          if (id === 5) {
            setValue([Moment().subtract(8, 'day').add(1, 'day').toDate(), Moment().toDate()]);
          }

    setSelectedBtnType(id)

  }
  

  const buttonArray = [

    {
      name: 'Today',
      type: 'T',
      id: 1,
    },
    {
      name: 'Yesterday',
      type: 'Y',
      id: 2,
    },
    {
      name: 'Last Week',
      type: 'LW',
      id: 3,
    },
    {
      name: 'Last Month',
      type: 'LM',
      id: 4,
    },
    {
      name: 'Custom',
      type: 'CS',
      id: 5,
    },
  ]
  const handleSelect = (ranges) => {
    console.log(ranges);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  }
  const [state, setState] = React.useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  return (
    <>
      <Box display="flex" flexDirection={'column'}>
        {
          value[0] && value[1] ?
            <>
              <Typography sx={{ textAlign: 'center', color: '#000', fontWeight: 'bold' }} variant="h6" gutterBottom >{Moment(value[0]).format('MMM DD, YYYY')} {SelectedBtnType != 1 && SelectedBtnType != 2 ? " - " + Moment(value[1]).format('MMM DD, YYYY') : null}</Typography>
            </>
            :
            null
        }
        <Box display="flex" flexDirection={'row'} justifyContent="space-between" sx={{ marginBottom: 2 }} >
          {
            buttonArray.map((item, index) => {
              return (
                <Button sx={{ marginLeft: 1, marginRight: 1 }} onClick={() => chooseDates(item.id)} variant={item.id === SelectedBtnType ? 'contained' : 'outlined'} >{item.name}</Button>
              )
            })
          }
        </Box>
        {
          SelectedBtnType === 5 ?
            <>
    <DateRange
        onChange={item => {setState([item.selection]); setValue([Moment(item.selection.startDate).toDate(), Moment(item.selection.endDate).toDate()])}}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
        maxDate={new Date()}
        showMonthAndYearPickers={false}
        // showPreview={false}
        
      />


              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>

                <StaticDateRangePicker
                  maxDate={new Date()}
                  displayStaticWrapperAs="desktop"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField {...endProps} />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider> */}
            </>
            :
            null
        }

      </Box>
    </>
  );
} 
