import { Autocomplete, Box, Button, Divider, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FIIsModelAction, FIISDateMasterAction, FIIsSectorlAction, FIIsTopBottomAction } from '../redux/actions/userAction';
import LineChart from '../components/CustomChart/LineChart';
import MUITable from '../components/MaterialUITable/MUITable';

const INV = () => {
    const dispatch = useDispatch();
    const { data: { Data: SectorsData }, loading: sectorLoading } = useSelector(state => state.FIIsSectorReducer);

    const { data: { Data: ModelData }, loading: ModelDataLoading } = useSelector(state => state.FIIsModelReducer);

    const { data: { Data: FIIsTopBottomData }, loading: FIIsTopBottomLoading } = useSelector(state => state.FIIsTopBottomReducer);

    const { data: { Data: FIISDateMaster }, loading: FIISDateMasterLoading } = useSelector(state => state.FIISDateMasterReducer);

    const [ActiveType, setActiveType] = useState(2);
    const [SectorsDataArr, setSectorsDataArr] = useState([])
    const [SelectedSectorsData, setSelectedSectorsData] = useState(null);

    const [ChartData, setChartData] = useState([])
    const [ChartLabel, setChartLabel] = useState([])

    const [TRows, setTRows] = useState([]);
    const [TCols, setTCols] = useState([]);

    const [DateMaster, setDateMaster] = useState([]);
    let defaultRange = {
        from:{title:'',value:''},
        to:{title:'',value:''},
    };
    if(FIISDateMaster && FIISDateMaster.length > 0){
        defaultRange = {
            from:{title:FIISDateMaster[0]?.date,value:FIISDateMaster[0]?.date},
            to:{title:FIISDateMaster[FIISDateMaster.length-1]?.date,value:FIISDateMaster[FIISDateMaster.length-1]?.date},
        };
    }

    const [SelectRange, setSelectRange] = useState(defaultRange);
    // console.log('defaultRange >>> ', defaultRange);
    // console.log('SelectRange >>> ', SelectRange);

    const changeDate = (type, cData) => {
        setSelectRange({
            ...SelectRange,
            [type]:cData
        });
    }

    
    const applyFilter = () => {
        if(SelectRange.from?.value == "" || SelectRange.to?.value == ""){
            alert('Please select date range!')
            return false;
        }

        dispatch(FIIsModelAction({ 
            "sector": ActiveType == 2 ? SelectedSectorsData?.value : "0",
            "date": [SelectRange.from?.value, SelectRange.to?.value],
            "type": "INV"
        }));
    }

    

    const DateRangeBox = () => {
        return (
            <>
                <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        // width:'100%',
                        marginY: 1,
                        padding: 1
                    }}
                >
                    <label style={{}}>Choose Date Range</label>
                    <Box sx={{
                        display: 'inline-flex',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        flexDirection: 'row',
                        paddingTop: 1
                    }}>
                        <Autocomplete
                            disablePortal
                            id="sectorsInput"
                            options={DateMaster}
                            size="small"
                            value={SelectRange?.from}
                            getOptionLabel={(option) => option.title}
                            sx={{ width: '200px', marginX: 1 }}
                            name="from"
                            onChange={(event, newInputValue) => {
                                // setSelectedSectorsData(newInputValue);
                              changeDate('from', newInputValue)
                            }}
                            renderInput={(params) => <TextField className="sectorsInput" style={{ fontSize: '100px !important' }} placeholder='Start Date' {...params} />}
                        />

                        <Autocomplete
                            disablePortal
                            id="sectorsInput"
                            options={DateMaster}
                            size="small"
                            name="to"
                            value={SelectRange?.to}
                            getOptionLabel={(option) => option.title}
                            sx={{ width: '200px' }}
                            onChange={(event, newInputValue) => {
                              // setSelectedSectorsData(newInputValue);
                              changeDate('to', newInputValue)
                            }}
                            renderInput={(params) => <TextField className="sectorsInput" style={{ fontSize: '100px !important' }} placeholder='End Date' {...params} />}
                        />
                            <Button sx={{ marginLeft:1 }} variant="contained" onClick={()=>applyFilter()} >Apply</Button>
                    </Box>
                </Box>
            </>
        )
    }

    const AllSectors = () => {
        return (
            <>
                <Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        flexDirection: 'row',
                        width: '100%',
                        marginY: 1,
                        padding: 1
                    }}>
                        <DateRangeBox />

                    </Box>

                    <Divider sx={{ marginY: 1, }} />
                    <Box>
                        <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                            <Grid items sm={12}>
                                <Box sx={{
                                    paddingTop: 2,
                                    paddingLeft: 2,
                                }}
                                >
                                    <Typography sx={{
                                        textAlign: "right"
                                    }}> In Cr.</Typography>
                                    <MUITable setActiveType={setActiveType} SectorsDataArr={SectorsDataArr} SelectedSectorsData={SelectedSectorsData} setSelectedSectorsData={setSelectedSectorsData} tableColumns={TCols} tableRows={TRows} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </>
        )
    }


    const SingleSector = () => {
        return (
            <>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                    width: '100%',
                    marginY: 1,
                    padding: 1
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        // width:'100%',
                        marginY: 1,
                        padding: 1
                    }}
                    >
                        <label style={{ paddingBottom: 10 }}>Choose Single Sector</label>
                        <Autocomplete
                            disablePortal
                            id="sectorsInput"
                            options={SectorsDataArr}
                            size="small"
                            value={SelectedSectorsData}
                            getOptionLabel={(option) => option.title}
                            sx={{ width: '400px' }}
                            onChange={(event, newInputValue) => {
                                setSelectedSectorsData(newInputValue);
                            }}
                            renderInput={(params) => <TextField className="sectorsInput" style={{ fontSize: '100px !important' }} placeholder='Choose Sector' {...params} />}
                        />
                    </Box>
                    
                    <DateRangeBox />

                </Box>

                <Divider sx={{ marginY: 1, }} />

                {
                    SelectedSectorsData && (
                        <Box>
                            <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                                <Grid items sm={3} >
                                    <Box sx={{
                                        paddingTop: 2,
                                        paddingLeft: 1,
                                        paddingRight: 1,
                                        marginLeft: 1
                                    }}
                                    >
                                        <MUITable tableColumns={TCols} tableRows={TRows} />
                                    </Box>
                                </Grid>
                                <Grid items sm={6} sx={{ paddingLeft: 1 }}>
                                    <LineChart SelectedSectorsData={SelectedSectorsData} chartData={ChartData} chartLabel={ChartLabel} />
                                </Grid>
                            </Grid>
                        </Box>
                    )
                }

            </>
        )
    }

    const FIIsTopBottom = () => {
        return (
            <>
                <Grid container spacing={2} >
                    {
                        FIIsTopBottomData && FIIsTopBottomData.map((item)=>{
                            let TopChartData = [];
                            let TopChartLabel = [];
                            let BottomChartData = [];
                            let BottomChartLabel = [];

                                TopChartData = item.Top5Data.map(itm => itm?.Value);
                                TopChartLabel = item.Top5Data.map(itm => itm?.sector);
                                BottomChartData = item.Bottom5Data.map(itm => itm?.Value);
                                BottomChartLabel = item.Bottom5Data.map(itm => itm?.sector);


                            return (
                                <>

                                <Grid item xs={6} >
                                    <Box sx={{ marginTop: 2 }}>
                                        <Typography variant="h6" sx={{
                                            textAlign:'center',
                                            fontWeight:'bold'
                                        }} >{item.Top5Data[0].Date}</Typography>
                                    </Box>
                                    <Box>
                                        <LineChart ratio_type="top_bottom" chartData={TopChartData} chartLabel={TopChartLabel} />
                                    </Box>
                                </Grid>
                                <Grid item xs={6} >
                                    <Box sx={{ marginTop: 2 }}>
                                        <Typography variant="h6" sx={{
                                            textAlign:'center',
                                            fontWeight:'bold'
                                        }} >{item.Bottom5Data[0].Date}</Typography>
                                    </Box>
                                    <Box>
                                        <LineChart ratio_type="top_bottom" chartData={BottomChartData} chartLabel={BottomChartLabel} />
                                    </Box>
                                </Grid>
                                
                                <Grid item xs={12} >
                                    <Divider />
                                </Grid>
                                </>
                            )
                        })
                    }
                </Grid>
            </>
        );
    }

    const SectorsDataMap = () => {
        if (SectorsData && SectorsData.length > 0) {
            var data1 = [];
            SectorsData.map((item) => {
                var d1 = { title: item.Sector, value: item.sectorId };
                data1.push(d1);
            })
            setSectorsDataArr(data1);
        }
    }

    useEffect(() => {
        if (sectorLoading) {
            dispatch(FIIsSectorlAction());
        }
        if (!sectorLoading) {
            SectorsDataMap();
        }
    }, [sectorLoading])

    useEffect(() => {
        setTRows([]);
        setTCols([]);
        if (SelectedSectorsData && ActiveType == 2) {
            setSelectRange(defaultRange);
            dispatch(FIIsModelAction({ "sector": SelectedSectorsData?.value, "date": [SelectRange?.from.value, SelectRange?.to.value], "type": "INV" }));
        }
        if (ActiveType == 1) {
            setSelectRange(defaultRange);
            dispatch(FIIsModelAction({ "sector": "0", "date": [SelectRange?.from.value, SelectRange?.to.value], "type": "INV" }));
        }
        if (ActiveType == 3) {
            // console.log('dasdas');
            dispatch(FIIsTopBottomAction({ "date": [SelectRange?.from.value, SelectRange?.to.value], "type": "INV" }));
        }
    }, [SelectedSectorsData, ActiveType]);
    
    useEffect(() => {
        if (!ModelDataLoading) {

            if (ActiveType == 1) {
                var colArr1 = [];
                var tRow1 = [];
                // let objClone  = {};
                // let td = {};
                var colArr = [];
                var tRow = [];
                ModelData.map((items, i) => {
                    var FIISSceDetails = items.FIISSceDetails;
                    let objClone = {};
                    setChartData(FIISSceDetails.map(item => parseFloat(item.Value)));
                    setChartLabel(FIISSceDetails.map(item => item.Date));
                    if (i == 0) {
                        var dd = {
                            id: 'sectorName',
                            label: 'Sector Name',
                            sticky: true,
                            minWidth: 170
                        }
                        colArr.push(dd);
                    }
                    objClone = { ...objClone, sectorName: items.sectorName };
                    FIISSceDetails.map((item, i0) => {
                        if (i == 0) {

                            var dd = {
                                id: item.Date,
                                label: item.Date,
                                sticky: false,
                                minWidth: 120
                            }
                            colArr.push(dd);
                        }
                        objClone = { ...objClone, [item.Date]: parseFloat(item.Value) };
                    });
                    tRow.push(objClone);

                });
                setTCols(colArr);
                setTRows(tRow);

            } else {
                if (ModelData && ModelData.length > 0) {
                    const NewModelData = ModelData[0]?.FIISSceDetails;
                    setChartData(NewModelData.map(item => parseFloat(item.Value)));
                    setChartLabel(NewModelData.map(item => item.Date));
                    var colArr = [];
                    var tRow = [];
                    let objClone = {};
                    let td = {};


                    colArr = [
                        {
                            id: 'label',
                            label: 'Date',
                            minWidth: 120,
                            maxWidth: 120,

                        },
                        {
                            id: 'values',
                            label: 'Values',
                            minWidth: 120,
                            maxWidth: 120,

                        }
                    ];

                    NewModelData.map(item => {
                        objClone = { ...objClone, 'label': item.Date, 'values': parseFloat(item.Value) };
                        tRow.push(objClone);

                    });
                    setTCols(colArr);
                    setTRows(tRow);
                }
            }


        }
    }, [ModelDataLoading]);

    useEffect(() => {
        if(FIISDateMasterLoading){
            dispatch(FIISDateMasterAction({ "sector": "1", "type": "INV" }));
        }
        if(!FIISDateMasterLoading){
            var aData = [];
            // console.log('FIISDateMaster >>> ', FIISDateMaster);
            FIISDateMaster.map((item)=>{
                aData.push({title:item?.date, value:item?.date});
            })
            setDateMaster(aData)
            setSelectRange(defaultRange)
        }
    }, [dispatch, FIISDateMasterLoading])
    



    return (
        <>
        
        
            <Box marginTop={2}>

                <Box sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%'
                }}>
                    <Button sx={{ marginX: 1 }} onClick={() => { setActiveType(1) }} variant={ActiveType == 1 ? "contained" : "outlined"} size="small">
                        Select All Sectors
                    </Button>
                    {/* <Typography variant="subtitle2" sx={{ 
                marginX:2
             }}>
                 Or 
            </Typography> */}
                    <Button sx={{ marginX: 1 }} onClick={() => { setActiveType(2) }} variant={ActiveType == 2 ? "contained" : "outlined"} size="small">
                        Choose Single Sector
                    </Button>
                    <Button sx={{ marginX: 1 }} onClick={() => { setActiveType(3) }} variant={ActiveType == 3 ? "contained" : "outlined"} size="small">
                        Top 5 Sector/ Bottom 5 Sector
                    </Button>
                </Box>

                <Box marginTop={2}>
                    {
                        ActiveType == 1 ?
                            <>
                                <AllSectors />
                            </>
                            : null
                    }
                    {
                        ActiveType == 2 ?
                            <>
                                <SingleSector />
                            </>
                            : null
                    }
                    {
                        ActiveType == 3 ?
                            <>
                                {/* <Typography>In Progress</Typography> */}
                                <FIIsTopBottom />
                            </>
                            : null
                    }

                </Box>
            </Box>
        </>
    )
}

export default INV