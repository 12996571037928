import React from 'react'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { TextField, Grid } from '@mui/material';

const InputComponent = ({ name, label, control, ...props }) => {


    return (
        <>
        
            {
                label && <label className='sidebar-label'>{label}</label>
            }

            <Controller
                name={name}
                control={control}
                render={({ field }) => <TextField {...props} {...field} />}
            />
            
        </>
    )
}

export default InputComponent

