import {
  Box,
  IconButton,
  Button,
  Chip,
  Container,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  ListItemText,
  Checkbox,
  Autocomplete,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { companyMasterAction } from "../../../redux/actions/userAction";

const PeersCompDrawer = (props) => {
  const { toggleDrawer, State, setState } = props;
  const { data: PeerRatioData, loading: PeerRatioLoading, isSelected, selected_companies, other_companies } = useSelector(state => state.PeerRatiosReducer);
  const { data:companyMaster, loading:companyMasterLoading, totalRow } = useSelector((state) => state.companyMasterData);

  const [ChipData, setChipData] = useState(PeerRatioData);
  const [checked, setChecked] = React.useState([]);

  const [ItemList, setItemList] = React.useState([]);
  const [SelectedCompanies, setSelectedCompanies] = React.useState([]);
  const [Companies, setCompanies] = React.useState([]);
  
  const [RatiosCategory, setRatiosCategory] = React.useState([]);
  const [CheckBoxDisable, setCheckBoxDisable] = React.useState(false);
  

const dispatch = useDispatch();
const selectCompany = () => {
  if(totalRow > 0){
    var data1 = [];
    companyMaster.map((item)=>{
      var d1 = {title:item.CompanyName,name:item.CompanyName,value:item.CompanyID};
      data1.push(d1);
    })
    
    data1.sort(function(a, b){
        if(a.title < b.title) { return -1; }
        if(a.title > b.title) { return 1; }
        return 0;
    })
    
    setItemList(data1);
    
  }
}
useEffect(()=>{
  if(companyMasterLoading){
    dispatch(companyMasterAction())
  }
  if(!companyMasterLoading){
    selectCompany()
  }
}, [dispatch, companyMasterLoading])

  useEffect(()=>{
    if(!PeerRatioLoading){
      // console.log('selected_companies ???? >', selected_companies)
      setSelectedCompanies(selected_companies)
      setCompanies(other_companies)
      const uniquePeerRatioData = [...new Set(PeerRatioData.map(item => item?.category))];
      // console.log('uniquePeerRatioData >> ', uniquePeerRatioData)
      setRatiosCategory(uniquePeerRatioData)
      let selectedData = PeerRatioData.filter(item=>item?.is_selected === true);
      setChipData(selectedData)
      setChecked(selectedData.map(item=>item.ID));
    }
  },[PeerRatioLoading])

  const handleDelete = (e, chipToDelete) => {
    setChipData((chips) => chips.filter((chip) => chip.ID !== chipToDelete.ID));
  };
  const FilterPeers = (e) => {
    dispatch({
      type:'PEER_RATIOS_SELECTED',
      payload: ChipData.map(item=>item.ID)
    })
    dispatch({
      type:'PEER_RATIOS_SELECTED_COMPANY',
      payload: SelectedCompanies
    })
    toggleDrawer('bottom', false)(e)
  }
  const handleToggle = (item) => () => {

    
    let value = item.ID;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    
    if (currentIndex === -1) {
      let a00 = ChipData;
          a00.push(item);
        setChipData(a00);
      newChecked.push(value);
    } else {          
      setChipData((chips) => chips.filter((chip) => chip.ID !== item.ID));
      newChecked.splice(currentIndex, 1);
    }

    // console.log('newChecked >> ', newChecked, (newChecked.length >= 10))
    if(newChecked.length >= 10){
      setCheckBoxDisable(true)
    }else{
      setCheckBoxDisable(false)
    }

    setChecked(newChecked);
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        height: "calc(100vh - 60px)",
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Container>
        <Box sx={{ 
              display:'flex',
              gap:".5rem",
              marginTop:'1rem',
              alignItems: 'center',
              justifyContent: 'space-between',
             }} >
        <Typography
          variant="h5"
          sx={{
            margin: ".5rem 0rem 0",
            fontSize:'1rem',
            // fontWeight:'500'
          }}
        >
          {/* Manage Ratios*/}
          Please select upto 5 companies and Multiple Ratios!

        </Typography>
        <div style={{ 
              display:'flex',
              gap:".5rem",
              marginTop:'1rem'
             }}>
              <Button disabled={ChipData.length === 0 ? true : false } onClick={(e)=>FilterPeers(e)} variant="contained" size="small">Apply</Button>
              <Button onClick={toggleDrawer(anchor, false)} variant="outlined" size="small">Cancel</Button>
            </div>

        </Box>
        <Divider
            sx={{
              marginTop: 1,
              marginBottom: 2,
            }}
          />
        <Box>
          <Box sx={{
              marginBottom: 2,
            }}>
            <Box sx={{ 
              width:'100%',
              // maxWidth:'350px'
             }}>
              <Typography sx={{ 

               }}>Choose Company</Typography>
              <Autocomplete
                id="free-solo-2-demo"
                disableClearable
                size="small"
                options={ItemList}
                multiple={true}
                disableCloseOnSelect
                defaultValue={SelectedCompanies}
                getOptionDisabled={(options) => {
                  if (Companies.length >= 5) {
                      if (!Companies.includes(options.value)) {
                          return true
                      }
                  }
                }}
                onChange={(evt, newVal) => {
                  var val1 = [];
                  for (var a = 0; a < newVal.length; a++) {
                      val1.push(newVal[a].value);
                  }
                  // console.log('newVal >>>> ', newVal)
                  setSelectedCompanies(newVal);
                  setCompanies(val1);
              }}
                sx={{ width: '100%', borderColor: "#000" }}
                getOptionLabel={(option) => option.title}
                // renderOption={(props, option) => <li {...props}>{option.title}</li>}
                renderInput={(params) => (
                  <>
                    <TextField size="small" {...params} placeholder="Select..." className='aaaaaa' sx={{ borderColor: "#000" }} />
                  </>
                )}
              />
             </Box>
          </Box>
          <Box>
            {
              ChipData.length === 0 ? (
                <Typography variant="h6" sx={{ color:'#c93333', fontSize:'1rem' }}>At least one column must be selected.</Typography>
              ) : 
                <>
                  <Typography sx={{ }}>Selected Ratios</Typography>
                </>
            }
            
            
            <Box sx={{ 
              gap:'.2rem',
              display:'flex',
              flexWrap:'wrap',
             }}>
              {ChipData.map((item, i) => {
                return (
                  <Chip
                    label={item?.Name}
                    onDelete={(e) => handleDelete(e, item)}
                  />
                );
              })}
            </Box>
            
          </Box>
          <Divider
            sx={{
              paddingY: 2,
            }}
          />
          <Box>
            
          <Typography variant="h6"  sx={{ marginTop: '1rem' }}>Manage Ratios</Typography>
          <Box sx={{ 
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            marginTop:'.5rem'
           }}>
            {
              RatiosCategory.map((c_item, i0)=>{
                  return (
                    <Box key={i0} >
                      <Typography sx={{ 
                        fontWeight:'600',
                        fontSize:'.9rem',
                       }}>{c_item}</Typography>
                       <Box >
                       <List sx={{ width: "100%", bgcolor: "background.paper", paddingTop:'0' }}>
                        {PeerRatioData && PeerRatioData.length > 0 && PeerRatioData.filter(fItm=>fItm.category === c_item).map((item) => {
                            
                          let crtItem = ChipData.find(cItm => cItm?.ID===item?.ID);
                          let value = item.ID;
                          const labelId = `checkbox-list-label-${value}`;
                          
                          return (
                            <>

                  <ListItem
                    key={value}
                    secondaryAction={
                      <>
                        {/* <IconButton edge="end" aria-label="comments">
                          <CommentIcon />
                        </IconButton> */}
                      </>
                    }
                    disablePadding
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(item)}
                      dense
                      disabled={!ChipData.find(cItm => cItm?.ID===item?.ID) && CheckBoxDisable}
                    >
                      <ListItemIcon sx={{ minWidth:'auto', padding:'0' }} >
                        <Checkbox
                          edge="start"
                          // checked={checked.indexOf(value) !== -1}
                          checked={(crtItem !== undefined && crtItem !== null) ? true : false}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                          size="small"
                          sx={{ minWidth:'auto', padding:'0', paddingRight:'0.2rem' }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{fontSize: '.8rem'}} 
                        id={labelId}
                        primary={`${item.Name}`}
                      />
                    </ListItemButton>
                  </ListItem>
                            </>
                          )
                        })}


                       </List>
                          
                       </Box>
                    </Box>
                  )
              })
            }
            <Box>
              
            </Box>
          </Box>

            {/* <List sx={{ width: "100%", bgcolor: "background.paper", columns:3 }}>
              {PeerRatioData && PeerRatioData.length > 0 && PeerRatioData.map((item) => {
                
                let crtItem = ChipData.find(cItm => cItm?.ID===item?.ID);
                let value = item.ID;
                const labelId = `checkbox-list-label-${value}`;

                return (
                  <ListItem
                    key={value}
                    secondaryAction={
                      <>
                      </>
                    }
                    disablePadding
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(item)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          // checked={checked.indexOf(value) !== -1}
                          checked={(crtItem !== undefined && crtItem !== null) ? true : false}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={`${item.Name}`}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List> */}
          </Box>
        </Box>
      </Container>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"bottom"}>
        {/* <Button onClick={toggleDrawer('bottom', true)}>{'bottom'}</Button> */}
        <Drawer
          anchor={"bottom"}
          open={State["bottom"]}
          onClose={toggleDrawer("bottom", false)}
          // className="i_AM_NEW"
        >
          {list("bottom")}
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default PeersCompDrawer;
