import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Box, Typography } from '@mui/material';


const SelectSector = (props) => {

    const {
        sectors,
        sectorMasterArr,
        setSectors,
        setSectorMasterArr,
        sectorsIds,
        setSectorsIds,
        sectorMaster,
    } = props;


    useEffect(() => {
        selectSectors();
        // console.warn(sectorMaster);
    }, [sectorMaster]);


    const selectSectors = () => {
        if (sectorMaster.length > 0) {
            var data1 = [];
            sectorMaster.map((item) => {
                var d1 = { title: item.Sector, value: item.sectorID };
                data1.push(d1);
            })
            setSectorMasterArr(data1);
        }
    }

    return (
        <>

            <Box
                display="flex"
                flexDirection="row"
                alignContent={'center'}
                justifyContent={'space-between'}
            >
                <div style={{width:'100%', margin:'0 0.2rem'}}>
                    
                    <Typography sx={{ fontSize:'0.7rem' }} className="fontWeight-600 " >Sectors ({sectorMasterArr.length})</Typography>
                    <Autocomplete
                    sx={{ 

                     }}
                        disableCloseOnSelect   // disable the close-on-select-option
                        multiple
                        limitTags={2}
                        id="multiple-limit-tags"
                        options={sectorMasterArr}
                        getOptionLabel={(option) => option.title}
                        defaultValue={sectors}
                        size="small"
                        className='selectInputNew'
                        renderInput={(params) => (
                            <TextField  className='fontSize-12px' {...params} size="small" placeholder="Select Sectors" />
                        )}
                        fullWidth
                        // sx={{ marginX: 1 }}
                        onChange={(event, newInputValue) => {
                            var val1 = [];
                            for (var a = 0; a < newInputValue.length; a++) {
                                val1.push(newInputValue[a].value);
                            }
                            // setInputs({ ...inputs, ['Sectors']: val1 });
                            setSectors(newInputValue);
                            setSectorsIds(val1);
                        }}


                    />
                </div>
            </Box>


        </>
    )
}

export default SelectSector