import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    IconButton,
    ListSubheader,
    Typography,
    Alert,
    Stack,
    Button,
} from '@mui/material'
import React, { useEffect } from 'react'

import Checkbox from '@mui/material/Checkbox';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
// import { historicalTrendChooseField } from '../../../constants/defaultArray';

import { red } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux'
import { historicalTrendChooseField } from '../../../../constants/defaultArray';




const HistoricalTrendChooseField = (props) => {

    const { applyChart } = props;
    
    const [checked, setChecked] = React.useState([]);
    const [DisabledItem, setDisabledItem] = React.useState(false);
    const [ShowDiv, setShowDiv] = React.useState(false);
    const [RatiosCategory, setRatiosCategory] = React.useState(false);

    const [ShowError, setShowError] = React.useState(false);
    const { data: PeerRatioData, loading: PeerRatioLoading, isSelected, selected_companies, other_companies } = useSelector(state => state.PeerRatiosReducer);

    useEffect(()=>{
        if(!PeerRatioLoading){
            const uniquePeerRatioData = [...new Set(PeerRatioData.map(item => item?.category))];
            setRatiosCategory(uniquePeerRatioData)
            let aaa = PeerRatioData.filter(item=>item?.is_selected === true);
            setChecked(aaa.map(item=> item?.Name));
        }
    }, [PeerRatioLoading])


    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        
        if (checked.length < 6 || checked.includes(value)) {
            const newChecked = [...checked];
            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
            setChecked(newChecked);
            setDisabledItem(false);
        } else {
            setDisabledItem(true);
        }
    };

    return (
        <>
            <Box
                sx={{
                    textAlign: 'right',
                    marginBottom: 2,
                }}
            >

                {
                    ShowDiv ?
                        <>
                            <>
                                <Button
                                    disabled={checked.length === 0 ? true : false}
                                    variant='contained'
                                    size="small"
                                    color='success'
                                    onClick={() => { applyChart(checked); setShowDiv(!ShowDiv); }}
                                >
                                    Apply
                                </Button>
                                <Button
                                    disabled={checked.length === 0 ? true : false}
                                    onClick={() => { setDisabledItem(false); setChecked([]); }}
                                    sx={{
                                        marginLeft: 0.5
                                    }}
                                    variant='contained'
                                    size="small"
                                    color='secondary'
                                >
                                    Uncheck All
                                </Button>
                            </>

                            <Button
                                sx={{
                                    marginLeft: 0.5
                                }}
                                variant='contained'
                                size="small"
                                color='error'
                                onClick={() => setShowDiv(!ShowDiv)}
                            >
                                Cancel
                            </Button>



                        </>
                        :
                        <>
                            <Button
                                variant='contained'
                                size="small"
                                onClick={() => setShowDiv(!ShowDiv)}
                            >
                                Choose Charts
                            </Button>
                        </>
                }

            </Box>
            {
                ShowDiv ?
                    <>

                        <Box
                            sx={{
                                marginBottom: 2,
                            }} >
                            {
                                ShowError ?
                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                        <Alert
                                            severity="error"
                                            action={
                                                ""
                                            }
                                        >
                                            Please select minimum 1 chart.
                                        </Alert>
                                    </Stack>
                                    :
                                    null
                            }
                            {
                                DisabledItem ?
                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                        <Alert
                                            severity="error"
                                            action={
                                                ""
                                            }
                                        >
                                            You can select upto {checked.length} chart type.
                                        </Alert>
                                    </Stack>
                                    :
                                    null
                            }

                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    margin: "0 0 1rem"
                                }}
                                justifyContent="space-between"
                                className="ChooseFieldContainer"
                            >
                                {
                                    RatiosCategory.length > 0 && RatiosCategory.map((c_item, i00) => {
                                        return (
                                            <Box className="ChooseFieldItem" key={i00} >
                                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                                        subheader={<ListSubheader sx={{ fontSize: 18 }}>{c_item}</ListSubheader>}
                                                    >
                                                        {PeerRatioData && PeerRatioData.length > 0 && PeerRatioData.filter(fItm=>fItm.category === c_item).map((value, i1) => {
                                                            const labelId = `checkbox-list-label-${i1}`;
                                                            return (
                                                                <ListItem
                                                                    key={value.title}
                                                                    disablePadding
                                                                >
                                                                    <ListItemButton role={undefined} onClick={() => {
                                                                        handleToggle(value.Name)
                                                                    }} dense>
                                                                        <ListItemIcon
                                                                            sx={{
                                                                                minWidth: '1rem',
                                                                            }}
                                                                        >
                                                                            <Checkbox
                                                                                sx={{
                                                                                    padding: '0 0.3rem 0 0.8rem'
                                                                                }}
                                                                                edge="start"
                                                                                checked={checked.indexOf(value.Name) !== -1}
                                                                                tabIndex={-1}
                                                                                disableRipple
                                                                                size="small"
                                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                                            />
                                                                            {/* <Radio
                                                                                sx={{
                                                                                    padding: '0 0.3rem 0 0rem'
                                                                                }}
                                                                                checked={selectedValue === value.Name}
                                                                                size="small"
                                                                                value={value.Name}
                                                                                name="radio-buttons"
                                                                                inputProps={{ 'aria-label': 'A' }}
                                                                            /> */}
                                                                        </ListItemIcon>
                                                                        <ListItemText id={labelId}
                                                                            primary={<Typography fontSize={12} >{value.Name}</Typography>}
                                                                        />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            );
                                                        })}
                                                    </List>
                                            </Box>
                                        )
                                    })
                                }

                                {/* {
                                    historicalTrendChooseField().map((item, i) => {
                                        return (
                                            <Box key={'aa'+i} className="ChooseFieldItem">
                                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                                    subheader={<ListSubheader sx={{ fontSize: 18 }}>{item.label}</ListSubheader>}
                                                >
                                                    {item?.columns.map((value, i1) => {
                                                        const labelId = `checkbox-list-label-${i1}`;
                                                        return (
                                                            <ListItem
                                                                key={value.title}
                                                                disablePadding
                                                            >
                                                                <ListItemButton role={undefined} onClick={handleToggle(value.title)} dense>
                                                                    <ListItemIcon
                                                                        sx={{
                                                                            minWidth: '1rem',
                                                                        }}
                                                                    >
                                                                        <Checkbox
                                                                            sx={{
                                                                                padding: '0 0.3rem 0 0.8rem'
                                                                            }}
                                                                            edge="start"
                                                                            checked={checked.indexOf(value.title) !== -1}
                                                                            tabIndex={-1}
                                                                            disableRipple
                                                                            size="small"
                                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText id={labelId}
                                                                        primary={<Typography fontSize={12} >{value.title}</Typography>}

                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                            </Box>
                                        )
                                    })
                                } */}


                            </Box>
                        </Box>
                    </>
                    :
                    null
            }
        </>
    )
}

export default HistoricalTrendChooseField