

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
    
    export default function InfoComponent(props) {

      const Ref = React.useRef(null);
        const {
            QuarterlySegmentType,
            Title,
            OpenInfo,
            setOpenInfo
        } = props

        const { 
            data: InfoData,
            loading: InfoLoading,
        } = useSelector(state=> state?.ResultDocumentNewReducer);
        
        const [NewData, setNewData] = React.useState({});

        React.useEffect(()=>{
            // console.log('InfoData?.Data[0] >>> ', InfoData?.Data[0])
            if(InfoData?.Data && InfoData?.Data.length > 0){
                setNewData(InfoData?.Data[0]);
            }
        }, [InfoLoading])
    
      const handleClickOpen = () => {
        setOpenInfo(true);
      };
    
      const handleClose = () => {
        setOpenInfo(false);
      };
      
    const [divData, setDivData] = React.useState(null);
    const [copied, setCopied] = React.useState(false);
    const onCopy = (props) => {
        navigator.clipboard.writeText(Ref.current.innerText)
        setCopied(true);
    };

    const getColorStatus = (val='', type='', isHide=true) => {
        if(!val) return 'N/A'
        let withStatus = val <= 0 ? 'Down' : 'Up';
        return (
            <>
                { type === 'withStatus' && withStatus } <span style={{ color: val <= 0 ? 'red' : 'green' }}>{val}{ isHide === true ? '%' : "" }</span>
            </>
        )

    }

    // if(InfoLoading){
    //     return <CircularProgress />
    // }
    
      return (
        <div>
          {/* <Button variant="outlined" onClick={handleClickOpen}>
            Open alert dialog
          </Button> */}
          <Dialog
            open={OpenInfo}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description0"
            maxWidth="lg"
          >
            <DialogTitle id="alert-dialog-title" style={{ paddingBottom:'0' }}>
              <Box sx={{ 
                display:'flex',
                alignItems:'center',
                justifyContent:'space-between'
               }}>
                <span>
                  {Title || ""}
                </span>
                <CopyToClipboard
                    onCopy={onCopy}
                    text={divData}
                >
                    <Tooltip onMouseEnter={() => setCopied(false)} title={copied ? "Copied!" : "Copy"} placement="top">
                        <IconButton>
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                </CopyToClipboard>
              </Box>
                
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description0">
                <Box>
                    {
                        InfoLoading ?
                        <>
                            <CircularProgress />
                        </>
                        :
                        <>
                            {
                                InfoData.status == 1 ?
                                <>
                                    <div id="dataToCopy" >
                                    
                                    
                                      <div ref={Ref} >
                                        <div>Omkara Results Review {QuarterlySegmentType ? ' - '+QuarterlySegmentType : ""}</div>
                                        <div><strong>{NewData?.company_name}</strong> </div>
                                        <div>M-cap Rs {NewData?.m_cap} Cr, CMP Rs {NewData?.ltp} (52W High Rs {NewData?.week52_high}, 52W Low at Rs {NewData?.week52_low}) </div>
                                        <div>Sales at Rs {NewData?.sales_S1Q} Cr vs {NewData?.sales_S2Q} Cr QoQ vs Rs {NewData?.sales_S5Q} Cr YoY ({getColorStatus(NewData?.Sales_QOQ_Per, 'withStatus')} QoQ, {getColorStatus(NewData?.Sales_YoY_Per, 'withStatus')} YoY) </div>
                                        <div>Gross Profit at Rs {NewData?.GrossProfit} Cr vs {NewData?.GrossProfit_QOQ} Cr QoQ vs Rs {NewData?.GrossProfit_YoY} Cr YoY ({getColorStatus(NewData?.GrossProfit_QOQ_Per, 'withStatus')} QoQ, {getColorStatus(NewData?.GrossProfit_YoY_Per, 'withStatus')} YoY)</div>
                                        <div>Gross Profit Margin at {getColorStatus(NewData?.GrossProfitMargin, '')} vs {getColorStatus(NewData?.GrossProfitMargin_QOQ_Per, '')} QoQ vs {getColorStatus(NewData?.GrossProfitMargin_YoY_Per, '')} YoY</div>
                                        <div>EBIDTA at Rs {NewData?.EBDITA_Cr1Q} Cr vs Rs {NewData?.EBDITA_Cr2Q} Cr QoQ vs Rs {NewData?.EBDITA_Cr5Q} Cr YoY ({getColorStatus(NewData?.EBIDTA_Growth_QoQ, 'withStatus')} QoQ, {getColorStatus(NewData?.EBIDTA_Growth_YoY, 'withStatus')} YoY) </div>
                                        <div>EBIDTA Margin at <span style={{ color: NewData?.EBDITA_Margin_Per1Q <= 0 ? 'red' : 'green' }}>{NewData?.EBDITA_Margin_Per1Q}%</span> vs <span style={{ color: NewData?.EBDITA_Margin_Per2Q <= 0 ? 'red' : 'green' }}>{NewData?.EBDITA_Margin_Per2Q}%</span> QoQ vs <span style={{ color: NewData?.EBDITA_Margin_Per5Q <= 0 ? 'red' : 'green' }}>{NewData?.EBDITA_Margin_Per5Q}%</span> YoY </div>
                                        <div>PAT at Rs {NewData?.PAT_Cr1Q} Cr vs Rs {NewData?.PAT_Cr2Q} Cr QoQ vs Rs {NewData?.PAT_Cr5Q} Cr YoY ({NewData?.PAT_Growth_QoQ_Per <= 0 ? 'Down' : 'Up'} <span style={{ color: NewData?.PAT_Growth_QoQ_Per <= 0 ? 'red' : 'green' }}> {NewData?.PAT_Growth_QoQ_Per}%</span> QoQ, {NewData?.PAT_Growth_YoY_Per <= 0 ? 'Down' : 'Up'} <span style={{ color: NewData?.PAT_Growth_YoY_Per <= 0 ? 'red' : 'green' }}>{NewData?.PAT_Growth_YoY_Per}%</span> YoY) </div>
                                        <div>Stock is trading at TTM P/E of {NewData?.TTM_P_E} </div>
                                        <div>EPS  Rs. {NewData?.EPS}. </div>
                                        <br />
                                        <br />


                                        <div><strong>Segmental Performance:-</strong></div>

                                        {
                                          NewData?.Segment && NewData?.Segment.length > 0 && NewData?.Segment.map((item, i)=>{
                                            if(item?.rData && item?.rData.length > 0){
                                              return (
                                                <React.Fragment key={i}>
                                                  <div><u>{item?.title}</u></div>
                                                  {
                                                    item?.rData.map((c_item, c_i)=>{
                                                      return (
                                                        <React.Fragment key={c_i}>
                                                          {
                                                            c_item.name === "EBIT Margin" ?
                                                            <>
                                                              <div>Segment {c_item.name} {getColorStatus(c_item?.value, '', false)} Vs {getColorStatus(c_item.YoY)} YoY, Vs {getColorStatus(c_item.QoQ)} QoQ</div>
                                                            </>
                                                            :
                                                            <>
                                                              <div>Segment {c_item.name} {getColorStatus(c_item.YoY, 'withStatus')} YoY, {getColorStatus(c_item.QoQ, 'withStatus')} QoQ at Rs.{c_item.value} Cr.</div>
                                                            </>
                                                          }
                                                        </React.Fragment>
                                                      )
                                                    })
                                                  }
                                                  <br />
                                                </React.Fragment>
                                              )
                                            }
                                          })
                                        }
                                        {/* <div>Segment Sales Up {getColorStatus(NewData?.SegmentSales_Abri_YoY, 'withStatus')} YoY, Down {getColorStatus(NewData?.SegmentSales_Abri_QoQ, 'withStatus')} QoQ at Rs.{NewData?.SegmentSales_Abri} Cr.</div>
                                        <div>Segment EBIT Up {getColorStatus(NewData?.SegmentEBIT_Abri, 'withStatus')} YoY, Down {getColorStatus(NewData?.SegmentEBIT_Abri, 'withStatus')} QoQ at Rs.{NewData?.SegmentEBIT_Abri} Cr.</div>
                                        <div>Segment EBIT Margin Up {getColorStatus(NewData?.SegmentEBITMargin_Abri, 'withStatus')} Vs {getColorStatus(NewData?.SegmentEBITMargin_Abri_YoY, 'withStatus')} YoY, Vs {getColorStatus(NewData?.SegmentEBITMargin_Abri_QoQ, 'withStatus')} QoQ</div> */}
                                        

                                        {/* <div><u>Abrasives</u></div>
                                        <div>Segment Sales Up {getColorStatus(NewData?.SegmentSales_Abri_YoY, 'withStatus')} YoY, Down {getColorStatus(NewData?.SegmentSales_Abri_QoQ, 'withStatus')} QoQ at Rs.{NewData?.SegmentSales_Abri} Cr.</div>
                                        <div>Segment EBIT Up {getColorStatus(NewData?.SegmentEBIT_Abri, 'withStatus')} YoY, Down {getColorStatus(NewData?.SegmentEBIT_Abri, 'withStatus')} QoQ at Rs.{NewData?.SegmentEBIT_Abri} Cr.</div>
                                        <div>Segment EBIT Margin Up {getColorStatus(NewData?.SegmentEBITMargin_Abri, 'withStatus')} Vs {getColorStatus(NewData?.SegmentEBITMargin_Abri_YoY, 'withStatus')} YoY, Vs {getColorStatus(NewData?.SegmentEBITMargin_Abri_QoQ, 'withStatus')} QoQ</div>
                                        <br />
                                        <div><u>Ceramics</u></div>
                                        <div>Segment Sales Up {getColorStatus(NewData?.SegmentSales_Ceramics_YoY, 'withStatus')} YoY, Down {getColorStatus(NewData?.SegmentSales_Ceramics_QoQ, 'withStatus')} QoQ at Rs.{NewData?.SegmentSales_Ceramics} Cr.</div>
                                        <div>Segment EBIT Up {getColorStatus(NewData?.SegmentEBIT_Ceramics, 'withStatus')} YoY, Down {getColorStatus(NewData?.SegmentEBIT_Ceramics, 'withStatus')} QoQ at Rs.{NewData?.SegmentEBIT_Ceramics} Cr.</div>
                                        <div>Segment EBIT Margin Up {getColorStatus(NewData?.SegmentEBITMargin_Ceramics, 'withStatus')} Vs {getColorStatus(NewData?.SegmentEBITMargin_Ceramics_YoY, 'withStatus')} YoY, Vs {getColorStatus(NewData?.SegmentEBITMargin_Ceramics_QoQ, 'withStatus')} QoQ</div>
                                        <br />
                                        <div><u>Electrominerals</u></div>
                                        <div>Segment Sales Up {getColorStatus(NewData?.SegmentSales_Electrominerals_YoY, 'withStatus')} YoY, Down {getColorStatus(NewData?.SegmentSales_Electrominerals_QoQ, 'withStatus')} QoQ at Rs.{NewData?.SegmentSales_Electrominerals} Cr.</div>
                                        <div>Segment EBIT Up {getColorStatus(NewData?.SegmentEBIT_Electrominerals, 'withStatus')} YoY, Down {getColorStatus(NewData?.SegmentEBIT_Electrominerals, 'withStatus')} QoQ at Rs.{NewData?.SegmentEBIT_Electrominerals} Cr.</div>
                                        <div>Segment EBIT Margin Up {getColorStatus(NewData?.SegmentEBITMargin_Electrominerals, 'withStatus')} Vs {getColorStatus(NewData?.SegmentEBITMargin_Electrominerals_YoY, 'withStatus')} YoY, Vs {getColorStatus(NewData?.SegmentEBITMargin_Electrominerals_QoQ, 'withStatus')} QoQ</div>
                                        <br />
                                        <div><u>Others</u></div>
                                        <div>Segment Sales Up {getColorStatus(NewData?.SegmentSales_Others_YoY, 'withStatus')} YoY, Down {getColorStatus(NewData?.SegmentSales_Others_QoQ, 'withStatus')} QoQ at Rs.{NewData?.SegmentSales_Others} Cr.</div>
                                        <div>Segment EBIT Up {getColorStatus(NewData?.SegmentEBIT_Others, 'withStatus')} YoY, Down {getColorStatus(NewData?.SegmentEBIT_Others, 'withStatus')} QoQ at Rs.{NewData?.SegmentEBIT_Others} Cr.</div>
                                        <div>Segment EBIT Margin Up {getColorStatus(NewData?.SegmentEBITMargin_Others, 'withStatus')} Vs {getColorStatus(NewData?.SegmentEBITMargin_Others_YoY, 'withStatus')} YoY, Vs {getColorStatus(NewData?.SegmentEBITMargin_Others_QoQ, 'withStatus')} QoQ</div>
                                        <br /> */}
{/* 
                                        <div><u>Ceramics</u></div>
                                        <div>Segment Sales Up {getColorStatus(NewData?.SegmentSales_Ceramics_YoY, 'withStatus')} YoY, Up {getColorStatus(NewData?.SegmentSales_Ceramics_YoY, 'withStatus')} QoQ at Rs.{getColorStatus(NewData?.SegmentSales_Ceramics_YoY, 'withStatus')} Cr.</div>
                                        <div>Segment EBIT Up {getColorStatus(NewData?.SegmentEBIT_Ceramics_YoY, 'withStatus')} YoY, Up {getColorStatus(NewData?.SegmentEBIT_Ceramics_YoY, 'withStatus')} QoQ at Rs.{getColorStatus(NewData?.SegmentEBIT_Ceramics_YoY, 'withStatus')} Cr.</div>
                                        <div>Segment EBIT Margin Up {getColorStatus(NewData?.SegmentEBITMargin_Ceramics_YoY, 'withStatus')} Vs {getColorStatus(NewData?.SegmentEBITMargin_Ceramics_YoY, 'withStatus')} YoY, Vs {getColorStatus(NewData?.SegmentEBITMargin_Ceramics_YoY, 'withStatus')} QoQ</div>
                                        <br />

                                        <div><u>Electrominerals</u></div>
                                        <div>Segment Sales Up {getColorStatus(NewData?.SegmentSales_Electrominerals_YoY, 'withStatus')} YoY, Up {getColorStatus(NewData?.SegmentSales_Electrominerals_YoY, 'withStatus')} QoQ at Rs.{getColorStatus(NewData?.SegmentSales_Electrominerals_YoY, 'withStatus')} Cr.</div>
                                        <div>Segment EBIT Up {getColorStatus(NewData?.SegmentEBIT_Electrominerals_YoY, 'withStatus')} YoY, Up {getColorStatus(NewData?.SegmentEBIT_Electrominerals_YoY, 'withStatus')} QoQ at Rs.{getColorStatus(NewData?.SegmentEBIT_Electrominerals_YoY, 'withStatus')} Cr.</div>
                                        <div>Segment EBIT Margin Up {getColorStatus(NewData?.SegmentEBITMargin_Electrominerals_YoY, 'withStatus')} Vs {getColorStatus(NewData?.SegmentEBITMargin_Electrominerals_YoY, 'withStatus')} YoY, Vs {getColorStatus(NewData?.SegmentEBITMargin_Electrominerals_YoY, 'withStatus')}% QoQ</div>
                                        <br />

                                        <div><u>Others</u></div>
                                        <div>Segment Sales Up {getColorStatus(NewData?.SegmentSales_Others_YoY, 'withStatus')} YoY, Up {getColorStatus(NewData?.SegmentSales_Others_YoY, 'withStatus')} QoQ at Rs.{getColorStatus(NewData?.SegmentSales_Others, 'withStatus')} Cr.</div>
                                        <div>Segment EBIT Up {getColorStatus(NewData?.SegmentEBIT_Others_YoY, 'withStatus')} YoY, Up {getColorStatus(NewData?.SegmentEBIT_Others_YoY, 'withStatus')}  QoQ at Rs.{getColorStatus(NewData?.SegmentEBIT_Others, 'withStatus')} Cr.</div>
                                        <div>Segment EBIT Margin Down {getColorStatus(NewData?.SegmentEBITMargin_Others_YoY, 'withStatus')} Vs {getColorStatus(NewData?.SegmentEBITMargin_Others_YoY, 'withStatus')} YoY, Vs {getColorStatus(NewData?.SegmentEBITMargin_Others, 'withStatus')} QoQ</div> */}

                                      </div>
                                    </div>
                                </>
                                :
                                <>
                                    <Typography>No Data Found!</Typography>
                                </>
                            }
                            
                        </>
                    }
                    
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
    