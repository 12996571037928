import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Box, Typography } from '@mui/material';


const SelectCompany = (props) => {

    const {
        sectors,
        industry,
        sectorMasterArr,
        setSectors,
        setSectorsIds,
        sectorsIds,
        IndustryIds,
        companyMaster,
        companyMasterArr,
        company,
        setCompany,
        companyMasterLoading,
        setCompanyMasterArr
    } = props;
    
    // console.log(companyMasterArr.length);

    useEffect(() => {
        selectCompany();
    }, [companyMasterLoading]);

  
    useEffect(() => {
        if (sectors.length === 0) {
            selectCompany();
        } else {
            filterSelectCompany('sector');
        }
    }, [sectors]);


    useEffect(() => {
        if (industry.length === 0) {
            selectCompany();
        } else {
            filterSelectCompany('industry');
        }
    }, [industry]);


    const selectCompany = () => {
        
        if (companyMaster.length > 0) {
          var data1 = [];
          companyMaster.map((item) => {
            var d1 = { title: item.CompanyName, value: item.CompanyID };
            data1.push(d1);
          })
          setCompanyMasterArr(data1);
        }
    }

    
    
      
    

  const filterSelectCompany = (type = '') => {

    let companyMasterFilter = [];
    if (type == 'sector') {
      for (var i = 0; i < sectors.length; i++) {
        var companyMasterFilter1 = companyMaster.filter(company => (sectors[i].value == company.sectorID));
        Array.prototype.push.apply(companyMasterFilter, companyMasterFilter1);
      }
    }
    
    if (type == 'industry') {
      for (var i = 0; i < industry.length; i++) {
        var companyMasterFilter1 = companyMaster.filter(company => (industry[i].value == company.IndustryID));
        Array.prototype.push.apply(companyMasterFilter, companyMasterFilter1);
      }
    }
    var data1 = [];
    if (companyMasterFilter.length > 0) {
      companyMasterFilter.map((item) => {
        var d1 = { title: item.CompanyName, value: item.CompanyID };
        data1.push(d1);
      })
    }
    setCompanyMasterArr(data1);
  }





    return (
        <>

            <Box
                display="flex"
                flexDirection="row"
                alignContent={'center'}
                justifyContent={'space-between'}
            >
                
                <div style={{ width: '100%', margin: '0 0.2rem' }}>
                    <Typography sx={{ fontSize:'0.7rem', marginTop:1 }} className="fontWeight-600 " >Company ({companyMasterArr.length})</Typography>
                    <Autocomplete
                    disableCloseOnSelect
                        multiple 
                        limitTags={2}
                        id="multiple-limit-tags"
                        key={companyMasterArr}
                        options={companyMasterArr}
                        defaultValue={company}
                        getOptionLabel={(option) => option.title}
                        className="selectInputNew"
                        size="small"
                        renderInput={(params) => (
                            <TextField className='fontSize-12px' {...params} size="small"  placeholder="Select Company" />
                        )}
                        onChange={(event, newInputValue) => {
                            var val1 = [];
                            for (var a = 0; a < newInputValue.length; a++) {
                                val1.push(newInputValue[a].value);
                            }
                            // setInputs({ ...inputs, ['Company']: val1 });
                            setCompany(newInputValue)
                            console.log('Company >> ',val1) 
                        }}
                        fullWidth
                    />
                </div>
            </Box>


        </>
    )
}

export default SelectCompany