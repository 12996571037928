import React, { useEffect } from 'react'
import { RenderPage, RenderPageProps, Viewer } from '@react-pdf-viewer/core';

import { watermarkText } from '../../constants/helpers';
import { useSelector } from 'react-redux';



const WaterMark = (props: RenderPageProps ) => {
    

    return (
        <>
            {props.canvasLayer.children}

            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    zIndex: 10000,
                    userSelect: 'none'
                }}
            >
                <div
                    style={{
                        color: '#cd1003',
                        fontSize: 50,
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        transform: 'rotate(-45deg)',
                        userSelect: 'none',
                        opacity: 0.4
                    }}
                >
                    {watermarkText} {
                        localStorage.getItem('ipData') ? localStorage.getItem('ipData')?.IPv4 : null
                    }
                </div>
            </div>
            {props.annotationLayer.children}
            {props.textLayer.children}
        </>
    )
}

export default WaterMark
