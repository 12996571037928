import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { vdrGroupAction, vdrUpdateGroupAction } from '../../../redux/actions/VdrAction';
import { InputComponent } from '../../Common'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DocumentNotes } from '../../../constants/helpers';
import MuiAutoComplete from '../../Common/MuiAutoComplete';
import { MultipleFileUploaderAction, SingleCompanyAboutUsUpdateAction, SingleCompanyCommentsAction } from '../../../redux/actions/userAction';
import Editor from './Editor';

const Add = (props) => {

    const {
        groupData,
        levelType,
        inputType,
        companyId,
        toggleDrawer,
        itemData,
        parentGroup
    } = props;

    console.log(props);

    const [checked, setChecked] = React.useState(false);
    const [Doc, setDoc] = React.useState([]);
    const [DocName, setDocName] = React.useState([]);
    const [TextData, setTextData] = React.useState("");

    const [DocTypeData, setDocTypeData] = React.useState([]);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const [checked1, setChecked1] = React.useState(false);

    const handleChange1 = (event) => {
        setChecked1(event.target.checked);
        // console.log(checked1);
    };

    const dispatch = useDispatch();
    const { control, handleSubmit, register, reset } = useForm({
        defaultValues: {
            status: 1,
        }
    });

    // console.log(itemData);

    useEffect(() => {
        setChecked(groupData?.enable_number ? true : false);
        setChecked1(groupData?.sticky  ? true : false);
        setTextData(itemData?.Description ? itemData?.Description : "");
        reset(groupData)
    }, [dispatch, props])

    const onSubmit = (data) => {
        

        const dd = [{
            Type: itemData?.Type,
            CompanyID: companyId,
            heading: data.heading || "",
            UserID: "22",
            description: TextData
        }];
        dispatch(SingleCompanyAboutUsUpdateAction(dd));
        toggleDrawer('bottom', false)();

        // console.log(dd, data);
        // data1 = {
        //     Doc, DocName, DocTypeData: DocTypeData.value
        // }
        // dispatch(MultipleFileUploaderAction(dd));
        // window.location.reload();
    };
    // const getBase64 = (file) => {
    //     return new Promise(resolve => {
    //       let fileInfo;
    //       let baseURL = "";
    //       // Make new FileReader
    //       let reader = new FileReader();
    
    //       // Convert the file to base64 text
    //       reader.readAsDataURL(file);
    //     //   console.log(file.name);
    //       setDocName(file.name);
    //       // on reader load somthing...
    //       reader.onload = () => {
    //         // Make a fileInfo Object
    //         console.log("Called", reader);
    //         baseURL = reader.result;
    //         // console.log(baseURL);
    //         setDoc(baseURL);
    //         resolve(baseURL);
    //       };
    //       console.log(fileInfo);
    //     });
    //   };

    var fileDoc = [];
    var fileName = [];
    const getBase64 = (file) => {
        console.log('setDocName')
        // console.log(typeof DocName)
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
                fileName.push(file.name)
            };
            reader.onerror = error => reject(error);
        });
    }

      const chooseFile = (e) => {
        // // getBase64(e.target.files[0]);
        // setDoc([]);
        // setDocName([]);

        fileDoc = [];
        fileName = [];

        var File = e.target;
        // var fileData = e.target.files[0];
        for (let l0 = 0; l0 < File.files.length; l0++) {
            var fileData = File.files[l0];
            // var fname = fileData.name;
            // fileNameArr.push(fname)
            fileName.push(fileData.name);
            const Reader = new FileReader();

            Reader.readAsDataURL(fileData);
        
            Reader.onload = () => {
              if (Reader.readyState === 2) {
                // props.setAvatar(Reader.result);
                // console.log(Reader.result);
                fileDoc.push(Reader.result)
              }
            };


            // console.log('enter ', fileData);
            // getBase64(fileData).then(
            //     data => {
            //         fileDoc.push(data)
            //     }
            // ).catch(err=>{
            //     console.log(err)
            // });

        }
        setDoc(fileDoc);
        setDocName(fileName);

      }

    return (
        <>
            <Box sx={{
                width: '30%',
                margin: "1rem auto"
            }}>
                <Typography variant="h5" fontSize={20} sx={{ margin: '1rem 0' }}>
                    Update {itemData?.Type}
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        {/* <span>group_id</span> */}
                        <input type="hidden" defaultValue={groupData?.group_id || 0} {...register("group_id")} />
                        {/* <span>parent_id</span> */}
                        <input type="hidden" defaultValue={groupData?.parent_id || (parentGroup ? parentGroup.group_id : 0) || 0} {...register("parent_id")} />
                        {/* <span>level_type</span> */}
                        <input type="hidden" defaultValue={groupData?.level_type || levelType || 0} {...register("level_type")} />
                        {/* <span>Inputtype</span> */}
                        <input type="hidden" defaultValue={groupData?.Inputtype || inputType || 0} {...register("Inputtype")} />

                        {/* <Grid item xs={12} >
                            <MuiAutoComplete
                                {...register("recommendation")}
                                placeholder="Select Comment Type"
                                className='cInput'
                                options={DocumentNotes.length && DocumentNotes}
                                multiple={false}
                                label="Comment Type"
                                // value={selectedRecommendationData}
                                onChange={(event, newInputValue) => {
                                    var val1 = [];
                                    setDocTypeData(newInputValue);
                                    // console.log(newInputValue)
                                }}
                            />
                        </Grid>
                        
                        <Grid item xs={12} >
                        <label style={{ 
                                margin:0,
                                fontSize:12,
                                fontWeight:'500',
                             }}>Heading</label>
                            <TextField 
                                {...register("heading")}
                                name="heading"
                                label=""
                                size="small"
                                placeholder="Enter Heading"
                                className='cInput'
                            /> 
                        </Grid> */}
                        
                        <Grid item xs={12} >
                            <label style={{ 
                                margin:0,
                                fontSize:12,
                                fontWeight:'500',
                             }}>Description</label>
                            <Editor TextData={TextData} setTextData={setTextData} /> 
                        </Grid>


                        <Grid item xs={12}>
                            {
                                inputType ?
                                    <>
                                        <Button type="submit" variant="contained">Update</Button>
                                    </> :
                                    <>
                                        <Button type="submit" variant="contained">Submit</Button>
                                    </>

                            }
                        </Grid>
                    </Grid>

                </form>

            </Box>
        </>
    )
}

export default Add