import React from 'react'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { TextField, Grid, Checkbox, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';

const RadioComponent = ({ name, label, control, items, ...props }) => {

    // console.log('items >> '+items)
    return (
        <>

            {
                label && <label className='sidebar-label'>{label}</label>
            }

            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <>
                        <RadioGroup
                            {...props}
                            defaultValue="female"
                        >
                            {
                                items &&
                                items.map((item, i) => (
                                    <FormControlLabel sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 16,
                                        },
                                    }} value={item.value} control={<Radio />} label={<Typography style={{ fontSize:11, color:item?.value }}>{item.label}</Typography>} />
                                ))
                            }
                            
                        </RadioGroup>

                    </>
                )}
            />

        </>
    )
}

export default RadioComponent

