import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { CompaniesComponent, InputComponent } from '../../Common'
import { useSelector, useDispatch } from 'react-redux'
import MuiAutoComplete from '../../Common/MuiAutoComplete';
import { ideaType, shortAsc, recommendationArr } from '../../../constants/helpers';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { companyMasterAction } from '../../../redux/actions/userAction';
import { vdrCompanyAction, vdrAssignEmployeeAction } from '../../../redux/actions/VdrAction';


const Add = (props) => {

    const [companyMasterArr, setCompanyMasterArr] = useState([]);
    const [employeeMasterArr, setEmployeeMasterArr] = useState([]);

    const [companyTypeArr, setCompanyTypeArr] = useState(ideaType);
    // const recommendationArr = [];
    const [selectedCompanyData, setSelectedCompanyData] = useState(null);

    const [selectedCompanyTypeData, setSelectedCompanyTypeData] = useState(null);

    const [selectedEmployee, setSelectedEmployee] = useState(null);


    const [UpdateState, setUpdateState] = useState(false);

    const [tags, setTags] = React.useState([])
    const {
        singleCompanyData,
        parentGroup,
        levelType,
        inputType
    } = props;

    // console.log(singleCompanyData)

    const dispatch = useDispatch();
    const { data: companyMaster, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
    const { data: employeeMaster, loading: employeeMasterLoading } = useSelector((state) => state.vdrEmployeeReducer);
    const { data: AssignEmployee, loading: AssignEmployeeLoading, loadingOnAdd } = useSelector((state) => state.AssignEmployeeReducer);

    const { data: companyData, loading: companyLoading } = useSelector((state) => state.vdrCompanyReducer);

    const { control, handleSubmit, register, reset } = useForm({
        defaultValues: {
            groups_id: (singleCompanyData?.groups_id || parentGroup.group_id || 0),
            groups_level: (singleCompanyData?.groups_level || parentGroup.level_type || 0),
            status: 1,
            recommendation_date: singleCompanyData?.recommendation_date,
            recommendation_price: singleCompanyData?.recommendation_price,
            Inputtype: inputType
        }
    });


    const onSubmit = data => {
        const paramsData = {
            user_id:1,
            company_id:selectedCompanyData.value,
            employee_id:selectedEmployee.value,
            optionType:"0"
        }

        dispatch(vdrAssignEmployeeAction(paramsData, 'add'));

        if (loadingOnAdd === false) {            
            window.location.reload();
        }

    };

    const selectCompany = () => {
        if (companyMaster.length > 0) {
            var data1 = [];
            companyMaster.map((item) => {
                var d1 = { title: item.CompanyName, value: item.CompanyID };
                data1.push(d1);
            })
            shortAsc(data1)
            setCompanyMasterArr(data1);
        }
    }

    const selectEmployee = () => {
        if (employeeMaster.data.length > 0) {
            var data1 = [];
            employeeMaster.data.filter((fItem)=> { if(fItem.employee_type == 'Analyst') return fItem }).map((item) => {
                var d1 = { title: item.employee_name, value: item.user_id };
                data1.push(d1);
            })
            shortAsc(data1)
            setEmployeeMasterArr(data1);
        }
    }

    const getUniqueListBy = (arr) => {
        return [...new Map(arr.map(item => [item, item])).values()]
    }

    const setTagsFunction = (tags1) => {
        setTags(getUniqueListBy(tags1))
        // console.log(tags1)
    }


    useEffect(() => {
        if (!companyMasterLoading) {
            selectCompany();
        }
        if (singleCompanyData) {
            setSelectedCompanyData({
                title: singleCompanyData?.company_name,
                value: singleCompanyData?.company_id
            });
        }
    }, [dispatch, props, companyMasterLoading])
    

    useEffect(() => {
        if (!employeeMasterLoading) {
            selectEmployee();
        }
        if (singleCompanyData) {
           
        }
    }, [dispatch, employeeMasterLoading])
    console.log(props)

    return (
        <>
            <Box sx={{
                width: '30%',
                margin: "1rem auto"
            }}>
                <Typography variant="h5" fontSize={20} sx={{ margin: '1rem 0' }}>
                    {inputType && inputType ? 'Update Assigned' : 'Assign'} Company
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <Grid container spacing={1}>
                        <input type="hidden" defaultValue={singleCompanyData?.group_id || parentGroup?.group_id || 0} {...register("groups_id")} />
                        <input type="hidden" defaultValue={singleCompanyData?.level_type || parentGroup?.level_type || 0} {...register("groups_level")} />
                        <input type="hidden" defaultValue={inputType || 0} {...register("Inputtype")} />

                        <Grid item xs={12} >
                            <MuiAutoComplete
                                {...register("company_data")}
                                placeholder="Select Company"
                                className='cInput'
                                readOnly={inputType ? true : false}
                                options={companyMasterArr && companyMasterArr}
                                multiple={false}
                                value={selectedCompanyData}
                                label="Company"
                                onChange={(event, newInputValue) => {
                                    var val1 = [];
                                    setSelectedCompanyData(newInputValue);
                                    // console.log(newInputValue)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <MuiAutoComplete
                                {...register("recommendation")}
                                placeholder="Select Employee"
                                className='cInput'
                                options={employeeMasterArr.length && employeeMasterArr}
                                multiple={false}
                                label="Employees"
                                value={selectedEmployee}
                                onChange={(event, newInputValue) => {
                                    var val1 = [];
                                    setSelectedEmployee(newInputValue);
                                }}
                            />
                        </Grid>



                        {/* <Grid item xs={12} >
                        <ReactTagInput
                            tags={tags} 
                            placeholder="Enter Tags"
                            maxTags={10}
                            editable={true}
                            readOnly={false}
                            removeOnBackspace={true}
                            onChange={(newTags) => setTagsFunction(newTags)}
                        />
                        </Grid> */}

                        <Grid item xs={12}>
                            {
                                inputType && inputType ?
                                    <>
                                        <Button type="submit" variant="contained">Update</Button>
                                    </> :
                                    <>
                                        <Button type="submit" variant="contained">Submit</Button>
                                    </>
                            }
                        </Grid>
                    </Grid>

                </form>

            </Box>
        </>
    )
}

export default Add