import { Box, Button, Checkbox, Container, Divider, List, ListItem, SwipeableDrawer, TextField } from '@mui/material'
import React, {useState, useEffect, Fragment} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { commentParams, crtUserId } from '../../../../constants/helpers';
import { NewsletterAddCommentAction, NewsletterCommentAction, VideoCommentAction } from '../../../../redux/actions/VdrAction';
import CommentCard from './commentCard';
import AddCommentComponent from './AddCommentComponent';


const CommentList = (props) => {

    const {
      ActiveItem,
    } = props
    
  const { data: NewsletterCommentData, loading: NewsletterCommentLoading } = useSelector((state) => state.NewsletterCommentReducer);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });



  const [CommentDesc, setCommentDesc] = useState("")
  const [ActiveComment, setActiveComment] = useState(null)

  const dispatch = useDispatch();
  const toggleDrawer = (anchor, open) => (event) => {
  if (
    event &&
    event.type === 'keydown' &&
    (event.key === 'Tab' || event.key === 'Shift')
  ) {
    return;
  }

    setState({ ...state, [anchor]: open });
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [checked, setChecked] = React.useState(true);
  const { data: UserLoginData, loading: UserLoginLoading, isAuth, isRedirect } = useSelector(state => state.UserLoginReducer);

  useEffect(() => {
    
    // console.log('NewsletterCommentLoading >>>> ', NewsletterCommentData);
  }, [NewsletterCommentLoading])

  
  const submitForm = () => {
      let params = {
                    "body": CommentDesc,
                    "blog_id": ActiveItem?.id,
                    "parent_id":"",
                    "ctype":"",
                    "user_id": (UserLoginData?.user_id || crtUserId())
                  }
      dispatch(NewsletterAddCommentAction(params));
      setState({ ...state, ['bottom']: false });
  }
    
  const removeComment = (commentId) => {
    let params = {
        ...commentParams,
        videoId: ActiveItem?.id,
        commentId:commentId,
        inputType: "2"
      }
      // dispatch(NewsletterCommentAction(params));
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, minHeight: '500px', padding: '1rem' }}
      role="presentation"
    //   onClick={toggleDrawer(anchor, false)}
    //   onKeyDown={toggleDrawer(anchor, false)}
    >
        <Container>
            <div style={{

            }}>
                <h3>Leave Your Comment</h3>
                <Divider sx={{
                    marginBottom: '.5rem'
                }}/>
            </div>
            <>
            <TextField
                id="outlined-multiline-static"
                multiline
                rows={4}
                placeholder='Comment'   
                size='small'
                defaultValue=""
                fullWidth
                onChange={(e)=>setCommentDesc(e.target.value)}
            />
            </>
            <div style={{
                display:"flex",
                marginTop:'.5rem',
                gap:8
            }}>
                <Button onClick={(e)=>submitForm(e)} variant='contained' color="success">Submit</Button>
                <Button onClick={toggleDrawer('bottom', false)} sx={{}} variant='contained' color="error">Close</Button>
            </div>
        </Container>
     
    </Box>
  );

  const rootComments = NewsletterCommentData?.data && NewsletterCommentData?.data.filter(item => item?.parent_id === null);


  const getRepliesFilter = (commentId) => {
    return NewsletterCommentData?.data.filter(item=> item?.parent_id === commentId);
  }

  if(NewsletterCommentLoading){
    return (
      <></>
    )
  }

  return (
    <>
<div>
      {['bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
    
        <div style={{ 
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between'
            }}>
            <h3 className='addToList' >Comments ({NewsletterCommentData?.data && NewsletterCommentData?.data.length})</h3>
            {/* <Button className='addToList' size="small" 
            onClick={toggleDrawer('bottom', true)} >+ Leave your comment</Button> */}
        </div>

        <Box className='nT'>
      
      
          <AddCommentComponent videoId={ActiveItem?.id} ActiveComment={ActiveComment} setActiveComment={setActiveComment} />
        </Box>

        <Box>
        

            { rootComments && rootComments.length > 0 &&
                rootComments.map((item, i)=>{
                    return (
                        <Fragment key={i} >
                          <CommentCard item={item} replies={getRepliesFilter(item?.commentId)} ActiveComment={ActiveComment} setActiveComment={setActiveComment} />
                        </Fragment>
                    )
                })
            }
        </Box>
    </>
  )
}

export default CommentList