import React, { useEffect, useState } from 'react'


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Box, Divider, Typography } from '@mui/material';

import TooltipText from './TooltipText'


ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: 'y',
  scales: {
    // r: {
    //   ticks: {
    //     backdropPadding: {
    //       x: 10,
    //       y: 40
    //     }
    //   }
    // },
    
    x: {
      ticks: {
        display: false
      }, grid: {
        drawBorder: false,
        display: false

      },
    },
    y: {
      ticks: {
        display: true,
      },
      grid: {
        drawBorder: false,
        display: false
      }
    }
  },
  elements: {
    bar: {
      borderWidth: 1,
      marginLeft: 20
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 0,
      right: 50,
      bottom: 0,
      left: 0
    }
  },
  aspectRatio: 10 / 3,

  plugins: {
    datalabels: {
      align: function (context) {
        var value = context.dataset.data[context.dataIndex];
        return 'end';
      },
      anchor: function (context) {
        var value = context.dataset.data[context.dataIndex];
        // console.log(' value >>>> ', value)
        return value > -1 ? 'end' : 'start';
      },
      borderRadius: 4,
      color: 'black',
      // rotation: function(context) {
      //   var value = context.dataset.data[context.dataIndex];
      //   return value > 0 ? 45 : 180 - 45;
      // },
      // backgroundColor: function(context) {
      //   return context.dataset.backgroundColor;
      // },
      // formatter: Math.round,
      display: true,
      padding: function (context) {
        var value = context.dataset.data[context.dataIndex];
        return value > -1 ? '0' : '5';
      },
    },
    legend: {
      display: false,
      position: 'left',
    },
    title: {
      display: false,
      // text: 'Chart.js Horizontal Bar Chart',
    },
  },
};


export default function CustomChart(props) {
  const { chartData, themeColor } = props;
  // console.warn(chartData);
  const [Height, setHeight] = useState(450)
  useEffect(() => {
    if (chartData?.cat.length > 10) {
      setHeight(chartData?.cat.length * 20)
    } else
      if (chartData?.typeFor === 10) {
        setHeight(350)
      } else {
          setHeight(150)
        }
  }, [props, chartData?.typeFor])

  const labels = chartData?.cat;
  // console.log(labels[0]);
  const YoYQoQ = chartData?.YoYQoQ;
  const CAGR = chartData?.CAGR;
  

  const data = {
    labels,
    legend: {
      display: false
    },
    datasets: [
      {
        // clip: {left: 10, top: 20, right: 30, bottom: 40},
        label: '',
        data: chartData?.value,
        borderRadius: function(context){
          return 5
        },
        borderColor: function (context) {
          return context.raw > 0 ? `${themeColor ? themeColor : 'rgb(34, 132, 65)'}` : 'rgba(244, 67, 54)';
          // return context.raw > 0 ? 'rgb(34, 132, 65)' : 'rgba(244, 67, 54)';
        },
        backgroundColor: function (context) {
          return context.raw > 0 ? `${themeColor ? themeColor : 'rgba(34, 132, 65, 0.5)'}` : 'rgba(244, 67, 54, 0.5)';
          // return context.raw > 0 ? 'rgba(34, 132, 65, 0.5)' : 'rgba(244, 67, 54, 0.5)';
        },
      },
    ],
  };


  return (
    <>
      {/* {labels.length} */}
      
      {
        labels.length > 0 && labels[0] !== undefined && labels[0] !== null
          ?
          <>
            <Box sx={{ 
               paddingRight: '5rem'
             }}>
              <TooltipText title={chartData?.title}>
                <Typography className='dotedText' variant="subtitle1" sx={{
                  textAlign: 'center'
                }}>
                  {chartData?.title}
                </Typography>
              </TooltipText>

              <>
                {
                  YoYQoQ != null
                    ?
                    <>
                      {
                        YoYQoQ?.YoY ?
                          <>
                            <Typography variant="subtitle2" sx={{
                              textAlign: 'center'
                            }}>YoY: {YoYQoQ?.YoY}</Typography>

                          </>
                          :
                          <Typography variant="subtitle2" sx={{
                            textAlign: 'center'
                          }} dangerouslySetInnerHTML={{
                            __html:
                              `&nbsp`
                          }} />
                      }
                      {
                        YoYQoQ?.QoQ ?
                          <>
                            <Typography variant="subtitle2" sx={{
                              textAlign: 'center'
                            }}>QoQ: {YoYQoQ?.QoQ}</Typography>

                          </>
                          :
                          <Typography variant="subtitle2" sx={{
                            textAlign: 'center'
                          }} dangerouslySetInnerHTML={{
                            __html:
                              `&nbsp`
                          }} />
                      }
                    </>
                    :
                    null
                }

                {
                  CAGR != null ?
                    <>
                      {
                        CAGR ?
                          <>
                            <Typography variant="subtitle2" sx={{
                              textAlign: 'center'
                            }}>CAGR: {CAGR}</Typography>

                          </>
                          :
                          <Typography variant="subtitle2" sx={{
                            textAlign: 'center'
                          }} dangerouslySetInnerHTML={{
                            __html:
                              `&nbsp`
                          }} />
                      }
                    </>
                    :
                    null

                }


              </>

            </Box>
            <Divider />
            <div style={{ height: Height }}>
              <Bar options={options} data={data} />
            </div>
          </>
          :
          null
      }

    </>
  )
}
