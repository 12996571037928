import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { vdrGroupAction, vdrUpdateGroupAction } from '../../../redux/actions/VdrAction';
import { InputComponent } from '../../Common'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DocumentType } from '../../../constants/helpers';
import MuiAutoComplete from '../../Common/MuiAutoComplete';
import { industryMasterAction, MultipleAnalystNotesAction, MultipleFileUploaderAction, UploadDocumentAnalystNotesAction } from '../../../redux/actions/userAction';


const Add = (props) => {

    const [checked, setChecked] = React.useState(false);
    const [Doc, setDoc] = React.useState([]);
    const [DocName, setDocName] = React.useState([]);

    const [DocTypeData, setDocTypeData] = React.useState([]);
    const [IndustryArr, setIndustryArr] = React.useState([]);
    const [SelectedIndustryArr, setSelectedIndustryArr] = React.useState([]);

    const {data: industryMasterData, loading: industryMasterLoading} = useSelector(state=>state.industryMasterData);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const [checked1, setChecked1] = React.useState(false);

    const handleChange1 = (event) => {
        setChecked1(event.target.checked);
        // console.log(checked1);
    };

    const dispatch = useDispatch();
    const { control, handleSubmit, register, reset } = useForm({
        defaultValues: {
            status: 1,
        }
    });

    const industrySector = () => {
        var dd = [];
        industryMasterData.filter(it=>it.sectorID==singleCompanyData?.SectorID).map((item, i) => {
            var sa = {
                title:item.Industry,
                value:item.IndustryID
            }
            dd.push(sa);
        })
        setIndustryArr(dd);
    }


    const {
        groupData,
        levelType,
        inputType,
        parentGroup,
        companyId,
        singleCompanyData,
        toggleDrawer
    } = props;

    useEffect(() => {
        // setChecked(groupData?.enable_number ? true : false);
        // setChecked1(groupData?.sticky  ? true : false);
        // reset(groupData)
        
        console.log('inputType >>> ', inputType)

    }, [dispatch, props])

    useEffect(() => {
        if(industryMasterLoading){
            dispatch(industryMasterAction());
        }

        if(!industryMasterLoading){

        }
      
    }, [industryMasterLoading])
    

    const onSubmit = (data) => {
        
        // console.log(Doc, DocName, DocTypeData.value);
        // let data1 = {...data, enable_number: checked, sticky: checked1};
        // let data1 = [];
        // data1 = [
        //     {
        //       "CompanyID": "122241",
        //       "UserID": "22",
        //       "SectorID": "4",
        //       "IndustryID": [
        //         "62"
        //       ],
        //       "DocumentType": DocTypeData.value,
        //       "FileName": DocName,
        //       "FileContent": Doc
        //     }
        //   ]

        // const dd = [{
        //     "CompanyID": 100251,
        //     "DocumentType": DocTypeData?.value,
        //     "FileContent": Doc,
        //     "FileName": DocName,
        //     "UserID": "22"
        // }];
        // console.log(dd);
        var prams = [
            {
              "CompanyID": companyId,
              "SectorID": singleCompanyData?.SectorID,
              "IndustryID": SelectedIndustryArr.map(item=>item?.value),
              "UserID": "22",
              "DocumentType": DocTypeData.value,
              "FileName": DocName,
              "FileContent": Doc
            }
          ];
        //   console.log(prams);
        dispatch(MultipleAnalystNotesAction(prams));
        toggleDrawer('bottom', false)();
        // data1 = {
        //     Doc, DocName, DocTypeData: DocTypeData.value
        // }
        // dispatch(MultipleFileUploaderAction(dd));
        // window.location.reload();
    };
   

    var fileDoc = [];
    var fileName = [];

    const getBase64 = (file) => {
        console.log('setDocName')
        // console.log(typeof DocName)
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
                fileName.push(file.name)
            };
            reader.onerror = error => reject(error);
        });
    }

    const chooseFile = (e) => {
        // // getBase64(e.target.files[0]);
        // setDoc([]);
        // setDocName([]);

        fileDoc = [];
        fileName = [];

        var File = e.target;
        // var fileData = e.target.files[0];
        for (let l0 = 0; l0 < File.files.length; l0++) {
            var fileData = File.files[l0];
            // var fname = fileData.name;
            // fileNameArr.push(fname)
            fileName.push(fileData.name);
            const Reader = new FileReader();

            Reader.readAsDataURL(fileData);
        
            Reader.onload = () => {
              if (Reader.readyState === 2) {
                // props.setAvatar(Reader.result);
                // console.log(Reader.result);
                fileDoc.push(Reader.result)
              }
            };


            // console.log('enter ', fileData);
            // getBase64(fileData).then(
            //     data => {
            //         fileDoc.push(data)
            //     }
            // ).catch(err=>{
            //     console.log(err)
            // });

        }
        setDoc(fileDoc);
        setDocName(fileName);

      }

    return (
        <>
            <Box sx={{
                width: '30%',
                margin: "1rem auto"
            }}>
                <Typography variant="h5" fontSize={20} sx={{ margin: '1rem 0' }}>
                    Upload Document
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        {/* <span>group_id</span> */}
                        <input type="hidden" defaultValue={groupData?.group_id || 0} {...register("group_id")} />
                        {/* <span>parent_id</span> */}
                        <input type="hidden" defaultValue={groupData?.parent_id || (parentGroup ? parentGroup.group_id : 0) || 0} {...register("parent_id")} />
                        {/* <span>level_type</span> */}
                        <input type="hidden" defaultValue={groupData?.level_type || levelType || 0} {...register("level_type")} />
                        {/* <span>Inputtype</span> */}
                        <input type="hidden" defaultValue={groupData?.Inputtype || inputType || 0} {...register("Inputtype")} />

                        <Grid item xs={12} >
                            <MuiAutoComplete
                                {...register("recommendation")}
                                placeholder="Select Document Type"
                                className='cInput'
                                options={DocumentType.length && DocumentType}
                                multiple={false}
                                label="Document Type"
                                // value={selectedRecommendationData}
                                onChange={(event, newInputValue) => {
                                    var val1 = [];
                                    setDocTypeData(newInputValue);
                                    if(newInputValue?.value == 'Sector Report'){
                                        industrySector()
                                    }else{
                                        setIndustryArr([]);
                                    }
                                }}
                            />
                        </Grid>

                                {
                                    IndustryArr.length > 0 && (
                                        <Grid item xs={12} >
                                            <MuiAutoComplete
                                                {...register("recommendation")}
                                                placeholder="Select Industry"
                                                className='cInput'
                                                options={IndustryArr.length && IndustryArr}
                                                multiple={true}
                                                label="Industry"
                                                // value={selectedRecommendationData}
                                                onChange={(event, newInputValue) => {
                                                    var val1 = [];
                                                    setSelectedIndustryArr(newInputValue);
                                                    // console.log(newInputValue)
                                                    // changeDocumentType()
                                                }}
                                            />
                                        </Grid>
                                    )
                                }
                       
                        
                        <Grid item xs={12} >
                            <label style={{ 
                                margin:0,
                                fontSize:12,
                                fontWeight:'500',
                             }}>Choose a File</label>
                            <TextField 
                                name="group_name"
                                label=""
                                size="small"
                                placeholder="Select File"
                                className='cInput'
                                onChange={(e)=>chooseFile(e)}
                                type="file"
                                inputProps={{ 
                                    accept:".pdf",
                                    multiple:true
                                 }}
                                 sx={{ 
                                    // marginTop:1
                                  }}
                            /> 
                        </Grid>

                        <Grid item xs={12}>
                            {
                                inputType ?
                                    <>
                                        <Button type="submit" variant="contained">Update</Button>
                                    </> :
                                    <>
                                        <Button type="submit" variant="contained">Submit</Button>
                                    </>

                            }
                            <Button onClick={(e)=>toggleDrawer('bottom', false)(e)} type="submit" variant="outlined" sx={{ marginLeft: 1 }}>Cancel</Button>
                        </Grid>
                    </Grid>

                </form>

            </Box>
        </>
    )
}

export default Add