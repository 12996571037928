import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QuarterlySnapShotAction } from '../../../../redux/actions/VdrAction';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import InCr from '../InCr';
import { BsFillBarChartFill } from 'react-icons/bs';


const QuarterlyReviewComp = (props) => {
  const {
    companyId,
    FirstLoad, setFirstLoad,
    setActivePrimaryBtn,
    QuarterlySegmentType,
    setQuarterlySegmentType
  } = props

  const [TableColumns, setTableColumns] = useState([]);
  const [TableBody, setTableBody] = useState([]);
  const {
    data,
    loading
  } = useSelector(state=>state.QuarterlySnapShotReducer)

  const dispatch = useDispatch();

  const callApi = () => {
    
    let pType = 'con';
    if(QuarterlySegmentType === "Standalone"){
      pType = 'std';
    }
    // pType = 'sat';

    let params =  {
      "CompanyId":companyId,
      "type":pType
    }
    dispatch(QuarterlySnapShotAction(params))
  }

  useEffect(() => {
    setTableColumns([])
    setTableBody([])
    callApi()
  }, [QuarterlySegmentType])

  useEffect(() => {
      if(!loading){
        
        if(data.header && data.header.length > 0){
          let cols = []
          let firstObj = data.header[0];
          let a0 = firstObj.row;
          Object.keys(a0).map((item, i)=>{
            if(i > 0){
              let dd = {
                  "id": item,
                  "width": "",
                  "align": "",
                  "bg_color": firstObj?.bg_color,
                  "isItalic": firstObj?.isItalic,
                  "isBold": firstObj?.isBold,
                  "text_color": firstObj?.text_color,
                  "label": a0[item]
                }
              cols.push(dd);
            }
          })
          setTableColumns(cols)
        }else{
          if(FirstLoad){
            setFirstLoad(false);
            setActivePrimaryBtn(2)
            setQuarterlySegmentType('Standalone')
          }
        }
        if(data.Data && data.Data.length){
          let dataA = []
          let a00 = data?.Data;
          console.log('a00 >>>> ', a00)
          a00.map((item, i)=>{
            let cData = item?.row;
            let childObj = {}
            Object.keys(cData).map((item0, i0)=>{
              if(i0 > 0){
                let data = {
                  "label":cData[item0],
                  "bg_color":item['bg_color'],
                  "isItalic":item['isItalic'],
                  "isBold":item['isBold'],
                  "text_color":item['text_color'],
                };
                childObj = {...childObj, [`Column${i0}`]: data}
              }
            })
            dataA.push(childObj)
          })
          setTableBody(dataA)
        }
      }
  }, [dispatch, loading])

    
  const [IsOpen, setIsOpen] = useState(false);
  const [CrtRow, setCrtRow] = useState(false);
  const showChart = (row)=>{
    dispatch({
      type:"Columns_Rows",
      payload:{columns:TableColumns, rows:row}
    })
  }
   


  return (
    <>
        <div className="box-body-sec">
        

          {
            loading && (
              <CircularProgress />
            )
          }
          {
            !loading && (
              <>
              {
                TableBody.length > 0 ?
                <>
                
                <table>
                  <thead>
                    <tr>
                      {
                        TableColumns.map((item,i)=>{
                          let cStyle = {
                                backgroundColor:item?.bg_color,
                                fontStyle:item?.isItalic ? "italic" : "",
                                fontWeight:item?.isBold ? "500" : "",
                                color:item?.text_color,
                              }
                          return (
                            <td style={cStyle} key={i}>{item.label}</td>
                          )
                        })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                        TableBody.map((row,i)=>{
                          return (
                            <tr>
                              {
                                TableColumns.map((item,i)=>{
                                  let rowData = row[item.id]
                                  let value = rowData?.label
                                  let cStyle = {
                                    backgroundColor:rowData?.bg_color,
                                    fontStyle:rowData?.isItalic ? "italic" : "",
                                    fontWeight:rowData?.isBold ? "500" : "",
                                    color:rowData?.text_color,
                                  }
                                  return (
                                    <td style={cStyle} key={i}>
                                      {/* {value || ""} */}
                                      <div style={{ 
                                        display:'flex',
                                        width:'100%',
                                        justifyContent: (i === 0 ? 'space-between' : 'flex-end'),
                                        alignItems:'center'
                                       }}>
                                      {value || ""}  
                                      <span>
                                        {
                                          i === 0 && 
                                          <IconButton onClick={(e)=>showChart(row)} sx={{ padding:0 }}>
                                            <BsFillBarChartFill size={16} />
                                          </IconButton>
                                        }
                                      </span>
                                      </div>
                                    </td>
                                  )
                                })
                              }
                            </tr>
                          )
                        })
                      }
                    
                  </tbody>
                </table>
                </>
                :
                <>
                  <div>
                      <Typography>N/A</Typography>
                  </div>
                </>

              }
                
              </>
            )
          }
        </div>
    </>
  )
}

export default QuarterlyReviewComp