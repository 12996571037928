import React, { useEffect } from 'react'

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { Link, useNavigate } from 'react-router-dom';
import { BsList } from "react-icons/bs";
import { Badge, Button, ClickAwayListener, Grow, List, ListItem, ListItemText, MenuList, Paper, Popper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationAction } from '../../../redux/actions/VdrAction';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import InfoIcon from '@mui/icons-material/Info';

import moment from 'moment';
import { companyMasterAction } from '../../../redux/actions/userAction';

const HeadMenu = () => {
  const dispatch = useDispatch();
  
  const { data: companyMasterData, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
  
  const {
    data: NotificationData,
    loading: NotificationLoading
  } = useSelector(state=>state.vdrNotificationReducer)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open0 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [NotificationArr, setNotificationArr] = React.useState([]);
  const [CompanyMasterArr, setCompanyMasterArr] = React.useState([]);
  const [NotificationLimit, setNotificationLimit] = React.useState(10);

  const handleToggle0 = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let navigate =  useNavigate();

  const handleClose0 = (event, comId, item_type='') => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    
    setOpen(false);
    // console.log('comId >> ', comId)
    let a = window.btoa(comId);
    
    if(comId != undefined){
      if(item_type === "ticker"){
        navigate('/ticker');
      }else
      if(item_type === "Report"){
        navigate('/company/'+a);
      }else
      if(item_type === "single_company"){
        navigate('/company/'+a);
      }else{
        navigate('/videos/');
      }
    }
  };
  
  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  
const filterSingleCompany = (comId) => {
  // console.log(comId)
  let singleComp = companyMasterData.find(s_item=> s_item.CompanyID == comId)
  // console.log(companyMasterData)
  return singleComp
}

  useEffect(() => {
    if(companyMasterLoading){
      dispatch(companyMasterAction());
    }
    if(!companyMasterLoading){
      setCompanyMasterArr(companyMasterData)
    }
  }, [dispatch, companyMasterLoading])

  
  useEffect(() => {
    if(NotificationLoading){
      let params = {
        "item_id":"",
        "item_type":"",
        "status":"1",
        "fromDate": "2023-04-30",
        "toDate":moment().add(1, 'days').format('YYYY-MM-DD')
      }
        dispatch(getNotificationAction(params));
    }
    if(!NotificationLoading){
      // const unique0 = [...new Map(NotificationData?.Data.map(item => [item['item_title'], item])).values()]; // [ 'A', 'B'] 
      const unique0 = NotificationData?.Data; 
      let demo  = unique0.sort((a,b)=>{
        return new Date(b.created_at) - new Date(a.created_at)
      });
      
      setNotificationArr(demo)
    }
  }, [dispatch, NotificationLoading])

  
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', color: '#000', textAlign: 'center' }} className="mainMenu" >
        <Box sx={{
          display: { xs: "none", sm: "flex" }
        }}>
<a href="https://boringamc.com/" target="_blank" rel="noopener noreferrer">

<MenuItem disableRipple >Back to BoringAMC</MenuItem>
</a>

          <Link to="/" >
            <MenuItem disableRipple >Data Room</MenuItem>
            {/* <MenuItem disableRipple >Back to BoringAMC</MenuItem> */}
          </Link>
          {/* <a rel="noopener noreferrer" target={'_blank'} href={"https://omkaracapital.in/user/forum"}>
            <MenuItem disableRipple >Blogs</MenuItem>
          </a> */}
          {/* <Link to="/resources">
            <MenuItem disableRipple >Resources</MenuItem>
          </Link>
          
          <Link to="/calendar">
            <MenuItem disableRipple >Result Calendar</MenuItem>
          </Link>
          <Link to="/small-case">
            <MenuItem disableRipple >Smallcase</MenuItem>
          </Link> */}
          {/* <Link to="/videos">
            <MenuItem disableRipple >Videos</MenuItem>
          </Link> */}
          {/* <a target={'_blank'} href="https://podcast.omkaracapital.in/">
            <MenuItem disableRipple >Podcast</MenuItem>
          </a> */}
          {/* <Link to="/favorite-listing">
            <MenuItem disableRipple >My Favorites</MenuItem>
          </Link> */}
          {/* <Link to="#0">
            <MenuItem disableRipple >Notification</MenuItem>
          </Link> */}
          {/* <div
          style={{ 
            display:'flex',
            alignItems:'center',
            cursor:'pointer'
           }}
          ref={anchorRef}
          id="composition-button" className='vdrhear-icons'
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle0}
        >
          <Badge color="error"  variant="dot">
              <NotificationsActiveOutlinedIcon />
          </Badge>
        </div> */}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose0}>
                  <MenuList
                    className='notification_list'
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    sx={{ 
                      minWidth:'300px'
                     }}
                  >
                    {
                      NotificationArr && NotificationArr.map((item, i)=>{
                        
                        if(i < NotificationLimit){
                          
                          let sComp = filterSingleCompany(item?.company_id);
                          return (
                            <React.Fragment key={i}>
                              <MenuItem sx={{ fontSize:'12px' }} onClick={(e)=>handleClose0(e, item?.company_id, item?.item_type)}>
                                {
                                  item?.item_type === "Report" ?
                                    <PictureAsPdfIcon sx={{ fontSize:16, marginRight:.75 }} />
                                  :
                                  item?.item_type === "ticker" ?
                                    <InfoIcon sx={{ fontSize:16, marginRight:.75 }} />
                                    :
                                    <PlayCircleIcon sx={{ fontSize:16, marginRight:.75 }} />
                                }
                                <div style={{ 
                                    lineHeight: 1
                                   }} className='notification-title'>
                                  <span style={{ fontWeight:'500' }}>{item?.item_title}</span>
                                  <div style={{ 
                                    textAlign: 'start'
                                   }}>
                                    <small>{sComp?.CompanyName}</small>
                                  </div>
                                </div>
                                </MenuItem>
                              {/* <Divider sx={{
                                margin:'0 !important'
                               }} /> */}
                            </React.Fragment>
                          )
                        }
                      })
                    }
                    {
                      NotificationLimit === 10 && 
                      <MenuItem sx={{ fontSize:'12.8px', fontWeight:'600', textAlign:'center' }} onClick={(e)=>setNotificationLimit(20)}>
                        View More
                      </MenuItem>
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        </Box>


        {/* <a  rel="noopener noreferrer" target={'_blank'} href={"https://omkaracapital.in/user/companyblog/whats-on-my-mind-by-vb"}>
                    <MenuItem disableRipple >What's on My mind</MenuItem>
                </a>
                <a  rel="noopener noreferrer" target={'_blank'} href={"https://omkaracapital.in/open-blog/category-1"}>
                    <MenuItem disableRipple >Result X-Ray</MenuItem>
                </a> */}
        {/* <a  rel="noopener noreferrer" target={'_blank'} href={"https://omkaradata.in/new-test/data2/calendar"}>
                    <MenuItem disableRipple >Result Calender</MenuItem>
                </a> */}

        <Box sx={{
          display: { xs: "block", sm: "none" }
        }}>
          <Tooltip title="Menu">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
              <BsList color='#000' fontSize={30} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open0}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* <MenuItem>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem>
          <Avatar /> My account
        </MenuItem>
        <Divider /> */}
        <Link className='mobLink' to="/">
          <MenuItem disableRipple >Data Room</MenuItem>
        </Link>
        {/* <a className='mobLink' rel="noopener noreferrer" target={'_blank'} href={"https://omkaracapital.in/user/forum"} >
          <MenuItem disableRipple >Blogs</MenuItem>
        </a> */}
        
        {/* <Link to="/resources">
            <MenuItem disableRipple >Resources</MenuItem>
          </Link> */}
        {/* <a className='mobLink' rel="noopener noreferrer" target={'_blank'} href={"https://omkaracapital.in/user/companyblog/todays-omkara-thought"}>
          <MenuItem disableRipple >TOT</MenuItem>
        </a> */}
        {/* <Link className='mobLink' to="/calendar">
          <MenuItem disableRipple >Result Calendar</MenuItem>
        </Link>
        <Link className='mobLink' to="/small-case">
          <MenuItem disableRipple >Smallcase</MenuItem>
        </Link> */}
        {/* <Link className='mobLink' to="/videos">
          <MenuItem disableRipple >Videos</MenuItem>
        </Link> */}
        {/* <a target={'_blank'} className='mobLink'  href="https://podcast.omkaracapital.in/">
          <MenuItem disableRipple >Podcast</MenuItem>
        </a> */}
        {/* <Link className='mobLink' to="/favorite-listing">
          <MenuItem disableRipple >My Favorites</MenuItem>
        </Link> */}
        {/* <Link className='mobLink' to="#0">
          <MenuItem disableRipple >Notification</MenuItem>
        </Link> */}
      </Menu>
    </>
  )
}

export default HeadMenu