import React, {
  Fragment,
  useContext,
  useEffect,
  useState
} from "react";
import { FaSortAmountUp, FaSortAmountDown } from "react-icons/fa";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Box, Button, CircularProgress, IconButton, List, ListItem, ListItemButton, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { NewsletterCommentAction, VideoLikeAction, forumDetailAction } from "../../../redux/actions/VdrAction";
import { FORUM_DETAIL_REQUEST, NEWSLETTER_COMMENT_REQUEST } from "../../../constants/VdrConstants";
import moment from 'moment'
import CalenderComp from "./Calender";
import { crtUserId } from "../../../constants/helpers";
import { GlobalContext } from "../../../context/GlobalContext";


const LeftSection = (props) => {
  const {
    VidSortStatus,
    setVidSortStatus,
    sortVideosTypeFun,
    FilterVideoData,
    setFilterVideoData,
    ActiveImage,
    setActiveImage,
    ActiveVideoType,
    setActiveVideoType,
    ActiveVideoItem,
    setActiveVideoItem,
    ActiveVideoNews,
    setActiveVideoNews,
    ActiveTag,
  } = props
  const [LikesData, setLikesData] = useState({});

  const [SpecificSortStatus, setSpecificSortStatus] = React.useState(false);
  const { data: companyMasterData, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
  const { data: VideoLikeData, loading: VideoLikeLoading } = useSelector((state) => state.VideoLikeReducer);

  const [BtnAction, setBtnAction] = useState(1);
  const [ActiveVideo, setActiveVideo] = useState("");

  // useEffect(() => {
  //   // console.log(VidSortStatus)
  // }, [VidSortStatus]);

  const dispatch = useDispatch();



  const {
    ActiveNewsLetterQuarter, setActiveNewsLetterQuarter
} = useContext(GlobalContext)

  const {
    data:{
        data:ForumData
    },
    loading:ForumLoading,
} = useSelector(state => state.ForumReducer)
  
  const {
    data,
    loading
} = useSelector(state=>state.ForumDetailReducer)

let userId = crtUserId()


  useEffect(() => {
    
    if(!ForumLoading && ActiveVideoNews && ActiveVideoNews.company_slug){
      console.log('data?.BlogAddonTot_date_new ???? ', data)
      dispatch({ type: FORUM_DETAIL_REQUEST });
      let params = {
          "slug": ActiveVideoNews.company_slug,
          "user_id": userId,
          "tag_id": ActiveTag?.value,
          // "date":data?.BlogAddonTot_date_new[0]
          // "from_date": moment().subtract(11, 'days').format('YYYY-MM-DD'),
          // "to_date": moment().format('YYYY-MM-DD'),
      }
      dispatch(forumDetailAction(params));
      setActiveVideo("")
      
    }

  }, [dispatch, ForumLoading, ActiveTag]);


  useEffect(() => {
    
    if(!ForumLoading && ActiveNewsLetterQuarter == null){
      dispatch({ type: FORUM_DETAIL_REQUEST });
      let params = {
          "slug": ActiveVideoNews.company_slug,
          "user_id": userId,
          "tag_id": ActiveTag?.value,
      }
      dispatch(forumDetailAction(params));
      setActiveVideo("")
      
    }

  }, [dispatch, ForumLoading, ActiveNewsLetterQuarter]);


  useEffect(() => {
    
    if(!loading){
      
      if(ActiveVideo === ""){
        setActiveVideo(data?.BlogAddonTot_date_new?.[0]);
      }
    }

  }, [loading, data]);


  // useEffect(() => {
  //   if(!VideoLikeLoading){
  //     setLikesData(VideoLikeData.Data[0]);
  //   }
  //   if(VideoLikeLoading){
  //     // console.log('VideoLikeData >>>> ', VideoLikeData)
  //   }
  // }, [VideoLikeLoading]);


  const SelectVideoFun = (vid_item) => {
    
    let item = vid_item;
    
    setActiveVideo(item);
    let a0 = item.split('__')
    let params = {
      "slug": ActiveVideoNews.company_slug,
      "user_id": userId,
      "date": (a0[0] || ""),
      "addon_id": (a0[1] || ""),
      "tag_id": ActiveTag?.value,
      "quarter_id": ActiveNewsLetterQuarter?.id,
    }
    dispatch({ type: NEWSLETTER_COMMENT_REQUEST });
    dispatch(forumDetailAction(params));
  }

  let cal_btn = [
    {
        "id":1,
        "label":"Latest",
    },
    {
        "id":2,
        "label":"Select Date On Calender",
    }
]
    
const [value, setValue] = React.useState(0);

const handleChange = (event, newValue, item) => {
  SelectVideoFun(item)
  setValue(newValue);
};

  const filterSingleCompany = (comId) => {
    let singleComp = companyMasterData.find(s_item=> s_item.CompanyID == comId)
    return singleComp
  }

  return (
    <div className={`video-left`}>
      {/* <div
        style={{
          width: "100%",
          textAlign: "right",
        }}
      >
        {
          ActiveVideoType !== 1 && (
            <>
              {VidSortStatus ? (
                <IconButton
                  onClick={() => {
                    sortVideosTypeFun(true, true);
                  }}
                >
                  <FaSortAmountUp style={{ 
                    fontSize: '1rem'
                   }} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    sortVideosTypeFun(true, true);
                  }}
                >
                  <FaSortAmountDown style={{ 
                    fontSize: '1rem'
                   }} />
                </IconButton>
              )}
            </>
          )
        }
      </div> */}

      <Box
        sx={
          {
            display:'flex',
            gap:'.5rem',
            marginBottom:'.5rem'
          }
        }
      >
        {
            cal_btn.map((itm, i)=>{
                return (
                    <Fragment key={itm?.id}>
                        <Button className={`${BtnAction === itm.id ? "btn_contained" : "btn_outlined"}`} sx={{ fontSize:'.7rem' }} size="small" onClick={()=>setBtnAction(itm.id)} variant={`${BtnAction === itm.id ? "contained" : "outlined"}`} >{itm?.label}</Button>
                    </Fragment>
                )
            })
        }

        {/* <Button size="small" variant="contained">Latest</Button>
        <Button size="small" variant="outlined">Choose Calender</Button> */}

      </Box>
      <Box>
        {
          loading === 0 ?
          <>
            <CircularProgress />
          </>
          :
          <>
          

       
          {
            BtnAction === 1 ?
            <>
             <Box sx={{ bgcolor: 'background.paper', borderRadius:'5px', display: { xs: 'block', md: 'none' } }}>
              <Tabs
                value={value}
                // onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                { data?.BlogAddonTot_date_new &&
                    data?.BlogAddonTot_date_new.length > 0 &&
                    data?.BlogAddonTot_date_new.map((item, index) => {
                      
                      let dateArr = item.split('__')
                      return (
                        <>
                            <Tab sx={{ 
                              fontSize: '.75rem',
                              color: '#000 !important',
                              paddingX:'.5rem'
                            }} label={moment(dateArr[0]).format('DD MMM, YYYY, dddd')} onClick={(e) => handleChange(e, index, item)} />
                        </>

                      )
                    })
                  }

              </Tabs>
            </Box>

            <List sx={{ position: "relative", display: { xs: 'none', md: 'block' } }} className="video-list">
              { data?.BlogAddonTot_date_new &&
                data?.BlogAddonTot_date_new.length > 0 &&
                data?.BlogAddonTot_date_new.map((item, index) => {
                  if(index < 10){
                    let dateArr = item.split('__')
                    return (
                      <React.Fragment key={index}>
                         <ListItem disablePadding  className={ActiveVideo === item ? "activeListCls" : ""}>
                                      <ListItemButton onClick={()=>{
                                        SelectVideoFun(item)
                                      }}>
                                        <Box sx={{ 
                                          display:'flex',
                                          justifyContent:'space-between',
                                          alignItems:'center',
                                          width:'100%'
                                         }}>
                                          <Box>
                                            <Box sx={{ 
                                              display:'flex',
                                              justifyContent:'flex-start',
                                              alignItems:'center',
                                              gap:1
                                            }}>
                                              <div className='vid-title'>
                                                <strong>{moment(dateArr[0]).format('DD MMM, YYYY, dddd')}</strong>
                                               
                                              </div>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </ListItemButton>
                                    </ListItem>
                        
                      </React.Fragment>
                    );
                  }
                })}
            </List>
            </>
            :
            <>
            
              <div style={{ 
                  marginTop:'1rem',
                  paddingRight:'.5rem'
                }}>
                  <CalenderComp item={ActiveVideoNews} ActiveDateData={data?.BlogAddonTot_date} />
              </div>
            </>
          }
            
          </>
        }
      </Box>
    </div>
  );
};

export default LeftSection;
