import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    IconButton,
    ListSubheader,
    Typography,
    Alert,
    Stack,
    Button,
    Radio,
    Autocomplete,
    TextField
} from '@mui/material'
import React, {useEffect, useState} from 'react'

import Checkbox from '@mui/material/Checkbox';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';

import { red } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux'
import { companyMasterAction } from '../../../../redux/actions/userAction';
import CompanyAutoComplete from '../../../../components/SingleCompanyContainer/FinancialCharts/CompanyAutoComplete';
import { historicalTrendChooseField } from '../../../../constants/defaultArray';




const PeerToPeerTrendChooseField = (props) => {

    const { applyAction, TypeActivePrimaryBtn, setTypeActivePrimaryBtn } = props;

    
    const { data, loading } = useSelector(state => state.companyMasterData);
    const dispatch = useDispatch();


    const [checked, setChecked] = React.useState([]);
    const [DisabledItem, setDisabledItem] = React.useState(false);
    const [ShowDiv, setShowDiv] = React.useState(false);
    const [ShowError, setShowError] = React.useState(false);

    const [SelectedCompanies, setSelectedCompanies] = useState([])

    const [Companies, setCompanies] = useState([]);

    const [selectedValue, setSelectedValue] = React.useState(null);

    const handleChange = (val) => {
        setSelectedValue(val);
    };
    const [AllCompanies, setAllCompanies] = useState([])
    const [RatiosCategory, setRatiosCategory] = useState([])
    const { data: PeerRatioData, loading: PeerRatioLoading, isSelected, selected_companies, other_companies } = useSelector(state => state.PeerRatiosReducer);


    useEffect(()=>{
        console.log('PeerRatioLoading >>>> ', PeerRatioLoading)
        if(!PeerRatioLoading){
          // console.log('selected_companies ???? >', selected_companies)
          setSelectedCompanies(selected_companies)
          setCompanies(other_companies)
          const uniquePeerRatioData = [...new Set(PeerRatioData.map(item => item?.category))];
          // console.log('uniquePeerRatioData >> ', uniquePeerRatioData)
          setRatiosCategory(uniquePeerRatioData)
          let selectedData = PeerRatioData.filter(item=>item?.is_selected === true);
        //   setChipData(selectedData)
          setChecked(selectedData.map(item=>item.ID));
        }
      },[PeerRatioLoading])

    useEffect(() => {
        setSelectedCompanies([]);
        setCompanies([]);
    },[props])

    useEffect(() => {
        

        if(loading){
            dispatch(companyMasterAction());
        }

        if (!loading) {
            // console.log('data >>>>> ', data.length);
            var data1 = [];

            data.map((item) => {
                // var d1 = { label: item.CompanyName, title: item.CompanyName, name: item.CompanyName, value: item.CompanyID };
                var d1 = {title:item.CompanyName,name:item.CompanyName,value:item.CompanyID};
                data1.push(d1);
            })

            data1.sort((a, b) => {
                var a1 = a.title.toLowerCase();
                var b1 = b.title.toLowerCase();
        
                if (a1 > b1) {
                    return 1
                } else
                    if (a1 < b1) {
                        return -1
                    } else {
                        return 0
                    }
            })
            console.log('data1 >>>>> ', data1.length);

            setAllCompanies(data1);
        }
    }, [dispatch, loading])



    return (
        <>

            <Box
                sx={{
                    marginBottom: 2,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Box>
                    {/* <Typography variant='subtitle1' >
                        Please select upto 5 companies and 1 chart type!
                    </Typography> */}
                </Box>

                <Box sx={{ 
                    width: (ShowDiv ? '100%' : 'unset')
                 }}>
                    {
                        ShowDiv ?
                            <Box sx={{ 
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'space-between',
                                width:'100%'
                             }}>
                                <Box sx={{ textAlign:'center', width:'100%' }}>
                                    <Typography variant='subtitle1' >
                                        Please select upto 5 companies and 1 chart type!
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '160px' }} style={{ 
                                    position:'absolute',
                                    right: '5rem'
                                 }}>
                                    <Button
                                        disabled={ !selectedValue || Companies.length == 0 > 5 ? true : false}
                                        variant='contained'
                                        size="small"
                                        color='success'
                                        onClick={() => { applyAction(selectedValue, Companies); setShowDiv(!ShowDiv); }}
                                    >
                                        Apply
                                    </Button>
                                    
                                    {/* <Button
                                        disabled={checked.length === 0 ? true : false}
                                        onClick={() => { setDisabledItem(false); setChecked([]); }}
                                        sx={{
                                            marginLeft: 0.5
                                        }}
                                        variant='contained'
                                        size="small"
                                        color='secondary'
                                    >
                                        Uncheck All
                                    </Button> */}
                                    <Button
                                        sx={{
                                            marginLeft: 0.5
                                        }}
                                        variant='contained'
                                        size="small"
                                        color='error'
                                        onClick={() => setShowDiv(!ShowDiv)}
                                    >
                                        Cancel
                                    </Button>

                                </Box>


                            </Box>
                            :
                            <>
                                <Button
                                    variant='outlined'
                                    size="small"
                                    onClick={() => setTypeActivePrimaryBtn(1)}
                                    sx={{ marginRight: '.5rem' }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant='contained'
                                    size="small"
                                    onClick={() => setShowDiv(!ShowDiv)}
                                >
                                    Select Companies
                                </Button>
                            </>
                    }
                </Box>

            </Box>
            {
                ShowDiv ?
                    <>
                        <Divider />
                        <Box
                            sx={{
                                marginBottom: 2,
                            }} >
                            <Box >
                                
                                {
                                    AllCompanies.length > 0 &&
                                    <>
                                    {console.log(AllCompanies.slice(0, 10))}
                                    {/* <Autocomplete
                                        multiple
                                        disablePortal
                                        disableCloseOnSelect
                                        defaultValue={SelectedCompanies}
                                        getOptionDisabled={(options) => {
                                            if (Companies.length >= 5) {
                                                if (!Companies.includes(options?.value)) {
                                                    return true
                                                }
                                            }
                                        }}
                                        onChange={(evt, newVal) => {
                                            var val1 = [];
                                            for (var a = 0; a < newVal.length; a++) {
                                                val1.push(newVal[a]?.value);
                                            }
                                            setSelectedCompanies(newVal);
                                            setCompanies(val1);
                                        }}
                                        id="combo-box-demo"
                                        options={AllCompanies}
                                        sx={{ width: '100%', margin: "0.5rem 0" }}
                                        renderInput={(params) => <TextField {...params} label="" size='small' placeholder={Companies.length >= 5 ? null : 'Select Companies'} />}
                                    /> */}
                                    <Autocomplete
                                        id="free-solo-2-demo0"
                                        disableClearable
                                        size="small"
                                        options={AllCompanies}
                                        multiple={true}
                                        disableCloseOnSelect
                                        defaultValue={SelectedCompanies}
                                        getOptionDisabled={(options) => {
                                            if (Companies.length >= 5) {
                                                if (!Companies.includes(options?.value)) {
                                                    return true
                                                }
                                            }
                                        }}
                                        onChange={(evt, newVal) => {
                                            var val1 = [];
                                            for (var a = 0; a < newVal.length; a++) {
                                                val1.push(newVal[a]?.value);
                                            }
                                            setSelectedCompanies(newVal);
                                            setCompanies(val1);
                                        }}
                                        sx={{ width: '100%', borderColor: "#000" }}
                                        getOptionLabel={(option) => option.title}
                                        // renderOption={(props, option) => <li {...props}>{option.title}</li>}
                                        renderInput={(params) => (
                                        <>
                                            <TextField size="small" {...params} placeholder="Select..." className='aaaaaa' sx={{ borderColor: "#000" }} />
                                        </>
                                        )}
                                    />
                                    
                                    
                                        {/* <CompanyAutoComplete SelectedCompanies={SelectedCompanies} setSelectedCompanies={setSelectedCompanies} AllCompanies={AllCompanies}  Companies={Companies} setCompanies={setCompanies} /> */}
                                    </>
                                }
                            </Box>
                            {
                                ShowError ?
                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                        <Alert
                                            severity="error"
                                            action={
                                                ""
                                            }
                                        >
                                            Please select minimum 1 chart.
                                        </Alert>
                                    </Stack>
                                    :
                                    null
                            }
                            {/* {
                                DisabledItem ?
                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                        <Alert
                                            severity="error"
                                            action={
                                                ""
                                            }
                                        >
                                            You can select upto 5 companies and 1 chart type.
                                        </Alert>
                                    </Stack>
                                    :
                                    null
                            } */}

                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    margin: "0 0 1rem"
                                }}
                                justifyContent="space-between"
                                className="ChooseFieldContainer"
                            >


                                {
                                    RatiosCategory.length > 0 && RatiosCategory.map((c_item, i) => {
                                        return (
                                            <Box className="ChooseFieldItem">
    
                                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                                        subheader={<ListSubheader sx={{ fontSize: 18 }}>{c_item}</ListSubheader>}
                                                    >
                                                        {PeerRatioData && PeerRatioData.length > 0 && PeerRatioData.filter(fItm=>fItm.category === c_item).map((value, i1) => {
                                                            const labelId = `checkbox-list-label-${i1}`;
                                                            return (
                                                                <ListItem
                                                                    key={value.title}
                                                                    disablePadding
                                                                >
                                                                    <ListItemButton role={undefined} onClick={() => handleChange(value.ID)} dense>
                                                                        <ListItemIcon
                                                                            sx={{
                                                                                minWidth: '1rem',
                                                                            }}
                                                                        >
                                                                            <Radio
                                                                                sx={{
                                                                                    padding: '0 0.3rem 0 0rem'
                                                                                }}
                                                                                checked={selectedValue === value.ID}
                                                                                size="small"
                                                                                value={value.ID}
                                                                                name="radio-buttons"
                                                                                inputProps={{ 'aria-label': 'A' }}
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText id={labelId}
                                                                            primary={<Typography fontSize={12} >{value.Name}</Typography>}
                                                                        />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            );
                                                        })}
                                                    </List>
                                            </Box>
                                        )
                                    })
                                }
                                {/* {
                                    historicalTrendChooseField().map((item, i) => {
                                        return (
                                            <Box className="ChooseFieldItem">



                                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                                    subheader={<ListSubheader sx={{ fontSize: 18 }}>{item.label}</ListSubheader>}
                                                >
                                                    {item?.columns.map((value, i1) => {
                                                        const labelId = `checkbox-list-label-${i1}`;
                                                        return (
                                                            <ListItem
                                                                key={value.title}
                                                                disablePadding
                                                            >
                                                                <ListItemButton role={undefined} onClick={() => handleChange(value.title)} dense>
                                                                    <ListItemIcon
                                                                        sx={{
                                                                            minWidth: '1rem',
                                                                        }}
                                                                    >
                                                                        <Radio
                                                                            sx={{
                                                                                padding: '0 0.3rem 0 0rem'
                                                                            }}
                                                                            checked={selectedValue === value.title}
                                                                            // onChange={handleChange}
                                                                            size="small"
                                                                            value={value.title}
                                                                            name="radio-buttons"
                                                                            inputProps={{ 'aria-label': 'A' }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText id={labelId}
                                                                        primary={<Typography fontSize={12} >{value.title}</Typography>}

                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                            </Box>
                                        )
                                    })
                                } */}


                            </Box>
                        </Box>
                    </>
                    :
                    null
            }
        </>
    )
}

export default PeerToPeerTrendChooseField