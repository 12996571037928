import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Avatar, Box, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Tab, Typography } from "@mui/material";
import React, { Fragment } from "react";
import './style.scss'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forumAction, forumDetailAction } from "../../../redux/actions/VdrAction";
import DetailPage from "./DetailPage";
import OtherSection from "./OtherSection";
import { FORUM_DETAIL_REQUEST } from "../../../constants/VdrConstants";
import { useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { crtUserId } from "../../../constants/helpers";
const TOTPage = () => {

    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');


// const [Value, setValue] = React.useState(String(code));
const [Value, setValue] = React.useState("1");
const [ActiveItem, setActiveItem] = React.useState(null);

const {
    data:{
        data:ForumData
    },
    loading:ForumLoading,
} = useSelector(state => state.ForumReducer)

useEffect(() => {
    let f = document.querySelector('footer');
    if(f){
      f.remove()
    }
    
  },[]);
  
const {
    data:{
        data:ForumetailData
    },
    loading:ForumetailLoading,
} = useSelector(state => state.ForumDetailReducer)


const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch({ type: FORUM_DETAIL_REQUEST });
};

const dispatch = useDispatch();

const {
    data:detailData,
    loading:detailLoading
} = useSelector(state=>state.ForumDetailReducer)

useEffect(() => {
    if(ForumLoading){
        dispatch(forumAction({}))
    }
    if(!ForumLoading){
    //  console.log(ForumData)
    }
}, [dispatch, ForumLoading])

const actionItems = (item0) => {
    // console.log('item0 >>> ', item0)
    let params = {
        "slug": item0.slug,
        "user_id": crtUserId()
    }
    dispatch(forumDetailAction(params));
}

  
  return (
    <>
      <Box
        className="headerContainerFront"
        sx={{
        //   minHeight: "70px",
          height: "auto",
        }}
      >
        <Typography
          style={{ zIndex: 2 }}
          fontSize={"2.5em"}
          color="#133e5b"
          fontWeight="700"
        >
          Blogs
        </Typography>
      </Box>
      <Box
        className="videoContainer"
        sx={{
          marginTop: 5,
          marginBottom: 5,
        }}
      >
           <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={Value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {/* {
                            ForumData?.newsletter && ForumData?.newsletter.map((item, i)=>{
                                return (
                                    <Tab label={`${item?.name}`} value={String(i+1)} 
                                    wrapped={true} />
                                )
                            })
                        } */}
                        <Tab label="Presentation" value={"1"}wrapped={true} />
                        <Tab label="Company/ Sector Discussion" value={"2"}wrapped={true} />
                    </TabList>
                    </Box>
                    
                    {/* {
                            ForumData?.newsletter && ForumData?.newsletter.map((item, i)=>{
                                return (
                                    <>
                                        <TabPanel value={String(i+1)}>
                                            <DetailPage item={item} ActiveItem={ActiveItem} setActiveItem={setActiveItem} />
                                        </TabPanel>
                                    </>
                                )
                            })
                        } */}
                        
                    <TabPanel value={"1"}>
                        
                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3} sx={{
                                                    maxHeight:'70vh',
                                                    overflow:'auto',
                                                    border:'1px solid #ccc',
                                                    bgcolor: 'background.paper',
                                                    position: 'relative',
                                                    '& ul': { padding: 0 },
                                            }}>
                                                <div>
                                                <List >

                                                { 
                                                    ForumData?.presentation && ForumData?.presentation.length > 0 && ForumData?.presentation.map((itm, i)=>{
                                                        return (
                                                            <Fragment key={i}>
                                                                <ListItem disablePadding>
                                                                    <ListItemButton onClick={()=>{
                                                                        actionItems(itm)
                                                                    }}>
                                                                        <Box className="listItem">
                                                                            <h3>{itm?.first_name}</h3>
                                                                            <div>{moment(itm.created_at).format('DD-MM-YYYY')}</div>
                                                                        </Box>
                                                                    </ListItemButton>
                                                                </ListItem> 
                                                                <Divider />
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                                </List>
                                                </div>
                                            </Grid>
                                            <Grid item xs={9} sx={{
                                                paddingRight:'2rem',
                                                maxHeight:'70vh',
                                                overflow:'auto',
                                                border:'1px solid #ccc'
                                            }}>
                                                <div className="box-head">
                                            <Avatar sx={{ width: 60, height: 60 }} alt={detailData?.author?.name} src={detailData?.author?.image} />
                                            <div className="uDetail">
                                                <h4>{detailData?.author?.name}</h4>
                                                <ul>
                                                    <li>{detailData?.author?.name}</li>
                                                    <li>{moment(detailData?.blog?.created_at).format('DD-MMM-YYYY')}</li>
                                                </ul>
                                                <div className='category-sec'>
                                                    <ul>
                                                    {
                                                        detailData?.category && detailData?.category.map((item0, i)=>{
                                                            return (
                                                                <li>{item0?.name}</li>
                                                            )
                                                        })
                                                    }
                                                    </ul>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-body" style={{ paddingLeft:'1rem' }}>
                                            <div dangerouslySetInnerHTML={{
                                                __html:detailData?.blog?.discription
                                            }} />
                                        </div>
                                        
                                            </Grid>
                                        </Grid>
                                    </Box>
                        {/* <OtherSection ForumData={ForumData} /> */}
                    </TabPanel>
                    <TabPanel value={"2"}>
                        
                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3} sx={{
                                                    maxHeight:'70vh',
                                                    overflow:'auto',
                                                    border:'1px solid #ccc',
                                                    bgcolor: 'background.paper',
                                                    position: 'relative',
                                                    '& ul': { padding: 0 },
                                            }}>
                                                <div>

                                                <List >
                                                    { 
                                                        ForumData?.company_blog && ForumData?.company_blog.length > 0 && ForumData?.company_blog.map((itm, i)=>{
                                                            return (
                                                                <>
                                                                    <ListItem disablePadding>
                                                                        <ListItemButton onClick={()=>{
                                                                            actionItems(itm)
                                                                        }}>
                                                                            <Box className="listItem">
                                                                                <h3>{itm?.first_name}</h3>
                                                                                <div>{moment(itm.created_at).format('DD-MM-YYYY')}</div>
                                                                            </Box>
                                                                        </ListItemButton>
                                                                    </ListItem> 
                                                                    <Divider />
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </List>
                                                </div>
                                            </Grid>
                                            <Grid item xs={9} sx={{
                                                paddingRight:'2rem',
                                                maxHeight:'70vh',
                                                overflow:'auto',
                                                border:'1px solid #ccc'
                                            }}>
                                                <div className="box-head">
                                            <Avatar sx={{ width: 60, height: 60 }} alt={detailData?.author?.name} src={detailData?.author?.image} />
                                            <div className="uDetail">
                                                <h4>{detailData?.author?.name}</h4>
                                                <ul>
                                                    <li>{detailData?.author?.name}</li>
                                                    <li>{moment(detailData?.blog?.created_at).format('DD-MMM-YYYY')}</li>
                                                </ul>
                                                <div className='category-sec'>
                                                    <ul>
                                                    {
                                                        detailData?.category && detailData?.category.map((item0, i)=>{
                                                            return (
                                                                <li>{item0?.name}</li>
                                                            )
                                                        })
                                                    }
                                                    </ul>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-body" style={{ paddingLeft:'1rem' }}>
                                            <div dangerouslySetInnerHTML={{
                                                __html:detailData?.blog?.discription
                                            }} />
                                        </div>
                                        
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    
                        {/* <DetailPage item={item} ActiveItem={ActiveItem} setActiveItem={setActiveItem} /> */}
                        {/* <OtherSection ForumData={ForumData} /> */}
                    </TabPanel>
                </TabContext>
            </Box>
      </Box>
    </>
  );
};

export default TOTPage;
