import { Box, Checkbox, Divider, Tooltip, FormControlLabel, FormGroup, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, Button } from '@mui/material';
import { borderRadius, margin } from '@mui/system';
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { LoadingComponent } from '../../components';
import { vdrForensicAction } from '../../redux/actions/VdrAction';
import ForensicItemPieChart from './ForensicItemPieChart';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HelpIcon from '@mui/icons-material/Help';
import { TbArrowBigDown, TbArrowBigTop } from "react-icons/tb";
import PieChart from './CustomChart/PieChart'
import { RatioTooltipAction } from '../../redux/actions/userAction';


function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}



const ForensicItemTable = (props) => {
  // console.table(props);
  const { title, companyId, typeData, setParentGroup, setOpenForm, toggleDrawer, dataFor } = props;
  const dispatch = useDispatch();

  const {
    loading,
    firstLoading,
    data,
    msg,
    error
  } = useSelector(state => state.ForensicReducer);
  const {
    loading:RatioTooltipLoading,
    data:RatioTooltipData
  } = useSelector(state => state.RatioTooltipReducer);
  
  const { data: companyData, loading: companyLoading } = useSelector((state) => state.vdrSingleCompanyReducer);
  const { loading: ForensicModelCommentsLoading } = useSelector((state) => state.ForensicModelCommentsReducer);

  const [tableColumns, setTableColumns] = React.useState(null);
  const [tableBodyData, setTableBodyData] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [checked, setChecked] = React.useState(false);
  
  const showHide = (event) => {
    setChecked(event.target.checked);
  };

    const {
        data:ForensicTabShowHide,
        loading:ForensicTabShowHideLoading
    } = useSelector(state=>state.ForensicTabShowHideReducer);
  useEffect(() => {
    const params = {
      "companyID": companyId || companyData.CompanyID,
      "Type": typeData
    }
    dispatch(vdrForensicAction(params));
    
    if(typeData == 'ratios'){
      dispatch(RatioTooltipAction({"Type": "ratios"}))
    }

  }, [ForensicTabShowHideLoading, ForensicModelCommentsLoading])


  const filterTooltip = (type="") => {
    
    let ddd = RatioTooltipData.Data && RatioTooltipData.Data[0].ToolTip.filter((item)=>item.key == type);

    // console.log(ddd);
    return (ddd && ddd[0].toolTip);
    
  }

  useEffect(() => {
    if (!firstLoading && !loading && data) {
    }
  }, [loading])
  
  // console.log(companyData);




  const divData = () => {
    return (
      <>
      
        {
          data && data.Data && data.Data.length > 0 && data.Data.map((res, i) => {
            
            if (i != 1 && typeData == "CF" || typeData != "CF") {

              const itemDetails = res.details.length > 0 ? res.details[0] : {};
              let highlights = res.details.length > 0 ? res.details[0].highlight : {};

              let a1 = 0;
              const tableHead = res.header;
              var mColArr = [];
              tableHead.map((resHeads) => {

                var hideCheck = false;
                if (a1 !== 1) {
                  var hideCheck = true;
                }
                var stickyLeft = 0;

                var stuff = {};
                Object.keys(resHeads).forEach((key, i0) => {

                  var label = resHeads[key];
                  var show_status = resHeads[key];
                  if (label != null && label != "") {
                    var width = 120;
                    var sticky = false;
                    if (key == 'Company_Name' || key == 'Sector' || key == 'Industry') {
                      width = 150;
                      sticky = true;

                      hideCheck = false;
                    } else {
                      hideCheck = true;
                    }

                    if (key != '$id' && key != 'AccordCode') {


                      // var a0 = { key:key, show_status:true };
                      // a00.push(a0);
                      stuff[key] = true;
                      var mCol = {
                        id: key,
                        label: label,
                        stickyLeft: stickyLeft,
                        minWidth: width,
                        maxWidth: width,
                        align: 'canter',
                        hideCheck: hideCheck,
                        sticky: sticky,
                      }
                      mColArr.push(mCol);
                    }
                  }
                  a1++;
                })
              });
              {/* console.log(mColArr); */}


              const tableBody = res?.TableData;
              
              var allRowsData = [];
              tableBody.map((resBody) => {

                var singleRow = {};
                Object.keys(resBody).forEach(key => {
                  let col_val = resBody[key] ? resBody[key].trim() : " ";
                  singleRow[key] = col_val;
                })
                allRowsData.push(singleRow);
              });

              
              if (i == 0 && typeData == "CF") {
                  const data3 = data.Data[1]?.TableData;
                if(data3){

                  data3.map((resBody) => {
                  var singleRow = {};
                  Object.keys(resBody).forEach(key => {
                    let col_val = resBody[key] ? resBody[key].trim() : " ";
                    singleRow[key] = col_val;
                  })
                  allRowsData.push(singleRow);
                  });
                }
              }


              {/* console.log(res.ApplicationPie);  */}
              {/* 
              */ }
              {/* console.log(i); */}
              return (
                <React.Fragment key={i} >
                <div style={{ display: !checked && i == 2 && typeData == "CF" ? 'none': 'unset' }} >
                  <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 3, padding: 1 }}>
                    <Box sx={{ marginBottom: 1 }}>
                      <Typography variant="subtitle1" fontWeight='bold' >
                        {itemDetails?.title}
                      </Typography>
                      <Typography sx={{ marginTop: 1 }} variant="title" dangerouslySetInnerHTML={{ __html: itemDetails?.description }} />
                    </Box>

                    <Grid container>
                      <Grid sm={res.sourcePie.length == 0 && res.ApplicationPie.length == 0 ? (typeData == 'ratios' ? 12 : 8) : 5}>
                      {
                        typeData != 'AH' && typeData != 'ESOP' && typeData != 'SH' && typeData != 'DH' && (
                        <Box sx={{ 
                          textAlign:'right',
                          color:'#000',
                          fontSize:11
                        }}>Rs. in Cr.</Box>
                        )
                      }

                        <TableContainer>
                          <Table className='custom-table forensic-table' size={'small'} stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {mColArr.map((column, clm) => {
                                  var cStyle = {}; 
                                  if(column.id !== 'column_1' && clm !== 0){
                                    cStyle['textAlign'] = 'center';
                                  }

                                  return (
                                  <TableCell
                                    style={cStyle}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.label}
                                  </TableCell>
                                )})}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {allRowsData.map((row, is) => {
                                
                                let lastR = false;
                                return (
                                  <TableRow role="checkbox" tabIndex={-1} key={is}>
                                    {mColArr.map((column, c1) => {

                                      const value = row[column?.id];
                                      const cStyle = {};
                                      {/* const a10 = allRowsData[allRowsData.length-1]; */}
                                      if (i < 2 && typeData == "CF" && value == "Total") {
                                        cStyle['backgroundColor'] = "#c7dbb0"
                                      }
                                        
                                      if(c1 > 0){
                                        cStyle['textAlign'] = "center"
                                        {/* cStyle['height'] = 40 */}
                                      }
                                      
                                      if(allRowsData[allRowsData.length-1]){
                                        var l1 = allRowsData.length-1;
                                        {/* cStyle['fontWeight'] = "bold" */}
                                        var lastRow = allRowsData[l1];
                                        var firstRow = allRowsData[0];
                                        if(
                                          value == ("I. Capital Allocation Trends") ||
                                          value == ("II.Survival Probability/ Balance Sheet Health") ||
                                          value == ("III. Forensic Evaluation") ||
                                          typeData == "ratios" && row['column_1'] == "I. Return Ratios" ||
                                          typeData == "ratios" && row['column_1'] == "II.Survival Probability" ||
                                          typeData == "ratios" && row['column_1'] == "III.Balance Sheet Health" ||

                                          typeData == "ratios" && row['column_1'] == "V. Profit & Loss Statement" ||
                                          typeData == "ratios" && row['column_1'] == "Vi. Cash Flow" ||

                                          typeData == "ratios" && row['column_1'] == "II .Survival Probability" ||
                                          typeData == "ratios" && row['column_1'] == "III .Balance Sheet Health" ||
                                          typeData == "ratios" && row['column_1'] == "IV. Financial Ratio" ||
                                          typeData == "CF" && row['column_1'] == "Total" ||
                                          typeData == "CF" && row['column_1'] == "Application" ||
                                          typeData == "CF" && row['column_1'] == "Sources" 
                                        ){
                                          cStyle['fontWeight'] = "bold";
                                        }
                                        

                                        if(i == 2 && typeData == "CF"){
                                          

                                          if(is == 0){
                                            cStyle['backgroundColor'] = "#fff";
                                          }else{
                                            if(column?.id == 'column_2'){
                                              cStyle['backgroundColor'] = "#b6e8ff";
                                            }else{
                                              {/* cStyle['backgroundColor'] = "#fff"; */}
                                            }
                                          }
                                          
                                        if(row['column_1'] == "Net Sales" && column?.id != 'column_1'){
                                            {/* console.log(row['column_1']); */}
                                            cStyle['backgroundColor'] = "#b6e8ff";
                                          }
                                          
                                        }
                                        
                                        {/* if(
                                          typeData == "ratios" && row['column_1'] == "Dividend Payout Ratio (%)"
                                        ){
                                          cStyle['fontWeight'] = "normal";
                                        } */}
                                        Object.keys(lastRow).map((lastRowItem)=>{
                                          if(l1 == is && lastRow[lastRowItem] == value){
                                             
                                            if(typeData != "ratios" && typeData != "CH" && typeData != "DH" && typeData != "ESOP" && typeData != "SH" && typeData != "AH"){
                                              cStyle['fontWeight'] = "bold";
                                            }
                                            {/* console.log(lastRowItem); */}
                                            if(i == 2 ){
                                              cStyle['backgroundColor'] = "#fff";
                                            }

                                            
                                            if(i == 3 && l1 == is && typeData == "BS" && lastRowItem != "column_1"){
                                                
                                                lastR = true;

                                                var newVal = value.trim() != "" ? parseFloat(value) : 0;
                                                if(newVal < 10){
                                                  cStyle['backgroundColor'] = "#9beb8d";
                                                }else{
                                                  cStyle['backgroundColor'] = "#ffa3a3";
                                                }
                                            }
                                          }
                                        })
                                      }
                                      {/* console.log(lastR); */}
                                      return (
                                        <TableCell style={cStyle} key={column?.id} align={column?.align}>
                                          {
                                            value === " "
                                              ?
                                              <>
                                                <div className="blankCell"></div>
                                              </>
                                              :
                                              <>
                                                {column.format && typeof value === 'number'
                                                  ? column.format(value)
                                                  : value}
                                                 {
                                                  column.id == "column_1" ? 
                                                  <>
                                                  {/* filterTooltip */}
                                                  {
                                                    value.trim() === "ROE (%)" || 
                                                    value.trim() === "ROCE (%)" || 
                                                    value.trim() === "Net Debt / Total Equity (x)" || 
                                                    value.trim() === "Asset Turnover (x)" || 
                                                    value.trim() === "Cash Conversion Cycle (Days)" || 
                                                    value.trim() === "Dividend Payout Ratio (%)" || 
                                                    value.trim() === "Interest Coverage (x)" || 
                                                    value.trim() === "Free Cash Flow (Rs)" ?
                                                    <Tooltip position="right" title={filterTooltip(value)}>
                                                      <HelpIcon sx={{ 
                                                        fontSize: 16,
                                                        color:'#c3c3c3',
                                                        position:'relative',
                                                        left:'0.2rem',
                                                        top:'0.2rem',
                                                       }} />
                                                    </Tooltip>
                                                    :
                                                    null
                                                  }
                                                  
                                                  </>
                                                  :
                                                  null
                                                 }

                                            {
                                              lastR === true
                                              ?
                                              <>
                                                  {
                                                     value < 10 ?
                                                      <TbArrowBigTop fontSize='18' style={{ marginLeft:5, position:'relative', top:2, fill:'#9beb8d' }} />
                                                    :
                                                      <TbArrowBigDown fontSize='18' style={{ marginLeft:5, position:'relative', top:2, fill:'#ffa3a3' }} />
                                                  }
                                              </>
                                              :
                                              null
                                            }
                                                  
                                                  
                                              </>
                                          }
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <Grid container spacing={2} style={{}}>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                marginTop: 1,
                                display: 'flex',
                                justifyContent: 'center',
                              }} 
                            >
                              {
                                highlights && highlights.length > 0 && highlights.map((highlight, i00) => {
                                  {/* console.log('highlight >> ', highlight) */}
                                  return (
                                    <Box sx={{
                                      backgroundColor: '#c7dbb0',
                                      borderRadius: '4px',
                                      border: '1px solid #ddd',
                                      margin: '0.5rem',
                                      textAlign: 'center',
                                    }}>
                                      <div style={{
                                        padding: '0.5rem 1rem',
                                      }}
                                      >
                                        <Typography fontSize={12} fontWeight={'600'} variant="subtitle1" fontWeight='bold' >
                                          {highlight?.title}
                                        </Typography>
                                        <Typography fontSize={12} fontWeight={'600'} variant="subtitle2" fontWeight='bold' >
                                          {highlight?.subtitle}
                                        </Typography>
                                      </div>
                                      <div style={{
                                        backgroundColor: (highlight?.arrow == 'Up' && highlight?.value > 50 ? '#9beb8d' : highlight?.arrow == '' ? '#fff' : '#ffa3a3'),
                                        borderTop: '1px solid',
                                        padding: '0.5rem 1rem',
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent:'center',
                                      }}>
                                        <Typography fontSize={13} sx={{ marginTop:.5 }} variant="subtitle2" fontWeight='bold' >
                                          {highlight?.value}
                                        </Typography>
                                        {
                                          highlight?.arrow == 'Up' && highlight?.value > 50 ?
                                            <TbArrowBigTop fontSize='18' style={{ marginLeft:5, fill:'#9beb8d' }} />
                                          :
                                            <>
                                            {
                                              highlight?.arrow == "" 
                                              ?
                                              <></>
                                              :
                                              <TbArrowBigDown fontSize='18' style={{ marginLeft:5, fill:'#ffa3a3' }} />

                                            }
                                            </>
                                        }

                                      </div>
                                    </Box>
                                  )
                                })
                              }
                            </Box>
                          </Grid>
                        </Grid>
                        
                        {
                          itemDetails?.title != "Cash Flow" && (
                            <Box sx={{
                              marginTop:1
                             }}>
                              <Box>
                              <Typography variant='h6' sx={{ fontWeight:'800', fontSize:15 }}>Comment</Typography>
                              {
                                !dataFor && (
                                  <Button onClick={(e)=>{ setParentGroup({...(res.Comment[0]), Type:itemDetails?.title, typeData, companyId }); toggleDrawer('bottom', true)(e);  setOpenForm('ForensicForm'); }} size="small" sx={{ fontSize:10 }} variant="outlined" >Edit</Button>
                                )
                              }
                              </Box>
                              <Divider sx={{ 
                                marginY:1
                               }}/>
                              <Typography dangerouslySetInnerHTML={{ 
                                __html: res.Comment[0] ? res.Comment[0].description : ""
                              }} />
                            </Box>
                          )
                        }
                        
                        {
                            typeData == "CF" && i == 0
                            ?
                            <>
                              <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox checked={checked} onChange={showHide} name="gilad" />
                                    }
                                    label="Show/Hide"
                                  />
                              </FormGroup>
                            </>
                            :
                            null
                          }
                      </Grid>
                      {
                        res.sourcePie.length > 0 && res.ApplicationPie.length > 0
                        ?
                        <>
                      <Grid md={7} sx={{ paddingLeft: 1 }} >
                        <Box>
                          <Grid container >
                            {
                              res.sourcePie.length > 0
                                ?
                                <>
                                  <Grid sm={6} sx={{ padding: '0.5rem' }}>
                                    <Box style={{ border: '1px solid #ddd', borderRadius: '5px', overflow: 'hidden' }} >
                                    <PieChart  ChartTitle="Sources" chartData={res.sourcePie} />
                                      {/* <ForensicItemPieChart ChartTitle="Sources" chartData={res.sourcePie} /> */}
                                    </Box>
                                  </Grid>
                                </>
                                :
                                null
                            }
                            {
                              res.ApplicationPie.length > 0
                                ?

                                <>
                                  <Grid sm={6} sx={{ padding: '0.5rem' }}>
                                    <Box style={{ border: '1px solid #ddd', borderRadius: '5px', overflow: 'hidden' }} >
                                        <PieChart  ChartTitle="Application" chartData={res.ApplicationPie} />
                                      {/* <ForensicItemPieChart ChartTitle="Application" chartData={res.ApplicationPie} /> */}
                                    </Box>
                                  </Grid>
                                </>
                                :
                                null
                            }


                          </Grid>
                        </Box>
                      </Grid>

                        </>
                        :
                        null
                      }
                    </Grid>

                  </Paper>
                </div>
                
                {
                          itemDetails?.title == "Cash Flow" && res?.Comment?.length > 0 && (
                            <Box sx={{ 
                              marginTop:1
                             }}>
                              <Typography variant='h6' sx={{ fontWeight:'800', fontSize:15 }}>Comment</Typography>
                              <Divider sx={{ 
                                marginY:1
                               }}/>
                              <Typography dangerouslySetInnerHTML={{ 
                                __html: res.Comment[0].description
                              }} />
                            </Box>
                          )
                        }

                </React.Fragment>
              )
            }
          })
        }

      </>
    )
  }


  return (
    <>
      <Box>
        <h2>{title}</h2>
        {
          loading ? <LoadingComponent /> : <>
            {divData()}
          </>
        }
      </Box>
    </>
  )
}

export default ForensicItemTable