import { Box, Button, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { FiSearch } from "react-icons/fi";
import './new_style.scss'
import { useState } from 'react';
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { crtUserId, mediaInput } from '../../../constants/helpers';
import moment from 'moment';
import { vdrMediaAction,VDRMediaMyFavouriteListAction } from '../../../redux/actions/VdrAction';
import { companyMasterAction } from '../../../redux/actions/userAction';

const VideoPageNew = () => {

    const dispatch = useDispatch();
    const { data: vdrMediaData, loading: vdrMediaLoading } = useSelector((state) => state.vdrMediaReducer);
    const { data: companyMasterData, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
    const { data: vdrMediaGroupData, loading: vdrMediaGroupLoading } = useSelector((state) => state.vdrMediaGroupReducer);
    const { data: VDRMediaMyFavouriteListData, loading: VDRMediaMyFavouriteListLoading } = useSelector((state) => state.VDRMediaMyFavouriteListReducer);


    const [ActiveVideoType, setActiveVideoType] = useState(1);
    let buttonsArr = [
        {
            id:1,
            text:"ALL",
            type:"all"
        },
        {
            id:2,
            text:"WITH CLIENTS",
            type:"clients"
        },
        {
            id:3,
            text:"COMPANY SPECIFIC",
            type:"company"
        // },
        // {
        //     id:4,
        //     text:"My List",
        //     type:"mylist"
        }
    ];

    const [FilterVideoData, setFilterVideoData] = React.useState([]);
    const [VidSortStatus, setVidSortStatus] = React.useState(false);
    const [ActiveImage, setActiveImage] = React.useState("");
    const [ActiveVideoItem, setActiveVideoItem] = React.useState("");
    const [ToBeUpdated, setToBeUpdated] = React.useState(false);
    const [VDRMediaMyFavouriteListData1, setVDRMediaMyFavouriteList] = React.useState(null);
    const [SearchWidth, setSearchWidth] = useState(342);
    useEffect(() => {
      let a0 = document.querySelector('.vid-head-btn');
      setSearchWidth(a0.offsetWidth)
    }, [])

    
    const sortVideos = (sortStatus=false, nextLoad=false, crtTab=ActiveVideoType) => {
        let fData = vdrMediaData.Data.filter(item00=>{ if (item00.VDRVideo === "Yes") return item00 });
        if(crtTab === 1){
            fData = fData.filter(item00=>{ if (item00.DocumentType !== "") return item00 });
        }else
        if(crtTab === 2){
            fData = fData.filter((items) => { if (items.DocumentType === 'Common') return items });
        }else
        if(crtTab === 3){
            fData = fData.filter((items) => { if (items.DocumentType === 'SINGLE VIDEO') return items });
        }
        else
        if(crtTab === 4){
             fData = VDRMediaMyFavouriteListData?.Data;
        }

        if(!nextLoad){
            fData.sort((a,b)=>{
                return new Date(b.dateTime) - new Date(a.dateTime)
            })
        }else{
            if(sortStatus){
                
                if(!VidSortStatus){
                    fData.sort((a,b)=>{
                        return new Date(a.dateTime) - new Date(b.dateTime)
                    })
                }else{
                    fData.sort((a,b)=>{
                        return new Date(b.dateTime) - new Date(a.dateTime)
                    })
                }
                setVidSortStatus(!VidSortStatus)
            }else{
                fData.sort((a,b)=>{
                    return new Date(b.dateTime) - new Date(a.dateTime)
                })
                setVidSortStatus(false);
            }
        }
        if(crtTab === 1){
            // fData = fData.slice(0, 11);
        }
        
        setFilterVideoData(fData);
    }

    const filterSearch = (e) => { 
        let searchedVal = e.target.value; 
        setToBeUpdated(false);
        const filteredRows = FilterVideoData.filter((row, i)=>{
          return String(row?.videoTitle).toLowerCase().includes(searchedVal.toLowerCase());
        })
        if (searchedVal.length < 1) {
            sortVideos(false, true, ActiveVideoType);
            // console.log('searchedVal >>>>> ', searchedVal)
        }
        else
        {
            setFilterVideoData(filteredRows);
            if(filteredRows.length === 0){
                setToBeUpdated(true);
            }
        }
    }
    useEffect(() => {
        if (VDRMediaMyFavouriteListLoading) {
            let prams = { "webuserId": crtUserId() };
            dispatch(VDRMediaMyFavouriteListAction(prams));
        }
        if (!VDRMediaMyFavouriteListLoading) {
            setVDRMediaMyFavouriteList(VDRMediaMyFavouriteListData);
        }
    }, [dispatch])
    
    useEffect(() => {
        if(ActiveVideoType === 4 && !VDRMediaMyFavouriteListLoading){
            console.log(buttonsArr[3]);
            chooseType(buttonsArr[3]);
        }
    }, [dispatch, VDRMediaMyFavouriteListLoading])
    
    useEffect(() => {

        if(companyMasterLoading){
            dispatch(companyMasterAction());
        }
        if (vdrMediaLoading && vdrMediaData.length === 0) {
            let a0 = mediaInput;
            a0 = {...a0, fromDate: moment().subtract(20, 'years').format('YYYY-MM-DD')}
            dispatch(vdrMediaAction(a0));
        }
        if (!vdrMediaLoading) {
            sortVideos();
        }

    }, [dispatch, vdrMediaLoading])

   
    
    const chooseType = (item) =>{
        // alert('sasa');
        sortVideos(false, true, item.id)
        setActiveVideoType(item.id)
    }
    
    useEffect(() => {
        let f = document.querySelector('footer');
        if(f){
            f.remove()
        }
    },[]);

      
  return (
    <>
    
    <Box className="headerContainerFront" sx={{
        // minHeight:'70px',
        height:'auto'
       }}>
        <Typography
          style={{ zIndex: 2 }}
          fontSize={"2.5em"}
          color="#000"
          fontWeight="700"
        >
          Videos
        </Typography>
        <Box className='vid-head-btn'>
            {
                buttonsArr.map((item, index)=>{
                    return (
                        <Fragment key={item.id}>
                            <Button onClick={(e)=>{
                                chooseType(item);
                            }} size="small" className={`cst-btn ${ActiveVideoType===item.id ? 'contained' : "outlined"}`} variant={ActiveVideoType===item.id ? 'contained' : "outlined"} >{item.text}</Button>
                        </Fragment>
                    )
                })
            }
        </Box>
        <div className='vid-head-sec'>
            <Box className='vid-head-search' mt={2}>
                { <div>
                    <TextField  onChange={(e)=>filterSearch(e)}
                        id="search-field"
                        size='small'
                        placeholder="Search"
                        fullWidth
                        sx={{ m: 1, width: SearchWidth, borderColor: '#000' }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">
                                <FiSearch color="#000" />
                            </InputAdornment>,
                            className:'searchBox',
                            style: { color: '#000' },
                            sx: {
                            '&::placeholder': {
                                color: '#000',
                                opacity: 1, // otherwise firefox shows a lighter color
                            },
                            },
                        }}
                        />
                </div> }
            </Box>
        </div>
      </Box>
      <Box className="videoContainer" sx={{
        marginTop: 5, marginBottom: 5
      }}>
        
        <div className='vid-body-sec'>
           
            <Box >
                <Grid container spacing={2}>
                <Grid item xs={6}>
                    <LeftSection 
                        VidSortStatus={VidSortStatus}
                        setVidSortStatus={setVidSortStatus}
                        sortVideosTypeFun={sortVideos}
                        FilterVideoData={FilterVideoData}
                        setFilterVideoData={setFilterVideoData}
                        ActiveImage={ActiveImage}
                        setActiveImage={setActiveImage}
                        ActiveVideoType={ActiveVideoType}
                        setActiveVideoType={setActiveVideoType}
                        ActiveVideoItem={ActiveVideoItem}
                        setActiveVideoItem={setActiveVideoItem}
                        ToBeUpdated={ToBeUpdated}
                        setToBeUpdated={setToBeUpdated}
                    />
                </Grid>
                <Grid item xs={6}>
                    <RightSection
                        ActiveImage={ActiveImage}
                        setActiveImage={setActiveImage}
                        ActiveVideoItem={ActiveVideoItem}
                        setActiveVideoItem={setActiveVideoItem}
                    />
                </Grid>
                </Grid>
            </Box>
        </div>
      </Box>
    </>
  )
}

export default VideoPageNew