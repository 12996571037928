import { Box, Container, Grid, List, ListItem, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoadingComponent } from '../../components'
import { loggedInUser } from '../../constants/helpers'
import { vdrFavoriteAction, vdrCompanyAction, vdrHomeAction } from '../../redux/actions/VdrAction'
import { SingleCompanyGrid } from '../components'

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import axios from 'axios'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


const SmallCase = () => {
  const { data, loading, error } = useSelector(state => state.vdrFavoriteReducer);
  const { data: companyData, loading: companyLoading, error: companyError } = useSelector(state => state.vdrHomeReducer);

  const dispatch = useDispatch();


  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [Manufacturing, setManufacturing] = React.useState([]);
  const [Opportunities, setOpportunities] = React.useState([]);

  const [CallApiStatus, setCallApiStatus] = React.useState(false);

  const [SmallCaseData, setSmallCaseData] = React.useState([]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

useEffect(() => {
  if(!CallApiStatus){

    axios.post('https://vasudeep.com:8084/https://omkaracapital.in/api/small-case')
    .then((res)=>{
      var resData = res.data;
      // console.log('res >>> ', res);
      // console.log('res >>> ', resData);
      setSmallCaseData(resData.data);
    })
    .catch((error)=>{
      console.log(error)
    })
  }
  if(Manufacturing.length == 0 && Opportunities.length == 0){

    axios.get('https://omkaracapital.in/api/small-case')
    .then((res)=>{
      var res = res.data;
      setOpportunities(res.opportunities);
      setManufacturing(res.manufacturing);
    })
    .catch((error)=>{
      console.log(error)
    })
  }
  
}, [])


  return (
    <>
      <Box className="headerContainerFront">

        <Typography
          style={{ zIndex: 2 }}
          fontSize={"2.5em"}
          color="#f37b21"
          fontWeight="700"
        >

          Smallcase

        </Typography>
      </Box>

      <Box className="bodyContainer" sx={{
        marginTop: 5, marginBottom: 5

      }}>

        <Grid container spacing={3}>
        <Grid sm={2}></Grid>
        <Grid sm={8} sx={{ bgcolor: 'background.paper'}}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            sx={{
              // maxWidth: '350px',
            }}
          >
          {
            SmallCaseData && SmallCaseData.length > 0 &&
            SmallCaseData.map((item, index)=>{
              return (
                <Tab key={index} label={item?.label} {...a11yProps(index)} />
              )
            })
          }

            {/* <Tab label="Omkara Manufacturing Smallcase" {...a11yProps(0)} />
            <Tab label="Omkara Opportunities Smallcase" {...a11yProps(1)} /> */}
          </Tabs>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
           {
            SmallCaseData && SmallCaseData.length > 0 &&
            SmallCaseData.map((mItem, mIndex)=>{
              return (
                <TabPanel key={mIndex} value={value} index={mIndex} dir={theme.direction}>
                  <List>
                    {
                      mItem.items.map((item, index) => {
                        return (
                          <ListItem key={index} disableGutters className='parent-list'
                            sx={{
                              display: "block",
                            }}>
                            <Box>
                              <Typography variant="h6" sx={{
                                borderLeft: `5px solid ${item.color}`,
                                paddingLeft: "10px",
                              }}>{item.label}</Typography>
                            </Box>
                            <List>
                              {
                                item.children.map((child, index0) => {
                                  return (
                                    <ListItem key={index0}>
                                      <Typography variant="contained">{child.label}</Typography>
                                    </ListItem>
                                  )
                                }
                                )
                              }
                            </List>
                          </ListItem>
                        )
                      }
                      )
                    }
                  </List>
                </TabPanel>
              )
            })
          }

          </SwipeableViews>
        </Grid>
        </Grid>


      </Box>
    </>


  )
}

export default SmallCase