import React, { useState } from 'react'
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6


const Editor = (props) => {
  const {TextData, setTextData} = props;

  const handleChange = (value) => {
    setTextData(value)
    console.log(value);
  }

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      [{ 'color': [] }, { 'background': [] }], 
      ['clean']
    ],
  };
 
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video',
    'color', 'background', 'code-block',
    'clean'
  ];



  return (
    <>
      <ReactQuill
        theme={"snow"}
        value={TextData}
        onChange={handleChange} 
        modules={modules}
        formats={formats} 
      />
    </>
  )
}

export default Editor