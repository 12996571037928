import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { CompaniesComponent, InputComponent } from '../../Common'
import { useSelector, useDispatch } from 'react-redux'
import MuiAutoComplete from '../../Common/MuiAutoComplete';
import { ideaType, shortAsc, recommendationArr, vimeo_parser, youtube_parser } from '../../../constants/helpers';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { companyMasterAction } from '../../../redux/actions/userAction';
import { vdrCompanyAction, vdrMediaAction } from '../../../redux/actions/VdrAction';
// import vimeo from vimeo in react
// import Vimeo from '@uuseb/vimeo';

const Add = (props) => {

    const [companyMasterArr, setCompanyMasterArr] = useState([]);

    const [companyTypeArr, setCompanyTypeArr] = useState(ideaType);
    // const recommendationArr = [];
    const [selectedCompanyData, setSelectedCompanyData] = useState(null);
    const [SelectedSite, setSelectedSite] = useState(null);

    const [NewVideoCode, setNewVideoCode] = useState("");

    const [selectedRecommendationData, setSelectedRecommendationData] = useState(null);

    const [checked, setChecked] = React.useState(false);
    const [UpdateState, setUpdateState] = useState(false);

    const [tags, setTags] = React.useState([])
    const {
        singleCompanyData,
        parentGroup,
        levelType,
        inputType
    } = props;

    // console.log(singleCompanyData)

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const dispatch = useDispatch();
    const { data: companyMaster, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
    const { data: companyData, loading: companyLoading, loadingAdd } = useSelector((state) => state.vdrCompanyReducer);

    const { control, handleSubmit, register, reset } = useForm({
        defaultValues: {
            videoCode: (NewVideoCode || ""),
            videoId: (singleCompanyData?.videoId || ""),
            videoType: (singleCompanyData?.videoType || ""),
            videoTitle: (singleCompanyData?.videoTitle || ""),
            videoDescription: (singleCompanyData?.videoDescription || ""),
            DocumentType: (singleCompanyData?.DocumentType || ""),
            isFeatured: (singleCompanyData?.isFeatured || ""),
            Inputtype: inputType
        }
    });


    const onSubmit = data => {


        // const paramsData = {
        //     company_id: selectedCompanyData?.value,
        //     company_name: selectedCompanyData?.title,
        //     groups_id: data?.groups_id,
        //     groups_level: data?.groups_level,
        //     recommendation_date: data?.recommendation_date,
        //     recommendation_price: data?.recommendation_price,
        //     company_type: selectedCompanyTypeData?.value,
        //     recommendation: selectedRecommendationData?.value,
        //     status: data?.status,
        //     tags: tags,
        //     Inputtype: inputType,
        // }
        data = { ...data, videoType: SelectedSite.value };
        let videoCode = "none";
        if(data.videoType){
            if (data.videoType == 'vimeo') {
                videoCode = vimeo_parser(data.videoCode);
            } else {
                // console.log(data.videoCode);
                videoCode = youtube_parser(data.videoCode);
            }
        }

        


        var videoParams =   {
            "userid": 1,
            "videoCode": videoCode,
            "videoId": "",
            "videoType": SelectedSite.value,
            "videoTitle": data.videoTitle,
            "videoDescription": "",
            "DocumentType": "Common",
            "isPinned" : checked,
            "groupId":data.groups_id,
            "group_name": "",
            "item_order": 0,
            "isFeatured": true,
            "item_order":"1",
            "Type": "",
            "inputType":inputType
          }

            // data.videoCode = videoCode;
            // data.videoType = SelectedSite.value;
            // data.videoId = "";
            // data.videoTitle = data.videoTitle;
            // data.DocumentType = "Common";
            // data.isPinned = checked;
            // data.groupId = data.groups_id;
            // data.inputType = inputType;
        //   console.table(videoParams);
        //   return false;

        dispatch(vdrMediaAction(videoParams))
        if (loadingAdd === false) {
            window.location.reload();
        }

    };

    const selectCompany = () => {
        if (companyMaster.length > 0) {
            var data1 = [];
            companyMaster.map((item) => {
                var d1 = { title: item.CompanyName, value: item.CompanyID };
                data1.push(d1);
            })
            shortAsc(data1)
            setCompanyMasterArr(data1);
        }
    }

    const getUniqueListBy = (arr) => {
        return [...new Map(arr.map(item => [item, item])).values()]
    }

    const setTagsFunction = (tags1) => {
        setTags(getUniqueListBy(tags1))
        // console.log(tags1)
    }


    useEffect(() => {
        if (!companyMasterLoading) {
            selectCompany();
        }
        if (singleCompanyData) {
                if(singleCompanyData?.videoType === 'vimeo'){
                    var a = "https://vimeo.com/" + singleCompanyData?.videoCode;
                }else{
                    var a = "https://www.youtube.com/watch?v=" + singleCompanyData?.videoCode;
                }
                setNewVideoCode(a);
                reset({
                    videoCode: a,
                })
                setChecked(singleCompanyData?.isFeatured);
            setSelectedSite({
                title: singleCompanyData?.videoType.charAt(0).toUpperCase() + singleCompanyData?.videoType.substring(1),
                value: singleCompanyData?.videoType
            });

        }
    }, [dispatch, props, companyMasterLoading]);


    const selectSitesArr = [
        { title: 'Select Site Type', value: 0 },
        { title: 'Youtube', value: 'youtube' },
        { title: 'Vimeo', value: 'vimeo' },

    ];
    return (
        <>
        
            <Box sx={{
                width: '30%',
                margin: "1rem auto"
            }}>
                <Typography variant="h5" fontSize={20} sx={{ margin: '1rem 0' }}>
                    {inputType && inputType ? 'Update' : 'Add'} Common Video
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <Grid container spacing={1}>
                        <input type="hidden" defaultValue={singleCompanyData?.group_id || parentGroup?.group_id || 0} {...register("groups_id")} />
                        <input type="hidden" defaultValue={singleCompanyData?.level_type || parentGroup?.level_type || 0} {...register("groups_level")} />
                        <input type="hidden" defaultValue={inputType || 0} {...register("Inputtype")} />

                        <Grid item xs={12} >
                            <MuiAutoComplete
                                {...register("videoType")}
                                placeholder="Select Site Type"
                                className='cInput'
                                readOnly={inputType ? true : false}
                                options={selectSitesArr && selectSitesArr}
                                multiple={false}
                                value={SelectedSite}
                                label="Select Site Type"
                                onChange={(event, newInputValue) => {
                                    var val1 = [];
                                    setSelectedSite(newInputValue);
                                    // console.log(newInputValue)
                                }}
                            />
                        </Grid>
                        
                        <Grid item xs={12} >
                            <InputComponent
                                name="videoTitle"
                                label="Video Title"
                                defaultValue={null}
                                control={control}
                                size="small"
                                placeholder="Enter Video Title"
                                className='cInput'
                            />
                        </Grid>
                        
                        <Grid item xs={12} >
                            <InputComponent
                                name="videoCode"
                                label="Video Link"
                                defaultValue={null}
                                control={control}
                                size="small"
                                placeholder="Enter Video Link"
                                className='cInput'
                            />
                            
                        </Grid>

                        <Grid item xs={12} sm={6} >

                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value={checked}
                                        control={<Checkbox
                                            {...register("isFeatured")}
                                            checked={checked}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }} />}
                                        label="Pinned"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>


                        <Grid item xs={12}>
                            {
                                inputType && inputType ?
                                    <>
                                        <Button type="submit" variant="contained">Update</Button>
                                    </> :
                                    <>
                                        <Button type="submit" variant="contained">Submit</Button>
                                    </>
                            }
                            {/* <Button onClick={(e)=>toggleDrawer('bottom', false)(e)} type="submit" variant="outlined" sx={{ marginLeft: 1 }}>Cancel</Button> */}
                        </Grid>
                    </Grid>

                </form>

            </Box>
        </>
    )
}

export default Add