import { Autocomplete, Box, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import InCr from "./InCr";
import { useDispatch, useSelector } from "react-redux";
import { SingleCompanypeersAction } from "../../../redux/actions/VdrAction";
import { useNavigate } from "react-router-dom";
import PeersCompDrawer from "./PeersCompDrawer";
import { companyMasterAction } from "../../../redux/actions/userAction";
import PeerToPeer from "./PeerToPeer/Index";

const PeersComp = (props) => {
  const {
    companyId,

    isCD
  } = props;
  const {
    data,
    loading
  } = useSelector(state=>state.SingleCompanypeersReducer);

  const { data: PeerRatioData, loading: PeerRatioLoading, isSelected, other_companies } = useSelector(state => state.PeerRatiosReducer);

  
  const [QuarterlySegmentType, setQuarterlySegmentType] = useState([]);
  const [TableColumns, setTableColumns] = useState([]);
  const [TableBody, setTableBody] = useState([]);
  const [ActivePrimaryBtn, setActivePrimaryBtn] = useState(1);
  const [FirstLoad, setFirstLoad] = useState(true);
  const [ActiveSecondaryBtn, setActiveSecondaryBtn] = useState(1);

  const [TypeActivePrimaryBtn, setTypeActivePrimaryBtn] = useState(1);

  const [PlusIcons, setPlusIcons] = useState({});
  
  const showDiv = (e, index) => {
    let a0 = PlusIcons;
    a0 = {
      ...a0,
      [`childTable_${index}`]: (!PlusIcons[`childTable_${index}`])
    }
    
    // console.log(PlusIcons, 'index >> ', index, PlusIcons[`childTable_${index}`], (!PlusIcons[`childTable_${index}`]));
    // PlusIcons[`childTable_${r_i}`]

    setPlusIcons(a0);
  }

const dispatch = useDispatch();

const { data:companyMaster, loading:companyMasterLoading, totalRow } = useSelector((state) => state.companyMasterData);

const [ItemList, setItemList] = React.useState([]);
const [TableHead, setTableHead] = React.useState([]);
const ThemeColorData = useSelector(state=> state.ThemeColorReducer);

useEffect(()=>{
    
}, [ThemeColorData])

const callApi = () => {
  let pType = 'con';
   if(QuarterlySegmentType === "Standalone"){
     pType = 'std';
   }
  let params =  {
    "CompanyId": companyId,
    "type": pType,
    "ratios": isSelected,
    "other_companies": other_companies,
  }
  dispatch(SingleCompanypeersAction(params))
}

// const selectCompany = () => {
      
//   if(totalRow > 0){
//     var data1 = [];
//     companyMaster.map((item)=>{
//       var d1 = {title:item.CompanyName,name:item.CompanyName,value:item.CompanyID};
//       data1.push(d1);
//     })
    
//     data1.sort(function(a, b){
//         if(a.title < b.title) { return -1; }
//         if(a.title > b.title) { return 1; }
//         return 0;
//     })
    
//     setItemList(data1);
    
//   }
// }
// useEffect(()=>{
//   if(companyMasterLoading){
//     dispatch(companyMasterAction())
//   }
//   if(!companyMasterLoading){
//     selectCompany()
//   }
// }, [dispatch, companyMasterLoading])

const navigate = useNavigate()

useEffect(() => {
  if(isSelected.length > 0){
    // selectCompany();
    setTableColumns([]);
    setTableBody([]);
    callApi();
  }
}, [QuarterlySegmentType, isSelected]);

useEffect(() => {
    if(!loading){
      let col_id = 'column_'
      if(data.header && data.header.length > 0){
        let cols = []
        let firstObj = data.header[0];
        let a0 = firstObj.row;
        // console.log('a0 >>>> ', a0)
        let TThead = [];
        
        a0.map((item, i)=>{
            let dd = {
                "id": col_id+i,
                "width": "",
                "align": "",
                "bg_color": firstObj?.bg_color,
                "isItalic": firstObj?.isItalic,
                "isBold": firstObj?.isBold,
                "text_color": firstObj?.text_color,
                "label": item?.col
              }
              // if(a0[item] != ""){
              //   TThead.push(a0[item]);
              // }
            cols.push(dd);
        })
        // Object.keys(a0).map((item, i)=>{
        //   if(i > 0){
        //     let dd = {
        //         "id": item,
        //         "width": "",
        //         "align": "",
        //         "bg_color": firstObj?.bg_color,
        //         "isItalic": firstObj?.isItalic,
        //         "isBold": firstObj?.isBold,
        //         "text_color": firstObj?.text_color,
        //         "label": a0[item]
        //       }
        //       if(a0[item] != ""){
        //         TThead.push(a0[item]);
        //       }
        //     cols.push(dd);
        //   }
        // })
        // console.log('cols <><<><><><><>< ', cols)
        setTableHead(TThead)
        setTableColumns(cols)
      }else{
        if(FirstLoad){
          setFirstLoad(false);
          setActivePrimaryBtn(2);
          setQuarterlySegmentType('Standalone')
        }
      }
      
      if(data.Data && data.Data.length){
        let dataA = []
        let plusIcon = {};
        let a00 = data.Data;
        a00.map((item, i)=>{
          let cData = item?.row;
          let childObj = {}
          cData.map((item0, i0)=>{
            let data = {
                  "label":item0?.col,
                  "bg_color":item['bg_color'],
                  "isItalic":item['isItalic'],
                  "isBold":item['isBold'],
                  "text_color":item['text_color']
              }
              
              childObj = {...childObj, [`${col_id}${i0}`]: data} 
        })

          // Object.keys(cData).map((item0, i0)=>{
          //   if(i0 > 0){
          //     let data = {
          //       "label":cData[item0],
          //       "bg_color":item['bg_color'],
          //       "isItalic":item['isItalic'],
          //       "isBold":item['isBold'],
          //       "text_color":item['text_color'],
          //     };
          //     childObj = {...childObj, [`${col_id}${i0}`]: data} 
          //   }
          // })
          // if(item?.childTable.length > 0){
          //   plusIcon = {...plusIcon, [`childTable_${i}`]: false}
          // }
          // childObj = {...childObj, [`childTable`]: (item?.childTable || [])}
          dataA.push(childObj)
        })
        // console.log('dataA >>>>>>>> ', dataA)
        setPlusIcons(plusIcon);
        setTableBody(dataA)
      }
    }
}, [dispatch, loading])
  
let secondaryBtnArr = [
  {
    "id":1,
    "label":"Consolidated",
    "value":"Consolidated",
    "show_in":[1,2,3]
  },
  {
    "id":2,
    "label":"Standalone",
    "value":"Standalone",
    "show_in":[1,2,3]
  },
]
const [State, setState] = React.useState({
  bottom: false,
});

const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return;
  }
  setState({ ...State, [anchor]: open });
};


// const PeersCompDrawer = React.lazy(()=> import('./PeersCompDrawer'))

  return (
    <>
    
      <PeersCompDrawer State={State} setState={setState} toggleDrawer={toggleDrawer} />
      
      {/* <button className='btn primary-btn'>Download Image</button> */}
      <Box className="valuation-box">
        
        <div className="box-head-sec" style={
          isCD && {
            marginBottom: '.25rem'
          }
        }>
        <div style={{ 
            display:'flex',
            // width:'100%',
            justifyContent:'space-between',
            alignItems:'center',
           }}>
            {
          !isCD && (
            <div style={{ 
            display:'flex',
            alignItems:'end',
            gap:'.5rem'
           }}>
                <h3>Peers</h3>
                
            </div>
          )}

          </div>
          <div style={{ 
            flexDirection:'column'
           }}>
            <h6>Cons Priority</h6>
          </div>
        </div>
        <div style={{ marginBottom: '.5rem', display:'flex', gap:'.5rem' }}>
          
        <button  className={`btn ${TypeActivePrimaryBtn === 1 ? "primary-btn" : "secondary-btn-outline"}`} onClick={(e)=> {
              toggleDrawer('bottom', true)(e);
              setTypeActivePrimaryBtn(1)
            }} >
          Manage Ratios & Companies
        </button>

        <button  className={`btn ${TypeActivePrimaryBtn === 2 ? "primary-btn" : "secondary-btn-outline"}`} onClick={(e)=> {
              setTypeActivePrimaryBtn(2)
            }} >
          Peer Analysis
        </button>
        </div>
        {/* <div style={{
          display:'flex',
          gap:'10px',
          marginBottom:'14px'
         }}>
        {
            secondaryBtnArr.map((item, i)=>{
              if(item.show_in.includes(ActiveSecondaryBtn)){
              }
              return (
                <>
              <button onClick={()=>{
                // console.log('item.id >>> ', item.id)
                setQuarterlySegmentType(item?.value)
                setActivePrimaryBtn(item.id);
              }
              } key={i} className={`btn ${ActivePrimaryBtn === item.id ? "primary-btn" : "secondary-btn-outline"}`}>{item?.label}</button>
                </>
              )
            })
          }
        </div> */}
        {
          TypeActivePrimaryBtn === 2 ?
          <div className="box-body-sec">
            <PeerToPeer themeColor={ThemeColorData.chartColor} setTypeActivePrimaryBtn={setTypeActivePrimaryBtn} TypeActivePrimaryBtn={TypeActivePrimaryBtn} title="PeerToPeer" companyId={companyId} />
            {/* <PeerToPeer themeColor="#598EF8B2" setTypeActivePrimaryBtn={setTypeActivePrimaryBtn} TypeActivePrimaryBtn={TypeActivePrimaryBtn} title="PeerToPeer" companyId={companyId} /> */}
          </div>
          :
          <>
          <div className="box-body-sec">
            {
              loading && <CircularProgress />
            }
          <table>
          <thead>
            <tr>
              {
                TableColumns.map((item,i)=>{
                  let cStyle = {
                    
                        backgroundColor:item?.bg_color,
                        fontStyle:item?.isItalic ? "italic" : "",
                        fontWeight:item?.isBold ? "500" : "",
                        color:item?.text_color,
                      }
                  return (
                    <td style={cStyle} key={i}>{item.label}</td>
                  )
                })
              }
            </tr>
          </thead>
          <tbody>
            {
                TableBody.map((row,r_i)=>{
                  let backgroundColor = "";
                  let fontStyle = "";
                  let fontWeight = "";
                  let color = "";
  
                  return (
                    <>
                      <tr>
                        {
                          TableColumns.map((item,i)=>{
                            let rowData = row[item.id]
                            let value = rowData?.label
                            let cStyle = {
                              backgroundColor:rowData?.bg_color,
                              fontStyle:rowData?.isItalic ? "italic" : "",
                              fontWeight:rowData?.isBold ? "500" : "",
                              color:rowData?.text_color,
                            }
                            if(backgroundColor === "" &&
                              fontStyle === "" &&
                              fontWeight === "" &&
                              color === ""){
                                backgroundColor = rowData?.bg_color;
                                fontStyle = rowData?.isItalic ? "italic" : "";
                                fontWeight = rowData?.isBold ? "500" : "";
                                color = rowData?.text_color;
                            }
                            return (
                              <td style={cStyle} key={i}>
                                <div style={{
                                display:'flex',
                                alignItems:'center',
                                justifyContent: i !==0 ? 'end' : "start",
                                columnGap:'.5rem'
                              }}>
                                <div>{value || ""}</div>
                                {
                                  i===0 && row?.childTable && row?.childTable.length > 0 && (
                                    PlusIcons[`childTable_${r_i}`] ?  <AiOutlineMinus onClick={(e)=>showDiv(e, r_i)} className="td-icon"/> : <AiOutlinePlus onClick={(e)=>showDiv(e, r_i)} className="td-icon"/> 
                                  )
                                }
                              </div>
                              </td>
                            )
                          })
                        }
                      </tr>
                      { 
                         PlusIcons[`childTable_${r_i}`] &&
                        row?.childTable && row?.childTable.length > 0 && (
                          <>
                            {
                              row.childTable.map((child, c)=>{
                                return (
                                  <tr key={c} className={r_i%2 !== 0 ? "tr-even" : "tr-odd"}>
                                    {
                                      TableColumns.map((item0,i0)=>{
                                        let c_value = child[item0.id];
                                        let cStyle = {};
                                        if(i0 === 0){
                                          cStyle = {
                                            ...cStyle,
                                            paddingLeft:'2rem'
                                          }
                                        }
                                        cStyle = {
                                          ...cStyle,
                                          backgroundColor:backgroundColor,
                                          fontStyle:fontStyle,
                                          fontWeight:fontWeight,
                                          color:color
                                        }
                                        return (
                                          <>
                                            <td key={i0} style={cStyle}>{c_value}</td>
                                          </>
                                        )
                                      })
                                    }
                                  </tr>
                                )
                              })
                            }
                          </>
                        )
                      }
                    </>
                  )
                })
              }
            
          </tbody>
        </table>
          </div>
              
          </>
        }





      {/* let compareData = localStorage.getItem('compareData');
          compareData = JSON.parse(compareData);
      let compareDataHead = compareData?.head
      let compareDataIds = compareData?.Ids */}

        {/* <Box sx={{ 
          mt: 2,
          display:'flex',
          gap: 1,
          // justifyContent:'center',
          alignItems:'center'
         }}>
        <Typography variant="h6" sx={{  }} >Detailed Comparison with: </Typography>
        <Autocomplete
          id="free-solo-2-demo"
          disableClearable
          size="small"
          options={ItemList}
          onChange={(event, newValue) => {
            console.log('newValue?.year >>>> ', window.btoa(newValue.value))
            let compareData0 = {} 
            let Ids = [Number(companyId), newValue.value] 
            compareData0 = {
              ...compareData0, head: TableHead, Ids:Ids, companyId
            }
            localStorage.setItem('compareData', JSON.stringify(compareData0));
            navigate(`/company-common/${window.btoa(companyId)}`)

        }}

          sx={{ width: 325, borderColor: "#000" }}
          getOptionLabel={(option) => option.title}
          // renderOption={(props, option) => <li {...props}>{option.title}</li>}
          renderInput={(params) => (
            <>
              <TextField size="small" {...params} placeholder="Search..." className='aaaaaa' sx={{ borderColor: "#000" }} />
            </>
          )}
        />
        </Box> */}

      </Box>
    </>
  );
};

export default PeersComp;
