import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { vdrGroupAction, vdrMediaGroupAction, vdrUpdateGroupAction } from '../../../redux/actions/VdrAction';
import { InputComponent } from '../../Common'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const Add = (props) => {

    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const [checked1, setChecked1] = React.useState(false);

    const handleChange1 = (event) => {
        setChecked1(event.target.checked);
        // console.log(checked1);
    };

    const dispatch = useDispatch();
    const { control, handleSubmit, register, reset } = useForm({
        defaultValues: {
            status: 1,
        }
    });


    const {
        groupData,
        levelType,
        inputType,
        parentGroup
    } = props;

    useEffect(() => {
        // console.log(groupData)
        // setChecked(groupData?.enable_number ? true : false);
        // setChecked1(groupData?.sticky  ? true : false);
        reset(groupData)
    }, [dispatch, props])

    const onSubmit = (data) => {
        let data1 = {...data, enable_number: checked, sticky: checked1}
        // console.log(data1)
        // return ;
        dispatch(vdrMediaGroupAction(data1));
        window.location.reload();
        
    };

    return (
        <>
            <Box sx={{
                width: '30%',
                margin: "1rem auto"
            }}>
                <Typography variant="h5" fontSize={20} sx={{ margin: '1rem 0' }}>
                    Add  {!inputType && parentGroup ? ' Sub Group of "' + parentGroup?.group_name + '"' : ' Media Group'}
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        {/* <span>group_id</span> */}
                        <input type="hidden" defaultValue={groupData?.group_id || 0} {...register("group_id")} />
                        {/* <span>parent_id</span> */}
                        <input type="hidden" defaultValue={groupData?.parent_id || (parentGroup ? parentGroup.group_id : 0) || 0} {...register("parent_id")} />
                        {/* <span>level_type</span> */}
                        <input type="hidden" defaultValue={groupData?.level_type || levelType || 0} {...register("level_type")} />
                        {/* <span>Inputtype</span> */}
                        <input type="hidden" defaultValue={groupData?.Inputtype || inputType || 0} {...register("Inputtype")} />

                        <Grid item xs={12} >
                            <InputComponent
                                name="group_name"
                                label="Group Name"
                                defaultValue={groupData?.group_name || null}
                                control={control}
                                size="small"
                                placeholder="Enter Group Name"
                                className='cInput'
                            />
                        </Grid>

                        {/* <Grid item xs={12} sm={6} >

                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value={checked}
                                        control={<Checkbox
                                            {...register("enable_number")}
                                            checked={checked}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }} />}
                                        label="Enable Numbers"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} >

                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value={checked1}
                                        control={<Checkbox
                                            {...register("sticky")}
                                            checked={checked1}
                                            onChange={handleChange1}
                                            inputProps={{ 'aria-label': 'controlled0' }}
                                             />}
                                        label="Sticky"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid> */}

                        <Grid item xs={12}>
                            {
                                inputType ?
                                    <>
                                        <Button type="submit" variant="contained">Update</Button>
                                    </> :
                                    <>
                                        <Button type="submit" variant="contained">Submit</Button>
                                    </>

                            }
                        </Grid>
                    </Grid>

                </form>

            </Box>
        </>
    )
}

export default Add