import { AiOutlineArrowUp } from "react-icons/ai"; 
import { Box, Button, Checkbox } from '@mui/material'
import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { useDispatch, useSelector } from 'react-redux';
// import { VideoLikeAction,VDRMediaAddToFavouriteAction,VDRMediaMyFavouriteListAction } from '../../../redux/actions/VdrAction';
// import CommentList from './comment/List';
import { BiTrash } from "react-icons/bi";
import { crtUserId } from "../../../constants/helpers";
import { VideoLikeAction, VDRMediaAddToFavouriteAction, VDRMediaMyFavouriteListAction  } from "../../../redux/actions/VdrAction";
import CommentList from "../../../frontend/pages/videos/comment/List";
// import { crtUserId } from "../../../constants/helpers";


const RightSection = (props) => {
  const {
    ActiveImage,
    setActiveImage,
    ActiveVideoItem,
    setActiveVideoItem
  } = props;
  const { data: VideoLikeData, loading: VideoLikeLoading } = useSelector((state) => state.VideoLikeReducer);
  const { data: VDRMediaMyFavouriteListData, loading: VDRMediaMyFavouriteListLoading } = useSelector((state) => state.VDRMediaMyFavouriteListReducer);



  const label = { inputProps: { 'aria-label': 'Checkbox demo' } }; 

  let userId = crtUserId();
  const dispatch = useDispatch();
  let params = {
    "type":"",
    "webuserId":userId,
    "inputType" : "0"
  }

  
  const [ActiveVideo, setActiveVideo] = React.useState({});
  const [checked, setChecked] = React.useState(true);
  const [addedtolist, setaddtolist] = React.useState('Add to List');
  const { data: VideoCommentData, loading: VideoCommentLoading } = useSelector((state) => state.VideoCommentReducer);
  
  const [MyFavourite, setMyFavourite] = React.useState([]);
  const myFavFun = () => {
    if(VDRMediaMyFavouriteListData?.Data && VDRMediaMyFavouriteListData.Data.length > 0){
        let a0 = VDRMediaMyFavouriteListData.Data.map((item)=>item?.videoId)
        setMyFavourite(a0);
      }
  }
  useEffect(() => {
    myFavFun();
  }, [VideoCommentLoading])
  

  useEffect(() => {
    if(!VideoLikeLoading){
      let aaa = VideoLikeData.Data[0];
      setChecked(ActiveVideo?.is_user_like);
      setActiveVideo(aaa)
    }
  }, [VideoLikeLoading, ActiveVideo])
  
  useEffect(() => {
    myFavFun();
}, [dispatch, VDRMediaMyFavouriteListLoading])


  const goToTop = () => {
    document.querySelector('.video-right-p').scroll(0, 0)
  }


  const handleChange = (event) => {

    let crt_check = event.target.checked;
    let like_type = 'like';
    if(!crt_check){
      like_type = 'unlike';
    }
    setChecked(crt_check);
    let vid_params = { ...params, video_id: ActiveVideoItem?.videoId, type: like_type}

    dispatch(VideoLikeAction(vid_params));

  };
  const addtolist = async(type) => {

    setaddtolist('Added to List');
    let vid_params = { webuserId:userId,video_id: ActiveVideoItem?.videoId, inputType: type}
    await dispatch(VDRMediaAddToFavouriteAction(vid_params));
    
    let prams = { "webuserId": crtUserId() };
    dispatch(VDRMediaMyFavouriteListAction(prams));

  };
 

  return (
    <div className={`video-right-p`} sx={{ position:'relative' }}>
      <Box sx={{ 
          width:'100%',
          // position:'sticky',
          // top:'6rem',
          borderRadius:'10px',
          overflow:'hidden',
          backgroundColor:'#fff'
        }}>
          <Box className='vid-comp'>
            <div style={{ 
              // border: '5px solid #000',
              borderRadius:'10px',
              
             }}>
              <ReactPlayer
                url={ActiveImage}
                width='100%'
                height="55vh"
                controls={true}
              />
            </div>
          </Box>
      </Box>
      {
        ActiveImage && (
          <>
            <div style={{ 
              marginTop:'.75rem',
              display:'flex',
              alignItems:'center',
              justifyContent:'space-between'
             }}>
              <Box style={{ 
                  display:'flex',
                  fontSize:'1rem',
                  alignItems:'center'
                }}
              >
                <Checkbox 
                  {...label}
                  onChange={handleChange} 
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite sx={{ color: 'red' }} />}
                  checked={checked}
                  sx={{ 
                    padding:'0 .2rem 0 0'
                  }}
                />
                <div>
                  {ActiveVideo?.total_video_like} Like
                </div>
              </Box>
              {
                MyFavourite.includes(ActiveVideoItem?.videoId) ? 
                  <>
                    <Button  onClick={()=>addtolist(Number(0))}  className='addToList' size="small" sx={{ color:'red' }}><BiTrash style={{ 
                        marginRight:'.2rem'
                       }} /> Remove From List</Button>
                  </>
                  :
                  <>
                    <Button  onClick={()=>addtolist(Number(1))}  className='addToList' size="small">Add to List</Button>
                  </>
              }
            </div>
            
            <div id='commentContainer' style={{ 
              paddingBottom: '1rem'
             }}>
              <CommentList videoId={ActiveVideoItem?.videoId}/>
              <>
                <Button onClick={()=>goToTop()} variant="contained" sx={{ 
                  borderRadius:'50px',
                  minHeight:'50px',
                  minWidth:'50px',
                  margin:'1rem 0',
                  padding: 0,
                  float:'right',
                  position:'relative',
                  bottom: '2.5rem'
                }}>
                  <AiOutlineArrowUp size={'1.5rem'}/>
                </Button>
              </>
            </div>
          </>
        )
      }
        
    </div>
  )
}

export default RightSection