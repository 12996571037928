import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import { FiSearch } from "react-icons/fi";
import "./new_style.scss";
import { useState } from "react";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { forumAction } from "../../../redux/actions/VdrAction";
import moment from "moment";

const VideoPageNew = () => {
  const { data: companyMasterData, loading: companyMasterLoading } =
    useSelector((state) => state.companyMasterData);

  const dispatch = useDispatch();
  const {
    data: { data: ForumData },
    loading: ForumLoading,
  } = useSelector((state) => state.ForumReducer);

  const { data: detailData, loading: detailLoading } = useSelector(
    (state) => state.ForumDetailReducer
  );

  let buttonsArr = [
    {
      id: 1,
      text: "ALL",
    },
    {
      id: 2,
      text: "PRESENTATION",
    },
    {
      id: 3,
      text: "COMPANY",
    },
  ];

  const [ActiveVideo, setActiveVideo] = useState(1);
  const [FilteredData, setFilteredData] = useState([]);
  const [AllFilteredData, setAllFilteredData] = useState([]);
  const [DatesArr, setDatesArr] = useState([]);
  const [ToBeUpdated, setToBeUpdated] = useState([]);
  const [SearchInput, setSearchInput] = useState("");
  const [ActiveItem, setActiveItem] = useState("");

  const chooseType = (item) => {
    sortDataFun(item.id);
    setActiveVideo(item.id);
  };
  // const ForumData1=[];
  // if(ForumData?.presentation && ForumData?.company_blog)
  // {
  //   const ForumData1 = [...ForumData?.presentation, ...ForumData?.company_blog]
  // console.log(ForumData1,'sdgg');
  // }

  const sortDataFun = (crtTab = 1) => {
    let fData = [];
    if (crtTab === 1) {
      let presentation = ForumData?.presentation;
      presentation = presentation.map((item) => {
        item["c_type"] = "Presentation";
        return item;
      });
      let company_blog = ForumData?.company_blog;
      company_blog = company_blog.map((item) => {
        item["c_type"] = "Company";
        return item;
      });
      // console.log('fData >?>>>>> ', fData)

      fData = [...presentation, ...company_blog];
    } else if (crtTab === 2) {
      let presentation = ForumData?.presentation;
      presentation = presentation.map((item) => {
        item["c_type"] = "Presentation";
        return item;
      });
      fData = presentation;
    } else if (crtTab === 3) {
      let company_blog = ForumData?.company_blog;
      company_blog = company_blog.map((item) => {
        item["c_type"] = "Company";
        return item;
      });
      fData = company_blog;
      // console.log('fData = company_blog >>>> ', company_blog)
    }
    let a0 = fData.sort((a, b) => {
      return new Date(b.updated_at) - new Date(a.updated_at);
    });
    let datedArr = fData.map(item=>moment(item.created_at).format('YYYY-MM-DD'));
    
    console.log('fData >>>> ', fData)
    setDatesArr(datedArr)
    setFilteredData(fData);
    setAllFilteredData(fData);
  };

  const filterSearch = (e) => {
    let searchedVal = e.target.value;
    // setSearchInput(searchedVal)
    setToBeUpdated(false);
    const filteredRows = AllFilteredData.filter((row, i)=>{
      return String(row?.first_name).toLowerCase().includes(searchedVal.toLowerCase());
    })
    // console.log('filteredRows >>> ', filteredRows)
    if (searchedVal.length < 1) {
      setFilteredData(AllFilteredData);
      // sortVideos(false, true, ActiveVideoType);
    }
    else
    {
      setFilteredData(filteredRows);
        if(filteredRows.length === 0){
            // console.log('000000 searche0dVal >>>>> ', searchedVal)
            setToBeUpdated(true);
        }
    }
  };

  const [SearchWidth, setSearchWidth] = useState(286);
  useEffect(() => {
    let a0 = document.querySelector('.vid-head-btn'); 
    // console.log('a0.offsetWidth >> ', a0.offsetWidth)
    setSearchWidth(a0.offsetWidth)
  }, [])



  useEffect(() => {
    if (ForumLoading) {
      dispatch(forumAction({}));
    }
    if (!ForumLoading) {
      sortDataFun();
    }
  }, [dispatch, ForumLoading]);

  useEffect(() => {
    let f = document.querySelector("footer");
    if (f) {
      f.remove();
    }
  }, []);

  return (
    <>
      <Box
        className="headerContainerFront"
        sx={{
          // minHeight:'70px',
          height: "auto",
        }}
      >
        <Typography
          style={{ zIndex: 2 }}
          fontSize={"2.5em"}
          color="#000"
          fontWeight="700"
        >
          Blogs
        </Typography>
        <Box className="vid-head-btn">
          {buttonsArr.map((item, index) => {
            return (
              <Fragment key={item.id}>
                <Button
                  onClick={(e) => chooseType(item)}
                  size="small"
                  className={`cst-btn ${
                    ActiveVideo === item.id ? "contained" : "outlined"
                  }`}
                  variant={ActiveVideo === item.id ? "contained" : "outlined"}
                >
                  {item.text}
                </Button>
              </Fragment>
            );
          })}
        </Box>
        
        <div className="vid-head-sec" >
          <Box className="vid-head-search" mt={2}>
            <div>
              <TextField
              style={{ border:"1px solid black",borderRadius:'5px' }}
                onChange={(e) => filterSearch(e)}
                id="search-field"
                size="small"
                placeholder="Search"
                fullWidth
                sx={{ m: 1, width: SearchWidth }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <FiSearch />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Box>
        </div>
        
      </Box>
      <Box
        className="videoContainer"
        sx={{
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <div className="vid-body-sec">
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <LeftSection
                  FilteredData={FilteredData}
                  setFilteredData={setFilteredData}
                  DatesArr={DatesArr}
                  setDatesArr={setDatesArr}
                  ActiveItem={ActiveItem}
                  setActiveItem={setActiveItem}
                  ActiveVideo={ActiveVideo}
                />
              </Grid>
              <Grid item xs={9}>
                <RightSection 
                  ActiveItem={ActiveItem}
                  setActiveItem={setActiveItem}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default VideoPageNew;
