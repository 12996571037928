import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forumAction } from "../../../redux/actions/VdrAction";
import './style.scss';
import { Link } from "react-router-dom";

const Resources = () => {
 
  const {
    data:{
        data:ForumetailData
    },
    loading:ForumetailLoading,
} = useSelector(state => state.ForumDetailReducer)
const {
  data:{
      data:ForumData
  },
  loading:ForumLoading,
} = useSelector(state => state.ForumReducer)

  
useEffect(() => {
  let f = document.querySelector('footer');
  if(f){
    f.remove()
  }
},[]);
const dispatch = useDispatch();
  useEffect(() => {
      if(ForumLoading){
          dispatch(forumAction({}))
      }
      if(!ForumLoading){
      //  console.log(ForumData)
      }
  }, [dispatch, ForumLoading])

  let dataArr = [
    {
      name:"Videos",
      link:"/videos"
    },
    {
      name:"Newsletter",
      link:"/newsletter"
    },
    {
      name:"Blogs",
      link:"/blogs"
    }
  ]
  return (
    <>
    
    <Box className="headerContainerFront" sx={{ 
        height:'auto'
       }}>
        <Typography
          style={{ zIndex: 2 }}
          fontSize={"2.5em"}
          color="#133e5b"
          fontWeight="700"
        >
          Resources
        </Typography>
      </Box>


      <Box className="bodyContainer">
        <Grid container spacing={2} sx={{ marginTop: 1, marginBottom: 1  }}>
          {
            dataArr.map((item, i)=>{
              return (
                <>
                  <Grid item xs={4} sx={{  }}>
                    <Link className="res-link" to={item?.link}>
                      <Box sx={{ 
                        margin: 0
                      }} className="card-box">
                        <text>{item?.name}</text>
                      </Box>
                    </Link>
                  </Grid>
                </>
              )
            })
            }
        </Grid>

      </Box>
    </>
  );
};

export default Resources;
