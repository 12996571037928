import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const linkBaseUrl = "/data2";
let domainLink = window.location.origin;
// let domainLink = "https://omkaradata.in";
//     domainLink = "https://omkaradata.com";
export const domainUrl = domainLink; 

export default function SiteMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const open3 = Boolean(anchorEl3);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} className='topMenu'>
        {/* <Typography sx={{ minWidth: 100, fontSize: '0.8rem', alignItems: 'center', display: 'inline-flex', cursor: 'pointer' }}
          onClick={handleClick} >Quarterly Result <KeyboardArrowDownIcon fontSize="small" /> </Typography>
        <Typography component={'a'} href="/valuation" sx={{ minWidth: 100, fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>Valuation</Typography>
        <Typography component={'a'} href="/delivery-data" sx={{ minWidth: 100, fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>Delivery Data</Typography>
        <Typography  component={'a'} href={`https://omkaradata.com/price/list`} sx={{ minWidth: 100, fontSize: '0.8rem', color:'#fff',textDecoration:'none' }}>Price Action</Typography> */}
        {/* <Typography sx={{ minWidth: 100, fontSize: '0.8rem', alignItems: 'center', display: 'inline-flex', cursor: 'pointer' }}
          onClick={handleClick} >Quarterly Result <KeyboardArrowDownIcon fontSize="small" /> </Typography>
        <Typography component={Link} to="/valuation" sx={{ minWidth: 100, fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>Valuation</Typography>
        <Typography component={Link} to="/delivery-data" sx={{ minWidth: 100, fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>Delivery Data</Typography> */}
        {/* <Typography component={Link} to="/admin" sx={{ minWidth: 100, fontSize: '0.8rem', color: '#fff', textDecoration: 'none' }}>VDR</Typography> */}


        <Typography sx={{ minWidth: 100, fontSize: '0.8rem', alignItems:'center', display:'inline-flex', cursor:'pointer' }} 
            onClick={handleClick} >Quarterly Result <KeyboardArrowDownIcon fontSize="small" /> </Typography>
        <Typography  component={'a'} href={`${linkBaseUrl}/valuation`} sx={{ marginX: 1, fontSize: '0.8rem', color:'#fff',textDecoration:'none' }}>Valuation</Typography>
        
        <Typography className="menu_item"  component={'a'} href={`./fii`} sx={{ marginX: 1, fontSize: '0.8rem', color:'#000',textDecoration:'none' }}>FII Sector Flow</Typography>

        {/* <Typography  component={'a'} href={`${linkBaseUrl}/delivery-data`} sx={{ marginX: 1, fontSize: '0.8rem', color:'#fff',textDecoration:'none' }}>Delivery Data</Typography>
        <Typography  component={'a'} href={`${domainUrl}/price/list`} sx={{ marginX: 1, fontSize: '0.8rem', color:'#fff',textDecoration:'none' }}>Price Action</Typography> */}
        {/* <Typography  component={'a'} href={`./global-snapshot`} sx={{ marginX: 1, fontSize: '0.8rem', color:'#fff',textDecoration:'none' }}>Global Snapshot</Typography>
        <Typography  component={'a'} href={`./auc`} sx={{ marginX: 1, fontSize: '0.8rem', color:'#fff',textDecoration:'none' }}>AUC</Typography> */}
        {/* <Typography  component={'a'} href={`./fii`} sx={{ marginX: 1, fontSize: '0.8rem', color:'#fff',textDecoration:'none' }}>FII Sector Flow</Typography> */}
        {/* <Typography  component={'a'} href={`./insider`} sx={{ marginX: 1, fontSize: '0.8rem', color:'#fff',textDecoration:'none' }}>Insider</Typography> */}
        
        <Typography component={'a'} sx={{ fontSize: '0.8rem', alignItems:'center', display:'inline-flex', cursor:'pointer', margin:'0 4px 0 8px' }} 
            onClick={handleClick2} >Daily Data <KeyboardArrowDownIcon fontSize="small" /> </Typography>
        <Typography component={'a'} sx={{ fontSize: '0.8rem', alignItems:'center', display:'inline-flex', cursor:'pointer', margin:'0 8px 0 8px' }} 
            onClick={handleClick3} >Reports Bank <KeyboardArrowDownIcon fontSize="small" /> </Typography>

            
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              margin: '0 auto',
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <MenuItem style={{ fontSize: '0.8rem' }} component={'a'} href={linkBaseUrl}>
          Result Data
        </MenuItem>
        {/* <MenuItem style={{ fontSize: '0.8rem' }} component={Link} to="/data">
          Result Data
        </MenuItem> */}
        <MenuItem style={{ fontSize: '0.8rem' }} component={'a'} href="/calendar/assign">
          Result Calendar (New)
        </MenuItem>
        <MenuItem style={{ fontSize: '0.8rem' }} component={'a'} href="/data2/calendar">
          Result Calendar (Old)
        </MenuItem>
      </Menu>


      
      <Menu
        anchorEl={anchorEl2}
        id="account-menu"
        open={open2}
        onClose={handleClose2}
        onClick={handleClose2}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              margin: '0 auto',
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        
        <MenuItem style={{ fontSize: '0.8rem' }} component="a" href={`${linkBaseUrl}/delivery-data`}>
          Delivery Data
        </MenuItem>
        <MenuItem style={{ fontSize: '0.8rem' }} component="a" href={`${domainUrl}/price/list`}>
          Price Action
        </MenuItem>
        <MenuItem style={{ fontSize: '0.8rem' }} component="a" href={`${linkBaseUrl}/insider`}>
          Insider Trading   
        </MenuItem>
        <MenuItem style={{ fontSize: '0.8rem' }} component="a" href={`${linkBaseUrl}/bulk-deal`}>
          Bulk-Block Deal
        </MenuItem>
        {/* <Typography  component={'a'} href={`./fii`} sx={{ marginX: 1, fontSize: '0.8rem', color:'#fff',textDecoration:'none' }}>FII Sector Flow</Typography> */}
        {/* <MenuItem style={{ fontSize: '0.8rem' }} component="a" href={`${linkBaseUrl}/fii`}>
          FII Sector Flow
        </MenuItem> */}
        
      </Menu>

      <Menu
        anchorEl={anchorEl3}
        id="account-menu"
        open={open3}
        onClose={handleClose3}
        onClick={handleClose3}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              margin: '0 auto',
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
      
        <MenuItem style={{ fontSize: '0.8rem' }} component="a" href={`${linkBaseUrl}/repository/upload`}>
          Upload
        </MenuItem>
        {/* <MenuItem style={{ fontSize: '0.8rem' }} component="a" href={`${linkBaseUrl}/repository/list`}>
          Search
        </MenuItem> */}

        <MenuItem style={{ fontSize: '0.8rem' }} component="a" href={`${linkBaseUrl}/repository/list-test`}>
          Search
        </MenuItem>
        
      </Menu>



    </React.Fragment>
  );
}
