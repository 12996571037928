import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link, Outlet, useLocation } from 'react-router-dom';
import SiteMenu from './components/SiteMenu';
import { Grid } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { UserDropDown } from './components';
import {
  HomeFilter,
  DeliveryDataFilter,
  ValuationFilter
} from './components/Filters';

import colors from './constants/colors'


const drawerWidth = 270;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function LayoutVdr(props) {

  const { pathname } = useLocation();
  // console.log(JSON.stringify(pathname));

  const sideBarArr = [
    '/data', '/data/valuation', '/data/delivery-data'
  ]


  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    // if (pathname === '' || pathname === '/valuation' || pathname === '/delivery-data') {
    if (sideBarArr.includes(pathname)) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [pathname])


  const handleDrawerOpen = () => {
    // setOpen(true);
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        {/* <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <div>
          <SiteMenu />
            <Typography variant="h6" noWrap component="div">
              Persistent drawer
            </Typography>
          </div>
        </Toolbar> */}

        <Toolbar className='headerContainer' style={{ backgroundColor:colors.primary }} >
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <Box
                className='mainLogoContainer' style={{ display: 'flex' }}>
                <Typography variant="h6" component="div" className='mainLogo'
                  sx={{ mr: 2, ...(open && { display: 'none !important' }) }} >
                  <Link to={'/'}>
                  <img  src={'./logo192.png'} style={{ width: '150px' }} />
                
                  </Link>
                </Typography>
                {
                  sideBarArr.includes(pathname) ?
                    <IconButton
                      className='drawerIcon-menu'
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      sx={{ mr: 2, ...(open && { display: 'none0' }) }}
                    >
                      <MenuIcon />
                    </IconButton>
                    :
                    null
                }
                {
                  !props.withoutLogin ?
                    <>
                      {/* <SiteMenu /> */}
                    </>
                    :
                    null
                }
              </Box>
            </Grid>
            <Grid item xs={6} style={{ justifyContent: 'end', display: "flex", alignItems: "center" }}>
              <Box>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {
                    props.withoutLogin ?
                      <a href="/data2/login" style={{ color: '#fff', textDecoration: 'none', fontSize: '0.8em' }}>
                        <ArrowBackIosNewIcon sx={{ fontSize: '0.8em', fontWeight: "900" }} /> {' Back to Login'}
                      </a>

                      :
                      <>
                        {
                          pathname !== '/navigation' ?
                            <>
                            {/* <a href="./navigation" style={{ color: '#fff', textDecoration: 'none', fontSize: '0.8em' }}>
                              <ArrowBackIosNewIcon sx={{ fontSize: '0.8em', fontWeight: "900" }} /> {' Back to Home'}
                            </a> */}
                            {/* <Link to="/navigation" style={{ color: '#fff', textDecoration: 'none', fontSize: '0.8em' }}>
                              <ArrowBackIosNewIcon sx={{ fontSize: '0.8em', fontWeight: "900" }} /> {' Back to Home'}
                            </Link> */}
                            </>
                            :
                            null
                        }
                        
                        {/* <UserDropDown /> */}
                      </>
                  }

                </div>

              </Box>
            </Grid>
          </Grid>


        </Toolbar>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Typography variant="h6" component="div" className='mainLogo' sx={{  width:'100%', display:'flex',justifyContent:'center'  }}>
            <img src={'./logo192.png'} style={{ width: '150px' }} />
          </Typography>
        </DrawerHeader>
        <Divider />
        <div style={{ padding: '0 0.5rem' }} className='sidebar-accordion'>

          {
            pathname === '/data' ? <HomeFilter /> : null
          }
          {
            pathname === '/data/valuation' ? <ValuationFilter /> : null
          }
          {
            pathname === '/data/delivery-data' ? <DeliveryDataFilter /> : null
          }

        </div>
      </Drawer>
      <Main open={open} style={{ width: '100px' }}>
          <DrawerHeader />
          <Outlet />
      </Main>
    </Box>
  );
}
