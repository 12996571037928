import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useDispatch } from 'react-redux';
import { SORT_DATA_REQUEST } from '../../constants/VdrConstants';

export default function DrawerContainer(props) {
  const { stateDrawer, setStateDrawer, anchor, open, toggleDrawer, containerFor } = props;
  const dispatch = useDispatch();

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : '250', height: '100vh', maxHeight: anchor === 'top' || anchor === 'bottom' ? (containerFor === 'sortData' || containerFor === 'SendNotification' ? '100vh' : '100vh') : 'auto' }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      {props.children}
    </Box>
  );

  return (
    <div>
      <React.Fragment key={anchor}>
        <Drawer
          anchor={anchor}
          open={stateDrawer[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
