import axios from "axios";
import { defaultWatchListCompanyReq, defaultWatchListReq, request as https, requestPost } from "./request";
import { baseUrl } from './userRequest';

let SymbolMaster_ACEAPI = "_ACEAPI";
SymbolMaster_ACEAPI = window.location.href.includes('aceapi') ? "_ACEAPI" : ""


const checkIsAceAPI = () => {
  // if(window.location.href.includes('aceapi')){
  //     return true;
  // }
  if( SymbolMaster_ACEAPI === "_ACEAPI" ){
      return true;
  }
  return false;
}


export const priceActionRequest = (params) => {  
  return requestPost.post(`${baseUrl}/PriceAction_BoringAMC`, params);
};

export const PriceIndexRequest = (params) => {  
  return https.get(`${baseUrl}/index_BoringAMC`, params);
};

export const vdrMediaGroupRequest = (params) => {  
  return requestPost.post(`${baseUrl}/VDRMediaLevel_BoringAMC`, params);
};

export const vdrGroupRequest = (params) => {  
  return requestPost.post(`${baseUrl}/VDR_BoringAMC`, params);
};

export const vdrForensicRequest = (params) => {  
  return requestPost.post(`${baseUrl}/forensic_BoringAMC`, params);
};

export const vdrFavoriteRequest = (params) => {  
  return requestPost.post(`${baseUrl}/Favorites_BoringAMC`, params);
};

export const vdrCompanyRequest = (params) => {  
  return requestPost.post(`${baseUrl}/VDRCompany_BoringAMC`, params);
};

export const vdrAssignEmployeeRequest = (params) => {  
  return requestPost.post(`${baseUrl}/AssignCompanyToEmployees_BoringAMC`, params);
};

export const vdrMediaRequest = (params) => {  
  return requestPost.post(`${baseUrl}/VDRMedia_BoringAMC`, params);
};
export const VDRMediaMyFavouriteListRequest = (params) => {  
  return requestPost.post(`${baseUrl}/VDRMediaMyFavouriteList_BoringAMC`, params);
};
 
export const vdrLevelRequest = () => {  
  return requestPost.post(`${baseUrl}/VDRlevelmaster`);
};
 
export const vdrSortDataRequest = (params) => {  
  return requestPost.post(`${baseUrl}/VDRCompanySorting_BoringAMC`, params);
};
 
export const vdrSortDataUpdateRequest = (params) => {  
  return requestPost.post(`${baseUrl}/VDRCompanySortingUpdate_BoringAMC`, params);
};
 
export const vdrEmployeeRequest = (params) => {  
  return requestPost.post(`${baseUrl}/EmployeeMaster_BoringAMC`, params);
};
 
let baseUrl_capital = "https://omkaracapital.in/api";
    baseUrl_capital = "https://omkaracapital.net/api";
    
    baseUrl_capital = "https://vasudeep.com:8084/"+baseUrl_capital;


export const dataRoomTickerRequest = (params) => {  
  return https.get(`${baseUrl_capital}/dataroom`, params);
  // return requestPost.post(`${baseUrl}/EmployeeMaster_BoringAMC`, params);
};
 
export const vdrHomeRequest = (params) => {  
  return requestPost.post(`${baseUrl}/VDRHome_BoringAMC`, params);
};
 
export const filesLogRequest = (params) => {  
  return requestPost.post(`https://omkaracapital.in/api/files-logs_BoringAMC`, params);
};
 
export const vdrImageUploadRequest = (params) => {  
  return requestPost.post(`${baseUrl}/VDRFileUploader_BoringAMC`, params);
};
 
 
export const vdrSingleCompanyRequest = (params) => {
  return requestPost.post(`${baseUrl}/VDRDataRoom_BoringAMC`, params);
};
 
export const vdrMediaDataRequest = (params) => {  
  return requestPost.post(`${baseUrl}/media_BoringAMC`, params);
};
 
 
export const vdrData20YearsRequest = (params) => {  
  return requestPost.post(`${baseUrl}/Data20YearsSingleCompany${SymbolMaster_ACEAPI}_BoringAMC`, params);
};
 
export const vdrPeerToPeerRequest = (params) => {  
  return requestPost.post(`${baseUrl}/PeerToPeer_BoringAMC`, params);
};
 
export const vdrPeerToPeerScRequest = (params) => {  
  return requestPost.post(`${baseUrl}/PeerToPeer_New_BoringAMC`, params);
};
 
export const vdrSegmentRequest = (params) => {  
  return requestPost.post(`${baseUrl}/VDR_Segments_Add_BoringAMC`, params);
};
 
export const vdrSegmentAssignRequest = (params) => {  
  return requestPost.post(`${baseUrl}/AssignSegmenttogroup_BoringAMC`, params);
};

 
export const quarterlyReviewRequest = (params) => {
  return requestPost.post(`${baseUrl}/ResultOverView_BoringAMC`, params);
};

 
export const quarterlySegmentRequest = (params) => {
  return requestPost.post(`${baseUrl}/QtrSegment${SymbolMaster_ACEAPI}_BoringAMC`, params);
};
 

export const quarterlySegmentStandaloneRequest = (params) => {
  return requestPost.post(`${baseUrl}/QtrSegmentStandalone_BoringAMC`, params);
};
 

export const videoLikeRequest = (params) => {
  return requestPost.post(`${baseUrl}/VDRMediaUserLiskeDislike_BoringAMC`, params);
};
export const VDRMediaAddToFavouriteRequest = (params) => {
  return requestPost.post(`${baseUrl}/VDRMediaAddToFavourite_BoringAMC`, params);
};

export const videoCommentRequest = (params) => {
  return requestPost.post(`${baseUrl}/VDRMediaCommentWithReply_BoringAMC`, params);
  return requestPost.post(`${baseUrl}/VDRMediaComment_BoringAMC`, params);
};

export const videoLikeCommentRequest = (params) => {
  return requestPost.post(`${baseUrl}/VDRCommentUserLiskeDislike_BoringAMC`, params);
};


export const QuarterlyResultsRequest = (params) => {
  return requestPost.post(`${baseUrl}/QuarterlyResultsConsolidated${SymbolMaster_ACEAPI}_BoringAMC`, params);
};

export const QuarterlySnapShotRequest = (params) => {
  return requestPost.post(`${baseUrl}/QuaterlyResultSnapShot${SymbolMaster_ACEAPI}_BoringAMC`, params);
};


export const RepositoryTestListRequest = (params) => {  
  return requestPost.post(`${baseUrl}/RepositoryListTesting_BoringAMC`, params);
};



export const SingleCompProfitAndLossRequest = (params) => {
  return requestPost.post(`${baseUrl}/QuaterlyResult_ProfitAndLoss${SymbolMaster_ACEAPI}_BoringAMC`, params);
};

export const SingleCompCashFlowRequest = (params) => {
  return requestPost.post(`${baseUrl}/SingleCompanyCashFlow_BoringAMC`, params);
};

export const SingleCompanyratiosRequest = (params) => {
  return requestPost.post(`${baseUrl}/SingleCompanyratios${SymbolMaster_ACEAPI}_BoringAMC`, params);
}; 

export const SingleCompanypeersRequest = (params) => {
  return requestPost.post(`${baseUrl}/SingleCompanypeers_BoringAMC`, params);
}; 

export const SingleCompanyBalanceSheetRequest = (params) => {
  return requestPost.post(`${baseUrl}/SingleCompanyBalanceSheet_BoringAMC`, params);
}; 

export const ComparePeerRequest = (params) => {
  return requestPost.post(`${baseUrl}/SingleCompanyPeers_New_BoringAMC`, params);
}; 

export const PeerRatiosRequest = (params) => {
  return requestPost.post(`${baseUrl}/RatioMaster_BoringAMC`, params);
}; 

export const ResultDocumentNewRequest = (params) => {
  return requestPost.post(`${baseUrl}/ResultDocument_New${SymbolMaster_ACEAPI}_BoringAMC`, params);
  // return requestPost.post(`${baseUrl}/${ checkIsAceAPI() ? "ResultDocument_NewControllerAPI" : "ResultDocument_New"}_BoringAMC`, params);
}; 

export const ProfitAndLossChartRequest = (params) => {
  return requestPost.post(`${baseUrl}/QuaterlyResult_ProfitAndLoss_Charts_BoringAMC`, params);
}; 

export const geoLocationDb = (params) => {  
  return requestPost.post(`https://geolocation-db.com/json`, params);
};


export const getNotification = (params) => {  
  // return requestPost.post(`${baseUrl}/TickerLogs_BoringAMC`, params);
  return requestPost.post(`${baseUrl_capital}/notification`, params);
};



export const DateAPI = () => {
  return https.get(`${baseUrl}/DateAPI_ACEAPI`);
  return https.get(`${baseUrl}/DateAPI`);
};


export const forumRequest = (params) => {
  return axios.post(`${baseUrl_capital}/forum2`,{...params, user_id:1037}, {
  })
};

export const forumTagsRequest = (params) => {
  return axios.post(`${baseUrl_capital}/tags`,{user_id:1037}, {
  })
};

export const MyBlogListRequest = (params) => {
  return axios.post(`${baseUrl_capital}/get/company/mylist`,params, {
  })
};


export const forumDetailRequest = (params) => {
  return axios.post(`${baseUrl_capital}/companyblogdetails2`,params, {
  })
};


export const DefaultMastersRequest = (params) => {
  return axios.get(`${baseUrl_capital}/default-masters`,params, {
  })
};




export const SingleNewsletterLikRequest = (params) => {
  
  return axios.post(`${baseUrl_capital}/companyblog/likedislike`,params, {
  })
};
export const newsletterLikeCommentRequest = (params) => {
  
  return axios.post(`${baseUrl_capital}/companycomment/likedislike`,params, {
  })
};

export const newsLetterCommentListRequest = (params) => {
  return axios.post(`${baseUrl_capital}/get/companyblog/comments`,params, {
  })
};

export const AddToMylistRequest = (params) => {
  return axios.post(`${baseUrl_capital}/add/company/mylist`,params, {
  })
};

export const RemoveToMylistRequest = (params) => {
  return axios.post(`${baseUrl_capital}/remove/company/mylist`,params, {
  })
};

export const newsLetterCommentAddRequest = (params) => {
  return axios.post(`${baseUrl_capital}/add/companyblog/comments`,params, {
  })
};

export const newsLetterCommentDeleteRequest = (params) => {
  return axios.post(`${baseUrl_capital}/delete/companyblog/comments`,params, {
  })
};

// export const newsLetterCommentDeleteRequest = (params) => {
//   return axios.post(`${baseUrl_capital}/delete/companyblog/comments`,params, {
//   })
// };


// const TL_BaseUrl = 'https://omkaraapi@trendlyne.com/clientapi/omkara';
const TL_BaseUrl = 'https://api.trendlyne.com/clientapi/omkara';



const TL_options = {
  userId: "OMKARAAPI",
  password: "W5UaT&K47dAm",
  key: "63e0a0c573e3f2ef1b8a234da450959eb0cfb291",
  // requestCode: "Trendlyne",
};

let TL_options1 = {
  headers:{
    userId: "OMKARAAPI",
    password: "W5UaT&K47dAm",
    key: "63e0a0c573e3f2ef1b8a234da450959eb0cfb291",
    // requestCode: "Trendlyne",
  }
};

    
// let config = {
//   method: 'get',
//   // maxBodyLength: Infinity,
//   url: 'https://api.trendlyne.com/clientapi/omkara/stock/annual-reports/INFY/',
//   headers: TL_options
// };

export const TLAnnualReportRequest = (compSlug, params) => {
  return axios.get(`${TL_BaseUrl}/stock/annual-reports/${compSlug}`, TL_options1)
  // return axios.request(config)
};

export const TLEarningsCallRequest = (compSlug, params) => {
  return axios.get(`${TL_BaseUrl}/stock/earnings-call/${compSlug}`, TL_options1)
};

export const TLInvestorPresentationRequest = (compSlug, params) => {
  return axios.get(`${TL_BaseUrl}/stock/investor-presentation/${compSlug}`, TL_options1)
};

export const TLAnnouncementsRequest = (compSlug, params) => {
  TL_options1 = {
    ...TL_options1, params:params
  }
  // console.log('TL_options1 >>>> ', TL_options1)
  return axios.get(`${TL_BaseUrl}/newsfeed/corporate/announcements`, TL_options1)
};


export const TLEOD_OHLCRRequest = (compSlug, params) => {
  // console.log('compSlug >>>> ', compSlug);
  return axios.get(`${TL_BaseUrl}/stock/EOD-OHLC/${compSlug}/`, TL_options1)
};


export const TLEPSRequest = (compSlug) => {
  // console.log('compSlug >>>> ', compSlug);
  return axios.get(`${TL_BaseUrl}/stock/chart-data/EPS/${compSlug}/`, TL_options1)
};

export const TLPBRequest = (compSlug) => {
  // console.log('compSlug >>>> ', compSlug);
  return axios.get(`${TL_BaseUrl}/stock/chart-data/PBV/${compSlug}/`, TL_options1)
};

export const TLPERequest = (compSlug) => {
  // console.log('compSlug >>>> ', compSlug);
  return axios.get(`${TL_BaseUrl}/stock/chart-data/PE/${compSlug}/`, TL_options1)
};



export const TLOverviewRequest = (compSlug=null, params=null) => {
  return axios.get(`${TL_BaseUrl}/stock/overview/${compSlug}/`, TL_options1)
};



export const TLQuarterlyResultsRequest = (compSlug=null, params=null) => {
  return axios.get(`${TL_BaseUrl}/stock/quarterly-results/${compSlug}/`, TL_options1)
};



// START WATCH LIST 

export const watchListRequest = (params=defaultWatchListReq) => {
  return axios.post(`${baseUrl}/Watch_list_Add_BoringAMC`, params)
};
export const watchListCompanyRequest = (params=defaultWatchListCompanyReq) => {
  return axios.post(`${baseUrl}/WatchList_AddCompany_BoringAMC`, params)
};

export const watchListBulkUploadRequest = (params) => {
  return axios.post(`${baseUrl}/BulkInsertInWatchList_BoringAMC`, params)
};



// END WATCH LIST 