import React, { useEffect } from 'react'
import { loggedInAdmin } from './constants/helpers';

const ProtectedRoute = (props) => {
    const {
        children,
    } = props;

    useEffect(() => {
      if (!localStorage.getItem('user')) {
        // window.location.replace(`/data2/login`);
      }
    },[props])

    // useEffect(() => {
    //     let a0 = ['omkaradata.in','omkaradata.com'];
    //     // let a0 = ['vdr.omkaracapital.in'];
    //     // console.log(window.location.host);
    //     if(!a0.includes(window.location.host)){
    //         if(!loggedInAdmin?.UserID){
    //             localStorage.clear();
    //             window.location.href = './login';
    //         }
    //     }
    // }, [])
    // return '00'
  return children;
}

export default ProtectedRoute