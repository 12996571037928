import { Avatar, Box, Button, CircularProgress, Divider, Grid, List, ListItem, ListItemButton } from '@mui/material'
import React, { useEffect } from 'react'
import { forumDetailAction } from '../../../redux/actions/VdrAction'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import moment from 'moment'
import { FORUM_DETAIL_REQUEST } from '../../../constants/VdrConstants'
import CalenderComp from './Calender'
import { Fragment } from 'react'
import { crtUserId } from '../../../constants/helpers'

const DetailPage = (props) => {
    const {
        ActiveItem, setActiveItem, item
    } = props
    let userId = crtUserId()
    const [ItemData, setItemData] = useState(null);
    const [BtnAction, setBtnAction] = useState(1);
    const [AllItemData, setAllItemData] = useState([]);

    const [FirstLoad, setFirstLoad] = useState(false);

    let dispatch = useDispatch();

    const {
        data,
        loading
    } = useSelector(state=>state.ForumDetailReducer)

    
    useEffect(() => {
        setActiveItem(null)
        setItemData(null)
    }, [props])

    useEffect(() => {
        
        if(loading){
            let params = {
                "slug": item.company_slug,
                "user_id": userId,
                // "from_date": moment().subtract(11, 'days').format('YYYY-MM-DD'),
                // "to_date": moment().format('YYYY-MM-DD'),
            }
            dispatch(forumDetailAction(params));
        }
        
    }, []);
    
useEffect(() => {
    if(!loading){
        // console.log('data >>>>> ', data)
        if(data && data?.BlogAddonTot_date.length > 0){
            
            if(FirstLoad){
                setItemData(data?.BlogAddonTot[0])
            }else
            if(!FirstLoad){
                let params = {
                    "slug": item.company_slug,
                    "user_id": userId,
                    "date": data?.BlogAddonTot_date[0]
                }
                dispatch(forumDetailAction(params));
                setFirstLoad(true);
            }
            
        }

        if(AllItemData.length === 0){
            setAllItemData(data?.BlogAddonTot_date)
        }
    }
}, [dispatch, loading])

    const actionItems = (item0) => {

        // let item00 =  AllItemData.find((itm)=>item0.created_at === itm.created_at)
        // setItemData(item00);

        let params = {
            "slug": item.company_slug,
            "user_id": userId,
            "date": item0
        }
        dispatch(forumDetailAction(params));

        // console.log('item >>>> ',item00)
        // let params = {
        //     "slug": item.company_slug,
        //     "user_id": userId,
        //     "date": moment(item0.created_at).format('DD-MM-YYYY')
        // }
        // dispatch(forumDetailAction(params));
    }



let cal_btn = [
    {
        "id":1,
        "label":"Latest",
    },
    {
        "id":2,
        "label":"Select Calender",
    }
]
    
    
  return (
    <>

<Box>
                                <Grid container spacing={2} >
                                    <Grid item xs={3}   sx={{
                                            maxHeight:'70vh',
                                            overflow:'auto',
                                            border:'1px solid #ccc'
                                        }}>
                                        <div style={{ }}>
                                            <div style={{
                                                display:'flex',
                                                gap:'.5rem'
                                             }} >

                                                {
                                                    cal_btn.map((itm, i)=>{
                                                        return (
                                                            <Fragment key={itm?.id}>
                                                                <Button onClick={()=>setBtnAction(itm.id)} variant={`${BtnAction === itm.id ? "contained" : "outlined"}`} >{itm?.label}</Button>
                                                            </Fragment>
                                                        )
                                                    })
                                                }

                                            </div>
                                            {
                                                BtnAction === 1 ?
                                                    <List>
                                                        {
                                                            AllItemData && AllItemData.length > 0 && AllItemData.map((itm, i)=>{
                                                                if(i < 7){
                                                                    return (
                                                                        <>
                                                                            <ListItem disablePadding>
                                                                                <ListItemButton onClick={()=>actionItems(itm)}>
                                                                                    <Box className="listItem">
                                                                                        <h3>{moment(itm).format('DD-MMM-YYYY, dddd')}</h3>
                                                                                        {/* <div>{moment(itm.created_at).format('DD-MM-YYYY')}</div> */}
                                                                                    </Box>
                                                                                </ListItemButton>
                                                                            </ListItem> 
                                                                            <Divider />
                                                                        </>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </List>
                                                :
                                                <>
                                                <div style={{ 
                                                    marginTop:'1rem',
                                                    paddingRight:'.5rem'
                                                 }}>
                                                    <CalenderComp item={item} ActiveDateData={AllItemData} />
                                                </div>
                                                </>

                                            }

                                        </div>
                                    </Grid>
                                    <Grid item xs={9} sx={{
                                        paddingRight:'2rem',
                                        maxHeight:'70vh',
                                        overflow:'auto',
                                        border:'1px solid #ccc'
                                    }}>
                                        { loading ? <CircularProgress /> : 
                                            <>
                                                <div style={{
                                                }}>
                                                    

                                                    <div className="box-head">
                                                        <Avatar sx={{ width: 60, height: 60 }} alt="Remy Sharp" src={data?.author?.image} />
                                                        <div className="uDetail">
                                                            <h4>{item?.name}</h4>
                                                            <ul>
                                                                <li>{data?.author?.name}</li>
                                                                <li>{moment(ItemData?.created_at).format('DD-MMM-YYYY')}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">
                                                        <div dangerouslySetInnerHTML={{
                                                            __html:ItemData?.discription
                                                        }} />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
    </>
  )
}

export default DetailPage