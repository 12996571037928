import React, {
  useEffect,
  useState
} from "react";
import { FaSortAmountUp, FaSortAmountDown } from "react-icons/fa";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Box, CircularProgress, IconButton, List, ListItem, ListItemButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { VideoCommentAction, VideoLikeAction } from "../../../redux/actions/VdrAction";
import { commentParams, crtUserId } from "../../../constants/helpers";
import { useParams } from "react-router-dom";


const LeftSection = (props) => {
  const {
    VidSortStatus,
    setVidSortStatus,
    sortVideosTypeFun,
    FilterVideoData,
    setFilterVideoData,
    ActiveImage,
    setActiveImage,
    ActiveVideoType,
    setActiveVideoType,
    ActiveVideoItem,
    setActiveVideoItem,
    ToBeUpdated,
    setToBeUpdated
  } = props
  const [LikesData, setLikesData] = useState({});

  const [SpecificSortStatus, setSpecificSortStatus] = React.useState(false);
  const { data: companyMasterData, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
  const { data: VideoLikeData, loading: VideoLikeLoading } = useSelector((state) => state.VideoLikeReducer);

  const [ActiveVideo, setActiveVideo] = useState("");

  let { vidSlug } = useParams();



  useEffect(() => {
    // console.log(VidSortStatus)
  }, [VidSortStatus]);


  
useEffect(() => {
  if(FilterVideoData.length > 0){
    let item0 = FilterVideoData[0];
    if(vidSlug !== undefined && vidSlug !== ""){
      item0 = FilterVideoData.find(item=> item.videoId === vidSlug);
      // console.log('item0 <<<<><>> ', vidSlug)
    }
    SelectVideoFun(item0)
    // console.log('item0 <<<<><>> ', item0)
    // let slug = item0?.slug
    // let params = {
    //     "slug": slug,
        // "user_id": crtUserId()
    // }
    // setActiveItem(item0.id);
    // dispatch(forumDetailAction(params));
  }
}, [FilterVideoData])



  useEffect(() => {
    if(!VideoLikeLoading){
      setLikesData(VideoLikeData.Data[0]);
      console.log('!!!!!VideoLikeData >>>> ', VideoLikeData.Data[0])
    }
    if(VideoLikeLoading){
      // console.log('VideoLikeData >>>> ', VideoLikeData)
    }
  }, [VideoLikeLoading]);

  let userId = crtUserId();

  const dispatch = useDispatch();
  let params = {
    "type":"",
    "webuserId":userId,
    "inputType" : "1"
  }

  const SelectVideoFun = (vid_item) => {
    
    let item = vid_item;
    let VidT = `https://vimeo.com/${item?.videoCode}`;
    if(item?.videoType === "youtube"){
      VidT = `https://www.youtube.com/watch?v=${item?.videoCode}`;
    }
    setActiveVideo(item?.videoId)
    setActiveImage(VidT);
    setActiveVideoItem(item)
    // VideoLikeData
    let vid_params = { ...params, video_id: item?.videoId}
    dispatch(VideoLikeAction(vid_params));


    let videoCommentParams = {
      ...commentParams,
      videoId: item?.videoId
    }
    dispatch(VideoCommentAction(videoCommentParams));

  }

  

  const filterSingleCompany = (comId) => {
    let singleComp = companyMasterData.find(s_item=> s_item.CompanyID == comId)
    return singleComp
  }

  return (
    <div className={`video-left`}>
      <div
        style={{
          width: "100%",
          textAlign: "right",
        }}
      >
        
        {VidSortStatus ? (
          <IconButton
            onClick={() => {
              sortVideosTypeFun(true, true);
            }}
          >
            <FaSortAmountUp style={{ 
              fontSize: '1rem'
              }} />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              sortVideosTypeFun(true, true);
            }}
          >
            <FaSortAmountDown style={{ 
              fontSize: '1rem'
              }} />
          </IconButton>
        )}
      </div>

      <Box
        sx={
          {
          }
        }
      >
        {
            ToBeUpdated ? 
            <>
              <Typography sx={{
                
              }} variant="h6">Videos Not Found</Typography>
            </>
            :
            <>
            {
          FilterVideoData.length === 0 ?
          <>
            <CircularProgress />
          </>
          :
          <>
          
            <List sx={{ position: "relative" }} className="video-list">
              {FilterVideoData &&
                FilterVideoData.length > 0 &&
                FilterVideoData.map((item, index) => {
                  
                  let sComp = filterSingleCompany(item?.CompanyID);
                  let labelName = sComp?.CompanyName || "Webinar With Clients";
                  
                  return (
                    <React.Fragment key={index}>
                       <ListItem disablePadding  className={ActiveVideo === item?.videoId ? "activeListCls" : ""}>
                                    <ListItemButton onClick={()=>{
                                      SelectVideoFun(item)
                                    }}>
                                      <Box sx={{ 
                                        display:'flex',
                                        justifyContent:'space-between',
                                        alignItems:'center',
                                        width:'100%'
                                       }}>
                                        <Box>
                                          <Box sx={{ 
                                            display:'flex',
                                            justifyContent:'flex-start',
                                            alignItems:'center',
                                            gap:1
                                          }}>
                                            <div className='vid-icon'>
                                              <PlayCircleIcon />
                                            </div>
                                            <div className='vid-title'>
                                              {item?.videoTitle}
                                              <div>
                                                <b><small>{labelName}</small></b>
                                              </div>
                                              </div>
                                          </Box>
                                        </Box>
                                        <div>
                                          <div className='vid-date'>
                                              {item?.dateTime}
                                          </div>
                                        </div>
                                      </Box>
                                    </ListItemButton>
                                  </ListItem>
                      
                    </React.Fragment>
                  );
                })}
            </List>
          </>
        }
            </>
          }

        
      </Box>
    </div>
  );
};

export default LeftSection;
