import React, { useEffect, useState } from 'react'

import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { Box, Checkbox } from '@mui/material';
import { VideoCommentAction, VideoCommentLikeAction } from '../../../../redux/actions/VdrAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { commentParams, crtUserId } from '../../../../constants/helpers';



const CommentCard = ({item}) => {

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [checked, setChecked] = React.useState(item?.is_user_like);
    const dispatch = useDispatch();
    const {
         data:CommentLikeData,
         loading:CommentLikeLoading,
    } = useSelector(state=>state.VideoCommentLikeReducer)

  const handleChange = (event) => {
    let crt_check = event.target.checked;
    let like_type = 'like';
    if(!crt_check){
      like_type = 'unlike';
    }
    setChecked(crt_check);
    let params = {
                    type: like_type, //  Blank , like or  unlike
                    comment_id: item.commentId,
                    video_id: item.videoId,
                    webuserId: crtUserId(),
                    inputType: "0" //0:edit 1:get
                 }
    let vid_params = { ...params }
    dispatch(VideoCommentLikeAction(vid_params));

    let params0 = {
        ...commentParams,
        videoId: item.videoId,
        comment: '',
        inputType: "3"
      }
    dispatch(VideoCommentAction(params0));

  };


  return (
    <>
        <Box className="comment-box">
            <div className="comment-author-cont">
                <div className="author-detail">
                    <img src={item?.webUserImage} alt={item?.webUserName} />
                    <h3>{item?.webUserName}</h3>
                </div>
            </div>
            <div className="comment-description">
                <div dangerouslySetInnerHTML={{ 
                    __html: item?.comment
                }}/>
            </div>
            <div>
            <Box style={{ 
                    display:'flex',
                    fontSize:'1rem',
                    alignItems:'center',
                    marginTop:10
                }}
                >
                <Checkbox
                    {...label}
                    onChange={handleChange} 
                    icon={<FavoriteBorder sx={{fontSize: '16px' }} />}
                    checkedIcon={<Favorite sx={{ fontSize: '16px', color: 'red' }} />}
                    checked={checked}
                    sx={{ 
                    padding:'0 .2rem 0 0'
                    }}
                />
                    <div style={{fontSize: '12px' }}>
                        {item?.total_comment_like} Like
                    </div>
                </Box>
            </div>
        </Box>
    </>
  )
}

export default CommentCard