
import React, { useEffect, useState } from "react";
import { PDFDocument, rgb, degrees, StandardFonts } from "pdf-lib";
import { Document, Page, pdfjs } from "react-pdf";

import { rangeArray, watermarkText, checkUser } from '../../constants/helpers';
import { Box, Button } from "@mui/material";
import { LoadingComponent } from "../../components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { filesLogAction } from "../../redux/actions/VdrAction";
// const pdfURL =
//   "https://omkaradata.com//UploadFiles//122205_1349_100875_202103.PDF";


const pdfURL = localStorage.getItem('PDFViewed') ? window.atob(localStorage.getItem('PDFViewed')) : null;

const filDatattt = localStorage.getItem('file_type') ? localStorage.getItem('file_type') : null;
let fileName = '';
if (pdfURL) {

  fileName = pdfURL.split('/');
  fileName = fileName[fileName.length - 1]

}





export default function MyPdfViewer(props) {
  // const {type} = props;
  const [mypdf, setMypdf] = useState(null);

  const [ShowIframe, setShowIframe] = useState(null);

  const { type } = useParams();

  // console.log(type)
  // console.log(filDatattt);

  var userEmail = "vineet@omkaracapital.in";

  const { data: filesLogData, loading: filesLogLoading } = useSelector((state) => state.filesLogReducer);
  const dispatch = useDispatch();

  let userData = {};
  if (localStorage.getItem('users_leggin')) {
    userData = JSON.parse(localStorage.getItem('users_leggin'));
  }

  let itemData1 = {};
  if (localStorage.getItem('itemData1')) {
    itemData1 = JSON.parse(localStorage.getItem('itemData1'));
  }
  console.log(itemData1);


  React.useEffect(() => {
    checkUser();

    dispatch(filesLogAction({
      'author': userData?.name,
      'group_name': userData?.type,
      'company': itemData1?.title,
      'action': 'file viewed',
      'description': 'description'+userData?.id,
      'details': itemData1?.link,
      'filename': itemData1?.fileName
    }));

  }, []);



  const createAndDownloadBlobFile = (body, filename, extension = "pdf") => {
    const blob = new Blob([body]);
    // const fileName = `${filename}.${extension}`;
    console.log("blob", blob);
    const link = document.createElement("a");
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        console.log("link", link, url);
        // document.body.removeChild(link);
      }
    }
      
    dispatch(filesLogAction({
      'author': userData?.name,
      'group_name': userData?.type,
      'company': itemData1?.title,
      'action': 'file download',
      'description': 'description'+userData?.id,
      'details': itemData1?.link,
      'filename': itemData1?.fileName
    }));
  
  }


  const getPdf = async (setMypdf) => {

    try {
      let url = pdfURL;


      // if( window.location.host != 'omkaradata.in' && window.location.host != 'omkaradata.com'){
      // }
      url = "https://vasudeep.com:8084/"+url;

      const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
  
      const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold)
      const courierFont = await pdfDoc.embedFont(StandardFonts.CourierBold)
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
      const pages = pdfDoc.getPages();
      pages.forEach((page) => {
        if (filDatattt === 'Final Output') {
          page.setFont(helveticaFont);
          page.drawText(watermarkText, {
            x: 100,
            y: page.getHeight() / 2 + -200,
            size: 50,
            color: rgb(0.8, 0.8, 0.8, 0.1),
            rotate: degrees(45),
            opacity: 0.4,
          });
        }
        // console.log(userEmail.length*10);
        // page.drawText(userEmail, {
        //   x: page.getWidth() / 2-(userEmail.length*5),
        //   y: page.getHeight() - page.getHeight() + 10,
        //   size: 20,
        //   color: rgb(0.8, 0.8, 0.8, 0.1),
        //   rotate: degrees(0),
        //   opacity: 0.4
        // });
      });
  
      const pdfBytes = await pdfDoc.save();
      setMypdf(pdfBytes);
      
    } catch (error) {
      setShowIframe(true);
      console.log(error)
    }

  }

  const PreviewPDF = ({ pdf }) => {
    const [page, setPage] = useState(10);
    const [TotalPage, setTotalPage] = useState(0);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    if (!pdf) return null;
    return (
      <>
        <div
          className="page-navigator"
        >
          <Box>
            {mypdf && (
              <Button variant="contained" size="small" onClick={() => createAndDownloadBlobFile(mypdf, fileName)}>
                Download Complete PDF
              </Button>
            )}
          </Box>
          <Box>
            Showing page No. {page - 9} - {TotalPage > page ? page : TotalPage} out of {TotalPage} Pages
          </Box>
          <Box sx={{
            marginRight: '1rem'
          }}>
            <Button sx={{
              margin: "0 0.2rem"
            }}

              disabled={page <= 10 ? true : false}
              variant="contained" size="small" onClick={() => setPage((p) => (p > 0 ? p - 10 : p))}>
              Back
              {
                page > 10 ?
                  <>
                    <Box sx={{
                      paddingLeft: 0.5
                    }}>

                      {page - 9 - 10}  - {page - 10}
                    </Box>
                  </>
                  : null
              }


            </Button>
            <Button sx={{
              margin: "0 0.2rem"
            }}
              disabled={page >= TotalPage ? true : false}
              variant="contained" size="small" onClick={() => setPage((p) => p + 10)}>
              Next
              {
                page <= TotalPage
                  ?
                  <>

                    <Box sx={{
                      paddingLeft: 0.5
                    }}>{page - 9 + 10}  - {TotalPage > page + 10 ? page + 10 : TotalPage}</Box>
                  </>
                  :
                  null
              }
            </Button>
          </Box>
        </div>
        <div style={{ marginTop: "4rem" }}>
          <Document
            file={{ data: pdf }}
            onLoadSuccess={({ numPages }) => setTotalPage(numPages)}
            options={{ cMapUrl: "/_next/cmaps/", cMapPacked: true }}
          >
            {
              rangeArray((page - 9), page).map((item, i) => {
                return (
                  <>
                    <Page key={item + '-' + i} error={""} width={1000} pageNumber={item} />
                  </>
                );
              })
            }
          </Document>

        </div>
      </>
    );
  }




  useEffect(() => {
    getPdf(setMypdf)
  }, [])


  return (
    <div className="App">
      {
        ShowIframe ?
        <>
          <iframe src={pdfURL} style={{ width: '100vw', height:'100vh' }}>

          </iframe>
        </>
        :
        <>
          {!mypdf && <LoadingComponent />}
          <PreviewPDF pdf={mypdf} />
        </>
      }
    </div>
  );
}
