import { Box, Button, ButtonGroup, CircularProgress, Collapse, Container, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoadingComponent } from '../../components'
import { loggedInUser, mediaInput } from '../../constants/helpers'
import { vdrFavoriteAction, vdrCompanyAction, vdrHomeAction, vdrMediaAction, vdrMediaGroupAction } from '../../redux/actions/VdrAction'
import { companyMasterAction } from '../../redux/actions/userAction';
import { CustomCarousel, SingleCompanyGrid, SingleVideoGrid } from '../components'

import ReactPlayer from 'react-player'

import BusinessIcon from '@mui/icons-material/Business';
import { 
  FaSortAmountUp,
  FaSortAmountDown
 } from "react-icons/fa";

 import './videos/style.scss'

import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ShareIcon from '@mui/icons-material/Share';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MediaModel from '../../components/SingleCompanyContainer/MediaSection_old/MediaModel'
import VidPlayer from './VidPlayer'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import moment from 'moment';
import { CloseFullscreen } from '@mui/icons-material'


const VideosPage = () => {
  const { data, loading, error } = useSelector(state => state.vdrFavoriteReducer);

  const dispatch = useDispatch();
  const { data: vdrMediaData, loading: vdrMediaLoading } = useSelector((state) => state.vdrMediaReducer);
  const { data: companyMasterData, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
  const { data: vdrMediaGroupData, loading: vdrMediaGroupLoading } = useSelector((state) => state.vdrMediaGroupReducer);

  const [Open, setOpen] = useState([]);
  
  const [VideosArr, setVideosArr] = useState([]);
  const [PinnedVideoArr, setPinnedVideoArr] = useState([]);
  const [CompanyVideosArr, setCompanyVideosArr] = useState([]);
  const [UniqueCompanyVideosArr, setUniqueCompanyVideosArr] = useState([]);
  const [FilterCompanyVideosArr, setFilterCompanyVideosArr] = useState([]);

  const [ActiveVideo, setActiveVideo] = useState("");
  const [ActiveVideo1, setActiveVideo1] = useState("");

  const [VideoIds, setVideoIds] = React.useState([]);


  const [ActiveImage, setActiveImage] = React.useState("");
  const [ActiveImage1, setActiveImage1] = React.useState("");

  const [SpecificSortStatus, setSpecificSortStatus] = React.useState(false);
  const [VidSortStatus, setVidSortStatus] = React.useState(false);
  
  const [CompanyMasterArr, setCompanyMasterArr] = useState([]);
  
  const [LatestVideosArr, setLatestVideosArr] = useState([]);

  const [SpecificWebinarArr, setSpecificWebinarArr] = useState([]);

  const [VideoCompanyMasterArr, setVideoCompanyMasterArr] = useState([]);
  const { data: vdrCompanyData, loading: vdrCompanyLoading, totalRows } = useSelector((state) => state.vdrCompanyReducer)
  
  
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  
  useEffect(() => {
    let f = document.querySelector('footer');
    if(f){
      f.remove()
    }
  },[]);


  // useEffect(() => {
    
  //   if (vdrCompanyLoading) {
  //     dispatch(vdrCompanyAction({ status: 1 }))
  //   }
  // },[dispatch, vdrCompanyLoading]);


  useEffect(() => {
// console.log('vdrMediaData')

    setUniqueCompanyVideosArr([]);
    setFilterCompanyVideosArr([]);

    if (vdrMediaLoading && vdrMediaData.length === 0) {
      let a0 = mediaInput;
      a0 = {...a0, fromDate: moment().subtract(20, 'years').format('YYYY-MM-DD')}

      dispatch(vdrMediaAction(a0));
    }

    if (!vdrMediaLoading) {
      let pinnedD = vdrMediaData.Data.filter((items) => { if (items.isPinned === true && items.DocumentType === "Common" && items.groupId != "" ) return items });
          pinnedD = pinnedD.sort((a,b)=>{
            return a.item_order - b.item_order
          })

      // console.log();
      setPinnedVideoArr(pinnedD);

      setVideoIds(pinnedD.map((item) => item.videoId));
      let fData = vdrMediaData.Data.filter((items) => { if (items.DocumentType === "Common") return items });
      fData.sort((a,b)=>{
          return new Date(b.dateTime) - new Date(a.dateTime)
      })
      
      setFilterVideoData(fData);
      setVideosArr(fData);
      // let a00 = vdrMediaData.Data.filter((items) => { if (items.DocumentType !== "Common") return items });
      let a00 = vdrMediaData.Data.filter((items) => { if (items.DocumentType === "SINGLE VIDEO") return items });
      setCompanyVideosArr(a00);
    }
  }, [dispatch, vdrMediaLoading])


  useEffect(() => {
    if(companyMasterLoading){
      dispatch(companyMasterAction());
    }
    if(!companyMasterLoading){
      setCompanyMasterArr(companyMasterData)
    }
  }, [dispatch, companyMasterLoading])
  

  
  const [VideoGroupData, setVideoGroupData] = React.useState([]);
  const [FilterVideoData, setFilterVideoData] = React.useState([]);


  useEffect(() => {
      if (vdrMediaGroupLoading) {
          var videoParams = {
              "status": 1,
              "inputType": 3
          }
          dispatch(vdrMediaGroupAction(videoParams));
      }
      if (!vdrMediaGroupLoading) {
          setVideoGroupData(vdrMediaGroupData.Data)
      }
  }, [dispatch, vdrMediaGroupLoading]);

  

  // let uniqueData = [];
  // if (CompanyVideosArr.length >= 0) {
  //   const unique = [...new Map(CompanyVideosArr.map(item => [item['CompanyID'], item])).values()]; // [ 'A', 'B'] 
  //   uniqueData = unique;
  // }

  

  useEffect(() => {
    if(CompanyVideosArr.length > 0){
      const unique = [...new Map(CompanyVideosArr.map(item => [item['CompanyID'], item])).values()]; // [ 'A', 'B'] 
      let uniqueData = unique;
      let a00 = [];
      // console.log(vdrCompanyData.Data)
      unique.map((item, i)=>{
        let a__0 =  item;
        if(item.VDRVideo === "Yes"){
          let companyData = CompanyMasterArr.find((cmp)=> cmp.CompanyID == item.CompanyID)
          a__0 = {...a__0, companyTitle:companyData?.CompanyName}
          a00.push(a__0);
        }
      })
      
      a00.sort((a,b)=>{ 
        if(a?.companyTitle){
          let a0 = a?.companyTitle.toLowerCase()
          let b0 = b?.companyTitle.toLowerCase()
          if (a0 < b0) {
            return -1;
          }
          if (a0 > b0) {
            return 1;
          }
        }
        return 0;
       });
       
      setUniqueCompanyVideosArr(a00);
      
      let A000000 = vdrMediaData.Data.filter(item00=>{ if (item00.DocumentType === "SINGLE VIDEO" && item00.VDRVideo === "Yes") return item00 }).sort((a,b)=>{ return new Date(b.dateTime) - new Date(a.dateTime) })
      setSpecificWebinarArr(A000000);

      // const unique0 = [...new Map(vdrMediaData.Data.map(item => [item['videoCode'], item])).values()]; // [ 'A', 'B'] 
      let latestVid = vdrMediaData.Data.filter(item00=>{ if (item00.DocumentType !== ""  && item00.VDRVideo === "Yes") return item00 }).sort((a,b)=>{ return new Date(b.dateTime) - new Date(a.dateTime) })
      setLatestVideosArr(latestVid);
      
    }
  }, [CompanyVideosArr, CompanyMasterArr])

  const specificSortStatusFun0 = (type) => {
    let A000000 =  [];

    let fData = vdrMediaData.Data.filter((items) => { if (items.DocumentType === "Common") return items });

    if(type){
      fData.sort((a,b)=>{
          return new Date(b.dateTime) - new Date(a.dateTime)
      })
    }else{
      fData.sort((a,b)=>{
          return new Date(a.dateTime) - new Date(b.dateTime)
      })
    }
    setFilterVideoData(fData);
    setVidSortStatus(!VidSortStatus);
  }

  const specificSortStatusFun = (type) => {
    let A000000 =  [];
    let sp = vdrMediaData.Data.filter(item00=>{ if (item00.DocumentType === "SINGLE VIDEO" && item00.VDRVideo === "Yes") return item00 });
    if(type){
      A000000 = sp.sort((a,b)=>{ return new Date(b.dateTime) - new Date(a.dateTime) })
    }else{
      A000000 = sp.sort((a,b)=>{ return new Date(a.dateTime) - new Date(b.dateTime) })
    }
    setSpecificSortStatus(!SpecificSortStatus);
    setSpecificWebinarArr(A000000);
  }

const filteredData = (gId) => {
  return VideosArr.filter((item0)=>{ if(item0.groupId == gId) return item0 } ).sort((a,b)=>{ return a.item_order - b.item_order })
}
  
const filteredCompanyData = (gId) => {
  let d1 = vdrMediaData.Data.filter((item0)=>{ if(item0.CompanyID == gId) return item0 } ).sort((a,b)=>{ return a.item_order - b.item_order });
  return d1 
}


const filterSingleCompany = (comId) => {
  // console.log(comId)
  let singleComp = companyMasterData.find(s_item=> s_item.CompanyID == comId)
  // console.log(companyMasterData)
  return singleComp
}

const handleClick = (e, item) => {
  setOpen(item);
};

const [ActiveVidM, setActiveVidM] = useState("1")

const filterSearch = (e) => {
  let searchedVal = e.target.value;
  
  const filteredRows = VideosArr.filter((row, i)=>{
    return String(row?.videoTitle).toLowerCase().includes(searchedVal.toLowerCase());
  })
  
  
  const filteredCompanyRows = UniqueCompanyVideosArr.filter((row, i)=>{
    return String(row?.companyTitle).toLowerCase().includes(searchedVal.toLowerCase());
  })

  let A000000 =  [];
  let sp = vdrMediaData.Data.filter(item00=>{ if (item00.DocumentType === "SINGLE VIDEO" && item00.VDRVideo === "Yes") return item00 });
  if(SpecificSortStatus){
    A000000 = sp.sort((a,b)=>{ return new Date(b.dateTime) - new Date(a.dateTime) })
  }else{
    A000000 = sp.sort((a,b)=>{ return new Date(a.dateTime) - new Date(b.dateTime) })
  }

  const filtered_data = A000000.filter((row, i)=>{
    return String(row?.videoTitle).toLowerCase().includes(searchedVal.toLowerCase());
  })


  // const unique0 = [...new Map(vdrMediaData.Data.map(item => [item['videoCode'], item])).values()]; // [ 'A', 'B'] 
  let latestVid = vdrMediaData.Data.filter(item00=>{ if (item00.DocumentType !== ""  && item00.VDRVideo === "Yes") return item00 }).sort((a,b)=>{ return new Date(b.dateTime) - new Date(a.dateTime) })
  

  const filtered_latestVid = latestVid.filter((row, i)=>{
    return String(row?.videoTitle).toLowerCase().includes(searchedVal.toLowerCase());
  })

  // setFilterCompanyVideosArr
  
  if (searchedVal.length < 1) {
    setFilterVideoData(VideosArr)
    setSpecificWebinarArr(A000000);
    setLatestVideosArr(latestVid);
    setFilterCompanyVideosArr(UniqueCompanyVideosArr)
  }
  else {
    setFilterVideoData(filteredRows)
    setSpecificWebinarArr(filtered_data);
    setLatestVideosArr(filtered_latestVid);
    setFilterCompanyVideosArr(filteredCompanyRows)
  }

  // if (searchedVal.length > 1) {
  //   console.log('filteredRows >>> ', filteredRows);
  //   setFilterVideoData(filteredRows)
  // }
  // else {
  //   console.log('VideosArr >>> ', VideosArr);
  //   setFilterVideoData(VideosArr)
  // }

}

const chooseDate = (value, item={}) => {
  let a0 = mediaInput;
  // setFilterCompanyVideosArr([])
  a0 = {...a0, fromDate: item.fDate}
  dispatch(vdrMediaAction(a0));
  setActiveVidM(value)
}

const sortType = [
  {
    "title":"This Week",
    "value":"1",
    "fDate": moment().subtract(1, 'week').format('YYYY-MM-DD')
  },
  {
    "title":"Last Month",
    "value":"2",
    "fDate": moment().subtract(1, 'month').format('YYYY-MM-DD')
  },
  {
    "title":"3 Months",
    "value":"3",
    "fDate": moment().subtract(3, 'months').format('YYYY-MM-DD')
  },
  {
    "title":"Last Year",
    "value":"4",
    "fDate": moment().subtract(1, 'year').format('YYYY-MM-DD')
  },
  {
    "title":"All",
    "value":"0",
    "fDate":moment().subtract(20, 'years').format('YYYY-MM-DD')
  },
]
  // console.log(companyMasterData);

  return (
    <>
      <Box className="headerContainerFront" sx={{ 
        // minHeight:'70px',
        height:'auto'
       }}>
        <Typography
          style={{ zIndex: 2 }}
          fontSize={"2.5em"}
          color="#133e5b"
          fontWeight="700"
        >
          Videos
        </Typography>
      </Box>

      <Box className="videoContainer" sx={{
        marginTop: 0, marginBottom: 5
      }}>

          <Box sx={{ 
            width:'100%',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            gap:1,
            backgroundColor:'#fff'
            }}
            >
            <TextField 
              onChange={(e)=>filterSearch(e)}
              placeholder="Search"
              fullWidth
              size="small"
              sx={{ 
                  backgroundColor:'#fff'
               }}
            />


            

            {/* <Button variant='contained' >Reset</Button> */}

          </Box>
          {/* <Box mt={2}>
                                <ButtonGroup size={'small'}>
  
                                {
                                    sortType.map((item, i)=>{
                                      return (
                                        <Button key={i} variant={ActiveVidM === item?.value ? "contained" : 'outlined'}  className={ActiveVidM === item?.value ? "ActiveVidMBtn" : ''} onClick={()=>chooseDate(item?.value, item)}>{item.title}</Button>
                                      )
                                    })
                                  }
</ButtonGroup>

                                </Box> */}


          <Box mt={5}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Latest Video" value="1" />
                    <Tab label="Webinar with Clients" value="2" />
                    <Tab label="Company Specific Webinar" value="3" />
                  </TabList>
                </Box>
                
                <TabPanel value="1">
                <Box>
                    <Grid container  spacing={2}>
                      <Grid item sm={8} >
                        <Box className='video-left'>

                        
                      <div style={{
                          width:'100%',
                          textAlign:'right'
                        }}>
                          

                        </div>
                        

                        <Box  sx={{ 
                          // border:'1px solid #ccc',
                          // height:'59vh',
                          // overflow:'auto',
                          // paddingX:1
                        }}>

                      <List sx={{ position:'relative' }} className='video-list'>
                          {
                            LatestVideosArr && LatestVideosArr.length > 0 && LatestVideosArr.map((item, index) => {
                              
                            if(index > 10){
                              return 
                            }
                            let sComp = filterSingleCompany(item?.CompanyID);
                            let labelName = sComp?.CompanyName || "Webinar With Clients";
                              return (
                                <React.Fragment key={index}>
                                  <ListItem disablePadding  className={ActiveVideo === item?.videoCode ? "activeListCls" : ""}>
                                    <ListItemButton onClick={()=>{
                                      let VidT = `https://vimeo.com/${item?.videoCode}`;
                                      if(item?.videoType == "youtube"){
                                        VidT = `https://www.youtube.com/watch?v=${item?.videoCode}`;
                                      }
                                      setActiveVideo(item?.videoCode)
                                      setActiveImage(VidT);
                                    }}>
                                      <Box sx={{ 
                                        display:'flex',
                                        justifyContent:'space-between',
                                        alignItems:'center',
                                        width:'100%'
                                       }}>
                                        <Box>
                                          <Box sx={{ 
                                            display:'flex',
                                            // justifyContent:'space-between',
                                            justifyContent:'flex-start',
                                            alignItems:'center',
                                            gap:1
                                          }}>
                                            <div className='vid-icon'>
                                              <PlayCircleIcon />
                                            </div>
                                            <div className='vid-title'>
                                              {item?.videoTitle}
                                              <div>
                                                <b><small>{labelName}</small></b>
                                              </div>
                                              </div>
                                          </Box>
                                        </Box>
                                        <div>
                                          <div className='vid-date'>
                                              {item?.dateTime}
                                          </div>
                                        </div>
                                      </Box>
                                    </ListItemButton>
                                  </ListItem>
                                  {/* <Divider variant="inset" component="li" /> */}
                                </React.Fragment>
                              )
                            })
                          }
                        </List>

                       </Box>
                        </Box>

                      </Grid>
                      <Grid item sm={4} sx={{ position:'relative' }}>
                        <Box sx={{ 
                            width:'100%', 
                            position:'sticky',
                            borderRadius:'10px',
                            overflow:'hidden',
                            top:'6rem',
                            border:'1px solid #ccc',
                            backgroundColor:'#fff'
                            
                           }}>
                            <Box className='vid-comp'>
                              <ReactPlayer
                                url={ActiveImage}
                                width='100%'
                                controls={true}
                              />
                            </Box>
                        </Box>
                      </Grid>
                    </Grid>
                </Box>
                </TabPanel>
                <TabPanel value="2">
                <Box>
                    <Grid container  spacing={2}>
                      <Grid item sm={8} >
                      <Box className='video-left'>

                      <div style={{
                          width:'100%',
                          textAlign:'right'
                        }}>
                              {
                                VidSortStatus ?
                                  <IconButton onClick={()=>{
                                    specificSortStatusFun0(true)
                                  }}>
                                    <FaSortAmountUp />
                                  </IconButton>
                                :
                                <IconButton onClick={()=>{
                                  specificSortStatusFun0(false)
                                }}>
                                    <FaSortAmountDown />
                                  </IconButton>
                              }

                        </div>
                        

                        <Box  sx={{ 
                          // border:'1px solid #ccc',
                          // height:'59vh',
                          // overflow:'auto',
                          // paddingX:1
                        }}>

                      <List sx={{ position:'relative' }} className='video-list'>
                          {
                            FilterVideoData && FilterVideoData.length > 0 && FilterVideoData.map((item, index) => {
                              
                            
                              return (
                                <React.Fragment key={index}>
                                  <ListItem disablePadding  className={ActiveVideo === item?.videoCode ? "activeListCls" : ""}>
                                    <ListItemButton onClick={()=>{
                                      let VidT = `https://vimeo.com/${item?.videoCode}`;
                                      if(item?.videoType == "youtube"){
                                        VidT = `https://www.youtube.com/watch?v=${item?.videoCode}`;
                                      }
                                      setActiveVideo(item?.videoCode)
                                      setActiveImage(VidT);
                                    }}>
                                      <Box sx={{ 
                                        display:'flex',
                                        justifyContent:'space-between',
                                        alignItems:'center',
                                        width:'100%'
                                       }}>
                                        <Box sx={{ 
                                          display:'flex',
                                          justifyContent:'space-between',
                                          alignItems:'center',
                                          gap:1
                                         }}>
                                          <div className='vid-icon'>
                                            <PlayCircleIcon />
                                          </div>
                                          <div className='vid-title'>{item?.videoTitle}</div>
                                        </Box>
                                        <div>
                                          <div className='vid-date'>
                                              {item?.dateTime}
                                          </div>
                                        </div>
                                      </Box>
                                    </ListItemButton>
                                  </ListItem>
                                  {/* <Divider variant="inset" component="li" /> */}
                                </React.Fragment>
                              )
                            })
                          }
                        </List>

                       </Box>
                      </Box>

                      </Grid>
                      <Grid item sm={4} sx={{ position:'relative' }}>
                        <Box sx={{ 
                            width:'100%', 
                            position:'sticky',
                            borderRadius:'10px',
                            overflow:'hidden',
                            top:'6rem',
                            border:'1px solid #ccc',
                            backgroundColor:'#fff'
                            
                           }}>
                            <Box className='vid-comp'>
                              <ReactPlayer
                                url={ActiveImage}
                                width='100%'
                                controls={true}
                              />
                            </Box>
                        </Box>
                      </Grid>
                    </Grid>
                </Box>
                </TabPanel>
                <TabPanel value="3">
                <Box>
                    <Grid container  spacing={2}>
                      <Grid item sm={8} >
                      <Box className='video-left'>

                        <div style={{
                          width:'100%',
                          textAlign:'right'
                        }}>
                              {
                                SpecificSortStatus ?
                                  <IconButton onClick={()=>{
                                    specificSortStatusFun(true)
                                  }}>
                                    <FaSortAmountUp />
                                  </IconButton>
                                :
                                <IconButton onClick={()=>{
                                  specificSortStatusFun(false)
                                }}>
                                    <FaSortAmountDown />
                                  </IconButton>
                              }

                        </div>
                        
                        <Box  sx={{ 
                          // border:'1px solid #ccc',
                          // height:'59vh',
                          // overflow:'auto',
                          // paddingX:1
                        }}>


                        <List sx={{ position:'relative' }} className='video-list'>

                          {
                             vdrMediaLoading
                             ?
                             (
                              <CircularProgress />
                             )
                             :
                             SpecificWebinarArr && SpecificWebinarArr.length === 0 ?
                             <><Typography><strong>Please choose another range to update the list.</strong></Typography></>
                             :
                             <>
                             
                          {
                            SpecificWebinarArr && SpecificWebinarArr.length > 0 && SpecificWebinarArr.map((itm, i_0)=>{
                              let sComp = filterSingleCompany(itm?.CompanyID);
                              
                              if(itm?.DocumentType != "SINGLE VIDEO"){
                                return "";
                              }
                              console.log('itm >>>> ', itm)
                              return (
                                <React.Fragment key={i_0}>
                                  <ListItem disablePadding  className={ActiveVideo1 === itm?.videoId ? "activeListCls" : ""}>
                                    <ListItemButton  onClick={()=>{
                                      
                                      let VidT = `https://vimeo.com/${itm?.videoCode}`;
                                      if(itm?.videoType == "youtube"){
                                        VidT = `https://www.youtube.com/watch?v=${itm?.videoCode}`;
                                      }
                                      setActiveVideo1(itm?.videoId)
                                      setActiveImage1(VidT);
                                    }} >
                                      <Box sx={{ 
                                        display:'flex',
                                        justifyContent:'space-between',
                                        alignItems:'center',
                                        width:'100%'
                                      }}>
                                        <Box>
                                        <Box sx={{ 
                                          display:'flex',
                                          // justifyContent:'space-between',
                                          justifyContent:'flex-start',
                                          alignItems:'center',
                                          gap:1
                                        }}>
                                          <div className='vid-icon'>
                                            <PlayCircleIcon />
                                          </div>
                                          <div className='vid-title'>
                                            {itm?.videoTitle}
                                            <div>
                                              <b><small>{sComp?.CompanyName}</small></b>
                                            </div>
                                            
                                            </div>
                                        </Box>
                                        
                                        </Box>
                                        <div>
                                          <div className='vid-date'>
                                            {itm?.dateTime}
                                          </div>
                                        </div>
                                      </Box>
                                    </ListItemButton>
                                  </ListItem>
                                </React.Fragment>
                              )
                            })
                          }
                             </>
                          }
                          {/* {SpecificWebinarArr.length} */}


                        </List>
                       </Box>
                      </Box>


                      </Grid>
                      <Grid item sm={4} sx={{ position:'relative' }}>
                        <Box sx={{ 
                            width:'100%', 
                            position:'sticky',
                            borderRadius:'10px',
                            overflow:'hidden',
                            top:'6rem',
                            border:'1px solid #ccc',
                            backgroundColor:'#fff'
                            
                           }}>
                            <Box className='vid-comp'>
                              <ReactPlayer
                                url={ActiveImage1}
                                width='100%'
                                controls={true}
                              />
                            </Box>
                        </Box>
                      </Grid>
                    </Grid>
                </Box>
                </TabPanel>
              </TabContext>
            </Box>
          </Box>

      </Box>

    </>


  )
}

export default VideosPage