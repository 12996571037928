import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  allCompanyData,
  allCompanyData2,
  companyMasterAction,
  industryMasterAction,
  resultFilterInputAction,
  resultFilters,
  sectorMasterAction,
  turnAroundMasterAction
} from "../redux/actions/userAction";

import XLSX from 'xlsx'
import DownloadIcon from '@mui/icons-material/Download';
import { CompanyDataComponent, LoadingComponent } from '../components'
import { defaultCompanyArr } from '../constants/defaultArray';
import { Link, useNavigate } from "react-router-dom";
import { FilterItemRender } from "../components/Common/index";
import {
  Button,
  Tabs,
  Tab,
  Typography,
  Box
} from "@mui/material";
import PropTypes from 'prop-types';
import moment from 'moment';
import CompanyDataComponent2 from "../components/Tables/CompanyDataComponent2";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Homepage = () => {

  const Navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const { data: resultData, loading: resultDataLoading } = useSelector((state) => state.allCompanies);
  const { data2: resultData2, loading: resultDataLoading2 } = useSelector((state) => state.allCompanies2);
  const { data: filterInputMaster, loading: filterInputLoading } = useSelector((state) => state.resultFilterInput);

  const [tableColumns, setTableColumns] = useState(null)
  const [TableTotalData, setTableTotalData] = useState(null)
  const [tableBodyData, setTableBodyData] = useState(null)
  const [tableColumns2, setTableColumns2] = useState(null)
  const [tableBodyData2, setTableBodyData2] = useState(null)

  const [ShowCheckboxButton, setShowCheckboxButton] = useState(false);
  
  const [ShowColumns, setShowColumns] = useState(null);
  const [FilterData, setFilterData] = useState(null)

  const [ShowColumns2, setShowColumns2] = useState(null);
  const [FilterData2, setFilterData2] = useState(null);

  const [showFilter, setShowFilter] = useState(false)

  useEffect(() => {
    if (resultDataLoading) {


      
    const topLabels =

    {
      "Market_Cap": {
        "label": "Market Cap",
        "value1": "",
        "value2": ""
      },
      "date_range": {
        "label": "Date Range",
        "value1": moment().add('-1', 'days').format('MM/DD/YYYY'),
        "value2": moment().format('MM/DD/YYYY') 
      },
      "LTP": {
        "label": "LTP",
        "value1": "",
        "value2": ""
      },
      "TTM_P_B": {
        "label": "TTM (P/B)",
        "value1": "",
        "value2": ""
      },
      "TTM_P_E": {
        "label": "TTM (P/E)",
        "value1": "",
        "value2": ""
      },
      "ROCE": {
        "label": "ROCE",
        "value1": "",
        "value2": ""
      },
      "TTM_Sales_Abs": {
        "label": "TTM_Sales_Abs",
        "value1": "",
        "value2": ""
      },
      "TTM_PAT_Abs": {
        "label": "TTM_PAT_Abs",
        "value1": "",
        "value2": ""
      },
      "Sales_YOY": {
        "label": "Sales_YOY",
        "value1": "",
        "value2": ""
      },
      "Sales_QOQ": {
        "label": "Sales_QOQ",
        "value1": "",
        "value2": ""
      },
      "EBDITA_YOY": {
        "label": "EBDITA_YOY",
        "value1": "",
        "value2": ""
      },
      "EBDITA_QOQ": {
        "label": "EBDITA_QOQ",
        "value1": "",
        "value2": ""
      },
      "PAT_YOY": {
        "label": "PAT_YOY",
        "value1": "",
        "value2": ""
      },
      "PAT_QOQ": {
        "label": "PAT_QOQ",
        "value1": "",
        "value2": ""
      },
      "GROSS_PROFIT_YOY": {
        "label": "GROSS_PROFIT_YOY",
        "value1": "",
        "value2": ""
      },
      "GROSS_PROFIT_QOQ": {
        "label": "GROSS_PROFIT_QOQ",
        "value1": "",
        "value2": ""
      },
      "EBDITA_TO": {
        "label": "EBIDTA Margin (%) - To +",
        "value1": "",
        "value2": ""
      },
      "PAT_TO": {
        "label": "PAT-TO +",
        "value1": "",
        "value2": ""
      },
      "ColorCode": {
        "label": "Color Code",
        "value1": "",
        "value2": ""
      },
      "sectors": {
        "label": "Sector",
        "value1": "",
        "value2": ""
      },
      "industry": {
        "label": "Industry",
        "value1": "",
        "value2": ""
      },
      "company": {
        "label": "Company",
        "value1": "",
        "value2": ""
      },
      "portfolio": {
        "label": "Portfolio",
        "value1": "",
        "value2": ""
      }
    };

    dispatch(resultFilterInputAction(topLabels));


      dispatch(allCompanyData(defaultCompanyArr)); 
      dispatch(companyMasterAction());
      dispatch(sectorMasterAction());
      dispatch(industryMasterAction());
      dispatch(turnAroundMasterAction());
      dispatch(allCompanyData2(defaultCompanyArr));
    }
    if (resultDataLoading2) {
    }
  }, [])


  useEffect(() => {
    // Navigate("/vdr")

    if (!resultDataLoading) {
      const totalData = resultData.Total;
      var arra = {};
      var a00 = 0;
      Object.keys(totalData[0]).map((key, i)=>{
        if (key !== '$id') {
          const element00 = totalData[0];
          arra['accessor_' + (i-1)] = element00[key];
        }
      });
      console.log('arra >>> ', arra);
      setTableTotalData(arra);
      const tableHead = resultData._Headers;
      var stuff = [];
      if (tableHead) {
        var a1 = 0;
        var mTitle = ' ';
        var width = 90;
        var mColArr = [];
        var resHeaderArr = tableHead.map((resHeads) => {

          let subColArr = [];
          var subCol = [];
          var sticky = null;
          if (resHeads.type === "0") {
            width = 150;
            if (a1 === 2) {
              width = 90;
            }
            let cols = {
              id: 'accessor_' + a1,
              label: ((resHeads.ColumnName).replace('&nbsp;', ' ')),
              minWidth: width,
              maxWidth: width,
              sticky: sticky,
              align: 'canter',
              hideCheck: false,
            }

            a1++;
            subColArr.push(cols);
            sticky = true;
          } else {
            width = 90;
            mTitle = resHeads.ColumnName;
            subCol = resHeads.SubColumn;
            // console.log('resHeads >> '+JSON.stringify(resHeads));
            var d10 = {};
            for (const key in subCol) {
              if (Object.hasOwnProperty.call(subCol, key)) {
                var style = {};
                if (key === 'Column5') {
                  style = {
                    'borderRight': '2px solid #ddd !important'
                  };
                }

                if (key !== '$id') {
                  const element = subCol[key];
                  var d10 = {...d10, ['accessor_' + a1]: true }

                  let cols = {
                    // id: key,
                    id: 'accessor_' + a1,
                    label: element,
                    minWidth: width,
                    maxWidth: width,
                    sticky: sticky,
                    align: 'canter',
                    hideCheck: true,
                    style: style,

                  }
                  subColArr.push(cols);
                  a1++;
                }

              }
            }
            stuff[mTitle] = d10
            // stuff.push({});
          }
          // console.log('d1 >> ', d1);

          var bgColor = 'th';

          var hideCheck = false;
          if (a1 !== 1) {
            var hideCheck = true;
          }
          var mCol = {
            id: mTitle,
            label: mTitle,
            minWidth: width,
            align: 'canter',
            sticky: sticky,
            hideCheck: hideCheck,
            headerClassName: bgColor,
            columns: subColArr,
          }

          mColArr.push(mCol);

        });
        setTableColumns(mColArr)

      }

      const tableBody = resultData.Data;
      var allRowsData = [];
       var stuff1 = {
        CompanyID:true,
        IndustryID:true,
        SectorID:true,
        CompanyDetail:true,
        accessor_0:true,
        accessor_1:true,
        accessor_2:true,
        accessor_3:true,
        accessor_4:true,
        accessor_5:true,
        accessor_6:true,
        accessor_7:true,
        accessor_8:true,
        accessor_9:true,
        accessor_10:true,
        accessor_11:true,
        accessor_12:true,
        accessor_13:true,
        accessor_14:true,
        accessor_15:true,
        accessor_16:true,
        accessor_17:true,
        accessor_18:true,
        accessor_19:true,
        accessor_20:true,
        accessor_21:true,
        accessor_22:true,
        accessor_23:true,
        accessor_24:true,
        accessor_25:true,
        accessor_26:true,
        accessor_27:true,
        accessor_28:true,
        accessor_29:true,
        accessor_30:true,
        accessor_31:true,
        accessor_32:true
      };

      // console.log('stuff >>>>>> ', stuff);
      setShowColumns(stuff);

      tableBody.map((resBody) => {
        var singleRow = {
          "CompanyID": resBody._MainHeaders.CompanyID,
          "IndustryID": resBody._MainHeaders.IndustryID,
          "SectorID": resBody._MainHeaders.SectorID,
          "CompanyDetail": resBody._MainHeaders._CompanyDetail,
          "accessor_0": resBody._MainHeaders._CompanyDetail.Company_Name,
          "accessor_1": resBody._MainHeaders.Sector,
          "accessor_2": resBody._MainHeaders.MarketCap ? parseFloat(resBody._MainHeaders.MarketCap) : 0,
          "accessor_3": resBody._sales.S1Q ? parseFloat(resBody._sales.S1Q) : 0,
          "accessor_4": resBody._sales.S2Q ? parseFloat(resBody._sales.S2Q) : 0,
          "accessor_5": resBody._sales.S3Q ? parseFloat(resBody._sales.S3Q) : 0,
          "accessor_6": resBody._sales.S4Q ? parseFloat(resBody._sales.S4Q) : 0,
          "accessor_7": resBody._sales.S5Q ? parseFloat(resBody._sales.S5Q) : 0,
          "accessor_8": resBody._GP.Gross_Profit_Cr1Q ? parseFloat(resBody._GP.Gross_Profit_Cr1Q) : 0,
          "accessor_9": resBody._GP.Gross_Profit_Cr2Q ? parseFloat(resBody._GP.Gross_Profit_Cr2Q) : 0,
          "accessor_10": resBody._GP.Gross_Profit_Cr3Q ? parseFloat(resBody._GP.Gross_Profit_Cr3Q) : 0,
          "accessor_11": resBody._GP.Gross_Profit_Cr4Q ? parseFloat(resBody._GP.Gross_Profit_Cr4Q) : 0,
          "accessor_12": resBody._GP.Gross_Profit_Cr5Q ? parseFloat(resBody._GP.Gross_Profit_Cr5Q) : 0,
          "accessor_13": resBody._GM.Gross_Profit_Margin1Q ? parseFloat(resBody._GM.Gross_Profit_Margin1Q) : 0,
          "accessor_14": resBody._GM.Gross_Profit_Margin2Q ? parseFloat(resBody._GM.Gross_Profit_Margin2Q) : 0,
          "accessor_15": resBody._GM.Gross_Profit_Margin3Q ? parseFloat(resBody._GM.Gross_Profit_Margin3Q) : 0,
          "accessor_16": resBody._GM.Gross_Profit_Margin4Q ? parseFloat(resBody._GM.Gross_Profit_Margin4Q) : 0,
          "accessor_17": resBody._GM.Gross_Profit_Margin5Q ? parseFloat(resBody._GM.Gross_Profit_Margin5Q) : 0,
          "accessor_18": resBody._Ebidta.EBDITA_Cr1Q ? parseFloat(resBody._Ebidta.EBDITA_Cr1Q) : 0,
          "accessor_19": resBody._Ebidta.EBDITA_Cr2Q ? parseFloat(resBody._Ebidta.EBDITA_Cr2Q) : 0,
          "accessor_20": resBody._Ebidta.EBDITA_Cr3Q ? parseFloat(resBody._Ebidta.EBDITA_Cr3Q) : 0,
          "accessor_21": resBody._Ebidta.EBDITA_Cr4Q ? parseFloat(resBody._Ebidta.EBDITA_Cr4Q) : 0,
          "accessor_22": resBody._Ebidta.EBDITA_Cr5Q ? parseFloat(resBody._Ebidta.EBDITA_Cr5Q) : 0,
          "accessor_23": resBody._EM.EBDITA_Margin_Per1Q ? parseFloat(resBody._EM.EBDITA_Margin_Per1Q) : 0,
          "accessor_24": resBody._EM.EBDITA_Margin_Per2Q ? parseFloat(resBody._EM.EBDITA_Margin_Per2Q) : 0,
          "accessor_25": resBody._EM.EBDITA_Margin_Per3Q ? parseFloat(resBody._EM.EBDITA_Margin_Per3Q) : 0,
          "accessor_26": resBody._EM.EBDITA_Margin_Per4Q ? parseFloat(resBody._EM.EBDITA_Margin_Per4Q) : 0,
          "accessor_27": resBody._EM.EBDITA_Margin_Per5Q ? parseFloat(resBody._EM.EBDITA_Margin_Per5Q) : 0,
          "accessor_28": resBody._pat.PAT_Cr1Q ? parseFloat(resBody._pat.PAT_Cr1Q) : 0,
          "accessor_29": resBody._pat.PAT_Cr2Q ? parseFloat(resBody._pat.PAT_Cr2Q) : 0,
          "accessor_30": resBody._pat.PAT_Cr3Q ? parseFloat(resBody._pat.PAT_Cr3Q) : 0,
          "accessor_31": resBody._pat.PAT_Cr4Q ? parseFloat(resBody._pat.PAT_Cr4Q) : 0,
          "accessor_32": resBody._pat.PAT_Cr5Q ? parseFloat(resBody._pat.PAT_Cr5Q) : 0,
        }
        allRowsData.push(singleRow);

      });
      setTableBodyData(allRowsData);
    }
  }, [dispatch, resultDataLoading])


  const [ActiveTab, setActiveTab] = useState(0);

  const changeTab = (val) => {
    setActiveTab(val)
  }


  useEffect(() => {
    if (!resultDataLoading2) {
      if (!resultDataLoading) {
        const tableHead = resultData2._Headers;
        let a1 = 0;
        var mColArr = [];
        if(tableHead){
        tableHead.map((resHeads) => {

          var hideCheck = false;
          if (a1 !== 1) {
            var hideCheck = true;
          }
          var stickyLeft = 0;
          var stuff = [];
          Object.keys(resHeads).forEach(key => {

            var label = resHeads[key];
            // var show_status = resHeads[key].show_status;
            // console.log(key);
            // if (show_status) {
            var width = 100;
            var sticky = false;
            if (key === 'Company_Name' || key === 'Sector' || key === 'MarketCap') {
              width = 150;
              sticky = true;
            }

            if (key !== '$id' && key !== 'AccordCode') {
              
              stuff[key] = true;
              var mCol = {
                id: key,
                label: label,
                stickyLeft: stickyLeft,
                minWidth: width,
                maxWidth: width,
                align: 'canter',
                hideCheck: hideCheck,
                sticky: sticky,
              }
              mColArr.push(mCol);
            }
            // }
            a1++;
          })
          // console.log('stuff >>>> ', stuff);
          setShowColumns(stuff);
        });

        setTableColumns2(mColArr);
      }
        // console.log('resultDataLoading2 >>> ', tableHead);

      }


      const tableBody = resultData2.Data;

      var allRowsData = [];
      if (Array.isArray(tableBody)) {
        tableBody.map((resBody) => {

          var singleRow = {};
          Object.keys(resBody).forEach(key => {
            singleRow[key] = resBody[key];
          })

          allRowsData.push(singleRow);

        });
      }
      console.log('allRowsData >>>> ', allRowsData);
      setTableBodyData2(allRowsData);
 
    }

  }, [dispatch, resultDataLoading2]);


      
  const ExportData = () => {

    setShowCheckboxButton(true);
    setTimeout(() => {
      // expBtn.current.children[0].click();
      const wb = XLSX.utils.book_new(); // book
      // let wb = XLSX.utils.table_to_book(document.getElementById('table-to-xls'));
      let dd = document.getElementById('table-to-xls');
      // console.log(dd);
      let sh = XLSX.utils.table_to_sheet(document.getElementById('table-to-xls'));
      // console.log('sh', sh);
      XLSX.utils.book_append_sheet(wb, sh, "Abs Result");
      let sh2 = XLSX.utils.table_to_sheet(document.getElementById('table-to-xls2'));
      XLSX.utils.book_append_sheet(wb, sh2, "Result (%)");
      XLSX.writeFile(wb, "Result_Data.xlsx");
    }
    , 2000);
    setTimeout(() => {
      setShowCheckboxButton(false);
    }
    , 3000);
    
  
 }


  const removeItem = (itemKey) => {
    const topLabels = filterInputMaster;
    let topLabels1 = null;
    let topLabels2 = null;
    Object.keys(topLabels).map((key) => {
      if (itemKey == key) {
        topLabels1 = topLabels[itemKey];
        topLabels1 = { ...topLabels1, value1: '', value2: '' };
        topLabels[itemKey] = topLabels1;
      }
    })

    const filterArray = resultFilters(topLabels);

    dispatch(resultFilterInputAction(topLabels));
    dispatch(allCompanyData(filterArray));
    dispatch(allCompanyData2(filterArray));


  }
  const refreshPage = () => {
    window.location.reload(false);
  }


  return (
    <>
      <div className="filterContainer" >
        <div className="fRow">
          {
            Object.keys(filterInputMaster).map((key) => (
              <>
                {<FilterItemRender showFilter={showFilter} setShowFilter={setShowFilter} keys={key} itemData={filterInputMaster[key]} onClick={() => removeItem(key)} />}
              </>
            ))
          }
        </div>
        {
          showFilter ?
            <div>
              <Button size='small' variant="contained" onClick={() => refreshPage()} >
                Reset
              </Button>
            </div>
            :
            null
        }
      </div>

      <Box sx={{ width: '100%' }} className="data-room1">
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display:'flex', justifyContent:'space-between', alignItems:'center' }}>
          <Box  sx={{ display:'flex', justifyContent:'flex-start', alignItems:'center' }}>
            <Typography sx={{ fontSize: '0.85rem', lineHeight:1 }} onClick={() => changeTab(0)} className={`cTabBtn ${ActiveTab == 0 ? 'active' : ''}`} >
              Abs Result
            </Typography>
            <Typography sx={{ fontSize: '0.85rem', lineHeight:1 }} onClick={() => changeTab(1)} className={`cTabBtn ${ActiveTab == 1 ? 'active' : ''}`} >
              Result (%)
            </Typography>
          </Box>
          <Box>
            <Button variant="contained" sx={{ backgroundColor:'#f37b22' }} onClick={()=>ExportData()}><DownloadIcon /></Button>
          </Box>
        </Box>
        <Box>
          <Box sx={{ 
            display: ActiveTab == 0 ? "unset" : "none"
           }}>
            <CompanyDataComponent
              ShowColumns={ShowColumns} 
              setShowColumns={setShowColumns} 
              ShowCheckboxButton={ShowCheckboxButton} 
              setShowCheckboxButton={setShowCheckboxButton} 
              FilterData={FilterData} 
              setFilterData={setFilterData} 
              resultDataLoading={resultDataLoading} 
              tableColumns={tableColumns} 
              resultData={tableBodyData} 
              TableTotalData={TableTotalData} 
            />
          </Box>
          <Box sx={{ 
            display: ActiveTab == 1 ? "unset" : "none"
           }}>
            <CompanyDataComponent2
              ShowColumns={ShowColumns2} 
              setShowColumns={setShowColumns2}
              ShowCheckboxButton={ShowCheckboxButton} 
              setShowCheckboxButton={setShowCheckboxButton} 
              FilterData={FilterData2} 
              setFilterData={setFilterData2}


              resultDataLoading={resultDataLoading2}
              tableColumns={tableColumns2}
              resultData={tableBodyData2}
            />
          </Box>
        </Box>
      </Box>

    </>
  )
}

export default Homepage