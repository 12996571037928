
import React, { useEffect, useState } from "react";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
    Box, Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,

    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Chip,
    Stack,

} from "@mui/material";

import { purple } from '@mui/material/colors';


import AvatarEditor from 'react-avatar-editor'

import DrawerContainer from "../Common/DrawerContainer";
import { GroupsAdd, CompanyAdd, UpdateImage, VideoAdd, MediaGroupsAdd, SegmentAdd, SegmentAssign } from "../Forms";
import { useDispatch, useSelector } from "react-redux";
import { mediaModelAction, segmentAction, segmentAssignAction, vdrCompanyAction, vdrGroupAction, vdrMediaAction, vdrMediaGroupAction } from "../../redux/actions/VdrAction";
import { SortDataPage } from "../../pages/VDR";
import { SORT_DATA_REQUEST } from "../../constants/VdrConstants";
import { ideaTypeTitle, mediaInput, recommendationType } from "../../constants/helpers";
import moment from "moment";
import MediaModel from "../SingleCompanyContainer/MediaSection_old/MediaModel";
import { segmentAddJson } from "../../constants/defaultArray";
export default function AccordionNested({
    groupsData,
    levelsData,
    companiesData
}) {

    const [stateDrawer, setStateDrawer] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const dispatch = useDispatch();

    const [anchor, setAnchor] = React.useState('bottom');

    const [groupData, setGroupData] = React.useState(null);
    const [videosList, setVideosList] = React.useState(null);
    const [companyData, setCompanyData] = React.useState(null);

    const [openForm, setOpenForm] = React.useState(null);
    const [parentGroup, setParentGroup] = React.useState(null);

    const [levelType, setLevelType] = React.useState(0);
    const [inputType, setInputType] = React.useState(0);

    const [pointerEvent, setPointerEvent] = React.useState("auto");

    const { data: vdrMediaData, loading: vdrMediaLoading } = useSelector((state) => state.vdrMediaReducer);
    const { data: vdrMediaGroupData, loading: vdrMediaGroupLoading } = useSelector((state) => state.vdrMediaGroupReducer);
    
    
    const { data: SegmentData, loading: SegmentLoading } = useSelector((state) => state.SegmentReducer);

    const {
        data: DefaultMasterData, loading:DefaultMasterLoading
    } = useSelector(state=>state.DefaultMasterReducer)
    useEffect(() => {
    }, [DefaultMasterLoading])
    
    const filterGroupsFromLevel = (levelId) => {
        console.log('levelId >>>>> ', levelId)
        if (groupsData) {
            return groupsData.filter((item) => levelId == item.level_type);
        }
    }

    const RecommendationTypeFun = (value) => {
        // return DefaultMasterData?.Recommendation[value]
    }

    const filterCompanyFromGroup = (groupId) => {
        if (companiesData) {
            return companiesData.filter((item) => groupId == item.groups_id);
        }
        return false
    }

    const getCompanyHtml = (groupId) => {
        if (companiesData) {
            return (<>
                <List key={groupId} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {filterCompanyFromGroup(groupId).map((item, index) => {
                        {/* console.log(item) */ }
                        let item_recommendation = DefaultMasterData?.Recommendation && DefaultMasterData?.Recommendation[item.recommendation];
                        let item_ideaType = DefaultMasterData?.IdeaType && DefaultMasterData?.IdeaType[item.company_type];

                        return (
                            <>

                                <ListItem
                                    key={index}
                                    disableGutters
                                    secondaryAction={
                                        <>
                                            <Box display={'inline-flex'} spacing={1} sx={{ marginRight: 4 }} >
                                                {
                                                    item.company_type ?
                                                        <>
                                                            <Chip variant="outlined" sx={{ marginLeft: 0.2, marginRight: 1 }} size="small" label={item_ideaType?.title} color="secondary" />
                                                        </>
                                                        :
                                                        null
                                                }
                                                {
                                                    item.recommendation ?
                                                        <>
                                                            <Chip variant="outlined" sx={{ marginLeft: 0.2, marginRight: 1, borderColor: item_recommendation?.color, color: item_recommendation?.color }} size="small" label={item_recommendation?.title} />
                                                        </>
                                                        :
                                                        null
                                                }
                                                {
                                                    item.recommendation_price ?
                                                        <>
                                                            <Chip variant="outlined" sx={{ marginLeft: 0.2, marginRight: 1, borderColor: '#e65100', color: '#e65100' }} size="small" label={item.recommendation_price} />
                                                        </>
                                                        :
                                                        null
                                                }
                                                {
                                                    item.recommendation_date ?
                                                        <>
                                                            <Chip variant="outlined" sx={{ marginLeft: 0.2, marginRight: 1, borderColor: '#001000', color: '#001000' }} size="small" label={moment(item.recommendation_date).format('DD MMM YYYY')} />
                                                        </>
                                                        :
                                                        null
                                                }
                                            </Box>

                                            <Button onClick={(e) => [
                                                toggleDrawer(anchor, true)(e),
                                                setOpenForm('company'),
                                                setCompanyData(item),
                                                // setParentGroup(item),
                                                setInputType(1),
                                            ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="primary" >
                                                Edit Details
                                            </Button>
                                            <Button
                                                onClick={(e) => [
                                                    toggleDrawer(anchor, true)(e),
                                                    setOpenForm('update-logo'),
                                                    setCompanyData(item),
                                                    setInputType(1),
                                                ]}
                                                size={'small'}
                                                sx={{ margin: '0 5px', fontSize: '0.6rem' }}
                                                variant="contained"
                                                color={"info"}
                                            >
                                                Update Logo
                                            </Button>
                                            <Button onClick={(e) => [dialogClickOpen(), setRemoveItemData(item), setRemoveItemType('company')]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="error" >Remove Company</Button>
                                        </>
                                    }
                                >
                                    <ListItemText primary={`${item.company_name}`} />
                                </ListItem>
                                <Divider />
                            </>
                        )
                    })}
                </List>
            </>)
        }
    }


    useEffect(() => {
        if(SegmentLoading){
            dispatch(segmentAction(segmentAddJson));
        }
    }, [SegmentLoading] )


    useEffect(() => {
        if (vdrMediaLoading) {
            // var videoParams = {
            //     "userid": 1,
            //     "videoCode": "",
            //     "videoId": "",
            //     "videoType": "",
            //     "videoTitle": "",
            //     "videoDescription": "",
            //     "DocumentType": "Common",
            //     "isPinned": true,
            //     "groupId": "",
            //     "group_name": "",
            //     "isFeatured": true,
            //     "inputType": 2
            // }
            dispatch(vdrMediaAction(mediaInput));
        }
    }, [dispatch, vdrMediaLoading]);


    const [VideoGroupData, setVideoGroupData] = React.useState([]);
    const [PinnedVideos, setPinnedVideos] = React.useState(null);
    
    let pinVid = [];

    const updateVideoPinStatus = (para1, para2="") => {
        // para1, para2
        var params = {
            ...para1,
            Inputtype: 1,
        }
        
        dispatch(vdrMediaAction(params));
        // window.location.reload();
    }


    useEffect(() => {
        if (vdrMediaGroupLoading) {
            var videoParams = {
                "status": 1,
                "inputType": 3
            }
            dispatch(vdrMediaGroupAction(videoParams));
        }
        if (!vdrMediaGroupLoading) {
            setVideoGroupData(vdrMediaGroupData.Data)
        }
    }, [dispatch, vdrMediaGroupLoading]);



    const getVideoHtml = (groupId, type) => {
        if (vdrMediaData) {
            let videosListData = null;
            videosListData = vdrMediaData.Data && vdrMediaData.Data.filter((items) => { if (items.DocumentType === "Common" && items.isPinned !== true) return items }).sort((a,b)=>{ return a.item_order - b.item_order });
            if(type === 'pinned'){
                videosListData = vdrMediaData.Data && vdrMediaData.Data.filter((items) => { if (items.isPinned === true) return items }).sort((a,b)=>{ return a.item_order - b.item_order });
            }
            
            return (<>
                <List key={groupId} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {videosListData && videosListData.map((item, index) => {
                        
                        if(type === 'pinned'){
                            pinVid.push(item?.videoId);
                        }

                        if (groupId == item.groupId || type === 'pinned') {
                            console.log('groupId, type ??',  groupId, type);

                            return (
                                <>
                                    <ListItem
                                        key={index}
                                        disableGutters
                                        secondaryAction={
                                            <>
                                                <Box display={'inline-flex'} spacing={1} sx={{ marginRight: 4 }} >
                                                {
                                                    item.isPinned === true ?
                                                        <>
                                                            {/* <Chip variant="outlined" sx={{ marginLeft: 0.2, marginRight: 1 }} size="small" label={'Remove Pin'} color="error" />*/}
                                                            <Button onClick={()=>updateVideoPinStatus({...item, isPinned:false})} variant="outlined" sx={{ marginLeft: 0.2, marginRight: 1, borderRadius:'50px', fontSize: 10, fontWeight:'600' }} size="small" color="error" >Remove Pin</Button>
                                                        </>
                                                        :
                                                        null
                                                }
                                                {
                                                    item.isPinned !== true ?
                                                        <>
                                                            {/* <Chip variant="outlined" sx={{ marginLeft: 0.2, marginRight: 1 }} size="small" label={'Pin this video'} /> */}
                                                            <Button onClick={()=>updateVideoPinStatus({...item, isPinned:true})} variant="outlined" sx={{ marginLeft: 0.2, marginRight: 1, borderRadius:'50px', fontSize: 10, fontWeight:'600' }} size="small" color="secondary" >Pin this video</Button>
                                                        </>
                                                        :
                                                        null
                                                }
                                                
                                            </Box>

                                                {/* <Button onClick={(e) => [
                                                toggleDrawer(anchor, true)(e),
                                                setOpenForm('video'),
                                                setCompanyData(item),
                                                setInputType(1),
                                            ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="primary" >
                                                Edit Video
                                            </Button> */}
                                                <Button onClick={() => dispatch(mediaModelAction({ isOpen: true, type: item.videoType, itemId: item.videoCode }))} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="warning" >Watch</Button>
                                                <Button onClick={(e) => [dialogClickOpen(), setRemoveItemData(item), setRemoveItemType('video')]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="error" >Remove Video</Button>
                                            </>
                                        }
                                    >
                                        <ListItemText primary={`${item?.videoTitle}`} />
                                    </ListItem>
                                    <Divider />
                                </>
                            )
                        }
                    })}
                </List>


                <MediaModel />
            </>)
        }
    }

    const filterGroupsFromParent = (parentId) => {
        if (groupsData) {
            return groupsData.filter((item) => parentId == item.parent_id);
        }
    }

    const toggleDrawer = (anchor, open) => (event) => {
        dispatch({ type: SORT_DATA_REQUEST })
        if (event) {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
        }
        setStateDrawer({ ...stateDrawer, [anchor]: open });
    };


    // console.log(companiesData);
    const singleGroupData = (itemData) => {
        toggleDrawer(anchor, true);
        setGroupData(itemData)
    }



    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [removeItemData, setRemoveItemData] = React.useState(null);
    const [removeItemType, setRemoveItemType] = React.useState(null);

    const [SortItemType, setSortItemType] = React.useState(null);
    const dialogClickOpen = () => {
        setDialogOpen(true);
    };

    const removeCurrentItem = (removeType) => {
        let params

        if (removeItemType === 'video') {
            params = {
                ...removeItemData,
                status: 0,
                Inputtype: 2,
                item_order: 0
            }
        } else {
            params = {
                ...removeItemData,
                status: 0,
                Inputtype: 3
            }
        }
        // console.log(params);
        // return ;
        delete params.$id
        if (removeItemType == 'company') {
            dispatch(vdrCompanyAction(params))
        }
        if (removeItemType == 'groups') {
            dispatch(vdrGroupAction(params))
        }
        if (removeItemType == 'video') {
            dispatch(vdrMediaAction(params))
        }
        if (removeItemType == 'mediaGroups') {
            dispatch(vdrMediaGroupAction(params))
        }

        window.location.reload();


    };

    const dialogClose = () => {
        setDialogOpen(false);
    };
    const removeSegment = (data) => {
        let data1 = {
            "segment_id": data.segment_id,
            "group_id": data.group_id,
            "assign_type": false,
        };
        dispatch(segmentAssignAction(data1));
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    };

    let subGrpIds = [];

    const subGroupsData = (item, segmentItem={}) => {
        let fListData = filterGroupsFromParent(item.group_id);
        

        return (
            <>
                {
                    fListData.map((subItem) => {
                        {/* console.log('segmentItem ', item.segment_id); */}
                        let assignBtn = true;
                        // if(Object.keys(segmentItem).length && segmentItem.segment_id != subItem.segment_id){
                        //     return 
                        // }
                        // if(Object.keys(segmentItem).length == 0 && subGrpIds.includes(subItem.group_id)){
                        //     {/* console.log('subGroupsData 2') */}
                        //     return 
                        // }
                        // if(Object.keys(segmentItem).length && !subGrpIds.includes(subItem.group_id)){
                        //     assignBtn = false;
                        //     subGrpIds.push(subItem.group_id);
                        // }
                        

                        return (
                            <Accordion key={subItem.group_id} sx={{ marginTop: 1 }} className="accordionBorder">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Box style={{
                                        justifyContent: 'space-between',
                                        display: 'flex',
                                        width: '100%'
                                    }}>
                                        {subItem.group_name}
                                        
                                        {/* <span style={{ color:'red' }}>{subItem.segment_label}<br />{subItem.segment_id}</span> */}
                                        <Box>
                                            {
                                                subItem.sticky ?
                                                    <>
                                                        <Chip variant="outlined" sx={{ marginLeft: 0.2, marginRight: 1 }} size="small" label='Divider' />
                                                    </>
                                                    :
                                                    null
                                            }
                                            {
                                                subItem.enable_number ?
                                                    <>
                                                        <Chip variant="outlined" sx={{ marginLeft: 0.2, marginRight: 1 }} size="small" label='Enable Number' />
                                                    </>
                                                    :
                                                    null
                                            }
                                            {/* {
                                                assignBtn === true ?
                                                    <Button 
                                                        onClick={(e) => [
                                                            toggleDrawer(anchor, true)(e),
                                                            setOpenForm('segment_assign'),
                                                            setParentGroup(subItem),
                                                        ]}
                                                    size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="outlined" color="primary" >Assign Segment</Button>
                                                :
                                            <Button 
                                                onClick={(e) => removeSegment({segment_id:subItem?.segment_id, group_id:subItem?.group_id})}
                                                size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="outlined" color="error" >Remove Segment</Button>
                                            } */}

                                            
                                            <Button onClick={(e) => [
                                                setGroupData(subItem),
                                                toggleDrawer(anchor, true)(e),
                                                setOpenForm('group'),
                                                setParentGroup(null),
                                                setLevelType(2),
                                                setInputType(1),
                                            ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="primary" >Edit Group</Button>
                                            <Button onClick={(e) => [
                                                toggleDrawer(anchor, true)(e),
                                                setOpenForm('company'),
                                                setParentGroup(subItem),
                                                setInputType(0),
                                                setCompanyData(null),
                                            ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="success" >Add Company</Button>
                                            <Button onClick={(e) => [
                                                setGroupData(null),
                                                toggleDrawer(anchor, true)(e),
                                                setOpenForm('group'),
                                                setLevelType(3),
                                                setParentGroup(subItem),
                                                setInputType(0),
                                            ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="warning" >Add Sub Group</Button>
                                            <Button onClick={(e) => [dialogClickOpen(), setRemoveItemData(subItem), setRemoveItemType('groups')]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="error" >Remove Group</Button>
                                            {
                                                filterGroupsFromParent(subItem.group_id).length > 1
                                                    ?
                                                    <>
                                                        <Button
                                                            onClick={(e) => [
                                                                setGroupData(null),
                                                                toggleDrawer(anchor, true)(e),
                                                                setOpenForm('sortData'),
                                                                setParentGroup(subItem),
                                                                setSortItemType('groups'),
                                                            ]}
                                                            size={'small'}
                                                            sx={{ margin: '0 5px', fontSize: '0.6rem', backgroundColor: '#333' }}
                                                            variant="contained"
                                                        >
                                                            Sort Group
                                                        </Button>
                                                    </>
                                                    :
                                                    null
                                            }

                                            {
                                                filterCompanyFromGroup(subItem.group_id).length > 1
                                                    ?
                                                    <>
                                                        <Button
                                                            onClick={(e) => [
                                                                setGroupData(null),
                                                                toggleDrawer(anchor, true)(e),
                                                                setOpenForm('sortData'),
                                                                setParentGroup(subItem),
                                                                setSortItemType('company'),
                                                            ]}
                                                            size={'small'}
                                                            sx={{ margin: '0 5px', fontSize: '0.6rem', backgroundColor: '#333' }}
                                                            variant="contained"
                                                        >
                                                            Sort Company
                                                        </Button>
                                                    </>
                                                    :
                                                    null
                                            }


                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        filterGroupsFromParent(subItem.group_id).map((sub2Item) => {

                                            return (
                                                <Accordion key={sub2Item.group_id} sx={{ marginTop: 1 }} className="accordionBorder">
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                    >
                                                        <Box style={{
                                                            justifyContent: 'space-between',
                                                            display: 'flex',
                                                            width: '100%'
                                                        }}>
                                                            {sub2Item.group_name}
                                                            <Box >
                                                                {
                                                                    sub2Item.sticky ?
                                                                        <>
                                                                            <Chip variant="outlined" sx={{ marginLeft: 0.2, marginRight: 1 }} size="small" label='Sticky' />
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    sub2Item.enable_number ?
                                                                        <>
                                                                            <Chip variant="outlined" sx={{ marginLeft: 0.2, marginRight: 1 }} size="small" label='Enable Number' />
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                                <Button onClick={(e) => [
                                                                    setGroupData(sub2Item),
                                                                    toggleDrawer(anchor, true)(e),
                                                                    setOpenForm('group'),
                                                                    setParentGroup(null),
                                                                    setLevelType(3),
                                                                    setInputType(1),
                                                                ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="primary" >Edit Group</Button>
                                                                <Button onClick={(e) => [
                                                                    toggleDrawer(anchor, true)(e),
                                                                    setOpenForm('company'),
                                                                    setParentGroup(sub2Item),
                                                                    setInputType(0),
                                                                    setCompanyData(null),
                                                                ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="success" >Add Company</Button>
                                                                {/* <Button onClick={(e) => [
                                                                    setGroupData(null),
                                                                    toggleDrawer(anchor, true)(e),
                                                                    setOpenForm('group'),
                                                                    setLevelType(3),
                                                                    setParentGroup(sub2Item),
                                                                    setInputType(0),
                                                                ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="warning" >Add Sub Group</Button> */}
                                                                <Button onClick={(e) => [dialogClickOpen(), setRemoveItemData(sub2Item), setRemoveItemType('groups')]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="error" >Remove Group</Button>

                                                                {
                                                                    filterCompanyFromGroup(sub2Item.group_id).length > 1
                                                                        ?
                                                                        <Button
                                                                            onClick={(e) => [
                                                                                setGroupData(null),
                                                                                toggleDrawer(anchor, true)(e),
                                                                                setOpenForm('sortData'),
                                                                                setParentGroup(sub2Item),
                                                                                setSortItemType('company'),
                                                                            ]}
                                                                            size={'small'}
                                                                            sx={{ margin: '0 5px', fontSize: '0.6rem', backgroundColor: '#333' }}
                                                                            variant="contained"
                                                                        >
                                                                            Sort Company
                                                                        </Button>
                                                                        :
                                                                        null
                                                                }



                                                            </Box>
                                                        </Box>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Box>
                                                            {
                                                                getCompanyHtml(sub2Item.group_id)
                                                            }
                                                        </Box>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })
                                    }
                                    <Box>
                                        {
                                            getCompanyHtml(subItem.group_id)
                                        }
                                    </Box>

                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }
            </>
        )
    };


    return (
        <>
            <DrawerContainer containerFor={openForm} stateDrawer={stateDrawer} setStateDrawer={setStateDrawer} toggleDrawer={toggleDrawer} anchor={anchor} >
                {
                    openForm === 'group'
                        ?
                        <GroupsAdd
                            levelType={levelType}
                            inputType={inputType}
                            groupData={groupData}
                            parentGroup={parentGroup}
                        />
                        :
                    openForm === 'mediaGroup'
                        ?
                        <MediaGroupsAdd
                            levelType={levelType}
                            inputType={inputType}
                            groupData={groupData}
                            parentGroup={parentGroup}
                        />
                        :
                        openForm === 'company' ?
                            <CompanyAdd
                                levelType={levelType}
                                inputType={inputType}
                                parentGroup={parentGroup}
                                singleCompanyData={companyData}
                            />
                            :
                            openForm === 'video' ?
                                <VideoAdd
                                    levelType={levelType}
                                    inputType={inputType}
                                    parentGroup={parentGroup}
                                    singleCompanyData={companyData}
                                />
                                :
                                openForm === 'update-logo' ?
                                    <UpdateImage
                                        singleCompanyData={companyData}
                                        toggleDrawer={toggleDrawer(anchor, false)}
                                    />
                                    :
                                    openForm === 'sortData' ?
                                        <SortDataPage
                                            sortItemType={SortItemType}
                                            parentGroup={parentGroup}
                                            toggleDrawer={toggleDrawer(anchor, false)}
                                            typeFor="sortData"
                                            PinnedVideos={SortItemType === 'media' ? PinnedVideos : null}
                                        />
                                        :
                                    openForm === 'segment_add' ?
                                        <SegmentAdd
                                            groupId=""
                                            inputType={inputType}
                                        />
                                        :
                                    openForm === 'segment_assign' ?
                                        <SegmentAssign
                                            parentGroup={parentGroup}
                                            inputType={inputType}
                                        />
                                        :
                                        null
                }
            </DrawerContainer>



            <Dialog
                open={dialogOpen}
                onClose={dialogClose}
                maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure?, You want to Remove This {removeItemType == 'groups' ? 'Group' : removeItemType == 'mediaGroups' ? 'Media Group' : removeItemType == 'video' ? 'Video' : "Company"}<br />
                    "{
                        removeItemType == 'groups' || removeItemType == 'mediaGroups'
                            ?
                            removeItemData.group_name
                            :
                            removeItemType == 'video'
                                ?
                                removeItemData.videoTitle
                                :
                                removeItemData?.company_name
                    }"
                </DialogTitle>
                <DialogActions>
                    <Button variant="contained" size="small" color="error" onClick={dialogClose}>No</Button>
                    <Button variant="contained" size="small" color="success" onClick={removeCurrentItem} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>





            <Box>


                <Box
                    justifyContent={'end'}
                    display="flex"
                    sx={{ width: '100%' }}
                >
                    <Button onClick={(e) => [
                        setGroupData(null),
                        toggleDrawer(anchor, true)(e),
                        setOpenForm('group'),
                        setLevelType(1),
                        setInputType(0),
                    ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="warning" >Add New Group</Button>
                </Box>
                <div >
                    <Accordion key={0} sx={{ marginTop: 1 }} className="accordionBorder">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Box style={{
                                justifyContent: 'space-between',
                                display: 'flex',
                                width: '100%'
                            }}>
                                All Videos List
                                <Box>

                                    {/* <Button onClick={(e) => [
                                        setGroupData(sub2Item),
                                        toggleDrawer(anchor, true)(e),
                                        setOpenForm('group'),
                                        setParentGroup(null),
                                        setLevelType(3),
                                        setInputType(1),
                                    ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="primary" >Edit Group</Button> */}
                                    <Button onClick={(e) => [
                                        setGroupData(null),
                                        toggleDrawer(anchor, true)(e),
                                        setOpenForm('mediaGroup'),
                                        setParentGroup(null),
                                        setLevelType(1),
                                        setInputType(0),
                                    ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="success" >Add Group</Button>

                                    {/* <Button onClick={(e) => [dialogClickOpen(), setRemoveItemData(sub2Item), setRemoveItemType('groups')]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="error" >Remove Group</Button> */}

                                    <Button
                                        onClick={(e) => [
                                            setGroupData(null),
                                            toggleDrawer(anchor, true)(e),
                                            setOpenForm('sortData'),
                                            setParentGroup(1),
                                            setSortItemType('mediaGroup'),
                                        ]}
                                        size={'small'}
                                        sx={{ margin: '0 5px', fontSize: '0.6rem', backgroundColor: '#333' }}
                                        variant="contained"
                                    >
                                        Sort Group
                                    </Button>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box>



                            <Accordion key={'001'} defaultExpanded sx={{ marginTop: 1 }} className="accordionBorder">
                                            <AccordionSummary
                                                sx={{
                                                    pointerEvents: "none"
                                                }}

                                                expandIcon={<ExpandMoreIcon
                                                    sx={{
                                                        pointerEvents: "auto"
                                                    }} />}
                                            >
                                                <Box style={{
                                                    justifyContent: 'space-between',
                                                    display: 'flex',
                                                    width: '100%'
                                                }}

                                                    sx={{
                                                        pointerEvents: pointerEvent
                                                    }}

                                                >
                                                    <Typography >
                                                        Pinned Videos
                                                    </Typography>
                                                    <Box >
                                                        
                                                        <Button
                                                            onClick={(e) => [
                                                                setGroupData(null),
                                                                toggleDrawer(anchor, true)(e),
                                                                setOpenForm('sortData'),
                                                                setParentGroup({...VideoGroupData[0], group_id: 1, group_name:"Pinned Videos", is_pinned_sort:true}),
                                                                setSortItemType('media'),
                                                                setPinnedVideos(pinVid),
                                                            ]}
                                                            size={'small'}
                                                            sx={{ margin: '0 5px', fontSize: '0.6rem', backgroundColor: '#333' }}
                                                            variant="contained"
                                                        >
                                                            Sort Videos
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box>
                                                    {
                                                        getVideoHtml("", "pinned")
                                                    }
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>



                                {VideoGroupData && VideoGroupData.map((item, i) => {
                                    return (
                                        <Accordion key={i} defaultExpanded sx={{ marginTop: 1 }} className="accordionBorder">
                                            <AccordionSummary
                                                sx={{
                                                    pointerEvents: "none"
                                                }}

                                                expandIcon={<ExpandMoreIcon
                                                    sx={{
                                                        pointerEvents: "auto"
                                                    }} />}
                                            >
                                                <Box style={{
                                                    justifyContent: 'space-between',
                                                    display: 'flex',
                                                    width: '100%'
                                                }}

                                                    sx={{
                                                        pointerEvents: pointerEvent
                                                    }}

                                                >
                                                    <Typography >
                                                        {item.group_name}
                                                    </Typography>
                                                    <Box >
                                                        <Button onClick={(e) => [
                                                            setGroupData(item),
                                                            toggleDrawer(anchor, true)(e),
                                                            setOpenForm('mediaGroup'),
                                                            setParentGroup(null),
                                                            setLevelType(1),
                                                            setInputType(1),
                                                        ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="primary" >Edit Group</Button>
                                                        <Button onClick={(e) => [
                                                            toggleDrawer(anchor, true)(e),
                                                            setOpenForm('video'),
                                                            setParentGroup(item),
                                                            setInputType(0),
                                                            setCompanyData(null),
                                                        ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="success" >Add Video</Button>

                                                        <Button onClick={(e) => [dialogClickOpen(), setRemoveItemData(item), setRemoveItemType('mediaGroups')]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="error" >Remove Group</Button>
                                                        <Button
                                                            onClick={(e) => [
                                                                setGroupData(null),
                                                                toggleDrawer(anchor, true)(e),
                                                                setOpenForm('sortData'),
                                                                setParentGroup(item),
                                                                setSortItemType('media'),
                                                                setPinnedVideos(pinVid),
                                                            ]}
                                                            size={'small'}
                                                            sx={{ margin: '0 5px', fontSize: '0.6rem', backgroundColor: '#333' }}
                                                            variant="contained"
                                                        >
                                                            Sort Videos
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box>
                                                    {
                                                        getVideoHtml(item.group_id)
                                                    }
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </div>


















                { groupsData && filterGroupsFromLevel(1).map((item, i) => {
                    console.log('item >>>> item', item)
                    return (
                        <Accordion key={i} defaultExpanded sx={{ marginTop: 1 }} className="accordionBorder">
                            <AccordionSummary
                                sx={{
                                    pointerEvents: "none"
                                }}

                                expandIcon={<ExpandMoreIcon
                                    sx={{
                                        pointerEvents: "auto"
                                    }} />}
                            >
                                <Box style={{
                                    justifyContent: 'space-between',
                                    display: 'flex',
                                    width: '100%'
                                }}

                                    sx={{
                                        pointerEvents: pointerEvent
                                    }}

                                >
                                    <Typography >
                                        {item.group_name}
                                    </Typography>
                                    <Box >
                                        <Button onClick={(e) => [
                                            setGroupData(item),
                                            toggleDrawer(anchor, true)(e),
                                            setOpenForm('group'),
                                            setParentGroup(null),
                                            setLevelType(1),
                                            setInputType(1),
                                        ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="primary" >Edit Group</Button>
                                        <Button onClick={(e) => [
                                            toggleDrawer(anchor, true)(e),
                                            setOpenForm('company'),
                                            setParentGroup(item),
                                            setInputType(0),
                                        ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="success" >Add Company</Button>
                                        <Button onClick={(e) => [
                                            setGroupData(null),
                                            toggleDrawer(anchor, true)(e),
                                            setOpenForm('group'),
                                            setLevelType(2),
                                            setParentGroup(item),
                                            setInputType(0),
                                            setCompanyData(null),
                                        ]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="warning" >Add Sub Group</Button>
                                        <Button onClick={(e) => [dialogClickOpen(), setRemoveItemData(item), setRemoveItemType('groups')]} size={'small'} sx={{ margin: '0 5px', fontSize: '0.6rem' }} variant="contained" color="error" >Remove Group</Button>
                                        <Button
                                            onClick={(e) => [
                                                setGroupData(null),
                                                toggleDrawer(anchor, true)(e),
                                                setOpenForm('sortData'),
                                                setParentGroup(item),
                                                setSortItemType('groups'),
                                            ]}
                                            size={'small'}
                                            sx={{ margin: '0 5px', fontSize: '0.6rem', backgroundColor: '#333' }}
                                            variant="contained"
                                        >
                                            Sort Group
                                        </Button>
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                            {/* {
                                                SegmentData?.Data && SegmentData?.Data.length > 0 && SegmentData?.Data.sort((a, b)=> a.segment_position - b.segment_position ).map((segmentItem, segIndex)=>{
                                                    return (
                                                        <Box sx={{ 
                                                            marginBottom:'20px'
                                                         }}>
                                                            <Typography variant="h6" sx={{ fontSize:'1.3rem' }}>{segmentItem.segment_label}</Typography>
                                                            <>
                                                                {
                                                                    subGroupsData(item, segmentItem)
                                                                }
                                                            </>
                                                        </Box>
                                                    )
                                                })
                                            }

                                            <Divider sx={{ marginBottom: '10px', height:3, backgroundColor:'#303030' }} /> */}
                                            
                                            
                                            <Box>
                                                {/* <Typography variant="h6" sx={{ fontSize:'1.3rem' }}>Groups Without Segment</Typography> */}
                                                <>
                                                    {
                                                        subGroupsData(item)
                                                    }
                                                </>
                                            </Box>
                                            
                                

                                <Box>
                                    {
                                        getCompanyHtml(item.group_id)
                                    }
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>
        </>
    );
}
