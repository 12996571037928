import { Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import InCr from "./InCr";
import { useDispatch, useSelector } from "react-redux";
import { vdrForensicAction } from "../../../redux/actions/VdrAction";

const ShareholdingPatternComp = (props) => {
  const { companyId, isCD } = props;

  const {
    loading,
    firstLoading,
    data,
    msg,
    error
  } = useSelector(state => state.ForensicReducer);

  const dispatch = useDispatch();

  let typeData = "SH";
  useEffect(() => {
    if(loading){
      const params = {
        "companyID": companyId,
        "Type": typeData
      }
      dispatch(vdrForensicAction(params));
    }
    if(!loading){
      // console.log('data >>>> ', data);
    }
  }, [dispatch, props])
  
if(loading){
  return (
    <> 
      <CircularProgress />
    </>
  )
}
  

  return (
    <>
      {/* <button className='btn primary-btn'>Download Image</button> */}
      <Box className="valuation-box">
        <div className="box-head-sec">
        <div style={{ 
            display:'flex',
            width:'100%',
            justifyContent:'space-between',
            alignItems:'center',
           }}>
            {
          !isCD && (
            <div style={{ 
            display:'flex',
            alignItems:'end',
            gap:'.5rem'
           }}>
          <h3>Shareholding Pattern (%)</h3>
                {/* <InCr /> */}
            </div>
          )}

          </div>
          <div>
            {/* <button className="btn primary-btn-outline">Edit</button>
            <button className="btn primary-btn">Download Image</button> */}
          </div>
        </div>
        <div className="" style={{
          marginBottom:'14px'
        }}>
            {/* <div className="btn-group">
              <button className="btn active">Consolidate</button>
              <button className="btn">Standalone</button>
            </div> */}
        </div>
        {
          data && data.Data && data.Data.length > 0 && data.Data.map((res, i) => {
            
              let a1 = 0;
              const tableHead = res.header;
              var mColArr = [];
              tableHead.map((resHeads) => {

                var hideCheck = false;
                if (a1 !== 1) {
                  var hideCheck = true;
                }
                var stickyLeft = 0;

                var stuff = {};
                Object.keys(resHeads).forEach((key, i0) => {

                  var label = resHeads[key];
                  var show_status = resHeads[key];
                  if (label != null && label != "") {
                    var width = 120;
                    var sticky = false;
                    if (key == 'Company_Name' || key == 'Sector' || key == 'Industry') {
                      width = 150;
                      sticky = true;

                      hideCheck = false;
                    } else {
                      hideCheck = true;
                    }

                    if (key != '$id' && key != 'AccordCode') {


                      // var a0 = { key:key, show_status:true };
                      // a00.push(a0);
                      stuff[key] = true;
                      var mCol = {
                        id: key,
                        label: label,
                        stickyLeft: stickyLeft,
                        minWidth: width,
                        maxWidth: width,
                        align: 'canter',
                        hideCheck: hideCheck,
                        sticky: sticky,
                      }
                      mColArr.push(mCol);
                    }
                  }
                  a1++;
                })
              });

              const tableBody = res?.TableData;
              
              var allRowsData = [];
              tableBody.map((resBody) => {

                var singleRow = {};
                Object.keys(resBody).forEach(key => {
                  let col_val = resBody[key] ? resBody[key].trim() : " ";
                  singleRow[key] = col_val;
                })
                allRowsData.push(singleRow);
              });

              return (
                <>
                <div className="box-body-sec">
                    <table>
                      <thead>
                        <tr>
                          {
                            mColArr.map((item,i)=>{
                              return (
                                <td key={i}>{item.label}</td>
                              )
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                            allRowsData.map((row,i)=>{
                              return (
                                <tr>
                                  {
                                    mColArr.map((item,i)=>{
                                      let value = row[item.id]
                                      return (
                                        <td key={i}>{value}</td>
                                      )
                                    })
                                  }
                                </tr>
                              )
                            })
                          }
                        
                      </tbody>
                    </table>
                  </div>
                </>
              )

          })
        }
        
      </Box>
    </>
  );
};

export default ShareholdingPatternComp;
