import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    IconButton,
    ListSubheader,
    Typography,
    Alert,
    Stack,
    Button,
    Radio
} from '@mui/material'
import React, {useEffect, useState} from 'react'

import Checkbox from '@mui/material/Checkbox';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import { historicalTrendChooseField } from '../../../constants/defaultArray';

import { red } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux'
import CompanyAutoComplete from './CompanyAutoComplete';
import { companyMasterAction } from '../../../redux/actions/userAction';




const PeerToPeerTrendChooseField = (props) => {

    
    const { data, loading } = useSelector(state => state.companyMasterData);
    const dispatch = useDispatch();

    const { applyAction } = props;

    const [checked, setChecked] = React.useState([]);
    const [DisabledItem, setDisabledItem] = React.useState(false);
    const [ShowDiv, setShowDiv] = React.useState(false);
    const [ShowError, setShowError] = React.useState(false);

    const [SelectedCompanies, setSelectedCompanies] = useState([])

    const [Companies, setCompanies] = useState([]);

    const [selectedValue, setSelectedValue] = React.useState(null);

    const handleChange = (val) => {
        setSelectedValue(val);
    };
    const [AllCompanies, setAllCompanies] = useState([])

    useEffect(() => {
        if(loading){
            dispatch(companyMasterAction());
        }

        if (!loading) {
            console.log(data);
            var data1 = [];

            data.map((item) => {
                var d1 = { label: item.CompanyName, value: item.CompanyID };
                data1.push(d1);
            })

            data1.sort((a, b) => {
                var a1 = a.label.toLowerCase();
                var b1 = b.label.toLowerCase();
        
                if (a1 > b1) {
                    return 1
                } else
                    if (a1 < b1) {
                        return -1
                    } else {
                        return 0
                    }
            })

            setAllCompanies(data1);
        }
    }, [dispatch, loading])



    return (
        <>

            <Box
                sx={{
                    marginBottom: 2,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Box>
                    <Typography variant='subtitle1' >
                        Please select upto 5 companies and 1 chart type!
                    </Typography>
                </Box>
                <Box>
                    {
                        ShowDiv ?
                            <>
                                <>
                                
                                    <Button
                                        disabled={ !selectedValue || Companies.length == 0 > 5 ? true : false}
                                        variant='contained'
                                        size="small"
                                        color='success'
                                        onClick={() => { applyAction(selectedValue, Companies); setShowDiv(!ShowDiv); }}
                                    >
                                        Apply
                                    </Button>
                                    {/* <Button
                                        disabled={checked.length === 0 ? true : false}
                                        onClick={() => { setDisabledItem(false); setChecked([]); }}
                                        sx={{
                                            marginLeft: 0.5
                                        }}
                                        variant='contained'
                                        size="small"
                                        color='secondary'
                                    >
                                        Uncheck All
                                    </Button> */}
                                </>

                                <Button
                                    sx={{
                                        marginLeft: 0.5
                                    }}
                                    variant='contained'
                                    size="small"
                                    color='error'
                                    onClick={() => setShowDiv(!ShowDiv)}
                                >
                                    Cancel
                                </Button>



                            </>
                            :
                            <>
                                <Button
                                    variant='contained'
                                    size="small"
                                    onClick={() => setShowDiv(!ShowDiv)}
                                >
                                    Select Companies
                                </Button>
                            </>
                    }
                </Box>

            </Box>
            {
                ShowDiv ?
                    <>
                        <Divider />
                        <Box
                            sx={{
                                marginBottom: 2,
                            }} >
                            <Box >
                                <CompanyAutoComplete SelectedCompanies={SelectedCompanies} setSelectedCompanies={setSelectedCompanies} AllCompanies={AllCompanies}  Companies={Companies} setCompanies={setCompanies} />
                            </Box>
                            {
                                ShowError ?
                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                        <Alert
                                            severity="error"
                                            action={
                                                ""
                                            }
                                        >
                                            Please select minimum 1 chart.
                                        </Alert>
                                    </Stack>
                                    :
                                    null
                            }
                            {
                                DisabledItem ?
                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                        <Alert
                                            severity="error"
                                            action={
                                                ""
                                            }
                                        >
                                            You can select upto 5 companies and 1 chart type.
                                        </Alert>
                                    </Stack>
                                    :
                                    null
                            }

                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    margin: "0 0 1rem"
                                }}
                                justifyContent="space-between"
                                className="ChooseFieldContainer"
                            >
                                {
                                    historicalTrendChooseField().map((item, i) => {
                                        return (
                                            <Box className="ChooseFieldItem">
                                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                                    subheader={<ListSubheader sx={{ fontSize: 18 }}>{item.label}</ListSubheader>}
                                                >
                                                    {item?.columns.map((value, i1) => {
                                                        const labelId = `checkbox-list-label-${i1}`;
                                                        return (
                                                            <ListItem
                                                                key={value.title}
                                                                disablePadding
                                                            >
                                                                <ListItemButton role={undefined} onClick={() => handleChange(value.title)} dense>
                                                                    <ListItemIcon
                                                                        sx={{
                                                                            minWidth: '1rem',
                                                                        }}
                                                                    >
                                                                        <Radio
                                                                            sx={{
                                                                                padding: '0 0.3rem 0 0rem'
                                                                            }}
                                                                            checked={selectedValue === value.title}
                                                                            // onChange={handleChange}
                                                                            size="small"
                                                                            value={value.title}
                                                                            name="radio-buttons"
                                                                            inputProps={{ 'aria-label': 'A' }}
                                                                        />

                                                                        {/* <Checkbox
                                                                            sx={{
                                                                                padding: '0 0.3rem 0 0.8rem'
                                                                            }}
                                                                            edge="start"
                                                                            checked={checked.indexOf(value.title) !== -1}
                                                                            tabIndex={-1}
                                                                            disableRipple
                                                                            size="small"
                                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                                        /> */}
                                                                    </ListItemIcon>
                                                                    <ListItemText id={labelId}
                                                                        primary={<Typography fontSize={12} >{value.title}</Typography>}

                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                            </Box>
                                        )
                                    })
                                }


                            </Box>
                        </Box>
                    </>
                    :
                    null
            }
        </>
    )
}

export default PeerToPeerTrendChooseField