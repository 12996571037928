import React from 'react'
import MuiAutoComplete from './MuiAutoComplete';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const AutoCompleteComponent = ({ name, label, placeholder, control, options, ...props }) => {


    return (
        <>

            {
                label && <label className='sidebar-label'>{label}</label>
            }

            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        options={options || null}
                        
                        getOptionLabel={(option) => option.title}
                        {...props}
                        renderOption={(props1, option, { selected }) => (
                            <li {...props1}>
                                {option.title}
                            </li>
                        )}
                        fullWidth
                        style={{ width: '100%' }}
                        renderInput={(params) => (
                            <TextField {...params} size={'small'} className="" placeholder={placeholder} />
                        )}
                    />
                )}
            />

        </>
    )
}

export default AutoCompleteComponent