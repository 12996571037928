import { AiOutlineArrowUp } from "react-icons/ai"; 
import { Box, Button, Checkbox, CircularProgress, Tab, Tabs, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { useDispatch, useSelector } from 'react-redux';
import { NewsletterCommentAction,AddToMylistAction,RemoveToMylistAction, SingleNewsletterLikAction, VideoLikeAction, MyBlogListAction } from '../../../redux/actions/VdrAction';
import CommentList from './comment/List';
import { BiTrash } from "react-icons/bi";
import { crtUserId } from "../../../constants/helpers";
import { FiEye } from "react-icons/fi";
import PropTypes from 'prop-types';
import { GlobalContext } from "../../../context/GlobalContext";




function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const RightSection = (props) => {
  const {
    ActiveImage,
    setActiveImage,
    ActiveVideoNews,
    setActiveVideoNews,
    ActiveVideoItem,
    ActiveTag,
    setActiveVideoItem
  } = props;
  const { data: VideoLikeData, loading: VideoLikeLoading } = useSelector((state) => state.VideoLikeReducer);

  const { data: SingleNewsletterLikeData, loading: SingleNewsletterLikeLoading } = useSelector((state) => state.SingleNewsletterLikeReducer);

  const { data: NewsletterCommentData, loading: NewsletterCommentLoading } = useSelector((state) => state.NewsletterCommentReducer);


  const { data: MyBlogListData, loading: MyBlogListLoading } = useSelector((state) => state.MyBlogListReducer);



  const {
    ActiveNewsLetter, setActiveNewsLetter,
    ActiveNewsLetterQuarter, setActiveNewsLetterQuarter
} = useContext(GlobalContext)

  const {
    data,
    loading
} = useSelector(state=>state.ForumDetailReducer)


  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [checked, setChecked] = React.useState((SingleNewsletterLikeData?.is_user_like ? true : false));
  const { data: UserLoginData, loading: UserLoginLoading, isAuth, isRedirect } = useSelector(state => state.UserLoginReducer);
  const [ActiveVideo, setActiveVideo] = React.useState({});

  const [ActiveTOT, setActiveTOT] = React.useState({});
  
  const goToTop = () => {
    document.querySelector('.video-right-1').scroll(0, 0)
  }

  let userId = (UserLoginData?.user_id || crtUserId());
  const dispatch = useDispatch();
  let params = {
    "type":"",
    "webuserId":userId,
    "inputType" : "0"
  }
  const [MyFavourite, setMyFavorite] = useState([])
  const myFavFun = () => {
    if(MyBlogListData?.data && MyBlogListData.data.length > 0){
        let a0 = MyBlogListData.data.map((item)=>item?.blogdata[0]?.blog_id)
        console.log('a0 >>>> ',a0, MyBlogListData?.data)
        setMyFavorite(a0)
      }
  }


  useEffect(() => {
    if(!MyBlogListLoading){
      myFavFun();
    }
  }, [MyBlogListLoading]);

  useEffect(() => {
    if(!SingleNewsletterLikeLoading){
      setChecked(SingleNewsletterLikeData?.is_user_like)
    }
  }, [SingleNewsletterLikeLoading]);



  useEffect(() => {
    if(!VideoLikeLoading){
      let aaa = VideoLikeData.Data?.[0];
      setActiveVideo(aaa)
    }
  }, [VideoLikeLoading])

  useEffect(() => {

    if(!loading){
      let a0 = data.BlogAddonTot?.[0];
      setActiveTOT(a0)
      let vid_params = { blog_id: a0?.id, type: "", user_id: userId}
      dispatch(SingleNewsletterLikAction(vid_params));
      let paramsList = {
        blog_id: a0?.id,
        user_id:userId,
      };
      dispatch(NewsletterCommentAction(paramsList));

    }
  }, [loading, data]);


  useEffect(()=>{

  }, [ActiveTag, ActiveTOT])


  
  


  const handleChange = (event) => { 

    // alert('0000000000000000')
    // params
    let crt_check = event.target.checked;
    let like_type = 'like';
    if(!crt_check){
      like_type = 'unlike';
    }
    setChecked(crt_check);
    let vid_params = { blog_id: ActiveTOT?.id, type: like_type, user_id: userId}
    dispatch(SingleNewsletterLikAction(vid_params));

  };
  
  const addtolist = async(type) => {
   if(type ==1)
   {
    let vid_params = { user_id:userId, blog_id: ActiveTOT?.id,type:"news"}
    await dispatch(AddToMylistAction(vid_params));
   }
   else{
    let r_params = { user_id:userId, blog_id: ActiveTOT?.id,type:"news"}
    await dispatch(RemoveToMylistAction(r_params));
   }
   let prams = { "user_id": userId,"type":"news"};
   dispatch(MyBlogListAction(prams));

  }; 
  const [value, setValue] = React.useState(0);


  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={`video-right-1`} >





        {
          ActiveNewsLetterQuarter === null ? (
            <>

    {/* -------------------- START SINGLE ACTIVE TOT ADDON ----------------------- */}
      <Box sx={{ 
          width:'100%',
          borderRadius:'10px',
          overflow:'hidden',
          backgroundColor:'#fff'
        }}>
          <Box >
            {
              loading ?
              <>
                <CircularProgress />
              </>
              :
              <>
              <div className="tabs-container">
                <ul>
                  {
                    ActiveTOT?.tag && ActiveTOT?.tag.length > 0 && ActiveTOT?.tag.map((t_item, i)=>{
                      return (
                        <li>
                          {t_item?.name}
                        </li>
                      )
                    })
                  }
                </ul>
                <div style={{"width":"fit-content","border":"1px solid #cccc","padding":"0 0.8rem","display":"flex","alignItems":"center","gap":"3px","fontWeight":"600","margin":".5rem 0","borderRadius":"3px","background":"#fff"}}>
                  <FiEye /> {ActiveTOT?.view_count}
                </div>
              </div>
                <div dangerouslySetInnerHTML={{ 
                  __html: ActiveTOT?.discription
                 }} />
              </>
            }
          </Box>
      </Box>



      {
        ActiveTOT && (
          <>
            <div style={{ 
              marginTop:'.5rem',
              display:'flex',
              alignItems:'center',
              justifyContent:'space-between'
             }}>
              <Box style={{ 
                  display:'flex',
                  fontSize:'1rem',
                  alignItems:'center'
                }}
              >
                <Checkbox 
                  {...label}
                  onChange={(e)=>handleChange(e)} 
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite sx={{ color: 'red' }} />}
                  checked={checked}
                  sx={{ 
                    padding:'0 .2rem 0 0'
                  }}
                />
                <div>
                  {SingleNewsletterLikeData?.likecount || Number(0)} Like
                </div>
              </Box>
              {
                MyFavourite.includes(ActiveTOT?.id) ?
                  <Button  onClick={()=>addtolist(Number(0))} className='addToList' size="small"><BiTrash style={{ 
                    marginRight:'.2rem'
                  }} /> Remove From List</Button>
                  :
                  <Button  onClick={()=>addtolist(Number(1))} className='addToList' size="small">Add To List</Button>
              }
            </div>
            <div id='commentContainer' style={{ 
              paddingBottom: '1rem'
             }}>
              <CommentList ActiveItem={ActiveTOT} />
              
              <Button onClick={()=>goToTop()} variant="contained" sx={{ 
                    borderRadius:'50px',
                    minHeight:'50px',
                    minWidth:'50px',
                    margin:'1rem 0',
                    padding: 0,
                    float:'right',
                    position:'relative',
                    bottom: '2.5rem'
                  }}>
                    <AiOutlineArrowUp size={'1.5rem'}/>
                  </Button>

            </div>

          </>
        )
      }

      
    {/* -------------------- END SINGLE ACTIVE TOT ADDON ----------------------- */}

            </>

          )
          :
          (
            <>
              {/* -------------------- START MULTIPLE TOT ADDON ----------------------- */}

              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
                    {
                      data?.quarter_company && data?.quarter_company.map((q_itm, q_i)=>{
                        return (
                          <Tab key={q_i} label={q_itm?.company_name} {...a11yProps(q_i)} />
                        )
                      })
                    }
                  </Tabs>
                </Box>
                {
                    data?.quarter_company && data?.quarter_company.map((q_itm, q_i)=>{
                      return (
                        <CustomTabPanel value={value} index={q_i} key={q_i}>
                          <div dangerouslySetInnerHTML={{ 
                            __html: q_itm?.description
                          }} />
                        </CustomTabPanel>
                      )
                    })
                  }
              </Box>

              {/* -------------------- END MULTIPLE TOT ADDON ----------------------- */}
            </>
          )
        }

        
    </div>
  )
}

export default RightSection