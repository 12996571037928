
import * as React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

export default function LoadingComponent() {
  return (
    <>
      <Box style={{ height: '80vh', display: 'flex', justifyContent: "center", alignItems: 'center' }}>
        <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
          <CircularProgress color="inherit" />
        </Stack>
      </Box>
    </>
  )

} 