import axios from "axios";

axios.interceptors.request.use((config) => {
  // config.headers["app-id"] = "61c31b58b78391f2209599e7";
//   config.headers["Access-Control-Allow-Credentials"] = true
// config.headers["Access-Control-Allow-Headers"] = "Origin, Content-Type, Accept, Authorization, X-Request-With";
// config.headers["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE, OPTIONS";
// config.headers["Access-Control-Allow-Origin"] = "*";
// config.headers["Access-Control-Max-Age"] = 600;
// config.headers["Cache-Control"] = "no-cache";
// config.headers["Content-Length"] = 2374
// config.headers["Content-Type"] = "text/plan";
// config.headers["Pragma"] = "no-cache";
// config.headers["Server"] = "Microsoft-IIS/10.0";
// config.headers["X-aspnet-version"] = "4.0.30319";
// config.headers["X-Powered-By"] = "ASP.NET";
  return config;
});

export const request = {
  get: axios.get,
};

export const requestPost = {
  post: axios.post,
};






let localUser = localStorage.getItem("user");
if(localUser){
  localUser = JSON.parse(localUser)
  console.log('localUser >> ', localUser?.UserID)
}

let selectedWatchList = localStorage.getItem("selected_wishlist");
if(selectedWatchList){
  selectedWatchList = JSON.parse(selectedWatchList)
  console.log('selectedWatchList >> ', selectedWatchList)
}

export const defaultWatchListReq = {
  ID: "",
  UserID: localUser?.UserID,
  WatchListNAme: "",
  status: false,
  input: 4, //1 insert// 2 update// 3 delete // 4 list
};

export const defaultWatchListCompanyReq = {
  ID: "",
  UserID: localUser?.UserID,
  WatchListID: "",
  AccordCode: "",
  CompanyName: "",
  status: false,
  input: 4, //1 insert// 2 update// 3 delete // 4 list
};

