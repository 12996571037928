import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import InCr from "./InCr";
import { useDispatch, useSelector } from "react-redux";
import { SingleCompanyBalanceSheetAction } from "../../../redux/actions/VdrAction";
import { BsFillBarChartFill } from "react-icons/bs";


const BalanceSheetComp = (props) => { 
  const {
    companyId, isCD
  } = props;

  const {
    data,
    loading
  } = useSelector(state=>state.SingleCompanyBalanceSheetReducer);


  const [QuarterlySegmentType, setQuarterlySegmentType] = useState([]);
  const [TableColumns, setTableColumns] = useState([]);
  const [TableBody, setTableBody] = useState([]);
  const [ActivePrimaryBtn, setActivePrimaryBtn] = useState(1);
  const [FirstLoad, setFirstLoad] = useState(true);
  const [ActiveSecondaryBtn, setActiveSecondaryBtn] = useState(1);

  const [PlusIcons, setPlusIcons] = useState({});
  

  const showDiv = (e, index, chd) => {
    console.log('chd >>> ', chd)
    console.log('TableBody >>> ', TableBody)
    let a0 = PlusIcons;
    a0 = {
      ...a0,
      [`childTable_${index}`]: (!PlusIcons[`childTable_${index}`])
    }
    
    console.log(PlusIcons, 'index >> ', index, PlusIcons[`childTable_${index}`], (!PlusIcons[`childTable_${index}`]));
    // PlusIcons[`childTable_${r_i}`]

    setPlusIcons(a0);
  }

const dispatch = useDispatch();


const [P_Type, setP_Type] = useState('con');
const [BtnStatus, setBtnStatus] = useState({})

const callApi = () => {
    
  let pType = 'con';
      setP_Type(pType)
  if(QuarterlySegmentType === "Standalone"){
    pType = 'std';
    setP_Type(pType)
   }
  let params =  {
    "CompanyId":companyId,
    "type":pType
  }
  dispatch(SingleCompanyBalanceSheetAction(params))
}

useEffect(() => {
  setTableColumns([])
  setTableBody([])
  callApi()
}, [QuarterlySegmentType])

useEffect(() => {
    if(!loading){

      setBtnStatus(data.button_status)
      
      if(data.header && data.header.length > 0 && data?.button_status[P_Type] === true){
        let cols = []
        let firstObj = data.header[0];
        let a0 = firstObj.row;
        Object.keys(a0).map((item, i)=>{
          if(i > 0){
            let dd = {
                "id": item,
                "width": "",
                "align": "",
                "bg_color": firstObj?.bg_color,
                "isItalic": firstObj?.isItalic,
                "isBold": firstObj?.isBold,
                "text_color": firstObj?.text_color,
                "label": a0[item]
              }
            cols.push(dd);
          }
        })
        setTableColumns(cols)
      }else{
        if(FirstLoad){
          setFirstLoad(false);
          setActivePrimaryBtn(2);
          setQuarterlySegmentType('Standalone')
        }
      }
      if(data.Data && data.Data.length && data?.button_status[P_Type] === true){
        let dataA = []
        let plusIcon = {};
        let a00 = data.Data;
        a00.map((item, i)=>{
          let cData = item?.row;
          let childObj = {}
          Object.keys(cData).map((item0, i0)=>{
            if(i0 > 0){
              let data = {
                "label":cData[item0],
                "bg_color":item['bg_color'],
                "isItalic":item['isItalic'],
                "isHeader":item['isHeader'],
                "isBold":item['isBold'],
                "text_color":item['text_color'],
              };
              childObj = {...childObj, [`Column${i0}`]: data} 
            }
          })
          if(item?.childTable.length > 0){
            plusIcon = {...plusIcon, [`childTable_${i}`]: false}
          }
          childObj = {...childObj, [`childTable`]: (item?.childTable || [])}
          dataA.push(childObj)
        })
        console.log('dataA >>>>>>>> ', dataA)
        setPlusIcons(plusIcon);
        setTableBody(dataA)
      }
    }
}, [dispatch, loading])
  
let secondaryBtnArr = [
  {
    "id":1,
    "label":"Consolidated",
    "value":"Consolidated",
    "short_name":"con",
    "show_in":[1,2,3]
  },
  {
    "id":2,
    "label":"Standalone",
    "value":"Standalone",
    "short_name":"std",
    "show_in":[1,2,3]
  },
]

const showChart = (row, level=1)=>{

let newRow = {};
if(level == 1){
  // let a00 = row
  // delete a00['childTable'];
  newRow = row
}else{
  Object.keys(row).map((item)=>{
      let a0 = {
          bg_color: "#ccdbf5",
          isBold: true,
          isItalic: false,
          label: row[item],
          text_color: "#000",
        
      }
      newRow = {...newRow, [item]: a0}
      
  })
  
}

  dispatch({
    type:"Columns_Rows",
    payload:{columns:TableColumns, rows:newRow}
  })
  
}
 


  return (
    <>
      {/* <button className='btn primary-btn'>Download Image</button> */}
      <Box className="valuation-box">
        {
          !isCD && (
            <div className="box-head-sec">
              
            <div style={{ 
                display:'flex',
                width:'100%',
                justifyContent:'space-between',
                alignItems:'center',
              }}>
                <div style={{ 
                display:'flex',
                alignItems:'end',
                gap:'.5rem'
              }}>
              <h3>Balance Sheet</h3>
                    <InCr />
                </div>

              </div>
              <div>
                {/* <button className="btn primary-btn-outline">Edit</button>
                <button className="btn primary-btn">Download Image</button> */}
              </div>
            </div>
          )
        }
        <div style={{
          display:'flex',
          gap:'10px',
          marginBottom:'14px'
         }}>
        {
            secondaryBtnArr.map((item, i)=>{
              if(item.show_in.includes(ActiveSecondaryBtn)){
              }
              return (
                <>
              <button onClick={()=>{
                // console.log('item.id >>> ', item.id)
                
                if((BtnStatus[item?.short_name])){
                  setQuarterlySegmentType(item?.value)
                  setActivePrimaryBtn(item.id);
                }

                // setQuarterlySegmentType(item?.value)
                // setActivePrimaryBtn(item.id);
              }
              } key={i} className={`btn ${ActivePrimaryBtn === item.id ? "primary-btn" : "secondary-btn-outline"}  ${!BtnStatus[item?.short_name] ? "cst_disabled" : ""} `}>{item?.label}</button>
                </>
              )
            })
          }

          {/* <button className="btn primary-btn-outline">Edit</button>
          <button className="btn primary-btn">Download Image</button> */}
        </div>
        <div className="box-body-sec">
          
        <table>
        <thead>
          <tr>
            {
              TableColumns.map((item,i)=>{
                let cStyle = {
                  
                      backgroundColor:item?.bg_color,
                      fontStyle:item?.isItalic ? "italic" : "",
                      fontWeight:item?.isBold ? "500" : "",
                      color:item?.text_color,
                    }
                return (
                  <td style={cStyle} key={i}>{item.label}</td>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {
              TableBody.map((row,r_i)=>{
                let backgroundColor = "";
                let fontStyle = "";
                let fontWeight = "";
                let color = "";
                // console.log('row >>> ', row)

                return (
                  <>
                    <tr>
                      {
                        TableColumns.map((item,i)=>{
                          let rowData = row[item.id]
                          let value = rowData?.label
                          let cStyle = {
                            backgroundColor:rowData?.bg_color,
                            fontStyle:rowData?.isItalic ? "italic" : "",
                            fontWeight:rowData?.isBold ? "500" : "",
                            color:rowData?.text_color,
                          }
                          if(backgroundColor === "" &&
                            fontStyle === "" &&
                            fontWeight === "" &&
                            color === ""){
                              backgroundColor = rowData?.bg_color;
                              fontStyle = rowData?.isItalic ? "italic" : "";
                              fontWeight = rowData?.isBold ? "500" : "";
                              color = rowData?.text_color;
                          }
                          // console.log('row >>>> ', row)
                          return (
                            <td style={cStyle} key={i}>
                              <div style={{
                              display:'flex',
                              alignItems:'center',
                              justifyContent: i !==0 ? 'end' : "space-between",
                              columnGap:'.5rem'
                            }}>
                              <div style={{  display:'flex', alignItems:'center', columnGap:'.5rem' }}>
                                <div>{value || ""}</div>
                                  {/* {Number(i)}
                                  {row?.childTable && row?.childTable.length} */}

                                  {
                                    (i===0 && row?.childTable && row?.childTable.length > 0) && (
                                      PlusIcons[`childTable_${r_i}`] ?  <AiOutlineMinus onClick={(e)=>showDiv(e, r_i, row)} className="td-icon"/> : <AiOutlinePlus onClick={(e)=>showDiv(e, r_i, row)} className="td-icon"/> 
                                    )
                                  } 
                              </div>
                              
                              <span>
                                {
                                  (rowData?.isHeader === false && i === 0) &&
                                  <IconButton onClick={(e)=>showChart(row)} sx={{ padding:0 }}>
                                    <BsFillBarChartFill size={16} />
                                  </IconButton>
                                }
                              </span>

                            </div>
                            </td>
                          )
                        })
                      }
                    </tr>
                    { 
                       PlusIcons[`childTable_${r_i}`] &&
                      row?.childTable && row?.childTable.length > 0 && (
                        <>
                          {
                            row.childTable.map((child, c)=>{
                              return (
                                <tr key={c} className={r_i%2 !== 0 ? "tr-even" : "tr-odd"}>
                                  {
                                    TableColumns.map((item0,i0)=>{
                                      let c_value = child[item0.id];
                                      let cStyle = {};
                                      if(i0 === 0){
                                        cStyle = {
                                          ...cStyle,
                                          paddingLeft:'2rem'
                                        }
                                      }
                                      cStyle = {
                                        ...cStyle,
                                        backgroundColor:backgroundColor,
                                        fontStyle:fontStyle,
                                        fontWeight:fontWeight,
                                        color:color
                                      }
                                      return (
                                        <>
                                          <td key={i0} style={cStyle}>
                                          <div style={{ 
                                              display:'flex',
                                              width:'100%',
                                              justifyContent: (i0 === 0 ? 'space-between' : 'flex-end'),
                                              alignItems:'center'
                                            }}>
                                              {c_value || ""}
                                              <span>
                                                {
                                                  i0 === 0 && 
                                                  <IconButton onClick={(e)=>showChart(child, 2)} sx={{ padding:0 }}>
                                                    <BsFillBarChartFill size={16} />
                                                  </IconButton>
                                                }
                                              </span>
                                            </div>
                                            
                                          </td>
                                        </>
                                      )
                                    })
                                  }
                                </tr>
                              )
                            })
                          }
                        </>
                      )
                    }
                  </>
                )
              })
            }
          
        </tbody>
      </table>
        </div>
      </Box>
    </>
  );
};

export default BalanceSheetComp;
