import { Box, Button, List } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DrawerContainer from '../../components/Common/DrawerContainer'
import { CompanyAdd, GroupsAdd } from '../../components/Forms'
import { vdrCompanyAction, vdrGroupAction, vdrLevelAction } from '../../redux/actions/VdrAction'
import AccordionNested from './../../components/Testing/AccordionNested'
import { LoadingComponent } from './../../components'
import { companyMasterAction } from '../../redux/actions/userAction'
import { useNavigate } from 'react-router-dom'
const GroupsPage = () => {

    const dispatch = useDispatch();
    const { data: levelData, loading: levelLoading } = useSelector((state) => state.vdrLevelReducer);
    const { data: groupData, loading: groupLoading } = useSelector((state) => state.vdrGroupsReducer);
    const { data: companyData, loading: companyLoading } = useSelector((state) => state.vdrCompanyReducer);
    const { data: imageUploadData, loading: imageUploadLoading } = useSelector((state) => state.vdrUploadImageReducer);
    const [groupsData, setGroupsData] = useState(null)
    const [levelsData, setLevelsData] = useState(null)
    const [companiesData, setCompaniesData] = useState(null)

    const navigation = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem('user')) {
            navigation('/login');
        }
    }, [])


    useEffect(() => {

        dispatch(companyMasterAction());
        dispatch(vdrLevelAction({}));

        if (groupLoading) {
            dispatch(vdrGroupAction({ status: 1 }));
        }

        if (companyLoading) {
            dispatch(vdrCompanyAction({ status: 1 }));
        }

        if (!groupLoading) {
            setGroupsData(groupData.Data);
        }

        if (!companyLoading) {
            setCompaniesData(companyData.Data);
        }

        if (!levelLoading) {
            setLevelsData(levelData.Data);
        }

        // console.log('companyLoading'+companyLoading);

    }, [dispatch, groupLoading, companyLoading, imageUploadLoading])

    if (groupLoading || companyLoading) {
        return (
            <LoadingComponent />
        )
    }

    return (
        <>
            <Box>
                <AccordionNested
                    groupsData={groupsData}
                    levelsData={levelsData}
                    companiesData={companiesData}
                />
            </Box>
        </>
    )

}

export default GroupsPage