import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { useForm, FormProvider } from "react-hook-form";
import { InputComponent, AutoCompleteComponent, SelectSector, SelectIndustry, SelectCompany } from '../Common';
import { Box, Button, Checkbox, Grid } from '@mui/material';
import RadioComponent from '../Common/RadioComponent';
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'moment';
import { allCompanyData, resultFilters, resultFilterInputAction, allCompanyData2 } from './../../redux/actions/userAction';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    // borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#000' }} />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: '2rem',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    // marginLeft: theme.spacing(1),
    margin: 0,
  },
  '& .MuiAccordionSummary-content p': {
    fontSize: '0.85rem',
    fontWeight: '700',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function HomeFilter() {
  // const [expanded, setExpanded] = React.useState('panel1');

  const [expanded1, setExpanded1] = React.useState('panel1');
  const [expanded2, setExpanded2] = React.useState(null);
  const [expanded3, setExpanded3] = React.useState(null);
  const [expanded4, setExpanded4] = React.useState(null);
  const [expanded5, setExpanded5] = React.useState(null);
  const [expanded6, setExpanded6] = React.useState(null);


  const { data: companyMaster, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
  const { data: sectorMaster, loading: sectorMasterLoading } = useSelector((state) => state.sectorMasterData);
  const { data: industryMaster, loading: industryMasterLoading } = useSelector((state) => state.industryMasterData);
  const { data: turnAroundMaster, loading: turnAroundMasterLoading } = useSelector((state) => state.turnAroundMasterData);


  const [sectors, setSectors] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [sectorMasterArr, setSectorMasterArr] = useState([]);
  const [company, setCompany] = useState([]);
  const [companyMasterArr, setCompanyMasterArr] = useState([]);
  const [filterArr, setFilterArr] = useState([]);
  const [industryMasterArr, setIndustryMasterArr] = useState([]);
  const [sectorsKey, setSectorsKey] = useState(false);
  const [industryKey, setIndustryKey] = useState(false);
  const [resetButton, setResetButton] = useState(false);
  const [sectorsIds, setSectorsIds] = useState([]);

  const [turnAroundMasterArr, setTurnAroundMasterArr,] = React.useState([]);
  const [Radio, setRadio,] = React.useState([]);


  const handleChange = (panel) => (event, newExpanded) => {

    if (panel === 'panel1') {
      setExpanded1(newExpanded ? panel : false);
    }
    if (panel === 'panel2') {
      setExpanded2(newExpanded ? panel : false);
    }
    if (panel === 'panel3') {
      setExpanded3(newExpanded ? panel : false);
    }
    if (panel === 'panel4') {
      setExpanded4(newExpanded ? panel : false);
    }
    if (panel === 'panel5') {
      setExpanded5(newExpanded ? panel : false);
    }
    if (panel === 'panel6') {
      setExpanded6(newExpanded ? panel : false);
    }
  };

  const handleChangeRadio = (event) => {
    setRadio(event.target.value);
  };

  const selectTurnAroundMaster = () => {
    if (turnAroundMaster.length > 0) {
      var data1 = [];
      // console.log('turnAroundMaster>> ' + JSON.stringify(turnAroundMaster))
      turnAroundMaster.map((item) => {
        var d1 = { title: item.FilterName, value: item.Id };
        data1.push(d1);
      })
      setTurnAroundMasterArr(data1);
    }
  }
  const dispatch = useDispatch();

  useEffect(() => {
    selectTurnAroundMaster();
  }, [])



  const { control, handleSubmit } = useForm();
  const onSubmit = data => {


    // console.log(data);
    // return 

    var sectorValue = sectors.map((item) => item.value);
    var industryValue = industry.map((item) => item.value);
    var companyValue = company;


    const topLabels =

    {
      "Market_Cap": {
        "label": "Market Cap",
        "value1": (data?.MarketCapFrom || ''),
        "value2": (data?.MarketCapTo || '')
      },
      "date_range": {
        "label": "Date Range",
        "value1": (data?.FromDate ? Moment(data?.FromDate).format('MM/DD/YYYY') : null),
        "value2": (data?.ToDate ? Moment(data?.ToDate).format('MM/DD/YYYY') : null)
      },
      "LTP": {
        "label": "LTP",
        "value1": (data?.LTP || ''),
        "value2": ""
      },
      "TTM_P_B": {
        "label": "TTM (P/B)",
        "value1": (data?.TTM_P_B || ""),
        "value2": ""
      },
      "TTM_P_E": {
        "label": "TTM (P/E)",
        "value1": (data?.TTM_P_E || ""),
        "value2": ""
      },
      "ROCE": {
        "label": "ROCE",
        "value1": (data?.ROCE || ""),
        "value2": ""
      },
      "TTM_Sales_Abs": {
        "label": "TTM_Sales_Abs",
        "value1": (data?.TTM_Sales_Abs || ""),
        "value2": ""
      },
      "TTM_PAT_Abs": {
        "label": "TTM_PAT_Abs",
        "value1": (data?.TTM_PAT_Abs || ""),
        "value2": ""
      },
      "Sales_YOY": {
        "label": "Sales_YOY",
        "value1": (data?.Sales_YOY || ""),
        "value2": ""
      },
      "Sales_QOQ": {
        "label": "Sales_QOQ",
        "value1": (data?.Sales_QOQ || ""),
        "value2": ""
      },
      "EBDITA_YOY": {
        "label": "EBDITA_YOY",
        "value1": (data?.EBDITA_YOY || ""),
        "value2": ""
      },
      "EBDITA_QOQ": {
        "label": "EBDITA_QOQ",
        "value1": (data?.EBDITA_QOQ || ""),
        "value2": ""
      },
      "PAT_YOY": {
        "label": "PAT_YOY",
        "value1": (data?.PAT_YOY || ""),
        "value2": ""
      },
      "PAT_QOQ": {
        "label": "PAT_QOQ",
        "value1": (data?.PAT_QOQ || ""),
        "value2": ""
      },
      "GROSS_PROFIT_YOY": {
        "label": "GROSS_PROFIT_YOY",
        "value1": (data?.GROSS_PROFIT_YOY || ""),
        "value2": ""
      },
      "GROSS_PROFIT_QOQ": {
        "label": "GROSS_PROFIT_QOQ",
        "value1": (data?.GROSS_PROFIT_QOQ || ""),
        "value2": ""
      },
      "EBDITA_TO": {
        "label": "EBIDTA Margin (%) - To +",
        "value1": (inputs?.EBDITA_TO || ""),
        "value2": ""
      },
      "PAT_TO": {
        "label": "PAT-TO +",
        "value1": (inputs?.PAT_TO || ""),
        "value2": ""
      },
      "ColorCode": {
        "label": "Color Code",
        "value1": (Radio || ""),
        "value2": ""
      },
      "sectors": {
        "label": "Sector",
        "value1": (sectorValue || ""),
        "value2": ""
      },
      "industry": {
        "label": "Industry",
        "value1": (industryValue || ""),
        "value2": ""
      },
      "company": {
        "label": "Company",
        "value1": (companyValue || ""),
        "value2": ""
      },
      "portfolio": {
        "label": "Portfolio",
        "value1": checked,
        "value2": ""
      }
    };
    const filterArray = resultFilters(topLabels);

    dispatch(resultFilterInputAction(topLabels));
    dispatch(allCompanyData(filterArray));
    dispatch(allCompanyData2(filterArray));

  };
  const [checked, setChecked] = React.useState(false);

  const [inputs, setInputs] = React.useState(false);

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked); 
  };
  return (
    <>
    <Box sx={{ 
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
     }}>
     <Typography sx={{ 
       marginY:2,
       fontSize: [14, 18, 24],
       fontWeight: 'bold',
     }} >Filter</Typography>
      <Button  className='btn-cst-primary' type='submit' onClick={handleSubmit(onSubmit)} variant='contained' size={'small'}>Apply</Button>
    </Box>
      {/* <div>
        <Button type='submit' onClick={handleSubmit(onSubmit)} variant='contained' size={'small'}>save</Button>
      </div> */}
      <Accordion expanded={expanded1 === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Data Range</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12} >
              <InputComponent
                name="FromDate"
                label="From"
                control={control}
                size="small"
                type="date"
                className='cInput'
              />
            </Grid> 
            <Grid item xs={12} >
              <InputComponent
                name="ToDate"
                label="To"
                control={control}
                size="small"
                type="date"
                className='cInput'
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded2 === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Classification</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={6} >
            <SelectSector
              sectors={sectors}
              sectorMasterArr={sectorMasterArr}
              setSectors={setSectors}
              setSectorMasterArr={setSectorMasterArr}
              sectorsIds={sectorsIds}
              setSectorsIds={setSectorsIds}
              sectorMaster={sectorMaster}
            />

            <SelectIndustry
              industry={industry}
              industryMasterArr={industryMasterArr}
              sectorsIds={sectorsIds}
              setIndustry={setIndustry}
              setIndustryMasterArr={setIndustryMasterArr}
              sectorMaster={sectorMaster}
              industryMaster={industryMaster}
            />

            <SelectCompany
              sectors={sectors}
              industry={industry}
              sectorMasterArr={sectorMasterArr}
              setSectors={setSectors}
              sectorsIds={sectorsIds}
              setSectorsIds={setSectorsIds}
              companyMaster={companyMaster}
              companyMasterArr={companyMasterArr}
              company={company}
              setCompany={setCompany}
              setCompanyMasterArr={setCompanyMasterArr}
              companyMasterLoading={companyMasterLoading}
            />
            <Box>
              <Checkbox
                size='small'
                className={'checkBoxContainer'}
                checked={checked}
                onChange={handleChangeChecked}
                inputProps={{ 'aria-label': 'Portfolio' }}
              />
              <label>Portfolio</label>
            </Box>

          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded3 === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Share Price (TTM)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12} >
              <label className='sidebar-label'>Market Cap</label>
            </Grid>
            <Grid item xs={6} >
              <InputComponent
                name="MarketCapFrom"
                control={control}
                size="small"
                placeholder=">100"
                className='cInput'
              />
            </Grid>
            <Grid item xs={6} >
              <InputComponent
                name="MarketCapTo"
                control={control}
                size="small"
                placeholder="<50000"
                className='cInput'
              />
            </Grid>

            <Grid item xs={12} >
              <InputComponent
                name="LTP"
                label="LTP"
                control={control}
                size="small"
                placeholder=">10"
                className='cInput'
              />
            </Grid>
            <Grid item xs={12} >
              <InputComponent
                name="TTM_P_B"
                label="TTM (P/B)"
                control={control}
                size="small"
                placeholder=">10"
                className='cInput'
              />
            </Grid>
            <Grid item xs={12} >
              <InputComponent
                name="TTM_P_E"
                label="TTM (P/E)"
                control={control}
                size="small"
                placeholder=">15"
                className='cInput'
              />
            </Grid>
            <Grid item xs={12} >
              <InputComponent
                name="ROCE"
                label="ROCE"
                control={control}
                size="small"
                placeholder=">10"
                className='cInput'
              />
            </Grid>
            <Grid item xs={12} >
              <InputComponent
                name="TTM_Sales_Abs"
                label="TTM SALES ABS"
                control={control}
                size="small"
                placeholder=">100"
                className='cInput'
              />
            </Grid>
            <Grid item xs={12} >
              <InputComponent
                name="TTM_PAT_Abs"
                label="TTM PAT ABS"
                control={control}
                size="small"
                placeholder=">50"
                className='cInput'
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded4 === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>Result Data</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} >
            <Grid item xs={12} >
              <InputComponent
                name="Sales_YOY"
                label="SALES (YOY%)"
                control={control}
                size="small"
                placeholder=">10"
                className='cInput'
              />
            </Grid>
            <Grid item xs={12} >
              <InputComponent
                name="Sales_QOQ"
                label="SALES (QOQ%)"
                control={control}
                size="small"
                placeholder=">10"
                className='cInput'
              />
            </Grid>
            <Grid item xs={12} >
              <InputComponent
                name="GROSS_PROFIT_YOY"
                label="GROSS PROFIT (YOY%)"
                control={control}
                size="small"
                placeholder=">10"
                className='cInput'
              />
            </Grid>
            <Grid item xs={12} >
              <InputComponent
                name="GROSS_PROFIT_QOQ"
                label="GROSS PROFIT (QOQ%)"
                control={control}
                size="small"
                placeholder=">10"
                className='cInput'
              />
            </Grid>
            <Grid item xs={12} >
              <InputComponent
                name="EBDITA_YOY"
                label="EBDITA (YOY%)"
                control={control}
                size="small"
                placeholder=">10"
                className='cInput'
              />
            </Grid>
            <Grid item xs={12} >
              <InputComponent
                name="EBDITA_QOQ"
                label="EBDITA (QOQ%)"
                control={control}
                size="small"
                placeholder=">10"
                className='cInput'
              />
            </Grid>
            <Grid item xs={12} >
              <InputComponent
                name="PAT_YOY"
                label="PAT (YOY%)"
                control={control}
                size="small"
                placeholder=">10"
                className='cInput'
              />
            </Grid>
            <Grid item xs={12} >
              <InputComponent
                name="PAT_QOQ"
                label="PAT (QOQ%)"
                control={control}
                size="small"
                placeholder=">10"
                className='cInput'
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded5 === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography>Turn Around</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <AutoCompleteComponent
            name="TurnAround"
            control={control}
            size="small"
            label="EBIDTA Margin (%) - To +"
            className='cInput'
            options={turnAroundMasterArr}
            onChange={(event, newInputValue) => {
              var val1 = [];
              setInputs({ ...inputs, ['EBDITA_TO']: newInputValue.value });
              // console.log('PAT_TO >> ' + val1)
            }}
          />
          <AutoCompleteComponent
            name="TurnAround2"
            control={control}
            size="small"
            label="PAT-TO +"
            className='cInput'
            options={turnAroundMasterArr}
            onChange={(event, newInputValue) => {
              var val1 = [];
              setInputs({ ...inputs, ['PAT_TO']: newInputValue.value });
              // console.log('PAT_TO >> ' + val1)
            }}
          />

        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded6 === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography>Conditions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RadioComponent
            name="conditions"
            control={control}
            size="small"
            onChange={handleChangeRadio}
            value={Radio}
            items={
              [
                {
                  label: 'All',
                  value: '1',
                  color: '',
                  value: ''
                },
                {
                  label: 'Sales Up 10% (YoY & QoQ), Gross And EBIDTA Margins[YoY/QoQ] Improving.',
                  value: '2',
                  color: 'label_Green',
                  value: 'Green'
                },
                {
                  label: 'Sales Up 20% (YoY & QoQ).',
                  value: '3',
                  color: 'label_Orange',
                  value: 'Orange'
                },
                {
                  label: 'Sales Down 10% (YoY & QoQ), Gross And EBIDTA Margins[YoY/QoQ] Declining.',
                  value: '4',
                  color: 'label_Red',
                  value: 'Red'
                }
              ]
            }
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
