import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import { commentParams } from '../../../../constants/helpers';
import { useDispatch } from 'react-redux';
import { VideoCommentAction } from '../../../../redux/actions/VdrAction';
import { toast } from 'react-toastify';

const AddCommentComponent = (props) => {
  const {
    videoId,
    submitLabel,
    ActiveComment,
    setActiveComment
  } = props
  const [TextData, setTextData] = useState("");

  const handleChange = (value) => {
    setTextData(value)
    // console.log(value);
  }
  const dispatch = useDispatch();

  const submitForm = () => {
      let message = 'Comment Added Successfully!'
      if(TextData == ""){
        alert('This field in required!')
        return ''
      }
    let params = {
        ...commentParams,
        videoId: ActiveComment?.item.videoId || videoId,
        comment: TextData,
        inputType: "0"
      }
      if(ActiveComment && ActiveComment?.type === 'replying'){
        params = {
          ...params,
          parentId:ActiveComment?.item.commentId
        }
      }
      if(ActiveComment && ActiveComment?.type === 'editing'){
        params = {
          ...params,
          commentId:ActiveComment?.item.commentId,
          inputType: "1"
        }
        message = 'Comment Updated Successfully!'
      }
      
      dispatch(VideoCommentAction(params));
      setActiveComment(null);
      toast.success(message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTextData("");
  }
useEffect(() => {
  
  if(ActiveComment && ActiveComment?.type === 'editing'){
    setTextData(ActiveComment?.item?.comment)
  }
}, [])


  const modules = {
    toolbar: [
      // [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike'],
      // [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      // ['link', 'image', 'video'],
      // [{ 'color': [] }, { 'background': [] }], 
      // ['clean']
    ],
  };
 
  const formats = [
    // 'header',
    'bold', 'italic', 'underline', 'strike', //'blockquote',
    // 'list', 'bullet', 'indent',
    // 'link', 'image', 'video',
    // 'color', 'background', 'code-block',
    // 'clean'
  ];



  return (
    <>
      <Box sx={{ 
        backgroundColor:'#fff', marginTop:'.5rem'
       }}>
        <ReactQuill
          style={{ 
            height:'150px'
          }}
          theme={"snow"}
          value={TextData}
          onChange={handleChange} 
          modules={modules}
          formats={formats} 
        />
      </Box>
      <Box sx={{ marginTop:'.3rem' }}>
        <Button size="small" onClick={(e)=>submitForm(e)} variant='contained'  color="primary">{submitLabel || "Submit" }</Button>
        {
          ActiveComment && (
            <Button sx={{ marginLeft:'.2rem' }} size="small" onClick={(e)=>setActiveComment(null)} variant='outlined' >Cancel</Button>
          )
        }
      </Box>
    </>
  )
}

export default AddCommentComponent