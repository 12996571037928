import { Box, Typography, Tooltip } from '@mui/material';
import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { mediaModelAction } from '../../redux/actions/VdrAction';
import $ from 'jquery';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import axios from 'axios';








const SingleVideoGrid = (props) => {
  
  const { itemData } = props;

  // console.log(itemData);

  const [VideoData, setVideoData] = useState(null);
  
const get_vimeo_thumbnail = (url, imgId, size = null, callback = null) => {
  // Missing required request header. Must specify one of: origin,x-requested-with axios
  // var urlA = `https://vasudeep.com:8084/https://player.vimeo.com/video/${url[0]}/config?h=${url[1]}`;
  var urlA = `https://omkaracapital.in/api/get-video-vimeo?h=${url[1]}&video=${url[0]}`;

  axios.get(urlA)
    .then(function (response) {
      var data = response.data;
      setVideoData(data.video);
      $(`#${imgId}`).attr('src',data.video.thumbs.base)
      // $(`#title${url[0]}`).text(data.video?.title)
    })
    .catch(function (error) {

      console.log(error);
    });

}


  // var VideoThumbnail1;
  const [VideoThumbnail1, setVideoThumbnail1] = useState(null)
  
  var vCode = itemData.videoCode ? itemData.videoCode.split('/') : [];
  const d = new Date();
  let time = d.getTime();

  const [ImgId, setImgId] = useState(`imgId${vCode[0]}`+time);
  useEffect(() => {
    // console.log(itemData.videoCode);
    if (itemData.videoType === 'vimeo') {
      // console.log(vCode);
      var VideoThumbnail10 = get_vimeo_thumbnail(vCode, ImgId);
    } else {
      var VideoThumbnail10 = `https://img.youtube.com/vi/${itemData.videoCode}/mqdefault.jpg`;
    }
    setVideoThumbnail1(VideoThumbnail10);
  }, [])
  
  const dispatch = useDispatch();
  const { data, loading } = useSelector(state => state.vdrMediaDataReducer);
// console.log(itemData);
  return (
    <>
      <Box

        sx={{
          position: 'relative',
        }}
        className='mediaItemBox' onClick={() => dispatch(mediaModelAction({ isOpen: true, type: itemData.videoType, itemId: itemData.videoCode }))}>
        <img
          className='mediaItem'
          id={ImgId}
          src={VideoThumbnail1}
        />
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: '#00000080',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <PlayCircleOutlineIcon sx={{ color: '#fff', fontSize: 70 }} />
        </Box>
      </Box>
        <Box>
          <Tooltip title={itemData?.videoTitle} placement="top">
            <Typography variant='h6' sx={{fontSize:17}} id={`title${vCode[0]}`} className={"vimeoTitle text-ellipsis"}>{itemData?.videoTitle}</Typography>
          </Tooltip>
        </Box>
    </>
  )
}

export default SingleVideoGrid