import React, { useEffect, useRef, useState } from 'react'
import { SingleCompanyGrid } from '../components'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tab, Tabs, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { vdrHomeAction, vdrCompanyAction, vdrFavoriteAction, vdrMediaAction, segmentAction, dataRoomTickerAction } from '../../redux/actions/VdrAction';
import { CustomCarousel, HomeSearch } from '../components';
import { getBaseLink, loggedInUser, wordLimit } from '../../constants/helpers';
import { LoadingComponent } from '../../components';
import { Link as SLink } from 'react-scroll'
import { Link, useNavigate } from 'react-router-dom';
import DrawerContainer from '../../components/Common/DrawerContainer';
import { motion } from 'framer-motion'
import MediaModel from '../../components/SingleCompanyContainer/MediaSection_old/MediaModel';
import { segmentAddJson } from '../../constants/defaultArray';
import axios from 'axios';
import moment from 'moment';





const TickerPage = () => {

  const {
    loading: loadingDataRoomTicker,
    data:DataRoomTicker
  } = useSelector(state => state.dataRoomTickerReducer);
  const dispatch = useDispatch()
  
  useEffect(() => {
    if(loadingDataRoomTicker){
      dispatch(dataRoomTickerAction())
    }
    if(!loadingDataRoomTicker){

    }
  }, [dispatch, loadingDataRoomTicker])
  

  return (
    <>
    <Box className="headerContainerFront">

<Typography
  style={{ zIndex: 2 }}
  fontSize={"2.5em"}
  color="#f37b21"
  fontWeight="700"
>

  All Updates

</Typography>
</Box>

<Box className="bodyContainer" sx={{
  marginTop: 5, marginBottom: 5, maxWidth:'700px'
}}>

          <Box>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
{
  DataRoomTicker?.data && DataRoomTicker?.data.map((item,i)=>{
    return (
      <>
          <ListItem key={i}>
            {/* <ListItemAvatar>
              <Avatar>
                <ImageIcon />
              </Avatar>
            </ListItemAvatar> */}
            <Typography>
              <strong>{item?.date ? moment(item.date).format('Do MMM YYYY')+": " : ""}</strong> &nbsp; <span dangerouslySetInnerHTML={{ __html:item?.description }} />
            </Typography>
            {/* <ListItemText primary={item?.description} secondary={item?.date ? moment(item.date).format('MMM, DD, YYYY') : ""} /> */}
          </ListItem>
      </>
    )
  })
}
          </List>
          </Box>
      </Box>
    </>
  )
}

export default TickerPage