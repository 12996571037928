import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Box, Typography } from '@mui/material';


const SelectIndustry = (props) => {

    const {
        industry,
        industryMasterArr,
        sectorsIds,
        setIndustry,
        setIndustryMasterArr,
        sectorMaster,
        industryMaster,
    } = props;


    useEffect(() => {
        // console.log(sectorMaster);
        selectIndustry();
    }, [industryMaster]);


    useEffect(() => {
        if (sectorsIds.length === 0) {
            selectIndustry();
        } else {
            filterSelectIndustry();
        }
    }, [sectorsIds])



    const selectIndustry = () => {

        if (industryMaster.length > 0) {
            var data1 = [];
            industryMaster.map((item) => {
                var d1 = { title: item.Industry, value: item.IndustryID };
                data1.push(d1);
            })
            setIndustryMasterArr(data1);
        }
    }

    const filterSelectIndustry = () => {
        let industryMasterFilter = [];
        for (var i = 0; i < sectorsIds.length; i++) {
            var industryMasterFilter1 = industryMaster.filter(industry => (sectorsIds[i] == industry.sectorID));
            Array.prototype.push.apply(industryMasterFilter, industryMasterFilter1);
        }
        var data1 = [];
        if (industryMasterFilter.length > 0) {
            industryMasterFilter.map((item) => {
                var d1 = { title: item.Industry, value: item.IndustryID };
                data1.push(d1);
            })
        }
        setIndustryMasterArr(data1);
    }

    return (
        <>

            <Box
                display="flex"
                flexDirection="row"
                alignContent={'center'}
                justifyContent={'space-between'}
            >
                <div style={{width:'100%', margin:'0 0.2rem'}}>
                    <Typography sx={{ fontSize:'0.7rem', marginTop:1 }} className="fontWeight-600 " >Industry ({industryMasterArr.length})</Typography>
                    <Autocomplete
                    disableCloseOnSelect
                        multiple
                        limitTags={2}
                        id="multiple-limit-tags"
                        options={industryMasterArr}
                        className="selectInputNew"
                        defaultValue={industry}
                        getOptionLabel={(option) => option.title}
                        size="small"
                        renderInput={(params) => (
                            <TextField {...params}  className='fontSize-12px' size="small" placeholder="Select Industry" />
                        )}
                        onChange={(event, newInputValue) => {
                            var val1 = [];
                            for (var a = 0; a < newInputValue.length; a++) {
                                val1.push(newInputValue[a].value);
                            }
                            //   setInputs({ ...inputs, ['Industry']: val1 });
                            setIndustry(newInputValue)

                        }}
                        fullWidth
                    />
                </div>
            </Box>


        </>
    )
}

export default SelectIndustry