import { Box, CircularProgress, List, ListItem, ListItemButton, Typography } from '@mui/material';
import React from 'react'
import moment from 'moment'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forumDetailAction } from '../../../../redux/actions/VdrAction';
import { crtUserId } from '../../../../constants/helpers';

const LeftSection = (props) => {

    const { data: MyBlogListData, loading: MyBlogListLoading } = useSelector((state) => state.MyBlogListReducer);
    let userId =crtUserId()
    let {
        ActiveVideoType,
        setActiveVideoType,
        ActiveItem,
        setActiveItem,
        ToBeUpdated,
        FilterVideoData,
        setFilterVideoData
    } = props;

    const dispatch = useDispatch();
    const {
        data:detailData,
        loading:detailLoading
    } = useSelector(state=>state.ForumDetailReducer) 


    const ActiveItemFun = (item0) => {

        console.log('ActiveVideoType >>> ', ActiveVideoType);
        console.log('item0 >>> ', item0);
        
        let params = {
            "user_id": userId,
            "slug": item0.company_slug,
            "date": item0.date
          }

        if(ActiveVideoType === 3){
              setActiveItem(item0?.company?.id);
        }else{
              setActiveItem(item0?.date);

          }
        
        dispatch(forumDetailAction(params));
    }

    useEffect(() => {
        if(!MyBlogListLoading){
            setFilterVideoData(MyBlogListData?.data);
            // if(detailLoading){
              let a0 = MyBlogListData?.data[0];
              setActiveItem(a0?.date);
              // console.log("a0 >>>>>>> ", a0?.date)
              let params = {
                      "user_id": userId,
                      "slug": a0.company_slug,
                      "date": a0.date
                  }
                  
              dispatch(forumDetailAction(params));
            // }
        }

    }, [MyBlogListLoading])

    useEffect(() => {
        console.log('ActiveItem >>>> ', ActiveItem)
    }, [ActiveItem])
    

    
    const UI1 = (item_0) => {
        
        let item = item_0.company;
        let labelName = item?.first_name;

        return (
            <>
                <ListItem disablePadding  className={ActiveItem === item_0?.date ? "activeListCls" : ""}>
                    <ListItemButton onClick={()=>{
                        ActiveItemFun(item_0)
                    }}>
                    <Box sx={{ 
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        width:'100%'
                        }}>
                        <Box>
                        <Box sx={{ 
                            display:'flex',
                            // justifyContent:'space-between',
                            justifyContent:'flex-start',
                            alignItems:'center',
                            gap:1
                        }}>
                            {/* <div className='vid-icon'>
                            <PlayCircleIcon />
                            </div> */}
                            <div className='vid-title'>
                            <strong>{moment(item_0?.date).format('DD MMM, YYYY, dddd')}</strong>
                            <div>
                                <b><small>{labelName}</small></b>
                            </div>
                            </div>
                        </Box>
                        </Box>
                        {/* <div>
                        <div className='vid-date'>
                            {item?.dateTime}
                        </div>
                        </div> */}
                    </Box>
                    </ListItemButton>
                </ListItem>
            </>
        )
    }
    
    const UI2 = (item_0) => {
        // console.log('Blogs item_0 >>>> ', item_0)
        let item = item_0.company;
        let item_blogdata = null;
            item_blogdata = (item_0?.blogdata && item_0?.blogdata.length > 0) ? item_0?.blogdata[0] : null;
        
        return (
            <>
                <ListItem disablePadding  className={ActiveItem === item?.id ? "activeListCls" : ""}>
                    <ListItemButton onClick={()=>{
                    ActiveItemFun(item_0)
                    }}>
                    <Box sx={{ 
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        width:'100%'
                        }}>
                        <Box>
                            <Box sx={{ 
                                display:'flex',
                                // justifyContent:'space-between',
                                justifyContent:'flex-start',
                                alignItems:'center',
                                gap:1
                            }}>
                                <div className='vid-title'>
                                    {item?.first_name}
                                <div>
                                    <b><small>{item_blogdata?.c_type}</small></b>
                                </div>
                                </div>
                            </Box>
                        </Box>
                        {/* <div>
                        <div className='vid-date'>
                            {item?.dateTime}
                        </div>
                        </div> */}
                    </Box>
                    </ListItemButton>
                </ListItem>
            </>
        )
    }







  return (
    <>
    
    <div className={`video-left`}>
      

      <Box
        sx={{ }}
      >
        {
          MyBlogListLoading ?
          <>
            <CircularProgress />
          </>
          :
          <>
          
            <List sx={{ position: "relative" }} className="video-list">
              { FilterVideoData &&
                FilterVideoData.length > 0 &&
                FilterVideoData.map((item, index) => {
             
                  return (
                    <React.Fragment key={index}>
                        {
                            ActiveVideoType === 2 && (
                                <>
                                    {UI1(item)}
                                </>
                            )
                        }
                        {
                            ActiveVideoType === 3 && (
                                <>
                                    {UI2(item)}
                                </>
                            )
                        }
                    </React.Fragment>
                  );
                })}
            </List>
          </>
        }

        
      </Box>
    </div>
    
    </>
  )
}

export default LeftSection