import { Box, Typography, Grid, CircularProgress, IconButton, TextField, InputAdornment } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import VID_PLAY_ICON from './../vid_play_icon.svg';
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
// import { mediaModelAction, vdrMediaDataAction } from "../../../redux/actions/VdrAction";
import axios from "axios";
import $ from 'jquery';
import { crtUserId } from "../../../../constants/helpers";
import { mediaModelAction, vdrMediaDataAction } from "../../../../redux/actions/VdrAction";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
// import { crtUserId, loggedInUser, showCalendarActionBtn } from "../../../constants/helpers";

function get_vimeo_thumbnail(url, imgId, size = null, callback = null) {

  axios.get(`https://vasudeep.com:8084/https://player.vimeo.com/video/${url[0]}/config?h=${url[1]}`)
      .then(function (response) {
          var data = response.data;
          $(`#${imgId}`).attr('src', data.video.thumbs.base)
          $(`#title${url[0]}`).text(data.video?.title)
      })
      .catch(function (error) {
      });
}












const MediaSection = (props) => {


    const [ActiveVideoType, setActiveVideoType] = React.useState(1);
    const [FilterVideoData, setFilterVideoData] = React.useState([]);
    const [VidSortStatus, setVidSortStatus] = React.useState(false);
    const [ActiveImage, setActiveImage] = React.useState("");
    const [ActiveVideoItem, setActiveVideoItem] = React.useState("");
    const [ToBeUpdated, setToBeUpdated] = React.useState(false);
    const [VDRMediaMyFavouriteListData1, setVDRMediaMyFavouriteList] = React.useState(null);
    const [SearchWidth, setSearchWidth] = React.useState(342);


  const { companyId, setOpenForm, toggleDrawer, isCD } = props;

  const dispatch = useDispatch();
  // const crtUser = loggedInUser
  const { data, loading } = useSelector(state => state.vdrMediaDataReducer);
  
  

  
  const sortVideos = (sortStatus=false, nextLoad=false, crtTab=ActiveVideoType) => {
    let fData = data.Data;
    
    // if(crtTab === 1){
    //     fData = fData.filter(item00=>{ if (item00.DocumentType !== "") return item00 });
    // }else
    // if(crtTab === 2){
    //     fData = fData.filter((items) => { if (items.DocumentType === 'Common') return items });
    // }else
    // if(crtTab === 3){
    //     fData = fData.filter((items) => { if (items.DocumentType === 'SINGLE VIDEO') return items });
    // }
    // else

    

    if(!nextLoad){
        fData.sort((a,b)=>{
            return new Date(b.dateTime) - new Date(a.dateTime)
        })
    }else{
        if(sortStatus){
            
          console.log('VidSortStatus')
            if(!VidSortStatus){
              console.log('VidSortStatus')
                fData.sort((a,b)=>{
                    return new Date(a.dateTime) - new Date(b.dateTime)
                })
            }else{
                fData.sort((a,b)=>{
                    return new Date(b.dateTime) - new Date(a.dateTime)
                })
            }
            setVidSortStatus(!VidSortStatus)
        }else{
          console.log('VidSortStatus fData')
            fData.sort((a,b)=>{
                return new Date(b.dateTime) - new Date(a.dateTime)
            })
            setVidSortStatus(false);
        }
    }
    
    // console.log({fData,VidSortStatus, sortStatus, nextLoad,ActiveVideoType} )


if(fData.length === 0){
      setToBeUpdated(true); 
  }else{
      setToBeUpdated(false); 
  }


    
    setFilterVideoData(fData);
}


const filterSearch = (e) => {
  let searchedVal = e.target.value; 
  setToBeUpdated(false);
  const filteredRows = FilterVideoData.filter((row, i)=>{
    return String(row?.videoTitle).toLowerCase().includes(searchedVal.toLowerCase());
  })
  if (searchedVal.length < 1) {
      sortVideos(false, true, ActiveVideoType);
      // console.log('searchedVal >>>>> ', searchedVal)
  }
  else
  {
      setFilterVideoData(filteredRows);
      if(filteredRows.length === 0){
          setToBeUpdated(true);
      }
  }
}


  useEffect(() => {
    if(loading){
        let params = [{"CompanyID":companyId,"userid":crtUserId(),"videoCode":"","videoId":"","videoType":"","videoTitle":"","videoDescription":"","DocumentType":"","SectorID":"","IndustryID":[], "Type":"SC_Media"}];
        dispatch(vdrMediaDataAction(params))
    }
    if(!loading){
      if(data?.Data && data?.Data.length === 0){
        setToBeUpdated(true);
      }
        setFilterVideoData(data?.Data)
    }

  }, [dispatch, loading]);

  let VideoArr = data.Data;
  
  return (
    <>
      <Box className="valuation-box" style={{
        minHeight:'100vh'
       }}>
        <div className="box-head-sec">
          <div>
            {/* <h3>Media Room</h3> */}
          </div>
          <div>
            <button onClick={(e)=>{
                    setOpenForm('AddVideo')
                    toggleDrawer('bottom', true)(e)
                }} className="btn primary-btn">Add Video</button>
          </div>
        </div>
        
        <div className="box-body-sec">
        <div className='vid-head-sec'>
            <Box className='vid-head-search' mt={2}>
                { <div>
                    <TextField  onChange={(e)=>filterSearch(e)}
                        id="search-field"
                        size='small'
                        placeholder="Search"
                        fullWidth
                        sx={{ m: 1, width: SearchWidth, borderColor: '#000' }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">
                                <FiSearch color="#000" />
                            </InputAdornment>,
                            className:'searchBox',
                            style: { color: '#000' },
                            sx: {
                            '&::placeholder': {
                                color: '#000',
                                opacity: 1, // otherwise firefox shows a lighter color
                            },
                            },
                        }}
                        />
                </div> }
            </Box>
        </div>


        <Box className="videoContainer sc-videoContainer" sx={{
            // marginTop: 5, marginBottom: 5
        }}>
            <div className='vid-body-sec'>
                <Box >
                    {
                      !ToBeUpdated ? 
                      <>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <LeftSection
                                VidSortStatus={VidSortStatus}
                                setVidSortStatus={setVidSortStatus}
                                FilterVideoData={FilterVideoData}
                                setFilterVideoData={setFilterVideoData}
                                ActiveImage={ActiveImage}
                                setActiveImage={setActiveImage}
                                sortVideosTypeFun={sortVideos}
                                ActiveVideoType={ActiveVideoType}
                                setActiveVideoType={setActiveVideoType}
                                ActiveVideoItem={ActiveVideoItem}
                                setActiveVideoItem={setActiveVideoItem}
                                ToBeUpdated={ToBeUpdated}
                                setToBeUpdated={setToBeUpdated}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <RightSection
                                ActiveImage={ActiveImage}
                                setActiveImage={setActiveImage}
                                ActiveVideoItem={ActiveVideoItem}
                                setActiveVideoItem={setActiveVideoItem}
                            />
                        </Grid>
                    </Grid>
                      </>
                      :
                      <>
                         <Typography sx={{
                
              }} variant="h6">Videos Not Found</Typography>
                      </>
                    }
                    
                </Box>
            </div>
        </Box>

        

          {/* {
            loading && (
              <>
                <CircularProgress />
              </>
            )
          }
          <Grid container spacing={2}>
            {
              VideoArr && VideoArr.length > 0 && VideoArr.map((item, i)=>{

                var videoThumbnail;
                var vCode = item.videoCode ? item.videoCode.split('/') : [];

                if (item.videoType === 'vimeo') {
                      console.log(vCode);
                    videoThumbnail = get_vimeo_thumbnail(vCode, `imgId${vCode[0]}`);
                } else {
                    videoThumbnail = `https://img.youtube.com/vi/${item.videoCode}/mqdefault.jpg`;
                }


                return (
                  <Fragment key={i}>
                    <Grid item xs={2}>
                      <Box style={{ 
                        position:'relative'
                       }}>

                          <div className="video-card" onClick={() => dispatch(mediaModelAction({ isOpen: true, type: item.videoType, itemId: item.videoCode }))}>
                            <div className="vid-thumb" style={{ cursor:'pointer' }}>
                              <img id={`imgId${vCode[0]}`} src={videoThumbnail} alt={item.title} />
                              <div className="play-icon">
                                <img src={VID_PLAY_ICON} alt="" />
                              </div>
                            </div>
                            <Typography variant="h5">{item.videoTitle}</Typography>
                            <Typography variant="h6" sx={{ 
                              fontSize:'.8rem'
                             }}>12 Oct, 2023</Typography>
                          </div>
                          <div>
                            
                          </div>
                       </Box>
                    </Grid>
                  </Fragment>
                )
              })
            }
            
          </Grid>
          {
            VideoArr && VideoArr.length === 0 && (
              <>
                <Typography sx={{ 
                  paddingTop:'1rem'
                 }}>To be updated</Typography>
              </>
            )
          } */}



        </div>
      </Box>
    </>
  );
};

export default MediaSection;
