import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { CompaniesComponent, InputComponent } from '../../Common'
import { useSelector, useDispatch } from 'react-redux'
import MuiAutoComplete from '../../Common/MuiAutoComplete';
import { ideaType, shortAsc, recommendationArr, vimeo_parser, youtube_parser, loggedInUser, crtUserId } from '../../../constants/helpers';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { companyMasterAction } from '../../../redux/actions/userAction';
import { vdrCompanyAction, vdrMediaAction, vdrMediaDataAction } from '../../../redux/actions/VdrAction';
// import vimeo from vimeo in react
// import Vimeo from '@uuseb/vimeo';

const Add = (props) => {

    const [companyMasterArr, setCompanyMasterArr] = useState([]);

    const [companyTypeArr, setCompanyTypeArr] = useState(ideaType);
    // const recommendationArr = [];
    const [selectedCompanyData, setSelectedCompanyData] = useState(null);
    const [SelectedSite, setSelectedSite] = useState(null);
    const [SelectedVideoType, setSelectedVideoType] = useState(null);

    const [NewVideoCode, setNewVideoCode] = useState("");

    const [selectedRecommendationData, setSelectedRecommendationData] = useState(null);

    const [checked, setChecked] = React.useState(false);
    const [UpdateState, setUpdateState] = useState(false);

    const [tags, setTags] = React.useState([])
    const {
        singleCompanyData,
        parentGroup,
        levelType,
        inputType,
        companyId,
        sectorId,
        toggleDrawer
    } = props;

    // console.log(singleCompanyData)

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const dispatch = useDispatch();
    const { data:videoData, loading:videoLoading } = useSelector(state => state.vdrMediaDataReducer);
    const { data: companyMaster, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
    // const { data: companyData, loading: companyLoading, loadingAdd } = useSelector((state) => state.vdrCompanyReducer);
    // const { data: companyData, loading: companyLoading } = useSelector(state => state.singleCompanyReducer);

    // const { data: companyData, loading: companyLoading } = useSelector(state => state.singleCompanyReducer);

    const { control, handleSubmit, register, reset } = useForm({
        defaultValues: {
            
        }
    });

    const [value, setValue] = React.useState([]);

    const onSubmit = async (data) => {

        data = { ...data, videoType: SelectedSite.value };
        let videoCode = "none";
        if(data.videoType){
            if (data.videoType == 'vimeo') {
                videoCode = vimeo_parser(data.videoCode);
            } else {
                // console.log(data.videoCode);
                videoCode = youtube_parser(data.videoCode);
            }
        }

        var IndustryIDArr = [];
        var videoParams = [
            {
              "CompanyID": companyId,
              "userid": crtUserId(),
              "videoCode": videoCode,
              "videoId": "",
              "videoType": SelectedSite?.value,
              "videoTitle": data?.videoTitle,
              "videoDescription": "",
              "Type": "SC_Media",
              "DocumentType": SelectedVideoType?.value,
              "SectorID": singleCompanyData?.SectorID,
              "IndustryID": IndustryIDArr
            }
          ];

        //   console.log(videoParams);

        dispatch(vdrMediaDataAction(videoParams));
        if(videoLoading === false){
            toggleDrawer('bottom', false)();
        }
        
    };

    const selectCompany = () => {
        if (companyMaster.length > 0) {
            var data1 = [];
            companyMaster.map((item) => {
                var d1 = { title: item.CompanyName, value: item.CompanyID };
                data1.push(d1);
            })
            shortAsc(data1)
            setCompanyMasterArr(data1);
        }
    }

    const getUniqueListBy = (arr) => {
        return [...new Map(arr.map(item => [item, item])).values()]
    }

    const setTagsFunction = (tags1) => {
        setTags(getUniqueListBy(tags1))
        // console.log(tags1)
    }


    useEffect(() => {
        // console.log(companyData);
        if (!companyMasterLoading) {
            selectCompany();
        }
        // if (singleCompanyData) {
        //         if(singleCompanyData?.videoType === 'vimeo'){
        //             var a = "https://vimeo.com/" + singleCompanyData?.videoCode;
        //         }else{
        //             var a = "https://www.youtube.com/watch?v=" + singleCompanyData?.videoCode;
        //         }
        //         setNewVideoCode(a);
        //         reset({
        //             videoCode: a,
        //         })
        //         setChecked(singleCompanyData?.isFeatured);
        //     setSelectedSite({
        //         title: singleCompanyData?.videoType.charAt(0).toUpperCase() + singleCompanyData?.videoType.substring(1),
        //         value: singleCompanyData?.videoType
        //     });

        // }
    }, [dispatch, props, companyMasterLoading]);

    // useEffect(() => {
    //   if(videoLoading === false ){
    //     console.log('videoLoading', videoLoading)
    //   }
    // }, [videoLoading])
    


    const selectSitesArr = [
        { title: 'Select Site Type', value: 0 },
        { title: 'Youtube', value: 'youtube' },
        { title: 'Vimeo', value: 'vimeo' },
    ];


    const selectVideoArr = [
        { title: 'Select Site Type', value: 0 },
        { title: 'Single Company', value: 'SINGLE VIDEO' },
        { title: 'Sector Video', value: 'Sector Video' },
    ];
    return (
        <>
    
            <Box sx={{
                width: '30%',
                margin: "1rem auto"
            }}>
                <Typography variant="h5" fontSize={20} sx={{ margin: '1rem 0' }}>
                    {inputType && inputType ? 'Update' : 'Add'} Video
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <Grid container spacing={1}>

                        <input type="hidden" defaultValue={inputType || 0} {...register("Inputtype")} />

                        <Grid item xs={12} >
                            <MuiAutoComplete
                                {...register("videoType")}
                                placeholder="Select Site Type"
                                className='cInput'
                                readOnly={inputType ? true : false}
                                options={selectSitesArr && selectSitesArr}
                                multiple={false}
                                value={SelectedSite}
                                label="Select Site Type"
                                onChange={(event, newInputValue) => {
                                    var val1 = [];
                                    setSelectedSite(newInputValue);
                                    // console.log(newInputValue)
                                }}
                            />
                        </Grid>
                        
                        <Grid item xs={12} >
                            <MuiAutoComplete
                                {...register("videoType")}
                                placeholder="Select Video Type"
                                className='cInput'
                                readOnly={inputType ? true : false}
                                options={selectVideoArr && selectVideoArr}
                                multiple={false}
                                value={SelectedVideoType}
                                label="Select Video Type"
                                onChange={(event, newInputValue) => {
                                    var val1 = [];
                                    setSelectedVideoType(newInputValue);
                                    // console.log(newInputValue)
                                }}
                            />
                        </Grid>

                        {/* <Grid item xs={12} >
                            <Autocomplete
                                multiple
                                id="fixed-tags-demo"
                                className='cInput'
                                value={value}
                                size="small"
                                onChange={(event, newValue) => {
                                    // setValue([
                                    // ...fixedOptions,
                                    // ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
                                    // ]);
                                }}
                                options={top100Films}
                                getOptionLabel={(option) => option.title}
                                // renderTags={(tagValue, getTagProps) =>
                                //     tagValue.map((option, index) => (
                                //     <Chip
                                //         label={option.title}
                                //         {...getTagProps({ index })}
                                //         disabled={fixedOptions.indexOf(option) !== -1}
                                //     />
                                //     ))
                                // }
                                // style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Fixed tag" placeholder="Favorites" />
                                )}
                            />
                        </Grid> */}
                        
                        <Grid item xs={12} >
                            <InputComponent
                                name="videoTitle"
                                label="Video Title"
                                defaultValue={null}
                                control={control}
                                size="small"
                                placeholder="Enter Video Title"
                                className='cInput'
                            />
                        </Grid>
                        
                        <Grid item xs={12} >
                            <InputComponent
                                name="videoCode"
                                label="Video Link"
                                defaultValue={null}
                                control={control}
                                size="small"
                                placeholder="Enter Video Link"
                                className='cInput'
                            />
                            
                        </Grid>

                        {/* <Grid item xs={12} sm={6} >

                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value={checked}
                                        control={<Checkbox
                                            {...register("isFeatured")}
                                            checked={checked}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }} />}
                                        label="Pinned"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid> */}


                        <Grid item xs={12} sx={{ 
                            marginTop:2,
                            gap: '.75rem',
                            display:'flex',
                         }}>
                            {
                                inputType && inputType ?
                                    <>
                                        <Button type="submit" variant="contained">Update</Button>
                                    </> :
                                    <>
                                        <Button type="submit" disabled={videoLoading} variant="contained">{videoLoading === false ? 'Submit' : "Please Wait..."}</Button>
                                    </>
                            }
                            
                            <Button type="button" onClick={(e)=>{
                                toggleDrawer('bottom', false)(e)
                            }} variant="contained" color='error'>Close</Button>
                        </Grid>
                    </Grid>

                </form>

            </Box>
        </>
    )
}

export default Add