import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";

import { FaSortAlphaDown, FaSortAlphaUp, FaRegEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";
import { valuationFilterInputAction } from "../../../../redux/actions/userAction";
import { AiOutlineFilePdf } from "react-icons/ai";
import { dateFormat } from "../../../../constants/helpers";
import moment from "moment";

import { BiSortDown, BiSortUp } from "react-icons/bi";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import { PDF_Icon } from "../../sc_images";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { TLAnnualReportAction } from "../../../../redux/actions/VdrAction";
const baseURL = "https://jsonplaceholder.typicode.com/posts/1";

const AnnualReportsComp = (props) => {
  const {
    listData,
    toggleDrawer,
    DataRoomDocDetail,
    setOpenForm,
    setDataRoomDocDetail,
    title,
    deleteFunction,
  } = props;

  const {
    AnnualReport:{
      data: AnnualReportData,
      loading: AnnualReportLoading
    }
  } = useSelector(state=>state.TrendlyneDataReducer)

  const { data: CompanyNoteData, loading: CompanyNoteLoading } = useSelector(state => state.singleCompanyNoteReducer);


  const [post, setPost] = React.useState(null);

  useEffect(() => {}, [props]);



  // useEffect(async () => {
  //   let response = await searchByDate();
  //   console.log("bm===",response.data.body.newsList)
  //   setFinalOutputData(response.data.body.newsList);
  // }, []);

  const shotButton = useRef();

  let cstHeight = 300;
      cstHeight = 340;

  const [arrowIcon, setArrowIcon] = useState(
    <RiArrowDownSLine fontSize={25} className="sc_arrow_color" />
  );
  const [ToggleData, setToggleData] = useState(true);
  const [DivHeight, setDivHeight] = useState(cstHeight);
  const [name, setName] = useState("");
  // const [ActiveBtn, setActiveBtn] = useState(0);
  const listContainer = useRef();
  const searchContainer = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [SortType, setSortType] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

const dispatch = useDispatch()



const [TL_ListData, setTL_ListData] = useState([]);

let compSlug = "INFY";
if(CompanyNoteData?.Data && CompanyNoteData?.Data.length > 0){
  compSlug = (CompanyNoteData?.Data[0].BSEcode || CompanyNoteData?.Data[0].NSEcode);
}

  useEffect(() => {
    if(CompanyNoteData?.Data && CompanyNoteData?.Data.length > 0){
      console.log('AnnualReportLoading >>> ', AnnualReportLoading, CompanyNoteData?.Data.length)
      if(AnnualReportLoading){
        dispatch(TLAnnualReportAction(compSlug));
      }
      if(!AnnualReportLoading){
        let newArr = [];

        AnnualReportData.map((item, i)=>{
          let item0 = item;
          // let nTitle = 'AR_FY'+moment(item0?.pubDate).format('YYYY')
          let nTitle = 'Financial Year '+moment(item0?.pubDate).format('YYYY')
          item0 = {...item0, n_title:nTitle};
          
          newArr.push(item0);
        }) 
        setTL_ListData(newArr);
        setFinalOutputData(newArr);
        console.log('AnnualReportLoading >>>>> ', newArr)
      }
    }
  }, [AnnualReportLoading, CompanyNoteLoading])
  

  // useEffect(() => {
  //   dispatch({ type: 'AnnualReportRequest' });
  // }, [props]);

  const buttonsArr = [
    {
      id: 1,
      label: "All",
    },
    {
      id: 2,
      label: "Initial Coverage",
    },
    {
      id: 3,
      label: "Quarterly Update",
    },
    {
      id: 4,
      label: "Forensic",
    },
    {
      id: 5,
      label: "Brief Notes",
    },
    {
      id: 6,
      label: "Others",
    },
  ];
  const [finalOutputData, setFinalOutputData] = useState(TL_ListData);

  const filterfinaloutput = (crtTab) => {
    // a ='all'
    // a.label
    let fData = [];
    if (crtTab.label !== "All") {
      fData = TL_ListData.filter((items) => {
        if (items.DocumentType === crtTab.label) return items;
      });
    } else {
      fData = TL_ListData.filter((items) => {
        if (items.DocumentType !== "") return items;
      });
    }

    setFinalOutputData(fData);
  };

  const [ActiveBtn, setActiveBtn] = useState(1);

  const chooseType = (item) => {
    // alert('sasa');
    filterfinaloutput(item);
    setActiveBtn(item.id);
  };

  const sortData = (itemData, type) => {
    let sData;
// console.log(SortType,
//   ActiveBtn,
//   ToggleData)
//   return ;
    if (SortType === "name") {
      if (ToggleData) {
        sData = finalOutputData.sort((a, b) =>
          // a.fileName.localeCompare(b.fileName)
          a.n_title.localeCompare(b.n_title)
        );
      } else {
        sData = finalOutputData.sort((a, b) =>
          // b.fileName.localeCompare(a.fileName)
          b.n_title.localeCompare(a.n_title)
        );
      }
      
    } else {
      if (ToggleData) {
        sData = finalOutputData.sort((a, b) => {
          // var a1 = moment(a.pubDate, "DD-MM-YYYY HH:mm:ss", true).format("DD-MMM-YYYY HH:mm:ss"); //a.pubDate
          let a1 = a.pubDate
          // var b1 = moment(b.pubDate, "DD-MM-YYYY HH:mm:ss", true).format("DD-MMM-YYYY HH:mm:ss"); //b.pubDate
          let b1 = b.pubDate
          var dd = new Date(a1) - new Date(b1);
          return dd;
        });
      } else {
        sData = finalOutputData.sort((a, b) => {
          // var a1 = moment(a.pubDate, "DD-MM-YYYY HH:mm:ss", true).format("DD-MMM-YYYY HH:mm:ss"); //a.pubDate
          let a1 = a.pubDate
          // var b1 = moment(b.pubDate, "DD-MM-YYYY HH:mm:ss", true).format("DD-MMM-YYYY HH:mm:ss"); //b.pubDate
          let b1 = b.pubDate
          var dd = new Date(b1) - new Date(a1);
          return dd;
        });
      }
      
      console.log('ToggleData >>> ', ToggleData, ActiveBtn)
    }

    setFinalOutputData(sData);
    setToggleData(!ToggleData);
  };
  const onUpdateHeight = () => {
    if (DivHeight === cstHeight) {
      setArrowIcon(<RiArrowUpSLine fontSize={25} className="sc_arrow_color" />);
      setDivHeight("auto");
    } else {
      setArrowIcon(
        <RiArrowDownSLine fontSize={25} className="sc_arrow_color" />
      );
      setDivHeight(cstHeight);
    }
  };

  const handleChange = (event, itemData) => {
    // console.log(itemData);
    let arrNew = [];
    itemData.forEach(function (a) {
      var fName = a.n_title.toLowerCase();
      var nVal = event.target.value.toLowerCase();
      // console.log(fName, "<=>", nVal);
      if (fName.indexOf(nVal) > -1) {
        arrNew.push(a);
      }
    });
    setFinalOutputData(arrNew);
    setName(event.target.value);
  };

  

  return (
    <>
      <Box className="cst-box">
        <Box className="c-head-sec00">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: ".5rem",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              Annual Reports
            </Typography>
            {/* <div>
                    <FaSortAlphaUp className='text-primary' />
                </div> */}

            <>
              <IconButton
                ref={shotButton}
                size="small"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}

                // onClick={() => sortData(itemData, (title || 'Final Output'))}
              >
                {
                  ToggleData ? (
                    <FaSortAlphaDown className="text-primary" />
                  ) : (
                    // <BiSortDown />
                    <FaSortAlphaUp className="text-primary" />
                  )
                  // <BiSortUp />
                }
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => [
                    sortData(finalOutputData, title || "Final Output"),
                    handleClose(),
                    setSortType("date"),
                  ]}
                >
                  Sort by Date
                </MenuItem>
                {/* <MenuItem
                  onClick={() => [
                    sortData(finalOutputData, title || "Final Output"),
                    handleClose(),
                    setSortType("name"),
                  ]}
                >
                  Sort by Name
                </MenuItem> */}
              </Menu>
            </>
          </Box>
          {/* <Box style={{ paddingBottom: "8px" }}>
            <div><p dangerouslySetInnerHTML={{ __html:"&nbsp;" }} /></div>
            <div
              style={{
                fontWeight: "600",
                color: "#767676",
              }}
            >
              <p dangerouslySetInnerHTML={{ __html:"&nbsp;" }} />
            </div>
          </Box> */}
          {/* <Box style={{ paddingBottom: "8px" }}>
            <div>Filename Format:</div>
            <div
              style={{
                fontWeight: "600",
                color: "#767676",
              }}
            >
              AR_FY2023
            </div>
          </Box> */}

          <Box>
            <TextField
              onChange={(e) => handleChange(e, TL_ListData && TL_ListData)}
              sx={{
                width: "99%",
                padding: 0,
              }}
              size="small"
              label="Search Document"
              id="fullWidth"
              value={name}
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: "gray" }} />,
              }}
              InputLabelProps={{
                style: { fontSize: 12, padding: 0, margin: 0 },
              }}
              inputProps={{
                style: { padding: "0.4rem", fontSize: 15 },
              }}
            />
          </Box>
        </Box>

        <Box
          className="cst-box-body"
          sx={{
            minHeight: cstHeight,
            height: DivHeight,
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {finalOutputData &&
              finalOutputData.map((value, i) => {
                let fileLink = "";
                let fileExtension = "";
                fileLink = value.pdfUrl;
                fileExtension = fileLink.split(".").pop();
                if (fileExtension.toLowerCase() === "pdf") {
                  // fileLink = `/company-detail/MyPdfViewer`;
                  if(window.location.href.includes('company-detail')){
                    fileLink = `/company-detail/MyPdfViewer`;
                  }else{
                    fileLink = `/MyPdfViewer`;
                  }
                }

                value = { ...value };

                return (
                  <React.Fragment key={i}>
                    <ListItem
                      disableGutters
                      sx={{ 
                        padding:"0.5rem 0"
                       }}
                      // secondaryAction={
                      //   <>
                      //     <IconButton
                      //       style={{ right: ".5rem" }}
                      //       aria-label="comment"
                      //       onClick={(e) => {
                      //         toggleDrawer("bottom", true)(e);
                      //         setOpenForm("UsersSendNotification");
                      //         setDataRoomDocDetail({
                      //           ...DataRoomDocDetail,
                      //           docId: value?.stockId,
                      //           dateTime: value?.pubDate,
                      //           docName: value?.title,
                      //         });
                      //       }}
                      //     >
                      //       <FaRegEnvelope
                      //         size="1rem"
                      //         className="text-primary"
                      //       />
                      //     </IconButton>
                      //   </>
                      // }
                    >
                      <ListItemButton
                          sx={{
                            padding: "0",
                            margin: "0",
                          }}
                          component={Link}
                          target="_blank"
                          to={fileLink}
                          onClick={(e) => {
                            console.table(value);
                            localStorage.setItem(
                              "PDFViewed",
                              window.btoa(value.pdfUrl)
                            );
                            localStorage.setItem(
                              "itemData1",
                              JSON.stringify(value)
                            );
                            localStorage.setItem(
                              "file_type",
                              title || "Final Output"
                            );
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              padding: "0px",
                              minWidth: "auto",
                              paddingRight: "0.3rem",
                            }}
                          >
                            <img
                              src={PDF_Icon}
                              alt="PDF_Icon"
                              className="svc_pdf_img"
                            />
                          </ListItemIcon>
                          <ListItemText
                            className="data-room-doc"
                            primary={
                              <Typography
                                variant="subtitle2"
                                className="docItem text-color1 dft-text"
                                sx={{
                                  // color: "#0C374D",
                                  // fontWeight:'400',
                                  // fontSize: {
                                  //   xs: 10,
                                  //   sm: 10,
                                  //   md: 11,
                                  //   lg: 13,
                                  //   xl: 14,
                                  // },
                                }}
                              >
                                {value.n_title}
                              </Typography>
                            }
                            secondary={
                              <>
                                <Box display={"flex"}>
                                  <div
                                    style={{
                                      display: "flex",
                                      fontSize: "10px",
                                      gap: "5px",
                                    }}
                                  >
                                    <div>
                                      {/* <strong>N/A</strong> */}
                                    </div>
                                    <div>
                                      {/* {moment(value?.pubDate).format('DD-MMM-YYYY HH:mm:ss')} */}
                                    </div>
                                    <div>{value?.DocumentType}</div>
                                  </div>
                                </Box>
                              </>
                            }
                          />
                        </ListItemButton>

                      {/* <Box>
                          <Typography sx={{ fontSize:'12px' }}>{value?.fileName}</Typography>
                          <div style={{
                            display:'flex',
                            fontSize:'10px',
                            gap:'5px'
                            }}>
                            <div><strong>{value?.UserName}</strong></div>
                            <div>{value?.pubDate}</div>
                            <div>{value?.DocumentType}</div>
                          </div>
                        </Box> */}
                    </ListItem>
                    {/* <Divider /> */}
                  </React.Fragment>
                );
              })}
          </List>
        </Box>
        <Box className="footer-section" sx={{}}>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton size="small" onClick={() => onUpdateHeight()}>
              {arrowIcon}
            </IconButton>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default AnnualReportsComp;
