import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { vdrGroupAction, vdrUpdateGroupAction } from '../../../redux/actions/VdrAction';
import { InputComponent } from '../../Common'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DocumentNotes, getForensicUpdateTitle } from '../../../constants/helpers';
import MuiAutoComplete from '../../Common/MuiAutoComplete';
import { ForensicModelCommentsAction, MultipleFileUploaderAction, SingleCompanyAboutUsUpdateAction, SingleCompanyCommentsAction } from '../../../redux/actions/userAction';
import Editor from '../UpdateAboutForm/Editor';

const Add = (props) => {

    
    const {
        groupData,
        levelType,
        inputType,
        companyId,
        toggleDrawer,
        itemData,
        parentGroup
    } = props;

    // console.log('props >> ', itemData)
    const { data: companyData, loading: companyLoading } = useSelector((state) => state.singleCompanyReducer);

    const [checked, setChecked] = React.useState(false);
    const [Doc, setDoc] = React.useState([]);
    const [DocName, setDocName] = React.useState([]);
    const [TextData, setTextData] = React.useState("");

    const [DocTypeData, setDocTypeData] = React.useState([]);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const [checked1, setChecked1] = React.useState(false);

    const handleChange1 = (event) => {
        setChecked1(event.target.checked);
        // console.log(checked1);
    };

    const dispatch = useDispatch();
    const { control, handleSubmit, register, reset } = useForm({
        defaultValues: {
            status: 1,
        }
    });

    // console.log(itemData);

    const updateTitle = getForensicUpdateTitle(itemData?.Type, itemData?.typeData);

    // console.log('updateTitle >>>> ', updateTitle);

    useEffect(() => {
        setChecked(groupData?.enable_number ? true : false);
        setChecked1(groupData?.sticky  ? true : false);
        setTextData(itemData?.description ? itemData?.description : "");
        reset(groupData)
    }, [dispatch, props])

    const onSubmit = (data) => {
        

        const dd = [{
                    "type":"1",
                    "userid":"1",
                    "CompanyID":companyData?.CompanyID,
                    "SectorID":companyData?.SectorID,
                    "IndustryID":companyData?.IndustryID,
                    "TableType": updateTitle?.type,
                    "description": TextData
                    }];

                    
        dispatch(ForensicModelCommentsAction(dd));
        toggleDrawer('bottom', false)();

    };

    return (
        <>
            <Box sx={{
                width: '30%',
                margin: "1rem auto"
            }}>
                <Typography variant="h5" fontSize={20} sx={{ margin: '1rem 0' }}>
                    Update {updateTitle?.title}
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        {/* <span>group_id</span> */}
                        <input type="hidden" defaultValue={groupData?.group_id || 0} {...register("group_id")} />
                        {/* <span>parent_id</span> */}
                        <input type="hidden" defaultValue={groupData?.parent_id || (parentGroup ? parentGroup.group_id : 0) || 0} {...register("parent_id")} />
                        {/* <span>level_type</span> */}
                        <input type="hidden" defaultValue={groupData?.level_type || levelType || 0} {...register("level_type")} />
                        {/* <span>Inputtype</span> */}
                        <input type="hidden" defaultValue={groupData?.Inputtype || inputType || 0} {...register("Inputtype")} />

                        {/* <Grid item xs={12} >
                            <MuiAutoComplete
                                {...register("recommendation")}
                                placeholder="Select Comment Type"
                                className='cInput'
                                options={DocumentNotes.length && DocumentNotes}
                                multiple={false}
                                label="Comment Type"
                                // value={selectedRecommendationData}
                                onChange={(event, newInputValue) => {
                                    var val1 = [];
                                    setDocTypeData(newInputValue);
                                    // console.log(newInputValue)
                                }}
                            />
                        </Grid>
                        
                        <Grid item xs={12} >
                        <label style={{ 
                                margin:0,
                                fontSize:12,
                                fontWeight:'500',
                             }}>Heading</label>
                            <TextField 
                                {...register("heading")}
                                name="heading"
                                label=""
                                size="small"
                                placeholder="Enter Heading"
                                className='cInput'
                            /> 
                        </Grid> */}
                        
                        <Grid item xs={12} >
                            <label style={{ 
                                margin:0,
                                fontSize:12,
                                fontWeight:'500',
                             }}>Description</label>
                            <Editor TextData={TextData} setTextData={setTextData} /> 
                        </Grid>


                        <Grid item xs={12}>
                            {
                                inputType ?
                                    <>
                                        <Button type="submit" variant="contained">Update</Button>
                                    </> :
                                    <>
                                        <Button type="submit" variant="contained">Submit</Button>
                                    </>

                            }
                        </Grid>
                    </Grid>

                </form>

            </Box>
        </>
    )
}

export default Add