import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import PieChart from './CustomChart/PieChart'
import {
    Tabs,
    Tab,
    Typography,
    Box
} from '@mui/material';
import ForensicItemTable from './ForensicItemTable';
import { useDispatch, useSelector } from 'react-redux';
import { ForensicTabShowHideAction } from './../../redux/actions/userAction'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const ForensicComponent = (props) => {
    const { companyId, setOpenForm, toggleDrawer, setParentGroup, dataFor } = props;
    const [value, setValue] = React.useState(0);

    const { data: companyData, loading: companyLoading } = useSelector((state) => state.vdrSingleCompanyReducer);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const {
        data:ForensicTabShowHide,
        loading:ForensicTabShowHideLoading
    } = useSelector(state=>state.ForensicTabShowHideReducer);
    const dispatch = useDispatch();
    const r_companyId = companyId || companyData.CompanyID;

    useEffect(() => {
      dispatch(ForensicTabShowHideAction({"companyID":r_companyId}))
    }, [dispatch])

    

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={value} onChange={handleChange} aria-label="basic tabs example">
                    {
                        ForensicTabShowHide?.Data && ForensicTabShowHide?.Data.filter((item)=>item.is_display).map((item, index)=>{
                            return (
                                <Tab sx={{ fontSize:'0.75rem' }} key={index} label={item.title} {...a11yProps(index)} />
                            )
                        })
                    }
                    </Tabs>
                </Box>
                {
                        ForensicTabShowHide?.Data && ForensicTabShowHide?.Data.filter((item)=>item.is_display).map((item, index)=>{
                            return (
                                <TabPanel key={index} value={value} index={index}>
                                    <ForensicItemTable dataFor={dataFor} setParentGroup={setParentGroup} setOpenForm={setOpenForm} toggleDrawer={toggleDrawer} companyId={r_companyId} title={item.title} typeData={item.type} />
                                </TabPanel> 
                            )
                        })
                    }
            </Box>
        </>
    )
}

export default ForensicComponent