import { Box } from "@mui/material";
import React, { useContext, useState } from "react";
import QuarterlyComp from "./InterimResults/QuarterlyComp";
import QuarterlyChartComp from "./InterimResults/QuarterlyChartComp";
import QuarterlySegment from "../../../components/SingleCompanyContainer/FinancialCharts/QuarterlySegment";
import QuarterlyReview from "../../../components/SingleCompanyContainer/FinancialCharts/QuarterlyReview";
import { useEffect } from "react";
import { quarterlyReviewAction } from "../../../redux/actions/VdrAction";
import { useDispatch, useSelector } from "react-redux";
import QuarterlyReviewComp from "./InterimResults/QuarterlyReviewComp";
import QuarterlySegmentComp from "./InterimResults/QuarterlySegmentComp";
import PriceChartComp from "./InterimResults/PriceChartComp";
import InCr from "./InCr";
import { GlobalContext } from "../../../context/GlobalContext";

const InterimResultsComp = (props) => {
  const {companyId, isCD } = props;

  const dispatch = useDispatch();
  const { data: quarterlyReviewData, loading: quarterlyReviewLoading } = useSelector(state => state.quarterlyReviewReducer);
  const { data: quarterlySegmentData, loading: quarterlySegmentLoading } = useSelector(state => state.quarterlySegmentReducer);
  const {
    data:QuarterlyResultsData,
    loading:QuarterlyResultsLoading
  } = useSelector(state=>state.QuarterlyResultsReducer)
  
  const [QuaterlySegmentType, setQuaterlySegmentType] = useState('Consolidated');
  const [ActivePrimaryBtn, setActivePrimaryBtn] = useState(1)

  const [BtnStatus, setBtnStatus] = useState(null)
  
  const [FirstLoad, setFirstLoad] = useState(true);

  let primaryBtnArr = [
    {
      "id":1,
      "label":"Result",
      "component": ""
    },
    {
      "id":2,
      "label":"Segments	",
      "component": ""
    },
    {
      "id":3,
      "label":"Latest Quarter",
      "component": ""
    },
    {
      "id":4,
      "label":"Chart",
      "component": ""
    }
  ]
  const {
    ActiveSecondaryBtn, setActiveSecondaryBtn
  } = useContext(GlobalContext)

  // const [ActiveSecondaryBtn, setActiveSecondaryBtn] = useState(1)
  let secondaryBtnArr = [
    {
      "id":1,
      "label":"Consolidated",
      "value":"Consolidated",
      "short_name":"con",
      "show_in":[1,2,3,4]
    },
    {
      "id":2,
      "label":"Standalone",
      "short_name":"std",
      "value":"Standalone",
      "show_in":[1,2,3,4]
    },
    // {
    //   "id":4,
    //   "label":"Price Chart",
    //   "value":"Price Chart",
    //   "show_in":[3]
    // },
    // {
    //   "id":3,
    //   "label":"Quarterly Chart",
    //   "show_in":[1]
    // }
  ]
  const ThemeColorData = useSelector(state=> state.ThemeColorReducer);

  useEffect(()=>{
      
  }, [ThemeColorData])

  useEffect(() => {
    if(quarterlyReviewLoading){
        dispatch(quarterlyReviewAction({companyID:companyId}));
    }
  }, [])

  useEffect(() => {
    if(!QuarterlyResultsLoading){
      setBtnStatus(QuarterlyResultsData.button_status)
      
    }
  }, [ QuarterlyResultsLoading ])
  

  return (
    <>
      {/* <button className='btn primary-btn'>Download Image</button> */}


      <Box className="valuation-box">
        {
          !isCD && (

            <div className="box-head-sec">
            <div style={{ 
                display:'flex',
                width:'100%',
                justifyContent:'space-between',
                alignItems:'center',
              }}>
                <div style={{ 
                display:'flex',
                alignItems:'end',
                gap:'.5rem'
              }}>
              <h3>Quarter</h3>
              {/* <h3>Quarterly Results</h3> */}
                    <InCr />
                </div>

              </div>
              <div>
                {/* <button className="btn primary-btn-outline">Edit</button>
                <button className="btn primary-btn">Download Image</button> */}
              </div>
            </div>
          )
        }


    
        <div className="interim-btn-sec">
           <div style={{
            display:'flex',
            gap:'10px'
           }} className="">

            {
              secondaryBtnArr.map((item, i)=>{
                if(item.show_in.includes(ActiveSecondaryBtn)){
                  return (
                    <>
                  <button onClick={()=>{
                    if((BtnStatus && BtnStatus[item?.short_name])){
                      setQuaterlySegmentType(item?.value)
                      setActivePrimaryBtn(item.id);
                    }
                  }
                } key={i} 
                  disabled={(BtnStatus && BtnStatus[item?.short_name] === false)}
                className={`btn ${(ActivePrimaryBtn === item.id || QuaterlySegmentType === item?.value) ? "primary-btn" : "secondary-btn-outline"} ${(BtnStatus && BtnStatus[item?.short_name] === false) ? "cst_disabled" : ""}`}>{item?.label} </button>
                    </>
                  )
                }
              })
            }

            {/* {
              primaryBtnArr.map((item, i)=>{
                return (
                  <button onClick={()=>{
                    setActivePrimaryBtn(item.id); 
                    setActiveSecondaryBtn(1)
                  }
                  } key={i} className={`btn ${ActivePrimaryBtn === item.id ? "primary-btn" : "secondary-btn-outline"}`}>{item?.label}</button>
                )
              })
            } */}
            

           </div>
           <div className="aaaaaaa">
            <div className="btn-group">

              
            {
              primaryBtnArr.map((item, i)=>{
                return (
                  <>
                    <button onClick={()=>{
                        setFirstLoad(true);
                        setActiveSecondaryBtn(item.id);
                        setActivePrimaryBtn(1)
                        setQuaterlySegmentType("Consolidated")
                      }}  key={i} className={`btn ${ActiveSecondaryBtn === item.id ? "active" : ""}`}>{item?.label} </button>
                    </>
                )
              })
            }

            {/* {
              secondaryBtnArr.map((item, i)=>{
                if(item.show_in.includes(ActivePrimaryBtn)){
                  return (
                    <button onClick={()=>{
                      setActiveSecondaryBtn(item.id);
                      if(ActivePrimaryBtn === 2){
                        setQuaterlySegmentType(item?.value)
                      }
                    }}  key={i} className={`btn ${ActiveSecondaryBtn === item.id ? "active" : ""}`}>{item?.label}</button>
                  )
                }
              })
            } */}
              </div>
           </div>
           
        </div>
        
        <Box>
          
          {
            ActiveSecondaryBtn === 4 && 
            <QuarterlyChartComp companyId={companyId} secondaryBtnArr={secondaryBtnArr} ActivePrimaryBtn={ActivePrimaryBtn} setActivePrimaryBtn={setActivePrimaryBtn}  themeColor={ThemeColorData.chartColor} QuarterlySegmentType={QuaterlySegmentType} setQuarterlySegmentType={setQuaterlySegmentType} />
          }

          {
            ActiveSecondaryBtn === 1 && (
              <>
                
                {
                  (ActivePrimaryBtn === 1 || ActivePrimaryBtn === 2) && 
                  <QuarterlyComp FirstLoad={FirstLoad} secondaryBtnArr={secondaryBtnArr}  setFirstLoad={setFirstLoad} setActivePrimaryBtn={setActivePrimaryBtn} companyId={companyId} QuarterlySegmentType={QuaterlySegmentType} setQuarterlySegmentType={setQuaterlySegmentType} />
                }
              </>
            )
          }
          {
            ActiveSecondaryBtn === 2 && (
              <>
                <QuarterlySegmentComp FirstLoad={FirstLoad} secondaryBtnArr={secondaryBtnArr}  setFirstLoad={setFirstLoad} setActivePrimaryBtn={setActivePrimaryBtn} companyId={companyId} QuarterlySegmentType={QuaterlySegmentType} setQuarterlySegmentType={setQuaterlySegmentType} />
              </>
            )
          }
          {
            ActiveSecondaryBtn === 3 && (
              <>
                {
                  ActivePrimaryBtn === 4 && 
                  <PriceChartComp companyId={companyId}  themeColor={ThemeColorData.chartColor}/>
                }
                {
                  (ActivePrimaryBtn === 1 || ActivePrimaryBtn === 2) && 
                <QuarterlyReviewComp FirstLoad={FirstLoad} secondaryBtnArr={secondaryBtnArr} setFirstLoad={setFirstLoad} setActivePrimaryBtn={setActivePrimaryBtn} companyId={companyId} QuarterlySegmentType={QuaterlySegmentType} setQuarterlySegmentType={setQuaterlySegmentType}  />
                }

              </>
            )
          }
          {/* {
            ActivePrimaryBtn === 1 && (
              <>
                {
                  ActiveSecondaryBtn === 3 && 
                  <QuarterlyChartComp companyId={companyId} themeColor="#598EF8B2"/>
                }
                {
                  (ActiveSecondaryBtn === 1 || ActiveSecondaryBtn === 2) && 
                  <QuarterlyComp />
                }
              </>
            )
          }
          {
            ActivePrimaryBtn === 2 && (
              <>
                <QuarterlySegmentComp companyId={companyId} QuarterlySegmentType={QuaterlySegmentType} setQuarterlySegmentType={setQuaterlySegmentType} />
              </>
            )
          }
          {
            ActivePrimaryBtn === 3 && (
              <>
                {
                  ActiveSecondaryBtn === 4 && 
                  <PriceChartComp companyId={companyId} themeColor="#598EF8B2"/>
                }
                {
                  (ActiveSecondaryBtn === 1 || ActiveSecondaryBtn === 2) && 
                <QuarterlyReviewComp companyId={companyId} />
                }

              </>
            )
          } */}
        </Box>
      </Box>
    </>
  );
};

export default InterimResultsComp;
