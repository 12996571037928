import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    IconButton,
    ListSubheader,
    Typography,
    Alert,
    Stack,
    Button,
    Skeleton
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { vdrSortDataAction, vdrSortDataUpdateAction } from '../../redux/actions/VdrAction'
import { LoadingComponent } from './../../components'

import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { useNavigate } from 'react-router-dom'

const SortDataPage = (props) => {

    const { typeFor, parentGroup, PinnedVideos, 
        toggleDrawer, sortItemType } = props;

        console.log(props);

    const dispatch = useDispatch();
    const { data, loading } = useSelector((state) => state.vdrSortDataReducer);
    const { data: dataSortDataUpdate, loading: loadingSortDataUpdate } = useSelector((state) => state.vdrSortUpdateDataReducer);
    const [items, setItems] = React.useState(null);

    const navigate = useNavigate();
    // let getReqData = localStorage.getItem('sortItem') ? JSON.parse(localStorage.getItem('sortItem')) : null;
        useEffect(() => {},[parentGroup])
    useEffect(() => {

        if (loading)
            dispatch(vdrSortDataAction({
                "type": sortItemType, 
                "parent_id": (sortItemType === 'mediaGroup' ? 0 : parentGroup.group_id),
                "is_pinned":((sortItemType === 'media' && parentGroup.is_pinned_sort === true) ? true : "")
            }));

        if (!loading) {
            let finalData = data?.Data.sort((a, b) => a.item_order - b.item_order);
            if(sortItemType === "media" && parentGroup.is_pinned_sort !== true){
                finalData = finalData.filter((items) => { if (!PinnedVideos.includes(items.item_id)) return items });
            }
            setItems(finalData);
        }

    }, [dispatch, loading])


    useEffect(() => {
        // console.log(dataSortDataUpdate?.status);
        if (dataSortDataUpdate?.status) {
            window.location.reload()
            console.log('Data Updated Successfully');
        }
    }, [dispatch, loadingSortDataUpdate])


    const onSortEnd = (oldIndex, newIndex) => {
        setItems((array) => arrayMoveImmutable(array, oldIndex, newIndex));
        // console.log(items);
    };


    const onUpdate = () => {
        // console.log('items');    
        let requestArr = [];

        items.map((item, index) => {
            var order = index + 1;
            // console.log('sortItemType >>> ', sortItemType, 'parentGroup.is_pinned_sort >> ', parentGroup.is_pinned_sort)
            // if(sortItemType === "media" && parentGroup.is_pinned_sort === false && PinnedVideos.include(item.item_id)){
            //     return false; 
            // }
            requestArr.push({
                "type": sortItemType,
                "item_id": item.item_id,
                "item_title": item.item_title,
                "item_order": order
            })
        });
        // console.log(requestArr);
        dispatch(vdrSortDataUpdateAction(requestArr));


    }

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ width: 300 }} >
                    {/* <Skeleton /> */}
                    <Skeleton height={30} animation="wave" />
                    <Skeleton height={30} animation="wave" />
                    <Skeleton height={30} animation="wave" />
                    <Skeleton height={30} animation="wave" />
                    <Skeleton height={30} animation="wave" />
                    <Skeleton height={30} animation="wave" />
                    <Skeleton height={30} animation="wave" />
                    <Skeleton height={30} animation="wave" />
                    <Skeleton height={30} animation="wave" />
                    <Skeleton height={30} animation="wave" />
                    <Skeleton height={30} animation="wave" />
                </Box>
            </Box>
        )
    }

    return (
        <>
            <Box sx={{ paddingTop: 2 }}>
                <Box className="sortListContainer">
                    <Box>
                        <Typography variant="h6" gutterBottom sx={{ textAlign: 'center0' }}>Sort {parentGroup.group_name}</Typography>
                        <List sx={{ width: '100%', maxWidth: 460, bgcolor: 'background.paper' }}
                        // subheader={<ListSubheader sx={{ fontSize: 18 }}>{item.label}</ListSubheader>}
                        >
                            <SortableList
                                onSortEnd={onSortEnd}
                                className="list"
                                draggedItemClassName="dragged"
                            >
                                {items && items.map((value, i1) => {

                                    return (
                                        <SortableItem key={'item_' + value.item_id}>
                                            <div className="item">

                                                <ListItem
                                                    // key={value.item_id}
                                                    disablePadding
                                                >
                                                    <ListItemButton role={undefined} dense>
                                                        <ListItemIcon
                                                            sx={{
                                                                minWidth: '1rem',
                                                                marginRight: '0.5rem',
                                                            }}
                                                        >
                                                            <ListItemText>
                                                                {i1 + 1}
                                                            </ListItemText>
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={<Typography fontSize={12} >{value.item_title}</Typography>}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                                {
                                                    items.length - 1 !== i1 ?
                                                        <Divider />
                                                        :
                                                        null
                                                }

                                            </div>
                                        </SortableItem>
                                    );
                                })}
                            </SortableList>
                        </List>
                    </Box>
                    <Box sx={{
                        margin: '1rem',
                    }}>
                        <Button
                            onClick={onUpdate}
                            sx={{
                                marginLeft: '0.5rem',
                            }}
                            size="small"
                            variant='contained' color="success">Update</Button>
                        <Button
                            onClick={() => window.location.reload()}
                            sx={{
                                marginLeft: '0.5rem',
                            }}
                            size="small"
                            variant='contained' color="error">Cancel</Button>
                    </Box>
                </Box>
            </Box>
        </>
    )

}

export default SortDataPage