import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PeerToPeerTrendChooseField from './PeerToPeerTrendChooseField';
import { vdrPeerToPeerScAction } from '../../../../redux/actions/VdrAction';
import { Divider, Grid, Typography } from '@mui/material';
import { CustomChart } from '../../../../frontend/components';

const PeerToPeer = ({companyId, TypeActivePrimaryBtn, setTypeActivePrimaryBtn, themeColor}) => {


    const dispatch = useDispatch();

    // const {
    //     data,
    //     loading
    // } = useSelector(state=>state.vdrPeerToPeerScReducer);

    const PeerToPeerRef = useRef();
    const { data: PeerRatioData } = useSelector(state => state.PeerRatiosReducer);
    const { data: peerToPeerData, loading: peerToPeerLoading } = useSelector(state => state.vdrPeerToPeerScReducer);
    const [PeerToPeerDataChartType, setPeerToPeerDataChartType] = useState(null)
    const [PeerToPeerData, setPeerToPeerData] = useState(null);
    

    const applyAction = (chartType, companies) => {
      let companies1 = companies;
        var companies0 = companies1.unshift(companyId);
      // console.log(companies1);
      dispatch(vdrPeerToPeerScAction({
        "CompanyId": companyId,
        "userid": 1,
        "param": [chartType],
        "CompanyParam": companies1
    }))
    let pData = PeerRatioData.find(itm=>itm.ID===chartType)
      setPeerToPeerDataChartType(pData);
  }
  
  useEffect(() => {
    if (peerToPeerLoading) {
        // dispatch(vdrPeerToPeerAction([{ "CompanyId": 132805, "userid": "22", "param": "PE (x)", "CompanyParam": ["132805", "105412", "106076", "132670", "101425", "124226"] }]))
    }
    if (!peerToPeerLoading) {
        let qData = peerToPeerData.Data;
        let demo0 = [];
        let title = null;
        if (qData && qData.length > 0) {
            qData = qData[0];
            Object.keys(qData).map((item, i) => {
                var demo1 = { cat: [], value: [], title: title, typeFor: 20 };
                if (item !== '$id' && item !== '_MainHeader') {
                    var itemQData = qData[item];
                    var a = 0;
                    Object.keys(itemQData).map((subItem, i1) => {

                        if (subItem !== '$id') {
                            if (subItem === '_chartName') {
                                demo1.title = title = itemQData[subItem]?.Name;
                            } else {
                                // if (itemQData[subItem]?.Value !== null) {
                                    demo1.cat = [...demo1.cat, itemQData[subItem]?.Name]
                                    // console.log('itemQData[subItem]?.Value ?? ', itemQData[subItem]?.Value)
                                    var value1 = itemQData[subItem]?.Value?parseFloat(itemQData[subItem]?.Value):null;
                                    demo1.value = [...demo1.value, value1]
                                // }
                            }
                        }
                    });
                    demo0[item] = demo1;
                }
            })
            setPeerToPeerData(demo0)
        }
    }
}, [dispatch, peerToPeerLoading])



  return (
    <>

        <PeerToPeerTrendChooseField setTypeActivePrimaryBtn={setTypeActivePrimaryBtn} TypeActivePrimaryBtn={TypeActivePrimaryBtn} applyAction={applyAction}/>
        
        {
            PeerToPeerDataChartType ?
                <>
                    <Grid item xs={12}
                        sx={{ margin: "0.5rem 0" }}
                    >
                        <Typography
                            variant='h6'
                            textAlign={'center'}
                        >
                            Selected Chart Type: <strong>{PeerToPeerDataChartType?.Name}</strong>
                            <Divider />
                        </Typography>
                    </Grid>
                    
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 1 }} ref={PeerToPeerRef} sx={{ backgroundColor:'#fff' }} >
                        {
                            PeerToPeerData &&
                            Object.keys(PeerToPeerData).map((item) => (
                                <Grid item xs={12} sm={6} md={2} >
                                    <div>
                                        <CustomChart themeColor={themeColor} chartData={PeerToPeerData && PeerToPeerData[item]} chartCategory={[]} />

                                    </div>
                                </Grid>
                            ))
                        }
                    </Grid>
                </>
                :
                null
        }

      
    </>
  )
}

export default PeerToPeer
