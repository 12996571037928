import { Box, Container, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { LoadingComponent } from '../../components'
import { loggedInUser } from '../../constants/helpers'
import { vdrFavoriteAction, vdrCompanyAction, vdrHomeAction } from '../../redux/actions/VdrAction'
import { SingleCompanyGrid } from '../components'

const FavoriteListing = () => {
  const { data, loading, error } = useSelector(state => state.vdrFavoriteReducer);
  const { data: companyData, loading: companyLoading, error: companyError } = useSelector(state => state.vdrHomeReducer);

  const dispatch = useDispatch();

  useEffect(() => {

    if (loading) {
      dispatch(vdrFavoriteAction({
        "user_id": (loggedInUser?.user_id),
        "Inputtype": 2
      }));
    }

    if (companyLoading) {
      dispatch(vdrHomeAction({ status: 1 }));
    }
    // if (!loading && companyLoading) {
    //   favoriteCompanies()
    // }
  }, [dispatch, loading, companyLoading])

  const [FavoriteData, setFavoriteData] = useState(null);
  useEffect(() => {
    favoriteCompanies();
  }, [dispatch, loading, companyLoading])

  const favoriteCompanies = () => {

    let favoriteData = null;
    if (!loading && data && data?.Data.length > 0) {
      favoriteData = (data.Data).map((item) => item.company_id);
      var cmpDataaaaa = [];

      var homeDataData1 = data.Data.map((item) => {
        var company_id = item.company_id;
        if (item.isFavorite && !cmpDataaaaa.includes(company_id)) {
          cmpDataaaaa.push(company_id.toString());
        }
      });

      dispatch({
        type: 'FavoriteCompanySuccess',
        payload: cmpDataaaaa
      });

    }

    var companyDataData = companyData?.Data;
    if (companyDataData && companyDataData.length > 0) {



      let newData = companyDataData.filter((item) => {
        if (favoriteData?.includes(parseInt(item?.companies.company_id))) {
          return true
        } else {
          return false
        }
      });
      newData = [...new Map(newData.map(item => [item.companies.company_id, item])).values()];
      setFavoriteData(newData);
      
    }
  }



  return (
    <>
      <Box className="headerContainerFront">

        <Typography
          style={{ zIndex: 2 }}
          fontSize={"2.5em"}
          color="#f37b21"
          fontWeight="700"
        >

          My Favorites

        </Typography>
      </Box>

      {
        loading ?
          <LoadingComponent />
          :

          <Box className="bodyContainer" sx={{
            marginTop: 5, marginBottom: 5

          }}>
            <Grid container spacing={2}>
              {
                FavoriteData && FavoriteData.length > 0 ?
                  FavoriteData.map((item) => {
                    return (
                      <Grid item xs={2}>
                        <SingleCompanyGrid numCount={null} companyData={item.companies} />
                      </Grid>
                    )
                  }
                  )
                  :
                  <Box
                    textAlign={'center'}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      style={{ zIndex: 2 }}
                      fontSize={"1rem"}
                      fontWeight="500"
                    >

                      We couldn't find any favorite companies.

                    </Typography>
                    <Typography
                      style={{ zIndex: 2 }}
                      fontSize={"1rem"}
                      fontWeight="500"
                      component={Link}
                      to="/"
                    >

                      Please Add some

                    </Typography>
                  </Box>

              }
            </Grid>
          </Box>
      }
    </>


  )
}

export default FavoriteListing