import React, { useState } from 'react'

import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { quarterlyReviewAction, vdrData20YearsQuarterlyAction } from '../../../../redux/actions/VdrAction';
import { useEffect } from 'react';
import { CustomChart } from '../../../../frontend/components';

const QuarterlyChartComp = (props) => {
  const {companyId, themeColor, QuarterlySegmentType, setQuarterlySegmentType, secondaryBtnArr, ActivePrimaryBtn, setActivePrimaryBtn} = props;

  const { data: quarterlyReviewData, loading: quarterlyReviewLoading } = useSelector(state => state.quarterlyReviewReducer);
  const { data: quarterlyData, loading: quarterlyLoading, error } = useSelector(state => state.vdrData20YearsQuarterlyReducer)

  const [FirstLoad, setFirstLoad] = useState(null);
  const [ActiveQuarterlyData, setActiveQuarterlyData] = useState(null);
  const [QuarterlyAllData, setQuarterlyAllData] = useState(null);
  const [QuarterlyAllDataTotal, setQuarterlyAllDataTotal] = useState(0);
  const [ActiveQuarterlyReview, setActiveQuarterlyReview] = useState(false);
  const dispatch = useDispatch();


  
  const quarterlySelect = (quarter) => {
    setActiveQuarterlyData(quarter)
    if(quarter === "QuarterlyReview" ){
        setActiveQuarterlyReview(true);
        if(quarterlyReviewLoading){
            dispatch(quarterlyReviewAction({companyID:companyId}));
        }
    }else
    if(quarter === "QuarterlySegment" ){
        setActiveQuarterlyReview(false);
        // if(quarterlySegmentLoading){
        //     dispatch(quarterlySegmentAction({companyID:companyId}, QuaterlySegmentType));
        // }
    }else
    {
        setActiveQuarterlyReview(false);
        
        let qData = quarterlyData.Data;
        let demo0 = [];
        let title = null;
        if (qData && qData.length > 0) {
            qData = qData[0];
            Object.keys(qData).map((item, i) => {
                var demo1 = { cat: [], value: [], title: title, typeFor: quarter, YoYQoQ: {} };
                if (item !== '$id' && item !== '_MainHeader') {
                    var itemQData = qData[item];
                    var a = 0;
                    var a1 = 0;
                    Object.keys(itemQData).map((subItem, i1) => {
                        if (quarter === 20 || a <= quarter) {
                            if (subItem !== '$id') {

                                if (subItem === '_chartName') {
                                    demo1.title = title = itemQData[subItem]?.Name;
                                } else {
                                    if(itemQData[subItem]?.Value !== null && itemQData[subItem]?.Value !== undefined){
                                        demo1.cat = [...demo1.cat, itemQData[subItem]?.Name]
                                        demo1.value = [...demo1.value, itemQData[subItem]?.Value]
                                    }
                                }
                            }
                        }
                        if(itemQData[subItem]?.Value !== null && itemQData[subItem]?.Value !== undefined){
                            a1++
                        }
                        a++;
                        if (subItem === '_chartName') {
                            demo1.title = title = itemQData[subItem]?.Name;
                        }

                        if (quarter === 5 && subItem === 'YoYQoQ') {
                            demo1.YoYQoQ = { YoY: (itemQData[subItem]?.YoY || null), QoQ: (itemQData[subItem]?.QoQ || null) }
                        }


                    });
                    setQuarterlyAllDataTotal(a1);
                    // console.log(demo1);
                    demo0[item] = demo1;
                }
            })

            // console.log('setQuarterlyAllData >>>> ', demo0);
            setQuarterlyAllData(demo0)
        }

    }

}


useEffect(() => {
  if (quarterlyLoading && FirstLoad === null) {
    setFirstLoad(true)
    dispatch(vdrData20YearsQuarterlyAction([
        {
            "CompanyID": companyId,
            "userid": 1,
            "Param": ["Market Cap (cr.)", "Net Sales (cr.)", "Cons PAT (cr.)", "Gross Profit (cr.)", "Gross Profit Margin (%)", "EBIDTA (cr.)"],
            "ChartType": "Quarterly",
            "Type": 'con'
        }
    ]))
}


if (!quarterlyLoading) {
    quarterlySelect(5);
}
}, [dispatch, quarterlyLoading, quarterlyData])


useEffect(()=>{

  if(FirstLoad){
    let pType = 'con';
    if(QuarterlySegmentType === 'Standalone'){
      pType = 'std';
    }
  
    dispatch(vdrData20YearsQuarterlyAction([
      {
            "CompanyID": companyId,
            "userid": 1,
            "Param": ["Market Cap (cr.)", "Net Sales (cr.)", "Cons PAT (cr.)", "Gross Profit (cr.)", "Gross Profit Margin (%)", "EBIDTA (cr.)"],
            "ChartType": "Quarterly",
            "Type": pType
        }
    ]))
  }else{
    // setQuarterlySegmentType('Standalone')
    // let secondBtn = secondaryBtnArr.find(item =>item.value == 'Standalone')
    // setActivePrimaryBtn(secondBtn?.id)
    
  }
}, [QuarterlySegmentType])

useEffect(()=>{

  if(error && quarterlyLoading == false){
    setQuarterlySegmentType('Standalone')
    let secondBtn = secondaryBtnArr.find(item =>item.value == 'Standalone')
    setActivePrimaryBtn(secondBtn?.id)
  }
}, [quarterlyLoading])



let btnArr = [
  {
    id:1,
    label:"5 Quarterly",
    len:5
  },
  {
    id:2,
    label:"10 Quarterly",
    len:10
  },
  {
    id:3,
    label:"13 Quarterly",
    len:13
  }
]

  return (
    <>
    <div className="box-body-sec">
      <Grid container spacing={2}>
      {/* <Grid item xs={12} >
          <div>  
            <h6>Cons Priority</h6>
          </div>
      </Grid> */}
        {
              QuarterlyAllData &&
              Object.keys(QuarterlyAllData).map((item) => (
                  <Grid item xs={12} sm={6} md={2} >
                      <div>
                          <CustomChart themeColor={themeColor} chartData={QuarterlyAllData && QuarterlyAllData[item]} chartCategory={[]} />
                      </div>
                  </Grid>
              ))
          }
      </Grid>

      <div style={{
            display:'flex',
            gap:'10px',
            justifyContent:'center',
            marginTop:'1rem'
           }} className="">
            
            {
              btnArr.map((item, i)=>{
                return (
                  <button onClick={()=>{
                    quarterlySelect(item.len)
                  }
                  } key={i} className={`btn ${ActiveQuarterlyData === item.len ? "primary-btn" : "secondary-btn-outline"}`}>{item?.label}</button>
                )
              })
            }
            
           </div>

      {/* <Grid container spacing={2}>
      <Grid item xs={12}>
        
        {
            QuarterlyAllDataTotal >= 5
            ?
            <Button className={`${ActiveQuarterlyData === 5 ? 'btn_active' : null}`} onClick={() => quarterlySelect(5)} variant="outlined" size="small" sx={{ margin: '0.2rem' }} type="button">5 Quarterly</Button>
            :
            null
        }
        {
            QuarterlyAllDataTotal > 5
            ?
            <Button className={`${ActiveQuarterlyData === 10 ? 'btn_active' : null}`} onClick={() => quarterlySelect(10)} variant="outlined" size="small" sx={{ margin: '0.2rem' }} type="button">10 Quarterly</Button>
            :
            null
        }
        {
            QuarterlyAllDataTotal > 10
            ?
            <Button className={`${ActiveQuarterlyData === 20 ? 'btn_active' : null}`} onClick={() => quarterlySelect(20)} variant="outlined" size="small" sx={{ margin: '0.2rem' }} type="button">20 Quarterly</Button>
            :
            null
        }
        
      </Grid>
        
      </Grid> */}
    </div>
    </>
  )
}

export default QuarterlyChartComp