
import { Box, Button, List } from '@mui/material'
import React, { useEffect, useState } from 'react'


import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";

import { Worker, Viewer } from "@react-pdf-viewer/core";
import { degrees, PDFDocument, rgb, StandardFonts, PDFName ,PrintScaling } from "pdf-lib";
import WaterMark from './WaterMark'
import { watermarkText } from '../../constants/helpers';
import dummyPdf from './../../dummy.pdf';
import { useSelector } from 'react-redux';

const PDFView = () => {
    const { data } = useSelector((state)=>state.vdrPDFLinkReducer);
    
    // const PDFViewed = localStorage.getItem('PDFViewed') ? window.atob(localStorage.getItem('PDFViewed')): null
    const PDFViewed = localStorage.getItem('PDFViewed') ? window.atob(localStorage.getItem('PDFViewed')): null
    // console.log(PDFViewed)

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    useEffect(() => {
      console.log(PDFViewed)
    }, [PDFViewed])
    
 
    const print2 = async () => { 
        const url = PDFViewed;
      
        // console.log(url);
        const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
      
        const pdfDoc = await PDFDocument.load(existingPdfBytes, {
          ignoreEncryption: false,
        });
      
        const viewerPrefs = pdfDoc.catalog.getOrCreateViewerPreferences();
        viewerPrefs.setPrintScaling(PrintScaling.None)
      
        // var meta =pdfDoc.getMetadata();
      
        // console.log("Meta Data>>"+meta);
      
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
      
        const pages = pdfDoc.getPages();
        console.log("page>>"+pages);
      
        console.log("Total pages=" + pages.length);
      
        for (var x = 0; x < pages.length; x++) {
          const firstPage = pages[x];
          const { width, height } = firstPage.getSize();
      
      
      
          firstPage.drawText(watermarkText, {
            x: 150,
            y: height/2-100,
            size: 50,
            font: helveticaFont,    
            color: rgb(0.95, 0.1, 0.1),
            rotate: degrees(45),
            opacity: 0.4
          });
        }
      
        pdfDoc.addJavaScript("foo", 'function foo() { return "foo"; } foo()');
      
        const pdfBytes = await pdfDoc.save();
      
        const bytes = new Uint8Array(pdfBytes);
      
        var file = new Blob([bytes], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      };

    return (
        <>
            <Box>
                <Box sx={{ 
                    margin:"10px auto",
                    // width:'100%',
                    position:'fixed',
                    left:0,
                    top:0,
                    zIndex:1

                 }}
                 display="flex"
                 justifyContent={'center'}
                 >
                <Button variant='contained' onClick={print2}>Download PDF</Button>
                </Box>
                <div id="finalpdf" style={{ userSelect: 'none' }}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                        <Viewer
                            fileUrl={PDFViewed}
                            plugins={[
                                // toolbarPluginInstance,
                                // Register plugins
                                // defaultLayoutPluginInstance,
                                // propertiesPluginInstance,
                                // getFilePluginInstance,
                            ]}
                            // onDocumentAskPassword={handleAskPassword}
                            renderPage={WaterMark}
                            withCredentials={true}
                        />
                    </Worker>
                </div>
            </Box>
        </>
    )
}

export default PDFView

