import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
// import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { useEffect } from 'react';

export default function BarChartModal(props) {
  
    const ThemeColorData = useSelector((state) => state.ThemeColorReducer);
    const {
        Columns_Rows,
        Open: BarOpen
    } = useSelector((state) => state.BarChartDataReducer);

  const { isOpen, setIsOpen } = props
  const [open, setOpen] = React.useState(false);

  const [Option, setOption] = React.useState(null);
  const [IsChart, setIsChart] = React.useState(false);

  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch({
      type:"Columns_Rows_close",
      payload:{}
    })
    setOpen(false);
  };

  const [ChartTitle, setChartTitle] = React.useState("");

  

  React.useEffect(()=>{
    let data0 = [];
    let xInTitle = false;
    let chartTitle = "Loading..."
    if(Columns_Rows?.Columns && Columns_Rows?.Columns.length > 0){
        
        let isPercentage = ""
        let row = Columns_Rows.Rows;
        // console.log('Columns_Rows?.Columns >> ', Columns_Rows?.Columns)
        // console.log('Columns_Rows?.Rows >> ', Columns_Rows?.Rows)

        Columns_Rows?.Columns.map((item, i) => {
          isPercentage = ""
          if(row[item?.id].label != "" && row[item?.id].label != null && row[item?.id].label != undefined){
            if(i===0){
              chartTitle = row[item?.id].label
              xInTitle = chartTitle.toLowerCase(chartTitle).includes('(x)')
              // console.log('xInTitle >>>> ', xInTitle)
              setChartTitle(chartTitle);
            }
            if (i > 0) {
              let r_num = row[item?.id].label;
              
              if(r_num == ""){
                r_num = Number(0);
              }
              // console.log('r_num >>> ', r_num)
              if(r_num && r_num.includes("%")){
                  isPercentage = "%"
              }
  
              if(r_num){
                  r_num = r_num.replaceAll("%", "");
              }
              r_num = parseFloat(r_num);
              
              let n_r_num = r_num;

              if(isPercentage){
                r_num = r_num.toFixed(1);
                n_r_num = r_num;
                // r_num = Number(r_num).toPrecision(3)
              }else{
                if(xInTitle){
                  r_num = r_num;
                }else{
                  r_num = r_num.toFixed();
                }
                n_r_num = r_num;
              }
              r_num = Number(r_num)
              
              let myobj = {
                name: item?.label,
                y_0: n_r_num+isPercentage,
                y: r_num,
              };
              data0.push(myobj);
            }
          }

        });
        console.log('data0 >>> ', data0)
        
        
        let options = {
          chart: {
              type: 'column',
          },
          title: {
                text: chartTitle,
                },
          accessibility: {
              announceNewData: {
                  enabled: true
              }
          },
          xAxis: {
              type: 'category'
          },
          legend: {
              enabled: false
          },
          plotOptions: {
              series: {
                  borderWidth: 0,
                  dataLabels: {
                      enabled: true,
                      format: `{point.y_0}`
                      // format: `${isPercentage ? "{point.y:.1f}"+isPercentage: "{point.y:.0f}"} -- 0`
                  }
              }
          },
      
          tooltip: {
              headerFormat: '<span style="font-size:11px"> </span>',
              pointFormat: `<span style="color:{point.color}">{point.name} </span>: <b>{point.y_0}</b><br/>`
              // pointFormat: `<span style="color:{point.color}">{point.name} </span>: <b>${isPercentage ? "{point.y:.1f}"+isPercentage: "{point.y:.0f}"}</b><br/>`
          },
      
          series: [
              {
                  // name: 'Browsers',
                  color: ThemeColorData?.chartColor,
                  data: data0
              }
          ]
      }
      
      setOption(options)
      setIsChart(true);
      
    }
    

  }, [Columns_Rows])

  useEffect(()=>{
    if(open != BarOpen){
        setOpen(BarOpen)
    }
  },[BarOpen, Columns_Rows]) 


  return (
    <React.Fragment>

      <Dialog
        fullWidth={'lg'}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
      >
        {/* <DialogTitle>{ChartTitle}</DialogTitle> */}
        <DialogContent>
            <Box>
                {
                    IsChart ? (
                        <HighchartsReact
                            containerProps={{ style: { height: "100%" } }}
                            highcharts={Highcharts}
                            // constructorType={"stockChart"}
                            options={Option}
                        />

                    )
                    :
                    <Typography>Loading...</Typography>
                }
            </Box>
          {/* <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel htmlFor="max-width">maxWidth</InputLabel>
              <Select
                autoFocus
                value={'lg'}
                onChange={handleMaxWidthChange}
                label="maxWidth"
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value={false}>false</MenuItem>
                <MenuItem value="xs">xs</MenuItem>
                <MenuItem value="sm">sm</MenuItem>
                <MenuItem value="md">md</MenuItem>
                <MenuItem value="lg">lg</MenuItem>
                <MenuItem value="xl">xl</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch checked={fullWidth} onChange={handleFullWidthChange} />
              }
              label="Full width"
            />
          </Box> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
