import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { FaSortAlphaDown, FaSortAlphaUp } from "react-icons/fa";
import FinalOutputComp from "./FinalOutputComp";
import AnnualReportsComp from "./AnnualReportsComp";
import AnnualReportsCompAna from "../AnalystRoom/AnnualReportsComp";
import ConcallTransriptsComp from "./ConcallTransriptsComp";
import { useDispatch, useSelector } from "react-redux";
import { UploadDocumentAction } from "../../../../redux/actions/userAction";
import { crtUserId } from "../../../../constants/helpers";

const dataRoom = [
  {
    label: "Annual Reports",
    position: 0,
  },
  {
    label: "Concall Transcripts",
    position: 1,
  },
  {
    label: "Initial Coverage",
    position: 2,
  },
  {
    label: "Investor Presentation",
    position: 3,
  },
  {
    label: "Others",
    position: 4,
  },
  {
    label: "Quarterly Update",
    position: 5,
  },
  {
    label: "Forensic",
    position: 6,
  },
  {
    label: "Brief Notes",
    position: 7,
  },
];

const ClientRoomComp = (props) => {
  const {
    title,
    isCD,
    toggleDrawer,
    DataRoomDocDetail,
    setDataRoomDocDetail,
    setOpenForm,
    OpenForm,
    isAdmin,
    setInputType,
    companyId
  } = props;

  const dispatch = useDispatch();

  
  const dataRoomDelete = (item) => {
    let msg = window.confirm('are you sure want to delete?');
    if(msg === true){
        let de000 = { "CompanyID": companyId, "UserID": crtUserId(), "SectorID": "", "IndustryID": [], "DocumentType": "", "FileName": "", "FileContent": "" };
        // de000['FileID'] = item?.FileID 
        de000 = {...de000, 'FileID': item?.FileID }
        de000 = {...de000, 'UserID': item?.UserID }
        var dataArr = [de000];
        dispatch(UploadDocumentAction(dataArr));
    }
    
  }



  const { data: UploadDocumentData, loading: UploadDocumentLoading } =
    useSelector((state) => state.UploadDocumentReducer);
  const { data: DocumentData, loading: DocumentLoading } = useSelector(state => state.UploadDocumentAnalystNotesReducer);   

  
  const [DocumentDatacomp, setDocumentData] = useState(null);
  
  const [FinalOutputData, setFinalOutputData] = useState(null);
  const [AnnualReportsData, setAnnualReportsData] = useState(null);
  const [ConcallTranscriptsData, setConcallTranscriptsData] = useState(null);
  const [InvestorPresentationData, setInvestorPresentationData] =
    useState(null);

  const FilterData = (fType) => {
    if (fType == "") {
      let arr2 = [dataRoom[0].label, dataRoom[1].label, dataRoom[3].label, ""];
      return UploadDocumentData?.Data
        ? UploadDocumentData?.Data.filter(
            (item) => !arr2.includes(item.DocumentType)
          )
        : [];
    } else {
      // console.log(fType);
      return UploadDocumentData?.Data
        ? UploadDocumentData?.Data.filter((item) => item.DocumentType == fType)
        : [];
    }
  };
  
  React.useEffect(() => {}, [companyId])
  React.useEffect(() => {
    
    if (!DocumentLoading) {
         console.log('DocumentData >>>> ', DocumentData.Data);
         setDocumentData(DocumentData.Data);
    }
}, [dispatch, DocumentLoading])

  React.useEffect(() => {
    if (!UploadDocumentLoading) {
      setFinalOutputData(FilterData(""));
      setAnnualReportsData(FilterData(dataRoom[0].label));
      setConcallTranscriptsData(FilterData(dataRoom[1].label));
      setInvestorPresentationData(FilterData(dataRoom[3].label));
    }
  }, [UploadDocumentLoading]);

  React.useEffect(() => {
    // console.log('FinalOutputData >>>> ', FinalOutputData)
  }, [
    FinalOutputData,
    AnnualReportsData,
    ConcallTranscriptsData,
    InvestorPresentationData,
  ]);

  return (
    <>
      <Box className="valuation-box">
        <div className="box-head-sec">
      {
              isCD !== true  ? (
                <h3>{title}</h3>
                )
                :
          <div></div>
        }
        {
          isAdmin && (

          <div>
            
                <button
                  className="btn primary-btn"
                  onClick={(e) => {
                    setOpenForm("DataRoomDocumentAdd");
                    toggleDrawer("bottom", true)(e);
                  }}
                >
                  Upload Document
                </button>
            
          </div>
          )
        }
        </div> 
        <div className="box-body-sec client-docs">
          <Box className="parentSec ">
            <Box  className="childSec">
            <FinalOutputComp
                isAdmin={isAdmin}
                DataRoomDocDetail={DataRoomDocDetail}
                setDataRoomDocDetail={setDataRoomDocDetail}
                toggleDrawer={toggleDrawer}
                listData={FinalOutputData}
                setOpenForm={setOpenForm}
                setInputType={setInputType}
                // title={title}
                companyId={companyId}
                deleteFunction={dataRoomDelete}
              />
            </Box>
            <Box  className="childSec">
            <AnnualReportsComp
                isAdmin={isAdmin}
                DataRoomDocDetail={DataRoomDocDetail}
                setDataRoomDocDetail={setDataRoomDocDetail}
                toggleDrawer={toggleDrawer}
                listData={AnnualReportsData}
                setOpenForm={setOpenForm}
                companyId={companyId}
                deleteFunction={dataRoomDelete}
              />
            </Box>
            <Box  className="childSec">
            <ConcallTransriptsComp
                isAdmin={isAdmin}
                DataRoomDocDetail={DataRoomDocDetail}
                setDataRoomDocDetail={setDataRoomDocDetail}
                listData={ConcallTranscriptsData}
                setOpenForm={setOpenForm}
                companyId={companyId}
                deleteFunction={dataRoomDelete}
              />
            </Box>
            
            {/* <Box  className="childSec">
              <AnnualReportsCompAna toggleDrawer={toggleDrawer} setOpenForm={setOpenForm} listdata={DocumentDatacomp} />
            </Box> */}
          </Box>
          {/* <Grid container spacing={2}>
            <Grid item md={4} lg={4} xl={3}>
              <FinalOutputComp
                DataRoomDocDetail={DataRoomDocDetail}
                setDataRoomDocDetail={setDataRoomDocDetail}
                toggleDrawer={toggleDrawer}
                listData={FinalOutputData}
                setOpenForm={setOpenForm}
                // title={title}
                deleteFunction={dataRoomDelete}
              />
            </Grid>
            <Grid item md={4} lg={3} xl={2}>
              <AnnualReportsComp
                DataRoomDocDetail={DataRoomDocDetail}
                setDataRoomDocDetail={setDataRoomDocDetail}
                toggleDrawer={toggleDrawer}
                listData={AnnualReportsData}
                setOpenForm={setOpenForm}
                // title={title}
                deleteFunction={dataRoomDelete}
              />
            </Grid>
            <Grid item md={4} lg={3} xl={2}>
              <ConcallTransriptsComp
                DataRoomDocDetail={DataRoomDocDetail}
                setDataRoomDocDetail={setDataRoomDocDetail}
                listData={ConcallTranscriptsData}
                setOpenForm={setOpenForm}
                deleteFunction={dataRoomDelete}
              />
            </Grid>
          </Grid> */}
        </div>
      </Box>
    </>
  );
};

export default ClientRoomComp;
