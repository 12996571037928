import { Box, Button, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { FiSearch } from "react-icons/fi";
import './new_style.scss'
import { useState } from 'react';
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import NewsLeftSection from './news_blogs/LeftSection';

import NewsRightSection from './../newsletter/RightSection';
import BlogRightSection from './../newblogs/RightSection';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { crtUserId, mediaInput } from '../../../constants/helpers';
import moment from 'moment';
import { vdrMediaAction,VDRMediaMyFavouriteListAction,MyBlogListAction } from '../../../redux/actions/VdrAction';
import { companyMasterAction } from '../../../redux/actions/userAction';
import { FORUM_DETAIL_REQUEST } from '../../../constants/VdrConstants';

const MylistPage = () => {

    const dispatch = useDispatch();
    const { data: vdrMediaData, loading: vdrMediaLoading } = useSelector((state) => state.vdrMediaReducer);
    const { data: companyMasterData, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
    const { data: vdrMediaGroupData, loading: vdrMediaGroupLoading } = useSelector((state) => state.vdrMediaGroupReducer);
    const { data: VDRMediaMyFavouriteListData, loading: VDRMediaMyFavouriteListLoading } = useSelector((state) => state.VDRMediaMyFavouriteListReducer);


    const [ActiveVideoType, setActiveVideoType] = useState(1);
    let buttonsArr = [
        
        {
            id:1,
            text:"Videos",
            type:"videos"
        },
        {
            id:2,
            text:"Newsletter",
            type:"newsletter"
        },
        {
            id:3,
            text:"Blogs",
            type:"blogs"
        }
    ];

    const [FilterVideoData, setFilterVideoData] = React.useState([]);
    const [VidSortStatus, setVidSortStatus] = React.useState(false);
    const [ActiveImage, setActiveImage] = React.useState("");
    const [ActiveVideoItem, setActiveVideoItem] = React.useState("");
    const [ToBeUpdated, setToBeUpdated] = React.useState(false);
    const [VDRMediaMyFavouriteListData1, setVDRMediaMyFavouriteList] = React.useState(null);
    const [ActiveVideoNews, setActiveVideoNews] = useState({});
    const { data: MyBlogListData, loading: MyBlogListLoading } = useSelector((state) => state.MyBlogListReducer);

    const sortVideos = (sortStatus=false, nextLoad=false, crtTab=ActiveVideoType) => {
        
        let fData = vdrMediaData?.Data && vdrMediaData.Data.filter(item00 => { if (item00.VDRVideo === "Yes") return item00 });
        if(crtTab === 1){
            fData = VDRMediaMyFavouriteListData?.Data;
            setFilterVideoData(fData);
        }
        // else
        // if(crtTab === 2){
        //     let prams = { "user_id": crtUserId(),"type":"news"};
        //     dispatch(MyBlogListAction(prams));
        //     setActiveVideoNews(MyBlogListData);
        // }else
        // if(crtTab === 3){
        //     let prams = { "user_id": crtUserId(),"type":"blog"};
        //     dispatch(MyBlogListAction(prams));
        //     setActiveVideoNews(MyBlogListData);
        // }
    }

    const filterSearch = (e) => { 
        let searchedVal = e.target.value; 
        setToBeUpdated(false);

        if(ActiveVideoType === 1){
            const filteredRows = FilterVideoData.filter((row, i)=>{
              return String(row?.videoTitle).toLowerCase().includes(searchedVal.toLowerCase());
            })
            if (searchedVal.length < 1) {
                sortVideos(false, true, ActiveVideoType);
                // console.log('searchedVal >>>>> ', searchedVal)
            }
            else
            {
                setFilterVideoData(filteredRows);
                if(filteredRows.length === 0){
                    setToBeUpdated(true);
                }
            }
        }else 
        if(ActiveVideoType === 2){
            const filteredRows = MyBlogListData?.data.filter((row, i)=>{
                let item = row.company;
                let date = moment(row?.date).format('DD MMM, YYYY, dddd');
                    date = String(date);
                console.log('row?.date >>> ', date.toLowerCase().includes(searchedVal.toLowerCase()))
                if(
                    String(item?.first_name).toLowerCase().includes(searchedVal.toLowerCase()) || 
                    date.toLowerCase().includes(searchedVal.toLowerCase())
                ){
                    return true;
                }
             })
             if (searchedVal.length < 1) {
                setFilterVideoData(MyBlogListData.data);
            }
            else
            {
                setFilterVideoData(filteredRows);
                if(filteredRows.length === 0){
                    setToBeUpdated(true);
                }
            }
            
        }else 
        if(ActiveVideoType === 3){
            const filteredRows = MyBlogListData?.data.filter((row, i)=>{
                let item = row.blogdata[0];
                return String(item?.first_name).toLowerCase().includes(searchedVal.toLowerCase());
            })
            if (searchedVal.length < 1) {
                setFilterVideoData(MyBlogListData.data);
            }
            else
            {
                setFilterVideoData(filteredRows);
                if(filteredRows.length === 0){
                    setToBeUpdated(true);
                }
            }
        }
        
    }
    
    const getNewsletterData = () => {
        let prams = { "user_id": crtUserId(),"type":"news"};
        dispatch(MyBlogListAction(prams));
        setActiveVideoNews(MyBlogListData?.data);
        dispatch({ type: FORUM_DETAIL_REQUEST });
    }
    const getBlogsData = () => {
        let prams = { "user_id": crtUserId(),"type":"blog"};
        dispatch(MyBlogListAction(prams));
        setActiveVideoNews(MyBlogListData?.data);
        dispatch({ type: FORUM_DETAIL_REQUEST });
    }

    useEffect(() => {
        if (VDRMediaMyFavouriteListLoading) {
            let prams = { "webuserId": crtUserId() };
            dispatch(VDRMediaMyFavouriteListAction(prams));
        }
        if (!VDRMediaMyFavouriteListLoading) {
            setVDRMediaMyFavouriteList(VDRMediaMyFavouriteListData);
            sortVideos()
        }
    }, [dispatch, VDRMediaMyFavouriteListLoading])
    
    useEffect(() => {
        if(ActiveVideoType === 4 && !VDRMediaMyFavouriteListLoading){
            chooseType(buttonsArr[3]);   
        }
    }, [dispatch, VDRMediaMyFavouriteListLoading])
    
    useEffect(() => {

        if(companyMasterLoading){
            dispatch(companyMasterAction());
        }
        if (vdrMediaLoading && vdrMediaData.length === 0) {
            let a0 = mediaInput;
            a0 = {...a0, fromDate: moment().subtract(20, 'years').format('YYYY-MM-DD')}
            dispatch(vdrMediaAction(a0));
        }
        if (!vdrMediaLoading) {
            sortVideos();
        }

    }, [dispatch, vdrMediaLoading])

   
    
    const chooseType = (item) =>{
        setActiveVideoItem("")
        setActiveVideoType(item.id)
        if(item.id === 1){
            sortVideos(false, true, item.id)
        }else
        if(item.id === 2){
            getNewsletterData();
        }else
        if(item.id === 3){
            getBlogsData();
        }
    }
    
    useEffect(() => {
        let f = document.querySelector('footer');
        if(f){
            f.remove()
        }
    },[]);

      
  return (
    <>
    
    <Box className="headerContainerFront" sx={{
        // minHeight:'70px',
        height:'auto'
       }}>
        <Typography
          style={{ zIndex: 2 }}
          fontSize={"2.5em"}
          color="#000"
          fontWeight="700"
        >
          My List
        </Typography>
        <Box className='vid-head-btn'>
            {
                buttonsArr.map((item, index)=>{
                    return (
                        <Fragment key={item.id}>
                            <Button onClick={(e)=>{
                                chooseType(item);
                            }} size="small" className={`cst-btn ${ActiveVideoType===item.id ? 'contained' : "outlined"}`} variant={ActiveVideoType===item.id ? 'contained' : "outlined"} >{item.text}</Button>
                        </Fragment>
                    )
                })
            }
        </Box>
        <div className='vid-head-sec'>
            <Box className='vid-head-search' mt={2}>
                { <div>
                    <TextField  onChange={(e)=>filterSearch(e)}
                        id="search-field"
                        size='small'
                        placeholder="Search"
                        fullWidth
                        sx={{ m: 1, width: '342px', borderColor: '#000' }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">
                                <FiSearch color="#000" />
                            </InputAdornment>,
                            className:'searchBox',
                            style: { color: '#000' },
                            sx: {
                            '&::placeholder': {
                                color: '#000',
                                opacity: 1, // otherwise firefox shows a lighter color
                            },
                            },
                        }}
                        />
                </div> }
            </Box>
        </div>
      </Box>
      <Box className="videoContainer" sx={{
        marginTop: 5, marginBottom: 5
      }}>


        {
            ActiveVideoType === 1 && 
            <div className='vid-body-sec'>
            
                <Box >
                    <Grid container spacing={2}>
                        {
                            FilterVideoData && FilterVideoData.length === 0 ?
                            <>
                                <Grid item xs={12}>
                                    <Typography>Videos List is Empty!</Typography>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xs={6}>
                                    <LeftSection 
                                        VidSortStatus={VidSortStatus}
                                        setVidSortStatus={setVidSortStatus}
                                        sortVideosTypeFun={sortVideos}
                                        FilterVideoData={FilterVideoData}
                                        setFilterVideoData={setFilterVideoData}
                                        ActiveImage={ActiveImage}
                                        setActiveImage={setActiveImage}
                                        ActiveVideoType={ActiveVideoType}
                                        setActiveVideoType={setActiveVideoType}
                                        ActiveVideoItem={ActiveVideoItem}
                                        setActiveVideoItem={setActiveVideoItem}
                                        ToBeUpdated={ToBeUpdated}
                                        setToBeUpdated={setToBeUpdated}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <RightSection
                                        ActiveImage={ActiveImage}
                                        setActiveImage={setActiveImage}
                                        ActiveVideoItem={ActiveVideoItem}
                                        setActiveVideoItem={setActiveVideoItem}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                </Box>
            </div>
        }



        {
            ( ActiveVideoType === 2 || ActiveVideoType === 3) &&
            <div className='vid-body-sec'>
                <Box >
                    <Grid container spacing={2}>
                        {
                            !MyBlogListLoading && MyBlogListData?.data && MyBlogListData?.data.length === 0 ? 
                                <Grid item xs={12}>
                                    <Typography>{ActiveVideoType === 2 ? 'Newsletter' : "Blogs" } List is Empty!</Typography>
                                </Grid>
                            :
                            <>
                                <Grid item xs={3}>
                                    <NewsLeftSection 
                                        ActiveVideoType={ActiveVideoType}
                                        setActiveVideoType={setActiveVideoType}
                                        ActiveItem={ActiveVideoItem}
                                        setActiveItem={setActiveVideoItem}
                                        FilterVideoData={FilterVideoData}
                                        setFilterVideoData={setFilterVideoData}




                                        

                                        VidSortStatus={VidSortStatus}
                                        setVidSortStatus={setVidSortStatus}
                                        sortVideosTypeFun={sortVideos}
                                        ActiveImage={ActiveImage}
                                        setActiveImage={setActiveImage}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    
                                    { ActiveVideoType === 2 &&
                                        <NewsRightSection
                                            ActiveVideoItem={ActiveVideoItem}
                                            setActiveVideoItem={setActiveVideoItem}
                                        />
                                    }
                                    { ActiveVideoType === 3 &&
                                        <BlogRightSection
                                            ActiveVideoItem={ActiveVideoItem}
                                            setActiveVideoItem={setActiveVideoItem}
                                        />
                                    }
                                    
                                </Grid>
                            </>
                        }

                       
                    </Grid>
                </Box>
            </div>
        }
      </Box>
    </>
  )
}

export default MylistPage