import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Charts, DocumentView, FinancialCharts, LoadingComponent, MediaSection } from '../components';
import { singleCompanyAction, SingleCompanyCommentsAction, SingleCompanyValuationAction, UploadDocumentAction, UploadDocumentAnalystNotesAction } from '../redux/actions/userAction';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import moment from 'moment';
import SingleCompanyValuation from '../components/SingleCompanyValuation/Index';
import AnalystRoom from '../components/DocumentView/AnalystRoom';
import SingleCompanyAbout from './SingleCompanyAbout';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DataRoomDocumentAdd, UploadDocumentAdd, UploadNoteAdd, CompanyVideoAdd, UpdateAboutForm, UpdateForensicForm } from '../components/Forms';
import DrawerContainer from '../components/Common/DrawerContainer';
import UsersSendNotification from '../components/DocumentView/UsersSendNotification/UsersSendNotification';
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG
} from "react-component-export-image";
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import { useNavigate, useParams } from 'react-router-dom';
import MediaModel from '../components/SingleCompanyContainer/MediaSection_old/MediaModel';


const SingleCompany = () => {
  const [Name, setName] = useState('');
  const [AboutData, setAboutData] = useState(null);

  const [ParentGroup, setParentGroup] = useState(null);

  const [ValuationData, setValuationData] = useState(null);
  const [UploadDocumentDataNew, setUploadDocumentDataNew] = useState(null);
  const [ValuationTableData, setValuationTableData] = useState([]);

  const [AnalystNotesData, setAnalystNotesData] = useState([]);
  const [CompanyComments, setCompanyComments] = useState([]);

  const [DataRoomDocDetail, setDataRoomDocDetail] = useState({});

  const rightRecommendation = "";
  const recommendationType = "";
  const rightLabel = "";
  const companyData1 = {};

  const [stateDrawer, setStateDrawer] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [anchor, setAnchor] = React.useState('bottom');
  const [openForm, setOpenForm] = React.useState(null);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event) {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
    }
    setStateDrawer({ ...stateDrawer, [anchor]: open });
  };

  
  const params  = useParams();
  
  // console.log('params', window.btoa("100251"));



  const componentRef = React.useRef();
  const divRef = React.useRef();
  const dispatch = useDispatch();
  const { data: companyData, loading: companyLoading } = useSelector(state => state.singleCompanyReducer);
  const { data: valuationData, loading: valuationLoading } = useSelector(state => state.SingleCompanyValuationReducer);
  const { data: UploadDocumentData, loading: UploadDocumentLoading } = useSelector(state => state.UploadDocumentReducer);

  const { data: UploadDocumentAnalystNotesData, loading: UploadDocumentAnalystNotesLoading } = useSelector(state => state.UploadDocumentAnalystNotesReducer);
  const { data: SingleCompanyCommentsData, loading: SingleCompanyCommentsLoading } = useSelector(state => state.SingleCompanyCommentsReducer);

  const comId = window.atob(params.companyId);
  const slug = `company/${comId}`;

  const companyId = comId;
  const doc4CompanyArray = {
    Company: [{
      companies: {
        company_name: companyData?.CompanyName,
        company_id: companyId
      }
    }]
  };

  const dataRoomDelete = (item) => {
    let de000 = { "CompanyID": companyId, "UserID": "22", "SectorID": "", "IndustryID": [], "DocumentType": "", "FileName": "", "FileContent": "" };
    // de000['FileID'] = item?.FileID 
    de000 = {...de000, 'FileID': item?.FileID }
    var dataArr = [de000];
    dispatch(UploadDocumentAction(dataArr));
    
  }

  const documentsDelete = (item) => {
    
    let de000 = { "CompanyID": companyId, "UserID": "22", "SectorID": "", "IndustryID": [], "DocumentType": "", "FileName": "", "FileContent": "" };
    // de000['FileID'] = item?.FileID 
    de000 = {...de000, 'FileID': item?.FileID }
    var dataArr = [de000];
    dispatch(UploadDocumentAnalystNotesAction(dataArr));
    // console.log('documentsDelete', dataArr);
  }

  const analystRoomDelete = (item) => {
    let prams = { "CompanyID": companyId, "UserID": "22", "CommentType": "" };
      prams = {...prams, CommentID:item?.CommentID}
      var dataArr = [prams]
    dispatch(SingleCompanyCommentsAction(dataArr));
  }
  
  // console.log(DataRoomDocDetail);

  useEffect(() => {

    if (UploadDocumentLoading) {
      var prams = [{ "CompanyID": companyId, "UserID": "22", "SectorID": "4", "IndustryID": ["62"], "DocumentType": "", "FileName": "", "FileContent": "" }];
      dispatch(UploadDocumentAction(prams));
    }
    if (!UploadDocumentLoading) {
      setUploadDocumentDataNew(UploadDocumentData?.Data)
    }
  }, [UploadDocumentLoading])

  useEffect(() => {

    if (UploadDocumentAnalystNotesLoading) {
      var prams = [{ "CompanyID": companyId, "SectorID": "4", "IndustryID": ["62"], "UserID": "22", "DocumentType": "", "FileName": "", "FileContent": "" }];
      dispatch(UploadDocumentAnalystNotesAction(prams));
    }
    if (!UploadDocumentAnalystNotesLoading) {
      setAnalystNotesData(UploadDocumentAnalystNotesData?.Data)
    }
  }, [UploadDocumentAnalystNotesLoading])


  useEffect(() => {

    if (SingleCompanyCommentsLoading) {
      var prams = [{ "CompanyID": "122241", "UserID": "22", "CommentType": "" }];
      dispatch(SingleCompanyCommentsAction(prams));
    }
    if (!SingleCompanyCommentsLoading) {
      // console.log('SingleCompanyCommentsData?.Data >> ', SingleCompanyCommentsData?.Data);
      setCompanyComments(SingleCompanyCommentsData?.Data);
    }
  }, [SingleCompanyCommentsLoading])


  useEffect(() => {
    if (companyLoading) {
      var prams = [{ "CompanyID": companyId, "userid": "admin", "Note": null }];
      dispatch(singleCompanyAction(prams));
    }
    if (valuationLoading) {
      var prams = [{ "CompanyID": companyId, "userid": "22" }];
      dispatch(SingleCompanyValuationAction(prams));
    }
    if (!valuationLoading) {
      setValuationData(valuationData);
      // alert(valuationData);
      const { Data, Header } = valuationData;
      const newTbData = [
        {
          Header: Header && Header[0],
          Financials: Data && Data[0].Financials,
          Valuations: Data && Data[0].Valuations,
        }
      ];
      setValuationTableData(newTbData);
      // console.log(newTbData);
    }
    if (!companyLoading) {
      // console.log(companyData);
      let abtData = {
        aboutUs: companyData.aboutUs,
        bigPicture: companyData.bigPicture,
        peers: companyData.Peers,
        promoters: companyData.Promoters,
        price: companyData.price,
        product: companyData.product,
        risk: companyData.risk,
        triggers: companyData.triggers,
      };
      setAboutData(abtData);
      const dataRow = {
        ...DataRoomDocDetail,
        comName: companyData?.CompanyName,
        comId: companyId,
        userId:22
    }
    setDataRoomDocDetail(dataRow);
    
    }
  }, [valuationLoading]);

  const exportImageData = (current_ref) => {
    htmlToImage.toPng(current_ref)
    .then(function (dataUrl) {
      // console.log(dataUrl);
      download(dataUrl, 'my-node.png');
    });
  }

  if (companyLoading) {
    return <LoadingComponent />
  }

  

  return (
    <>
      <Box id="div1">
        <DrawerContainer containerFor={openForm} stateDrawer={stateDrawer} setStateDrawer={setStateDrawer} toggleDrawer={toggleDrawer} anchor={anchor} >
          
          {
            openForm === 'DataRoomDocument'
              ?
              <DataRoomDocumentAdd
                levelType={""}
                companyId={companyId}
                inputType={""}
                groupData={""}
                parentGroup={""}
                toggleDrawer={toggleDrawer}
              />
              :
              openForm === 'DocumentUpload'
                ?
                <UploadDocumentAdd
                  levelType={""}
                  inputType={""}
                  groupData={""}
                  parentGroup={""}
                  toggleDrawer={toggleDrawer}
                  companyId={companyId}
                  singleCompanyData={companyData}
                />
                :
                openForm === 'UploadNote'
                  ?
                  <UploadNoteAdd
                    levelType={""}
                    inputType={""}
                    groupData={""}
                    parentGroup={""}
                    companyId={companyId}
                    toggleDrawer={toggleDrawer}
                  />
                :
                openForm === 'UpdateAbout'
                  ?
                  <UpdateAboutForm
                    levelType={""}
                    inputType={""}
                    groupData={""}
                    itemData={ParentGroup}
                    companyId={companyId}
                    toggleDrawer={toggleDrawer}
                  />
                :
                openForm === 'ForensicForm'
                  ?
                  <UpdateForensicForm
                    levelType={""}
                    inputType={""}
                    groupData={""}
                    itemData={ParentGroup}
                    companyId={companyId}
                    toggleDrawer={toggleDrawer}
                  />
                :
                openForm === 'AddVideo'
                  ?
                  <CompanyVideoAdd
                    levelType={""}
                    inputType={""}
                    groupData={""}
                    parentGroup={""}
                    companyId={companyId}
                    singleCompanyData={companyData}
                    toggleDrawer={toggleDrawer}
                  />
                  :
                openForm === 'SendNotification'
                  ?
                <UsersSendNotification
                    setOpenForm={setOpenForm} 
                    DataRoomDocDetail={DataRoomDocDetail}
                    setDataRoomDocDetail={setDataRoomDocDetail}
                    companyId={companyId}
                    singleCompanyData={companyData}
                    toggleDrawer={toggleDrawer}
                  />
                  :
                  null
          }
        </DrawerContainer>




        <Box className='detailContainer' sx={{

        }} >

          <Box
            sx={{
              zIndex: 1,
              position: 'relative',
              padding: 2,
            }}
          >

    
        {/* <Button onClick={(e) => [
          toggleDrawer(anchor, true)(e),
          setOpenForm('SendNotification'),
        ]} size={'small'} variant="contained" color="primary" >
          SendNotification
        </Button> */}
        

            <Box className='TopContainer' sx={{
              // marginTop: 2,
              backgroundColor: '#fff',
              padding: 3,
              minHeight: '100vh'
            }}>
              <Box className='categoryContainer' sx={{
                marginBottom:2
              }}>

                <Box>
                
                  <Box className='mobLogoContainer'>
                  </Box>
                  <Box className='titleContainer'>
                    <Typography variant="h4"  >
                      {companyData?.CompanyName}
                    </Typography>
                    <Box>
                      <div>
                        <span>
                          <Typography variant='body' fontSize={20} fontWeight="500">₹ {ValuationData?.CMP}</Typography>
                        </span>
                        <span style={{ color: 'red' }}>
                          <div style={{ display: 'inline-flex', alignItems: 'center' }} >
                            {
                              ValuationData?.ChangeInPrice > 0 ?
                                <>
                                  <ArrowUpwardIcon sx={{ fontSize: 20, position: 'relative', top: '0.2rem' }} />
                                </>
                                :
                                <>
                                  <ArrowDownwardIcon sx={{ fontSize: 20, }} />
                                </>
                            }
                            <span>
                              {ValuationData?.ChangeInPrice} %
                            </span>
                          </div>
                        </span>
                      </div>
                      <div>
                        {/* <Typography variant='body' >20-Jun-2022 16:10:55</Typography> */}
                        <Typography variant='body' >{ValuationData?.DateAndTime ? moment(ValuationData?.DateAndTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss') : null}</Typography>
                      </div>
                    </Box>
                  </Box>
                </Box>

              </Box>

              {
                ValuationData
                  ?
                  <>
                    <Box className='card-box'>
                    <Box sx={{
                      marginTop: 2,
                      backgroundColor:'#fff'
                    }}
                    
                    >
                      <Box sx={{ textAlign: "right", marginBottom:0.5 }}>
                        <Button onClick={()=>exportImageData(divRef.current)} type="button" size="small" sx={{ fontSize:10 }} variant="outlined" >Download Image</Button>
                      </Box>
                      <Box  sx={{
                        backgroundColor:'#fff'
                      }}
                      ref={divRef}
                      >
                        <SingleCompanyValuation valuationTable={ValuationTableData && ValuationTableData} />
                      </Box>
                    </Box>
                    </Box>
                  </>
                  :
                  ''
              }

              {
                ValuationData
                  ?
                  <Box sx={{
                    marginTop: 2,
                  }}
                    className='orangeBoxShadow' >
                    <SingleCompanyAbout 
                      itemsData={AboutData} 
                      toggleDrawer={toggleDrawer}
                      setParentGroup={setParentGroup}
                      setOpenForm={setOpenForm}
                    />
                  </Box>
                  :
                  ''
              }
              {
                ValuationData
                  ?
                  <Box sx={{
                    marginTop: 2,
                  }}
                    className='orangeBoxShadow '
                  >
                    <FinancialCharts setParentGroup={setParentGroup} setOpenForm={setOpenForm} toggleDrawer={toggleDrawer}  showDownload={true} showForensic={true} divRef={componentRef} companyId={companyId} technicalData={{ $id: "3", companySymbolGauge: "CRAFTSMAN", companySymbolChart: "CRAFTSMAN" }} />
                  </Box>
                  :
                  ''
              }
              {/* {
                ValuationData
                  ?
                  <Box sx={{
                    marginTop: 2,
                  }}
                    className='orangeBoxShadow'
                  >
                    <Typography sx={{
                      color: "#76838f",
                      fontSize: 18,
                      fontWeight: "700",
                      marginBottom: 1,
                    }} >Technical</Typography>
                    <Charts companyId={companyId} technicalData={{ $id: "3", companySymbolGauge: "CRAFTSMAN", companySymbolChart: "CRAFTSMAN" }} />
                  </Box>
                  :
                  ''
              } */}

              {
                UploadDocumentDataNew
                  ?
                  <Box sx={{
                    marginTop: 2,
                  }}
                    className='orangeBoxShadow'
                  >
                    <Typography sx={{
                      color: "#76838f",
                      fontSize: 18,
                      fontWeight: "700",
                      marginBottom: 1,
                    }} >Data Room</Typography>
                    
                    <DocumentView 
                      showForensic={true}
                      deleteFunction={dataRoomDelete}
                      DataRoomDocDetail={DataRoomDocDetail}
                      setDataRoomDocDetail={setDataRoomDocDetail}
                      companyData={UploadDocumentDataNew && UploadDocumentDataNew}
                      allData={doc4CompanyArray && doc4CompanyArray}
                      toggleDrawer={toggleDrawer}
                      setOpenForm={setOpenForm}
                    />

                    <Box sx={{
                      marginTop: 1
                    }}>
                      <Button onClick={(e) => [
                        toggleDrawer(anchor, true)(e),
                        setOpenForm('DataRoomDocument'),
                        // setCompanyData(item),
                        // setParentGroup(item),
                        // setInputType(1),
                      ]} size={'small'} variant="contained" color="primary" >
                        Upload Document
                      </Button>
                      {/* <Button variant='contained' size="small">Upload Document</Button> */}
                    </Box>
                  </Box>
                  :
                  ''
              }
              {
                ValuationData
                  ?
                  <Box sx={{
                    marginTop: 2,
                  }}
                    className='orangeBoxShadow'
                  >
                    <Typography sx={{
                      color: "#76838f",
                      fontSize: 18,
                      fontWeight: "700",
                      marginBottom: 1,
                    }} >Analyst Room</Typography>
                    <AnalystRoom deleteFunction={analystRoomDelete} deleteFunction1={documentsDelete} setOpenForm={setOpenForm} toggleDrawer={toggleDrawer} CompanyComment={CompanyComments} companyData={AnalystNotesData && AnalystNotesData} allData={doc4CompanyArray && doc4CompanyArray} />
                  </Box>
                  :
                  ''
              }
              {
                ValuationData
                  ?
                  <Box sx={{
                    marginTop: 2,
                  }}
                    className='orangeBoxShadow'
                  >
                    <Typography sx={{
                      color: "#76838f",
                      fontSize: 18,
                      fontWeight: "700",
                      marginBottom: 1,
                    }} >Media</Typography>
                    <MediaSection CompanyID={companyId} /><Box sx={{
                      marginTop: 1
                    }}>
                      {/* <Button variant='contained' size="small">Add Video</Button> */}
                      <Button onClick={(e) => [
                        toggleDrawer(anchor, true)(e),
                        setOpenForm('AddVideo'),
                        // setCompanyData(item),
                        // setParentGroup(item),
                        // setInputType(1),
                      ]} size={'small'} variant="contained" color="primary" >
                        Add Video
                      </Button>
                    </Box>
                  </Box>
                  :
                  ''
              }

            </Box>
          </Box>
        </Box>
      </Box>
<MediaModel />
    </>
  )
}

export default SingleCompany