
import React from 'react'
import ModalVideo from 'react-modal-video'
import { useDispatch, useSelector } from 'react-redux'
import { mediaModelAction } from '../../../redux/actions/VdrAction';


const MediaModel = () => {
    const dispatch = useDispatch();
    const { data:{isOpen, type, itemId} } = useSelector(state=>state.mediaModelReducer);
    let vimeoH = "";
    let vimeoId = itemId;
    if(type === 'vimeo'){
        vimeoH = itemId.split('/');
        vimeoId = vimeoH[0];
        vimeoH = vimeoH[vimeoH.length-1];
        let vimeoHArr = vimeoH.split('?');
        if(vimeoHArr && vimeoHArr.length > 0){
            vimeoH = vimeoHArr[0];
        }
        
    }
    // console.log(type, itemId, vimeoH);
    return (
        <>
            <ModalVideo channel={type} autoplay isOpen={isOpen}  vimeo={{ h:vimeoH }} videoId={vimeoId} onClose={() => dispatch(mediaModelAction(false))} />
        </>
    )
}

export default MediaModel




