import React, { useEffect, useRef, useState } from 'react'
import { 
    AppBar, 
    Box, 
    Button, 
    Divider, 
    Grid, 
    IconButton, 
    List, 
    ListItem, 
    ListItemButton, 
    ListItemIcon, 
    ListItemText, 
    Tab, 
    Tabs, 
    TextField, 
    Typography, 
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { AiOutlineFilePdf } from "react-icons/ai";
import { BiSortDown } from "react-icons/bi";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { dateFormat } from '../../constants/helpers';

import SearchIcon from '@mui/icons-material/Search';
import ContainerView from './ContainerView';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../Loading';
import UsersListTable from './UsersListTable';
import UsersSendNotification from './UsersSendNotification/UsersSendNotification';


const dataRoom = [
    {
        "label": 'Annual Reports',
        "position": 0
    },
    {
        "label": 'Concall Transcripts',
        "position": 1
    },
    {
        "label": 'Initial Coverage',
        "position": 2
    },
    {
        "label": 'Investor Presentation',
        "position": 3
    },
    {
        "label": 'Others',
        "position": 4
    },
    {
        "label": 'Quarterly Update',
        "position": 5
    },
    {
        "label": 'Forensic',
        "position": 6
    },
    {
        "label": 'Brief Notes',
        "position": 7
    },
];


const DocumentView = (props) => {
    const { showForensic, companyData, deleteFunction, DataRoomDocDetail, setDataRoomDocDetail, toggleDrawer, setOpenForm } = props;

    const [FinalOutputData, setFinalOutputData] = useState(null);
    const [AnnualReportsData, setAnnualReportsData] = useState(null);
    const [ConcallTranscriptsData, setConcallTranscriptsData] = useState(null);
    const [InvestorPresentationData, setInvestorPresentationData] = useState(null);

    const FilterData = (fType) => {
        if (fType == '') {
            let arr2 = [
                dataRoom[0].label,
                dataRoom[1].label,
                dataRoom[3].label,
                ""
            ];
            return companyData.filter((item) => !arr2.includes(item.DocumentType));
        } else {
            // console.log(fType);
            return companyData.filter((item) => item.DocumentType == fType);
        }
    }


    useEffect(() => {console.log(1)
        setFinalOutputData(FilterData(''));
        setAnnualReportsData(FilterData(dataRoom[0].label));
        setConcallTranscriptsData(FilterData(dataRoom[1].label));
        setInvestorPresentationData(FilterData(dataRoom[3].label));
    }, [props])

    useEffect(() => {
// console.log(2)
    }, [FinalOutputData,
        AnnualReportsData,
        ConcallTranscriptsData,
        InvestorPresentationData])

    return (
        <>
           

            <Grid container spacing={2}>
                <Grid item xs={12} sm={5} >
                    <ContainerView showForensic={showForensic} toggleDrawer={toggleDrawer} setOpenForm={setOpenForm} DataRoomDocDetail={DataRoomDocDetail} setDataRoomDocDetail={setDataRoomDocDetail} deleteFunction={deleteFunction} companyData={props?.allData?.Company[0].companies} itemData={FinalOutputData} />
                </Grid>
                <Grid item xs={12} sm={7} >
                    <Grid container spacing={2} className="documentContainer1" >
                        <Grid item xs={12} sm={4} >
                            <ContainerView showForensic={showForensic} toggleDrawer={toggleDrawer} setOpenForm={setOpenForm} DataRoomDocDetail={DataRoomDocDetail} setDataRoomDocDetail={setDataRoomDocDetail} deleteFunction={deleteFunction} companyData={props?.allData?.Company[0].companies} itemData={AnnualReportsData} title={dataRoom[0].label} />
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <ContainerView showForensic={showForensic} toggleDrawer={toggleDrawer} setOpenForm={setOpenForm} DataRoomDocDetail={DataRoomDocDetail} setDataRoomDocDetail={setDataRoomDocDetail} deleteFunction={deleteFunction} companyData={props?.allData?.Company[0].companies} itemData={ConcallTranscriptsData} title={dataRoom[1].label} />
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <ContainerView showForensic={showForensic} toggleDrawer={toggleDrawer} setOpenForm={setOpenForm} DataRoomDocDetail={DataRoomDocDetail} setDataRoomDocDetail={setDataRoomDocDetail} deleteFunction={deleteFunction} companyData={props?.allData?.Company[0].companies} itemData={InvestorPresentationData} title={dataRoom[3].label} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default DocumentView