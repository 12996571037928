import React, { useEffect, useRef, useState } from 'react'
import { AppBar, Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { AiOutlineFilePdf } from "react-icons/ai";
import { BiSortDown } from "react-icons/bi";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { dateFormat } from '../../constants/helpers';

import SearchIcon from '@mui/icons-material/Search';
import ContainerView from './ContainerView';
import AnalystRoomContainer from './AnalystRoomContainer';
import AnalystRoomCommentContainer from './AnalystRoomCommentContainer';


const dataRoom = [
    {
        "label": 'Annual Reports',
        "position": 0
    },
    {
        "label": 'Concall Transcripts',
        "position": 1
    },
    {
        "label": 'Initial Coverage',
        "position": 2
    },
    {
        "label": 'Investor Presentation',
        "position": 3
    },
    {
        "label": 'Others',
        "position": 4
    },
    {
        "label": 'Quarterly Update',
        "position": 5
    },
    {
        "label": 'Forensic',
        "position": 6
    }
];


const AnalystRoom = (props) => {
    const { companyData, CompanyComment, setOpenForm, toggleDrawer, deleteFunction, deleteFunction1 } = props;

    const [FinalOutputData, setFinalOutputData] = useState(null);
    const FilterData = (fType) => {
        if (fType == '') {
            let arr2 = [];
            return companyData.filter((item) => !arr2.includes(item.DocumentType));
        } else {
            // console.log(fType);
            return companyData.filter((item) => item.DocumentType == fType);
        }
    }
    useEffect(() => {
        setFinalOutputData(FilterData(''));
    }, [props])
    useEffect(() => {
    }, [FinalOutputData])
    
    
    useEffect(() => {
        // console.log('CompanyComment >> ', CompanyComment);
    }, [])


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={7} >
                    <AnalystRoomCommentContainer deleteFunction={deleteFunction} companyData={props?.allData?.Company[0].companies} itemData={CompanyComment} title="Notes" />
                    <Box sx={{ 
                      marginTop:1
                     }}>
                     <Button onClick={(e) => [
                        toggleDrawer('bottom', true)(e),
                        setOpenForm('UploadNote'),
                        // setCompanyData(item),
                        // setParentGroup(item),
                        // setInputType(1),
                      ]} size={'small'} variant="contained" color="primary" >
                        Upload Note
                      </Button>
                      {/* <Button variant='contained' size="small">Upload Note</Button> */}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={5} >
                    <AnalystRoomContainer deleteFunction={deleteFunction1} companyData={props?.allData?.Company[0].companies} itemData={FinalOutputData} title="Documents" />
                    <Box sx={{ 
                      marginTop:1
                     }}>
                     <Button onClick={(e) => [
                        toggleDrawer('bottom', true)(e),
                        setOpenForm('DocumentUpload'),
                        // setCompanyData(item),
                        // setParentGroup(item),
                        // setInputType(1),
                      ]} size={'small'} variant="contained" color="primary" >
                        Upload Document
                      </Button>
                      {/* <Button variant='contained' size="small">Upload Document</Button> */}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default AnalystRoom