import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MuiAutoComplete({ name, label, placeholder, control,options, ...props }) {
  return (
    <>
      {
        label && <label className='sidebar-label'>{label}</label>
      }


      <Autocomplete
        options={options || null}
        getOptionLabel={(option) => option.title}
        {...props}
        renderOption={(props1, option, { selected }) => (
          <li {...props1}>
            {option.title}
          </li>
        )}
        fullWidth
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField {...params} size={'small'} className="" placeholder={placeholder} />
        )}
      />
    </>
  );
}