"use client";
import React, { createContext, useEffect, useState } from "react";
export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {

    const [ActiveSecondaryBtn, setActiveSecondaryBtn] = useState(1)
    const [ActiveNewsLetter, setActiveNewsLetter] = useState(null)
    const [ActiveNewsLetterQuarter, setActiveNewsLetterQuarter] = useState(null)


  
  return (
    <GlobalContext.Provider value={{ 
        ActiveSecondaryBtn, setActiveSecondaryBtn,
        ActiveNewsLetterQuarter, setActiveNewsLetterQuarter,
        ActiveNewsLetter, setActiveNewsLetter
    }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
