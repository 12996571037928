import { FormLabel, TextField } from '@mui/material';
import React from 'react'

function CustomText(props) {

    const [MessageValue, setMessageValue] = React.useState(0);
    return (
        <div>
            <FormLabel id="demo-controlled-radio-buttons-group">Message</FormLabel><br />
            <TextField
                id="filled-multiline-static"
                multiline
                rows={4}
                variant="outlined"
                defaultValue=""
                // value={}
                helperText={`${MessageValue}/${props.CHARACTER_LIMIT}`}
                inputProps={{
                    maxLength: props.CHARACTER_LIMIT,
                }}
                inputRef={props.ref1}
                // onBlur={(e) => props.handleMessageChange(e)}
                onChange={(e) => {
                    setMessageValue(e.target.value.length);
                    // console.log(dd);
                }}
                sx={{
                    width: '100%'
                }}
            />
        </div>
    )
}

export default CustomText