import React from 'react'

const InCr = ({Align, }) => {
  return (
    <>
        <div style={{ 
            textAlign: (Align ? Align : 'left'),
            color:'#000',
            fontWeight:'600',
            marginBottom:'0.5rem',
            }}>
            <small>(in Cr.)</small>
        </div>
    </>
  )
}

export default InCr