import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { InputComponent, SelectCompany, SelectIndustry, SelectSector } from '../Common';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DeliveryDataAction, DeliveryDataFilterInputAction, deliveryDataFilters } from '../../redux/actions/userAction';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    // borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#000' }} />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: '2rem',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    // marginLeft: theme.spacing(1),
    margin: 0,
  },
  '& .MuiAccordionSummary-content p': {
    fontSize: '0.85rem',
    fontWeight: '700',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function DeliveryData() {
  // const [expanded, setExpanded] = React.useState('panel1');

  const [expanded1, setExpanded1] = React.useState('panel1');
  const [expanded2, setExpanded2] = React.useState(null);
  const [expanded3, setExpanded3] = React.useState(null);
  const [expanded4, setExpanded4] = React.useState(null);
  const [expanded5, setExpanded5] = React.useState(null);
  const [expanded6, setExpanded6] = React.useState(null);
  const [inputs, setInputs] = React.useState({});
  const [checked, setChecked] = useState(false);

  const dispatch = useDispatch();
  const { data: companyMaster, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
  const { data: sectorMaster, loading: sectorMasterLoading } = useSelector((state) => state.sectorMasterData);
  const { data: industryMaster, loading: industryMasterLoading } = useSelector((state) => state.industryMasterData);
  const { data: turnAroundMaster, loading: turnAroundMasterLoading } = useSelector((state) => state.turnAroundMasterData);
  const { data: filterInputMaster, loading: filterInputLoading } = useSelector((state) => state.DeliveryDataFilterReducerData);


  const [sectors, setSectors] = React.useState([]);
  const [industry, setIndustry] = useState([]);
  const [sectorMasterArr, setSectorMasterArr] = useState([]);
  const [company, setCompany] = useState([]);
  const [companyMasterArr, setCompanyMasterArr] = useState([]);
  const [filterArr, setFilterArr] = useState([]);
  const [industryMasterArr, setIndustryMasterArr] = useState([]);
  const [sectorsKey, setSectorsKey] = useState(false);
  const [industryKey, setIndustryKey] = useState(false);
  const [resetButton, setResetButton] = useState(false);
  const [sectorsIds, setSectorsIds] = useState([]);

  const [turnAroundMasterArr, setTurnAroundMasterArr,] = React.useState([]);



  const handleChange = (panel) => (event, newExpanded) => {

    if (panel === 'panel1') {
      setExpanded1(newExpanded ? panel : false);
    }
    if (panel === 'panel2') {
      setExpanded2(newExpanded ? panel : false);
    }
    if (panel === 'panel3') {
      setExpanded3(newExpanded ? panel : false);
    }
    if (panel === 'panel4') {
      setExpanded4(newExpanded ? panel : false);
    }
    if (panel === 'panel5') {
      setExpanded5(newExpanded ? panel : false);
    }
    if (panel === 'panel6') {
      setExpanded6(newExpanded ? panel : false);
    }
  };

  const { control, handleSubmit } = useForm();

  const DeliverableVolume = [
    {
      label: 'Market Cap (Cr.)',
      placeholder: '>1',
      name: '',
      type: 'array',
      gridSize: '12',
      children: [
        {
          label: '',
          placeholder: '>500',
          name: 'MarketCapFrom',
          type: 'string',
          gridSize: '6',
        },
        {
          label: '',
          placeholder: '>500000',
          name: 'MarketCapTo',
          type: 'string',
          gridSize: '6',
        },
      ]
    },
    {
      label: 'LTP vs 200DMA (%)',
      placeholder: '>2',
      name: 'LtpVS200DMA',
      type: 'string',
    },
    {
      label: 'Yesterday Deliverable Volume (\'000)',
      placeholder: '>100',
      name: 'DelQty',
      type: 'string',
    },
    {
      label: 'Deliverable Times (x)',
      placeholder: '>0.5',
      name: 'DelPer7D',
      type: 'string',
    },
    {
      label: 'Deliverable Value(Cr.)',
      placeholder: '>100',
      name: 'DelValue',
      type: 'string',
    },
  ];

  const handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (value === 'F_O') {
      setInputs({ ...inputs, ['chkF_O']: true, ['chkPortfolio']: false });
      console.log('inputs >> ' + value);
    } else
      if (value === 'portfolio') {
        setInputs({ ...inputs, ['chkPortfolio']: true, ['chkF_O']: false });
        console.log('inputs >> ' + value);
      } else {
        setInputs({ ...inputs, [name]: value });
      }

  }
  
const handleChangeChecked = (event) => {
  const name = event.target.name;
  const value = event.target.checked;
  setChecked({...checked, [name]: value});
  // console.log('checked >> '+JSON.stringify(checked));
};
 


  const applyNow = (data) => {

    var sectorValue = sectors.map((item) => item.value);
    var industryValue = industry.map((item) => item.value);
    var companyValue = company.map((item) => item.value);

    const topLabels =
    {
      "sectors": {
        "label": "Sector",
        "value1": (sectorValue || ""),
        "value2": ""
      },
      "industry": {
        "label": "Industry",
        "value1": (industryValue || ""),
        "value2": ""
      },
      "company": {
        "label": "Company",
        "value1": (companyValue || ""),
        "value2": ""
      },
      "Market_Cap": {
        "label": "Market Cap (Cr.)",
        "value1": (data?.MarketCapFrom || ''),
        "value2": (data?.MarketCapTo || '')
      },
      "LtpVS200DMA": {
        "label": "LTP vs 200DMA (%)",
        "value1": (data?.LtpVS200DMA || ''),
        "value2": ""
      },
      "DelDays": {
        "label": "Del Days",
        "value1": (inputs?.DelDays || ""),
        "value2": ""
      },
      "DelQty": {
        "label": "Yesterday Deliverable Volume ('000)",
        "value1": (inputs?.DelQty || ""),
        "value2": ""
      },
      "DelPer7D": {
        "label": "Deliverable Times (x)",
        "value1": (inputs?.DelPer7D || ""),
        "value2": ""
      },
      "Delvalue": {
        "label": "Deliverable Value (Cr.)",
        "value1": (inputs?.DelValue || ""),
        "value2": ""
      },
      "chkPortfolio": {
        "label": "Portfolio",
        "value1": (inputs?.chkPortfolio || false),
        "value2": ""
      },
      "chkF_O": {
        "label": "F&O",
        "value1": (inputs?.chkF_O || false),
        "value2": ""
      },
      "chkbox200DMA":{
          "label":"Check box 200 DMA",
          "value1":(checked?.chkbox200DMA || false),
          "value2":""
      },
      "chkbox50DMA":{
          "label":"Check Box 50DMA",
          "value1":(checked?.chkbox50DMA || false),
          "value2":""
      }
    };
    const filterArray = deliveryDataFilters(topLabels);
    dispatch(DeliveryDataFilterInputAction(topLabels));
    dispatch(DeliveryDataAction(filterArray));
  }



  return (
    <>
      <Box sx={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
       }}>
       <Typography sx={{ 
         marginY:2,
         fontSize: [14, 18, 24],
         fontWeight: 'bold',
       }} >Filter</Typography>
        <Button className='btn-cst-primary' type='submit' onClick={handleSubmit(applyNow)} variant='contained' size={'small'}>Apply</Button>
      </Box>
      <Accordion expanded={expanded1 === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Classification</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={6} >
            <SelectSector
              sectors={sectors}
              sectorMasterArr={sectorMasterArr}
              setSectors={setSectors}
              setSectorMasterArr={setSectorMasterArr}
              sectorsIds={sectorsIds}
              setSectorsIds={setSectorsIds}
              sectorMaster={sectorMaster}
            />

            <SelectIndustry
              industry={industry}
              industryMasterArr={industryMasterArr}
              sectorsIds={sectorsIds}
              setIndustry={setIndustry}
              setIndustryMasterArr={setIndustryMasterArr}
              sectorMaster={sectorMaster}
              industryMaster={industryMaster}
            />

            <SelectCompany
              sectors={sectors}
              industry={industry}
              sectorMasterArr={sectorMasterArr}
              setSectors={setSectors}
              sectorsIds={sectorsIds}
              setSectorsIds={setSectorsIds}
              companyMaster={companyMaster}
              companyMasterArr={companyMasterArr}
              company={company}
              setCompany={setCompany}
              setCompanyMasterArr={setCompanyMasterArr}
              companyMasterLoading={companyMasterLoading}
            />
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Portfolio_FO"
                defaultValue=""
                name="Portfolio_FO"
                onChange={handleChangeInput}
              >
                <Grid container spacing={1} className='demp123465'>
                  <Grid item xs={6}>
                    <FormControlLabel className="labelItem" value="portfolio" control={<Radio size='small' />} label="Portfolio" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel className="labelItem" value="F_O" control={<Radio size='small' />} label="F&O" />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded2 === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Daily Moving Average</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

            <Grid item xs={6}>
              <Box>
                <Checkbox
                  className={'checkBoxContainer'}
                  name='chkbox50DMA'
                  onChange={handleChangeChecked}
                  inputProps={{ 'aria-label': '50 DMA' }}
                  size='small'
                />
                <label>50 DMA</label>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Checkbox
                  className={'checkBoxContainer'}
                  name='chkbox200DMA'
                  onChange={handleChangeChecked}
                  inputProps={{ 'aria-label': '200 DMA' }}
                  size='small'
                />
                <label>200 DMA</label>
              </Box>
            </Grid>

          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded3 === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Time Interval</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12}>
            <Box>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  defaultValue=""
                  name="DelDays"
                  onChange={handleChangeInput}
                >
                  <Grid container className='demp123465'>
                    <Grid item xs={6}>
                      <FormControlLabel className="labelItem" value="7D" control={<Radio size='small' />} label="Weekly" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel className="labelItem" value="15D" control={<Radio size='small' />} label="15 Days" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel className="labelItem" value="1M" control={<Radio size='small' />} label="Monthly" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel className="labelItem" value="3M" control={<Radio size='small' />} label="Quarterly" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel className="labelItem" value="6M" control={<Radio size='small' />} label="Half Yearly" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel className="labelItem" value="1Y" control={<Radio size='small' />} label="Yearly" />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>

            </Box>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded4 === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>Deliverable Volume</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {
              DeliverableVolume.map((item, index) => {
                if (item.type === 'array') {
                  return (
                    <>
                      <Grid item xs={12} key={index}>
                        <label className='sidebar-label'>{item.label}</label>
                      </Grid>
                      {
                        item?.children.map((child, index0) => {
                          return (
                            <Grid item xs={child.gridSize} key={index0}>
                              <InputComponent
                                name={child.name}
                                label={child.label}
                                control={control}
                                size="small"
                                placeholder={child.placeholder}
                                className='cInput'
                              />
                            </Grid>
                          )
                        })
                      }

                    </>
                  )
                } else {
                  return (
                    <Grid item xs={item.gridSize} key={index}>
                      <InputComponent
                        name={item.name}
                        label={item.label}
                        control={control}
                        size="small"
                        placeholder={item.placeholder}
                        className='cInput'
                      />
                    </Grid>
                  )
                }
              })
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion expanded={expanded5 === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography>Turn Around</Typography>
        </AccordionSummary>
        <AccordionDetails>

        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded6 === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography>Conditions</Typography>
        </AccordionSummary>
        <AccordionDetails>

        </AccordionDetails>
      </Accordion> */}
    </>
  );
}

