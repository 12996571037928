import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { InputComponent, SelectCompany, SelectIndustry, SelectSector } from '../Common';
import { Grid, Box, Checkbox, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { valuationDataAction, valuationFilterInputAction, valuationFilters } from '../../redux/actions/userAction';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    // borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#000' }} />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: '2rem',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    // marginLeft: theme.spacing(1),
    margin: 0,
  },
  '& .MuiAccordionSummary-content p': {
    fontSize: '0.85rem',
    fontWeight: '700',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function ValuationFilter() {
  // const [expanded, setExpanded] = React.useState('panel1');

  const [expanded1, setExpanded1] = React.useState('panel1');
  const [expanded2, setExpanded2] = React.useState(null);
  const [expanded3, setExpanded3] = React.useState(null);
  const [expanded4, setExpanded4] = React.useState(null);
  const [expanded5, setExpanded5] = React.useState(null);
  const [expanded6, setExpanded6] = React.useState(null);




  const { data: companyMaster, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
  const { data: sectorMaster, loading: sectorMasterLoading } = useSelector((state) => state.sectorMasterData);
  const { data: industryMaster, loading: industryMasterLoading } = useSelector((state) => state.industryMasterData);
  const { data: turnAroundMaster, loading: turnAroundMasterLoading } = useSelector((state) => state.turnAroundMasterData);


  const [sectors, setSectors] = React.useState([]);
  const [industry, setIndustry] = useState([]);
  const [sectorMasterArr, setSectorMasterArr] = useState([]);
  const [company, setCompany] = useState([]);
  const [companyMasterArr, setCompanyMasterArr] = useState([]);
  const [filterArr, setFilterArr] = useState([]);
  const [industryMasterArr, setIndustryMasterArr] = useState([]);
  const [sectorsKey, setSectorsKey] = useState(false);
  const [industryKey, setIndustryKey] = useState(false);
  const [resetButton, setResetButton] = useState(false);
  const [sectorsIds, setSectorsIds] = useState([]);

  const [turnAroundMasterArr, setTurnAroundMasterArr,] = React.useState([]);





  const handleChange = (panel) => (event, newExpanded) => {

    if (panel === 'panel1') {
      setExpanded1(newExpanded ? panel : false);
    }
    if (panel === 'panel2') {
      setExpanded2(newExpanded ? panel : false);
    }
    if (panel === 'panel3') {
      setExpanded3(newExpanded ? panel : false);
    }
    if (panel === 'panel4') {
      setExpanded4(newExpanded ? panel : false);
    }
    if (panel === 'panel5') {
      setExpanded5(newExpanded ? panel : false);
    }
    if (panel === 'panel6') {
      setExpanded6(newExpanded ? panel : false);
    }
  };
  const { control, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);

  const [inputs, setInputs] = useState(false);

  // const handleChangeChecked = (event) => {
  //   setChecked(event.target.checked);
  // };



  
  const handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    
    console.log('inputs >> ' + value);
    if (value === 'F_O') {
      setInputs({ ...inputs, ['chkF_O']: true, ['chkPortfolio']: false });
    } else
      if (value === 'portfolio') {
        setInputs({ ...inputs, ['chkPortfolio']: true, ['chkF_O']: false });
        console.log('inputs >> ' + value);
      } else {
        setInputs({ ...inputs, [name]: value });
      }

  }
  
const handleChangeChecked = (event) => {
  const name = event.target.name;
  const value = event.target.checked;
  setChecked({...checked, [name]: value});
  // console.log('checked >> '+JSON.stringify(checked));
};
 

  const balanceSheet = [
    {
      label: 'Total Debt/ Equity (x)',
      placeholder: '>1',
      name: 'Total_DebtEquity',
      type: 'string',
    },
    {
      label: 'Gross Block Addition in 5yrs(Cr.)',
      placeholder: '>1',
      name: 'GrossBlockAdditionin_5yrs',
      type: 'string',
    },
    {
      label: 'Total Debt Increase in 5yrs(Cr.)',
      placeholder: '>1',
      name: 'TotalDebtIncreasein_5yrs',
      type: 'string',
    },
    {
      label: 'ROCE (%)',
      placeholder: '>1',
      name: '',
      type: 'array',
      gridSize: '12',
      children: [
        {
          label: '',
          placeholder: '>1',
          name: 'ROCEFrom',
          type: 'string',
          gridSize: '6',
        },
        {
          label: '',
          placeholder: '>1',
          name: 'ROCETo',
          type: 'string',
          gridSize: '6',
        },
      ]
    },
    {
      label: 'Net Cash/(Net Debt) (cr.)',
      placeholder: '>1',
      name: 'NetCash',
      type: 'string',
    },
    {
      label: 'Net Cash/ Mcap (x)',
      placeholder: '>1',
      name: 'Net_Cash_Mcap',
      type: 'string',
    },
    {
      label: 'CFO/EBIDTA (x)',
      placeholder: '>1',
      name: 'CFO_EBIDTA',
      type: 'string',
    },
  ];


  const sharePrice = [

    {
      label: 'Market Cap',
      placeholder: '>1',
      name: '',
      type: 'array',
      gridSize: '12',
      children: [
        {
          label: '',
          placeholder: '>500',
          name: 'MarketCapFrom',
          type: 'string',
          gridSize: '6',
        },
        {
          label: '',
          placeholder: '>5000',
          name: 'MarketCapTo',
          type: 'string',
          gridSize: '6',
        },
      ]
    },
    {
      label: 'LTP',
      placeholder: '>10',
      name: 'LTP',
      type: 'string',
    },
    {
      label: 'Away 52wk High (%)',
      placeholder: '>30',
      name: 'Away52wkHigh',
      type: 'string',
    },
    {
      label: 'Away 52wk Low (%)',
      placeholder: '>10',
      name: 'Away52wkLow',
      type: 'string',
    },
    {
      label: 'All Time High (%)',
      placeholder: '>10',
      name: 'AllTimeHigh',
      type: 'string',
    },
    {
      label: 'TTM PE (x)',
      placeholder: '>1',
      type: 'array',
      gridSize: '12',
      children: [
        {
          label: '',
          placeholder: '>1',
          name: 'TTM_PEFrom',
          type: 'string',
          gridSize: '6',
        },
        {
          label: '',
          placeholder: '>20',
          name: 'TTM_PETo',
          type: 'string',
          gridSize: '6',
        },
      ]
    },
    {
      label: 'TTM PBV (x)',
      type: 'array',
      gridSize: '12',
      children: [
        {
          label: '',
          placeholder: '>1',
          name: 'TTM_PBVFrom',
          type: 'string',
          gridSize: '6',
        },
        {
          label: '',
          placeholder: '>20',
          name: 'TTM_PBVTo',
          type: 'string',
          gridSize: '6',
        },
      ]
    },

  ];


  const holdingPattern = [
    {
      label: 'Promoter Holding (%)',
      placeholder: '>1',
      name: 'Promoter_Holding',
    },
    {
      label: 'Pledge (%)',
      placeholder: '>1',
      name: 'Pledge',
    },
  ];

  const HistoricalFilters = [
    {
      label: 'Diff B/w 5yrs Avg P/BV(x)',
      placeholder: '>1',
      name: 'Diff_Bw_5yrsAvg_PBV',
    },
    {
      label: 'Diff B/w 10yrs Avg P/BV(x)',
      placeholder: '>1',
      name: 'Diff_Bw_10yrsAvg_PBV',
    },
    {
      label: 'Diff B/w 5yrs Avg P/E(x)',
      placeholder: '>1',
      name: 'Diff_Bw_5yrsAvg_PE',
    },
    {
      label: 'Diff B/w 10yrs Avg P/E(x)',
      placeholder: '>1',
      name: 'Diff_Bw_10yrsAvg_PE',
    },
    {
      label: 'Avg. Sales 3yrs (cr.)',
      placeholder: '>1',
      name: 'Avg_Sales_3yrs',
    },
    {
      label: 'Avg. Sales 5yrs (cr.)',
      placeholder: '>1',
      name: 'Avg_Sales_5yrs',
    },
    {
      label: 'Avg. Sales 10yrs (cr.)',
      placeholder: '>1',
      name: 'Avg_Sales_10yrs',
    },
    {
      label: 'Avg. PAT 3yrs (cr.)',
      placeholder: '>1',
      name: 'Avg_PAT_3yrs',
    },
    {
      label: 'Avg. PAT 5yrs (cr.)',
      placeholder: '>1',
      name: 'Avg_PAT_5yrs',
    },
    {
      label: 'Avg. PAT 10yrs (cr.)',
      placeholder: '>1',
      name: 'Avg_PAT_10yrs',
    },
  ];




  const applyNow = (data) => {

    console.log(data);

    var sectorValue = sectors.map((item) => item.value);
    var industryValue = industry.map((item) => item.value);
    var companyValue = company.map((item) => item.value);


    const topLabels =

    {
      "Market_Cap": {
        "label": "Market Cap (Cr.)",
        "value1": (data.MarketCapFrom || ''),
        "value2": (data.MarketCapTo || '')
      },
      "LTP": {
        "label": "LTP",
        "value1": (data.LTP || ''),
        "value2": ""
      },
      "Away52wkHigh": {
        "label": "Away 52wk High",
        "value1": (data.Away52wkHigh || ""),
        "value2": ""
      },
      "Away52wkLow": {
        "label": "Away 52wk Low",
        "value1": (data.Away52wkLow || ""),
        "value2": ""
      },
      "AllTimeHigh": {
        "label": "All Time High (%)",
        "value1": (data?.AllTimeHigh || ""),
        "value2": "",
      },
      "TTM_PE": {
        "label": "TTM PE (x)",
        "value1": (data?.TTM_PEFrom || ""),
        "value2": (data?.TTM_PETo || ""),
      },
      "TTM_Sales_Abs": {
        "label": "TTM_Sales_Abs",
        "value2": (data.TTM_Sales_Abs || ""),
      },
      "TTM_PBV": {
        "label": "TTM PBV (x)",
        "value1": (data?.TTM_PBVFrom || ""),
        "value2": (data?.TTM_PBVTo || "")
      },
      "Diff_Bw_5yrsAvg_PBV": {
        "label": "Diff B/w 5yrs Avg P/BV (x)",
        "value1": (data.Diff_Bw_5yrsAvg_PBV || ""),
        "value2": ""
      },
      "Diff_Bw_10yrsAvg_PBV": {
        "label": "Diff B/w 10yrs Avg P/BV (x)",
        "value1": (data.Diff_Bw_10yrsAvg_PBV || ""),
        "value2": ""
      },
      "Diff_Bw_5yrsAvg_PE": {
        "label": "Diff B/w 5yrs Avg P/E (x)",
        "value1": (data.Diff_Bw_5yrsAvg_PE || ""),
        "value2": ""
      },
      "Diff_Bw_10yrsAvg_PE": {
        "label": "Diff B/w 10yrs Avg P/E (x)",
        "value1": (data.Diff_Bw_10yrsAvg_PE || ""),
        "value2": ""
      },
      "Avg_Sales_3yrs": {
        "label": "Avg Sales 3yrs (cr.)",
        "value1": (data.Avg_Sales_3yrs || ""),
        "value2": ""
      },
      "Avg_Sales_5yrs": {
        "label": "Avg Sales 5yrs (cr.)",
        "value1": (data.Avg_Sales_5yrs || ""),
        "value2": ""
      },
      "Avg_Sales_10yrs": {
        "label": "Avg Sales 10yrs (cr.)",
        "value1": (data.Avg_Sales_10yrs || ""),
        "value2": ""
      },
      "Avg_PAT_3yrs": {
        "label": "Avg_PAT_3yrs (cr.)",
        "value1": (data.Avg_PAT_3yrs || ""),
        "value2": ""
      },
      "Avg_PAT_5yrs": {
        "label": "Avg PAT 5yrs (cr.)",
        "value1": (data.Avg_PAT_5yrs || ""),
        "value2": ""
      },
      "Avg_PAT_10yrs": {
        "label": "Avg PAT 10yrs (cr.)",
        "value1": (data.Avg_PAT_10yrs || ""),
        "value2": ""
      },
      "Total_DebtEquity": {
        "label": "Total Debt Equity",
        "value1": (data.Total_DebtEquity || ""),
        "value2": ""
      },
      "GrossBlockAdditionin_5yrs": {
        "label": "Gross Block Addition in 5yrs (cr.)",
        "value1": (data.GrossBlockAdditionin_5yrs || ""),
        "value2": ""
      },
      "TotalDebtIncreasein_5yrs": {
        "label": "Total Debt Increase in 5yrs (cr.)",
        "value1": (data.TotalDebtIncreasein_5yrs || ""),
        "value2": ""
      },
      "ROCE": {
        "label": "ROCE (%)",
        "value1": (data?.ROCEFrom || ""),
        "value2": (data?.ROCETo || ""),
      },
      "NetCash": {
        "label": "Net Cash/(Net Debt) (cr.)",
        "value1": (data.NetCash || ""),
        "value2": ""
      },
      "CFO_EBIDTA": {
        "label": "CFO EBIDTA (x)",
        "value1": (data.CFO_EBIDTA || ""),
        "value2": ""
      },
      "Net_Cash_Mcap": {
        "label": "Net Cash/ Mcap (x)",
        "value1": (data.Net_Cash_Mcap || ""),
        "value2": ""
      },
      "Promoter_Holding": {
        "label": "Promoter Holding (%)",
        "value1": (data.Promoter_Holding || ""),
        "value2": ""
      },
      "Pledge": {
        "label": "Pledge (%)",
        "value1": (data.Pledge || ""),
        "value2": ""
      },
      "sectors": {
        "label": "Sector",
        "value1": (sectorValue || ""),
        "value2": ""
      },
      "industry": {
        "label": "Industry",
        "value1": (industryValue || ""),
        "value2": ""
      },
      "company": {
        "label": "Company",
        "value1": (companyValue || ""),
        "value2": ""
      },
      "portfolio": {
        "label": "Portfolio",
        "value1": checked,
        "value2": ""
      }
    };

    const filterArray = valuationFilters(topLabels);
    console.log('filterArray >>> ', filterArray);
    dispatch(valuationFilterInputAction(topLabels));
    dispatch(valuationDataAction(filterArray));


  }






  return (
    <>

      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Typography sx={{
          marginY: 2,
          fontSize: [14, 18, 24],
          fontWeight: 'bold',
        }} >Filter</Typography>
        <Button type='submit' onClick={handleSubmit(applyNow)} variant='contained' size={'small'}>Apply</Button>
      </Box>

      <Accordion expanded={expanded1 === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Classification</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={6} >
            <SelectSector
              sectors={sectors}
              sectorMasterArr={sectorMasterArr}
              setSectors={setSectors}
              setSectorMasterArr={setSectorMasterArr}
              sectorsIds={sectorsIds}
              setSectorsIds={setSectorsIds}
              sectorMaster={sectorMaster}
            />

            <SelectIndustry
              industry={industry}
              industryMasterArr={industryMasterArr}
              sectorsIds={sectorsIds}
              setIndustry={setIndustry}
              setIndustryMasterArr={setIndustryMasterArr}
              sectorMaster={sectorMaster}
              industryMaster={industryMaster}
            />

            <SelectCompany
              sectors={sectors}
              industry={industry}
              sectorMasterArr={sectorMasterArr}
              setSectors={setSectors}
              sectorsIds={sectorsIds}
              setSectorsIds={setSectorsIds}
              companyMaster={companyMaster}
              companyMasterArr={companyMasterArr}
              company={company}
              setCompany={setCompany}
              setCompanyMasterArr={setCompanyMasterArr}
              companyMasterLoading={companyMasterLoading}
            />
            <Box>
              <Checkbox
                size='small'
                className={'checkBoxContainer'}
                checked={checked}
                onChange={handleChangeChecked}
                inputProps={{ 'aria-label': 'Portfolio' }}
              />
              <label>Portfolio</label>
            </Box>

          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded2 === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Share Price (TTM)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {
              sharePrice.map((item, index) => {
                if (item.type === 'array') {
                  return (
                    <>
                      <Grid item xs={12} key={index}>
                        <label className='sidebar-label'>{item.label}</label>
                      </Grid>
                      {
                        item?.children.map((child, index0) => {
                          return (
                            <Grid item xs={child.gridSize} key={index0}>
                              <InputComponent
                                name={child.name}
                                label={child.label}
                                control={control}
                                size="small"
                                placeholder={child.placeholder}
                                className='cInput'
                              />
                            </Grid>
                          )
                        })
                      }
                    </>
                  )
                } else {
                  return (
                    <Grid item xs={item.gridSize} key={index}>
                      <InputComponent
                        name={item.name}
                        label={item.label}
                        control={control}
                        size="small"
                        placeholder={item.placeholder}
                        className='cInput'
                      />
                    </Grid>
                  )
                }


              })
            }

          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded3 === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Historical</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {
              HistoricalFilters.map((item, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <InputComponent
                      name={item.name}
                      label={item.label}
                      control={control}
                      size="small"
                      placeholder={item.placeholder}
                      className='cInput'
                    />
                  </Grid>
                )

              })
            }

          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded4 === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>Balance Sheet</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {
              balanceSheet.map((item, index) => {
                if (item.type === 'array') {
                  return (
                    <>
                      <Grid item xs={12} key={index}>
                        <label className='sidebar-label'>{item.label}</label>
                      </Grid>
                      {
                        item?.children.map((child, index0) => {
                          return (
                            <Grid item xs={child.gridSize} key={index0}>
                              <InputComponent
                                name={child.name}
                                label={child.label}
                                control={control}
                                size="small"
                                placeholder={child.placeholder}
                                className='cInput'
                              />
                            </Grid>
                          )
                        })
                      }

                    </>
                  )
                } else {
                  return (
                    <Grid item xs={item.gridSize} key={index}>
                      <InputComponent
                        name={item.name}
                        label={item.label}
                        control={control}
                        size="small"
                        placeholder={item.placeholder}
                        className='cInput'
                      />
                    </Grid>
                  )
                }


              })
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded5 === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography>Share Holding Pattern</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Grid container spacing={1}>
            {
              holdingPattern.map((item, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <InputComponent
                      name={item.name}
                      label={item.label}
                      control={control}
                      size="small"
                      placeholder={item.placeholder}
                      className='cInput'
                    />
                  </Grid>
                )

              })
            }

          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion expanded={expanded6 === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography>Conditions</Typography>
        </AccordionSummary>
        <AccordionDetails>

        </AccordionDetails>
      </Accordion> */}
    </>
  );
}
