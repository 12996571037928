import axios from "axios";
import {
  ALL_USERS_FAIL,
  ALL_USERS_SUCCESS,
  ALL_USERS_REQUEST,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  ALL_COMPANY_REQUEST,
  ALL_COMPANY_SUCCESS,
  ALL_COMPANY_FAIL,
  SINGLE_COMPANY_REQUEST,
  SINGLE_COMPANY_SUCCESS,
  SINGLE_COMPANY_FAIL,
  ALL_COMPANY_REQUEST_2,
  ALL_COMPANY_SUCCESS_2,
  ALL_COMPANY_FAIL_2,
  CALENDER_REQUEST,
  CALENDER_SUCCESS,
  CALENDER_FAIL,
  COMPANY_MASTER_REQUEST,
  COMPANY_MASTER_SUCCESS,
  COMPANY_MASTER_FAIL,
  SECTOR_REQUEST,
  SECTOR_SUCCESS,
  SECTOR_FAIL,
  INDUSTRY_REQUEST,
  INDUSTRY_SUCCESS,
  INDUSTRY_FAIL,
  TURN_AROUND_REQUEST,
  TURN_AROUND_SUCCESS,
  TURN_AROUND_FAIL,
  RESULT_DATA_REQUEST,
  RESULT_DATA_SUCCESS,
  RESULT_DATA_FAIL,
  VALUATION_REQUEST,
  VALUATION_SUCCESS,
  VALUATION_FAIL,
  VALUATION_FILTER_REQUEST,
  VALUATION_FILTER_SUCCESS,
  VALUATION_FILTER_FAIL,
  DELIVERY_DATA_REQUEST,
  DELIVERY_DATA_SUCCESS,
  DELIVERY_DATA_FAIL,
  DELIVERY_DATA_FILTER_REQUEST,
  DELIVERY_DATA_FILTER_SUCCESS,
  DELIVERY_DATA_FILTER_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  SINGLE_COMPANY_VALUATION_REQUEST,
  SINGLE_COMPANY_VALUATION_FAIL,
  SINGLE_COMPANY_VALUATION_SUCCESS,
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAIL,
  UPLOAD_DOCUMENT_ANALYST_REQUEST,
  UPLOAD_DOCUMENT_ANALYST_SUCCESS,
  UPLOAD_DOCUMENT_ANALYST_FAIL,
  COMPANY_COMMENTS_REQUEST,
  COMPANY_COMMENTS_SUCCESS,
  COMPANY_COMMENTS_FAIL,
  ABOUT_US_UPDATE_REQUEST,
  ABOUT_US_UPDATE_SUCCESS,
  ABOUT_US_UPDATE_FAIL,
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAIL,
  

  FIIS_MODEL_REQUEST,
  FIIS_MODEL_SUCCESS,
  FIIS_MODEL_FAIL,
  FIIS_SECTORS_REQUEST,
  FIIS_SECTORS_SUCCESS,
  FIIS_SECTORS_FAIL,
  DATE_MASTER_REQUEST,
  DATE_MASTER_SUCCESS,
  DATE_MASTER_FAIL,
  FIIS_TOP_BOTTOM_REQUEST,
  FIIS_TOP_BOTTOM_SUCCESS,
  FIIS_TOP_BOTTOM_FAIL,
  
  RATIO_TOOLTIP_REQUEST,
  RATIO_TOOLTIP_SUCCESS,
  RATIO_TOOLTIP_FAIL,

  FORENSIC_TAB_SHOW_HIDE_REQUEST,
  FORENSIC_TAB_SHOW_HIDE_SUCCESS,
  FORENSIC_TAB_SHOW_HIDE_FAIL,

  FIIS_MODEL_COMMENT_REQUEST,
  FIIS_MODEL_COMMENT_SUCCESS,
  FIIS_MODEL_COMMENT_FAIL,

SINGLE_COMPANY_NOTE_REQUEST,
SINGLE_COMPANY_NOTE_SUCCESS,
SINGLE_COMPANY_NOTE_FAIL,

} from "../../constants/userConstants";


import {  
          baseUrl, 
          GetUsers,
          userLogin,
          forgotPassword,
          allCompanyDataRequest,
          allCompanyData2Request,
          singleCompanyRequest,
          calenderRequest,
          companyMasterRequest,
          industryMasterRequest, 
          sectorMasterRequest, 
          turnAroundMasterRequest,
          resetPasswordRequest,
          valuationDataRequest,
          DeliveryDataRequest,
          SingleCompanyValuationRequest,
          UploadDocumentRequest,
          SingleCompanyCommentsRequest,
          MultipleFileUploaderRequest,
          MultipleAnalystNotesUploaderRequest,
          UploadDocumentAnalystNotesRequest,
          SingleCompanyAboutUsUpdateRequest,
          SendNotificationRequest,
                  
          FIIsTopBottomRequest,
          FIIsSectorsRequest,
          FIIsModelRequest,
          FIISDateMasterRequest,
          RatioTooltipRequest,
          ForensicTabShowHideRequest,
          ForensicModelCommentsRequest,
          singleCompanyNoteRequest,
          SingleCompanyValuationNewRequest,
        } from "../../utils/userRequest";
// import SingleCompanyComponent from "../components/TableWithExport/SingleCompanyComponent";

// get All Users
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_USERS_REQUEST });
    const { data, status } = await GetUsers({ limit: 10 });

    dispatch({ type: ALL_USERS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: ALL_USERS_FAIL, payload: error.response.data.message });
  }
};


export const loginAction = (userName, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    const { data } = await userLogin({userName, password});
    if(data.status == 0){
      // console.log('data. >>> '+JSON.stringify(data.msg))
      dispatch({ type: LOGIN_FAIL, payload: data.msg });
    }else{
      dispatch({ type: LOGIN_SUCCESS, payload: data });
      localStorage.setItem('user', JSON.stringify(data));
      var expires = (7*24*60*60);
      var now = Date.now()
      var schedule = now + expires*1000;
      localStorage.setItem('user_expiresIn', schedule);


    }
  } catch (error) {
    dispatch({ type: LOGIN_FAIL, payload: error.response.data.msg });
  }
};


export const forgotPasswordAction = (userName) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    const { data } = await forgotPassword({userName});
    if(data.status == 0){
      // console.log('data. >>> '+JSON.stringify(data.msg))
      dispatch({ type: FORGOT_PASSWORD_FAIL, payload: data.msg });
    }else{
      dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data });
      // localStorage.setItem('user', JSON.stringify(data));
      // var expires = (7*24*60*60);
      // var now = Date.now()
      // var schedule = now + expires*1000;
      // localStorage.setItem('user_expiresIn', schedule);


    }
  } catch (error) {
    dispatch({ type: FORGOT_PASSWORD_FAIL, payload: error.response.data.msg });
  }
};


export const resetPasswordAction = (arrayData) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    const { data } = await resetPasswordRequest(arrayData);
    if(data.status == 0){
      // console.log('data. >>> '+JSON.stringify(data.msg))
      dispatch({ type: RESET_PASSWORD_FAIL, payload: data.msg });
    }else{
      dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data });
      localStorage.setItem('user', JSON.stringify(data));
    }
  } catch (error) {
    dispatch({ type: RESET_PASSWORD_FAIL, payload: error.response.data.msg });
  }
};




export const resultFilters = (inputsArray) => {
  
  const filterArray =[
    {
       "$id":null,
       "type":"Share Price (TTM)",
       "sub_type":[
          {
             "Market_Cap":[
                (inputsArray.Market_Cap.value1 || ''),
                (inputsArray.Market_Cap.value2 || '')
             ],
             "LTP":(inputsArray.LTP.value1 || ""),
             "TTM_P_B":(inputsArray.TTM_P_B.value1 || ""),
             "TTM_P_E":(inputsArray.TTM_P_E.value1 || ""),
             "ROCE":(inputsArray.ROCE.value1 || ""),
             "TTMSalesAbs":(inputsArray.TTM_Sales_Abs.value1 || ""),
             "TTMPATAbs":(inputsArray.TTM_PAT_Abs.value1 || "")
          }
       ]
    },
    {
       "type":"Result Data",
       "sub_type":[
          {
             "$id":"5",
             "Sales_YOY":(inputsArray.Sales_YOY.value1 || ""),
             "Sales_QOQ":(inputsArray.Sales_QOQ.value1 || ""),
             "EBDITA_YOY":(inputsArray.EBDITA_YOY.value1 || ""),
             "EBDITA_QOQ":(inputsArray.EBDITA_QOQ.value1 || ""),
             "PAT_YOY":(inputsArray.PAT_YOY.value1 || ""),
             "PAT_QOQ":(inputsArray.PAT_QOQ.value1 || ""),
             "GP_YOY":(inputsArray.GROSS_PROFIT_YOY.value1 || ""),
             "GP_QOQ":(inputsArray.GROSS_PROFIT_QOQ.value1 || "")
          }
       ]
    },
    {
       "type":"Turn Around",
       "sub_type":[
          {
             "EBDITA_TO":inputsArray.EBDITA_TO.value1,
             "PAT_TO":inputsArray.PAT_TO.value1
          }
       ]
    },
    {
       "type":"More Filters",
       "sub_type":[
          {
             "Sector":inputsArray.sectors.value1,
             "Industry":inputsArray.industry.value1,
             "Company":inputsArray.company.value1,
             "Portfolio":inputsArray.portfolio.value1,
          }
       ]
    },
    {
       "type":"Date",
       "sub_type":[
          {
             "FromDate":inputsArray.date_range.value1,
             "ToDate":inputsArray.date_range.value2
          }
       ]
    },
    {
      "type": "Color",
      "sub_type": [
        {
          "ColorCode": inputsArray.ColorCode.value1
        }
      ]
    }
  ];

  return filterArray;

};



export const valuationFilters = (inputsArray) => {

  const filterArray = [
    {
          "type":"Classification",
          "sub_type":[
              {
                  "Sector":(inputsArray?.sectors?.value1 || null),
                  "Industry":(inputsArray?.industry?.value1 || null),
                  "Company":(inputsArray?.company?.value1 || null),
                  "Portfolio":(inputsArray?.portfolio?.value1 || false)
              }
          ]
      },
      {
          "type":"Share Price (TTM)",
          "sub_type":[
              {
                "Market_Cap":[(inputsArray?.Market_Cap?.value1 || ""),(inputsArray?.Market_Cap?.value2 || "")],
                "LTP":(inputsArray?.LTP?.value1 || null),
                "Away52wkHigh":(inputsArray?.Away52wkHigh?.value1 || null),
                "Away52wkLow":(inputsArray?.Away52wkLow?.value1 || null),
                "AllTimeHigh":(inputsArray?.AllTimeHigh?.value1 || null),
                "TTM_PE":[(inputsArray?.TTM_PE?.value1 || ""),(inputsArray?.TTM_PE?.value2 || "")], //(inputsArray?.TTM_PE?.value1 || null),
                "TTM_PBV":[(inputsArray?.TTM_PBV?.value1 || ""),(inputsArray?.TTM_PBV?.value2 || "")], //(inputsArray?.TTM_PBV?.value1 || null)
              }
          ]
      },
      {
          "type":"Historical",
          "sub_type":[
              {
              "Diff_Bw_5yrsAvg_PBV":(inputsArray?.Diff_Bw_5yrsAvg_PBV?.value1 || null),
              "Diff_Bw_10yrsAvg_PBV":(inputsArray?.Diff_Bw_10yrsAvg_PBV?.value1 || null),
              "Diff_Bw_5yrsAvg_PE":(inputsArray?.Diff_Bw_5yrsAvg_PE?.value1 || null),
              "Diff_Bw_10yrsAvg_PE":(inputsArray?.Diff_Bw_10yrsAvg_PE?.value1 || null),
              "Avg_Sales_3yrs":(inputsArray?.Avg_Sales_3yrs?.value1 || null),
              "Avg_Sales_5yrs":(inputsArray?.Avg_Sales_5yrs?.value1 || null),
              "Avg_Sales_10yrs":(inputsArray?.Avg_Sales_10yrs?.value1 || null),
              "Avg_PAT_3yrs":(inputsArray?.Avg_PAT_3yrs?.value1 || null),
              "Avg_PAT_5yrs":(inputsArray?.Avg_PAT_5yrs?.value1 || null),
              "Avg_PAT_10yrs":(inputsArray?.Avg_PAT_10yrs?.value1 || null)
              }
          ]
      },
      {
          "type":"Balance Sheet",
          "sub_type":[
              {
                  "Total_DebtEquity":(inputsArray?.Total_DebtEquity?.value1 || null),
                  "GrossBlockAdditionin_5yrs":(inputsArray?.GrossBlockAdditionin_5yrs?.value1 || null),
                  "TotalDebtIncreasein_5yrs":(inputsArray?.TotalDebtIncreasein_5yrs?.value1 || null),
                  "ROCE":[(inputsArray?.ROCE?.value1 || ""),(inputsArray?.ROCE?.value2 || "")], //(inputsArray?.ROCE?.value1 || null),
                  "NetCash":(inputsArray?.NetCash?.value1 || null),
                  "CFO_EBIDTA":(inputsArray?.CFO_EBIDTA?.value1 || null),
                  "Net_Cash_Mcap":(inputsArray?.Net_Cash_Mcap?.value1 || null)
              }
          ]
      },
    
        {
          "type":"ShareHolding Pattern",
          "sub_type":[
              {
                  "Promoter_Holding":(inputsArray?.Promoter_Holding?.value1 || null),
                  "Pledge":(inputsArray?.Pledge?.value1 || null)
              }
          ]
      }
  ];

  return filterArray;

};


export const deliveryDataFilters = (inputsArray) => {

  // const filterArray = [
  //             {
  //               "UserID":1,
  //               "Market_Cap":[(inputsArray?.Market_Cap?.value1 || ""),(inputsArray?.Market_Cap?.value2 || "")],
  //               "LtpVS200DMA":(inputsArray?.LtpVS200DMA?.value1 || null),
  //               "DelDays":(inputsArray?.DelDays?.value1 || null),
  //               "DelQty":(inputsArray?.DelQty?.value1 || null),
  //               "DelPer7D":(inputsArray?.DelPer7D?.value1 || null),
  //               "chkPortfolio":(inputsArray?.chkPortfolio?.value1 || false),
  //               "chkbox200DMA":(inputsArray?.chkbox200DMA?.value1 || false),
  //               "chkbox50DMA":(inputsArray?.chkbox50DMA?.value1 || false),
  //             }
  //         ];
  const filterArray = [
    {
      "type": "Classification",
      "sub_type": [
        {
          "Sector":(inputsArray?.sectors?.value1 || null),
          "Industry":(inputsArray?.industry?.value1 || null),
          "Company":(inputsArray?.company?.value1 || null),
          "UserID": 1,
          "F_O": (inputsArray?.chkF_O?.value1 || false),
          "chkPortfolio": (inputsArray?.chkPortfolio?.value1 || false)
        }
      ]
    },
    {
      "type": "Deliverable Volume",
      "sub_type": [
        {
          "LtpVS200DMA": (inputsArray?.LtpVS200DMA?.value1 || null),
          "Market_Cap": [(inputsArray?.Market_Cap?.value1 || ""),(inputsArray?.Market_Cap?.value2 || "")],
          "DelQty": (inputsArray?.DelQty?.value1 || null),
          "DelPer7D": (inputsArray?.DelPer7D?.value1 || null),
          "Delvalue": (inputsArray?.Delvalue?.value1 || null)
        }
      ]
    },
    {
      "type": "Time Interval",
      "sub_type": [
        {
          "DelDays": (inputsArray?.DelDays?.value1 || "")
        }
      ]
    },
    {
      "type": "Daily Moving Average",
      "sub_type": [
        {
          "chkbox200DMA": (inputsArray?.chkbox200DMA?.value1 || false),
          "chkbox50DMA": (inputsArray?.chkbox50DMA?.value1 || false),
        }
      ]
    }
  ];
          // console.log('deliveryDataFilters >>> '+JSON.stringify(filterArray))
  return filterArray;

};


export const valuationDataAction = (filterArray) => async (dispatch) => {
  // console.log('filterArray >> '+JSON.stringify(filterArray));
  try {
    dispatch({ type: VALUATION_REQUEST });
    const { data } = await valuationDataRequest(filterArray);
    // console.log(' data >>> '+JSON.stringify(data));
    dispatch({ type: VALUATION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: VALUATION_FAIL, payload: error.response.data.message });
  }
};


export const allCompanyData = (filterArray) => async (dispatch) => {
  // console.log('filterArray >> '+JSON.stringify(filterArray));
  try {
    dispatch({ type: ALL_COMPANY_REQUEST });
    const { data } = await allCompanyDataRequest(filterArray);
    dispatch({ type: ALL_COMPANY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ALL_COMPANY_FAIL, payload: error.response.data.message });
  }
};


export const allCompanyData2 = (filterArray) => async (dispatch) => {
  // console.log('filterArray >> '+filterArray);
  try {
    dispatch({ type: ALL_COMPANY_REQUEST_2 });
    const { data } = await allCompanyData2Request(filterArray);
    dispatch({ type: ALL_COMPANY_SUCCESS_2, payload: data });
  } catch (error) {
    dispatch({ type: ALL_COMPANY_FAIL_2, payload: error.response.data.message });
  }
};


export const singleCompanyNoteAction = (params) => async (dispatch) => {
  console.log(params);
  try {
    dispatch({ type: SINGLE_COMPANY_NOTE_REQUEST });
    const { data } = await singleCompanyNoteRequest(params);
    dispatch({ type: SINGLE_COMPANY_NOTE_SUCCESS, payload: data });
  } catch (error) {
    // error.response.data.message
    console.log(error);
    dispatch({ type: SINGLE_COMPANY_NOTE_FAIL, payload: error.response });
  }
};


export const singleCompanyAction = (params) => async (dispatch) => {
  console.log(params);
  try {
    dispatch({ type: SINGLE_COMPANY_REQUEST });
    const { data } = await singleCompanyRequest(params);
    dispatch({ type: SINGLE_COMPANY_SUCCESS, payload: data });
  } catch (error) {
    // error.response.data.message
    console.log(error);
    dispatch({ type: SINGLE_COMPANY_FAIL, payload: error.response });
  }
};


export const UploadDocumentAction = (params) => async (dispatch) => {
  console.log(params);
  try {
    dispatch({ type: UPLOAD_DOCUMENT_REQUEST });
    const { data } = await UploadDocumentRequest(params);
    dispatch({ type: UPLOAD_DOCUMENT_SUCCESS, payload: data });
  } catch (error) {
    // error.response.data.message
    console.log(error);
    dispatch({ type: UPLOAD_DOCUMENT_FAIL, payload: error.response });
  }
};


export const MultipleFileUploaderAction = (params) => async (dispatch) => {
  console.log(params);
  try {
    // [{"CompanyID":100251,"UserID":"22","SectorID":"39","IndustryID":["100"],"DocumentType":"","FileName":"","FileContent":""}]
    var nParm = params[0];
    await MultipleFileUploaderRequest(params);
    dispatch({ type: UPLOAD_DOCUMENT_REQUEST });
    // let newParams = [{"CompanyID":nParm?.CompanyID,"UserID":nParm?.UserID,"SectorID":"","IndustryID":["100"],"DocumentType":"","FileName":"","FileContent":""}]
    // // const { data } = await UploadDocumentRequest(newParams);
    // // dispatch({ type: UPLOAD_DOCUMENT_SUCCESS, payload: data });
  } catch (error) {
    // error.response.data.message
    console.log(error);
    dispatch({ type: UPLOAD_DOCUMENT_FAIL, payload: error.response });
  }
};


export const SingleCompanyCommentsAction = (params) => async (dispatch) => {
  console.log(params);
  try {
    dispatch({ type: COMPANY_COMMENTS_REQUEST });
    const { data } = await SingleCompanyCommentsRequest(params);
    dispatch({ type: COMPANY_COMMENTS_SUCCESS, payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: COMPANY_COMMENTS_FAIL, payload: error.response });
  }
};


export const UploadDocumentAnalystNotesAction = (params) => async (dispatch) => {
  // console.log(params);
  try {
    dispatch({ type: UPLOAD_DOCUMENT_ANALYST_REQUEST });
    const { data } = await UploadDocumentAnalystNotesRequest(params);
    dispatch({ type: UPLOAD_DOCUMENT_ANALYST_SUCCESS, payload: data });
  } catch (error) {
    // error.response.data.message
    console.log(error);
    dispatch({ type: UPLOAD_DOCUMENT_ANALYST_FAIL, payload: error.response });
  }
};


export const MultipleAnalystNotesAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: UPLOAD_DOCUMENT_ANALYST_REQUEST });
    const { data } = await MultipleAnalystNotesUploaderRequest(params);
    dispatch({ type: UPLOAD_DOCUMENT_ANALYST_SUCCESS, payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: UPLOAD_DOCUMENT_ANALYST_FAIL, payload: error.response });
  }
};


export const SendNotificationAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: SEND_NOTIFICATION_REQUEST });
    const { data } = await SendNotificationRequest(params);
    dispatch({ type: SEND_NOTIFICATION_SUCCESS, payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: SEND_NOTIFICATION_FAIL, payload: error.response });
  }
};


export const SingleCompanyAboutUsUpdateAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: ABOUT_US_UPDATE_REQUEST });
    const { data } = await SingleCompanyAboutUsUpdateRequest(params);
    dispatch({ type: ABOUT_US_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: ABOUT_US_UPDATE_FAIL, payload: error.response });
  }
};



export const SingleCompanyValuationAction = (params, type="") => async (dispatch) => {
  console.log('SingleCompanyValuationAction >>>>> ', params);
  try {
    dispatch({ type: SINGLE_COMPANY_VALUATION_REQUEST });
    let resData = {};
    console.log('type ---- params >>>>>>>>> ', type, params)
    if(type != ""){
      const apiData = await SingleCompanyValuationNewRequest(params);
            resData = apiData?.data
    }else{
      const apiData = await SingleCompanyValuationRequest(params);
            resData = apiData?.data
    }
    dispatch({ type: SINGLE_COMPANY_VALUATION_SUCCESS, payload: resData });
  } catch (error) {
    // error.response.data.message
    console.log(error);
    dispatch({ type: SINGLE_COMPANY_VALUATION_FAIL, payload: error.response });
  }
};



export const calenderAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: CALENDER_REQUEST });
    const { data } = await calenderRequest(params);
    dispatch({ type: CALENDER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CALENDER_FAIL, payload: error.response.data.message });
  }
};





export const companyMasterAction = (params) => async (dispatch) => {
  // alert('dassd')
  try {
    dispatch({ type: COMPANY_MASTER_REQUEST });
    const { data } = await companyMasterRequest(params);

    // console.log('companyMasterAction data Action >> '+data)

    dispatch({ type: COMPANY_MASTER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COMPANY_MASTER_FAIL, payload: error.response.data.message });
  }
};

export const sectorMasterAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: SECTOR_REQUEST });
    const { data } = await sectorMasterRequest(params);
    dispatch({ type: SECTOR_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SECTOR_FAIL, payload: error.response.data.message });
  }
};

export const industryMasterAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: INDUSTRY_REQUEST });
    const { data } = await industryMasterRequest(params);
    dispatch({ type: INDUSTRY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: INDUSTRY_FAIL, payload: error.response.data.message });
  }
};

export const turnAroundMasterAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: TURN_AROUND_REQUEST });
    const { data } = await turnAroundMasterRequest(params);
    dispatch({ type: TURN_AROUND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: TURN_AROUND_FAIL, payload: error.response.data.message });
  }
};



export const resultFilterInputAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: RESULT_DATA_REQUEST });
    dispatch({ type: RESULT_DATA_SUCCESS, payload: params });
    // console.log('Enter')
  } catch (error) {
    dispatch({ type: RESULT_DATA_FAIL, payload: error.response.data.message });
  }
};


export const valuationFilterInputAction = (params) => async (dispatch) => {
  try {
    // console.log('params >> >'+JSON.stringify(params));
    dispatch({ type: VALUATION_FILTER_REQUEST });
    dispatch({ type: VALUATION_FILTER_SUCCESS, payload: params });
  } catch (error) {
    dispatch({ type: VALUATION_FILTER_FAIL, payload: error.response.data.message });
  }
};


export const DeliveryDataFilterInputAction = (params) => async (dispatch) => {
  try {
    console.log('params >>>> '+JSON.stringify(params));
    dispatch({ type: DELIVERY_DATA_FILTER_REQUEST });
    dispatch({ type: DELIVERY_DATA_FILTER_SUCCESS, payload: params });
  } catch (error) {
    dispatch({ type: DELIVERY_DATA_FILTER_FAIL, payload: error.response.data.message });
  }
};




export const DeliveryDataAction = (filterArray) => async (dispatch) => {
  // console.log('filterArray >> '+JSON.stringify(filterArray));
  try {
    dispatch({ type: DELIVERY_DATA_REQUEST });
    const { data } = await DeliveryDataRequest(filterArray);
    // console.log('filterArray >> '+JSON.stringify(data));
    dispatch({ type: DELIVERY_DATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: DELIVERY_DATA_FAIL, payload: error.response.data.message });
  }
};



export const FIIsModelAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: FIIS_MODEL_REQUEST });
    const { data } = await FIIsModelRequest(params);
    dispatch({ type: FIIS_MODEL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FIIS_MODEL_FAIL, payload: error.response.data.message });
  }
};


export const FIISDateMasterAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: DATE_MASTER_REQUEST });
    const { data } = await FIISDateMasterRequest(params);
    dispatch({ type: DATE_MASTER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: DATE_MASTER_FAIL, payload: error.response.data.message });
  }
};


export const RatioTooltipAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: RATIO_TOOLTIP_REQUEST });
    const { data } = await RatioTooltipRequest(params);
    dispatch({ type: RATIO_TOOLTIP_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: RATIO_TOOLTIP_FAIL, payload: error.response.data.message });
  }
};



export const FIIsSectorlAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: FIIS_SECTORS_REQUEST });
    const { data } = await FIIsSectorsRequest(params);
    dispatch({ type: FIIS_SECTORS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FIIS_SECTORS_FAIL, payload: error.response.data.message });
  }
};


export const FIIsTopBottomAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: FIIS_TOP_BOTTOM_REQUEST });
    const { data } = await FIIsTopBottomRequest(params);
    dispatch({ type: FIIS_TOP_BOTTOM_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FIIS_TOP_BOTTOM_FAIL, payload: error.response.data.message });
  }
};




export const ForensicModelCommentsAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: FIIS_MODEL_COMMENT_REQUEST });
    const { data } = await ForensicModelCommentsRequest(params);
    dispatch({ type: FIIS_MODEL_COMMENT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FIIS_MODEL_COMMENT_FAIL, payload: error.response.data.message });
  }
};





export const ForensicTabShowHideAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: FORENSIC_TAB_SHOW_HIDE_REQUEST });
    const { data } = await ForensicTabShowHideRequest(params);
    dispatch({ type: FORENSIC_TAB_SHOW_HIDE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FORENSIC_TAB_SHOW_HIDE_FAIL, payload: error.response.data.message });
  }
};



















export const openInNewTab = (url, type) => {
  const newWindow = window.open(url, type, 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}


export const openSingleCompany = async (rowData, tableInstance1, CompanyName, type='_blank') => {
  localStorage.setItem('companyId', rowData.CompanyID);
  localStorage.setItem('companyName', CompanyName);
  localStorage.setItem('openedCompany', rowData.CompanyID);
  // tableInstance1.current.click();
  const urlD = `./single-company/${window.btoa(rowData.CompanyID)}`
  return openInNewTab(urlD, type);
}






