import React, {useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { AppBar, Tabs, Tab, Typography, Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { singleCompanyAction } from '../redux/actions/userAction';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const SingleCompanyAbout = (props) => {


    const dispatch = useDispatch();
    const { data:AboutDataR, loading:loadingData } = useSelector(state => state.AboutUsUpdateReducer);
    const { data: companyData, loading: companyLoading } = useSelector(state => state.singleCompanyReducer);
    const {
        itemsData, 
        toggleDrawer, 
        setParentGroup, 
        setOpenForm
    } = props;
    
    const [value, setValue] = React.useState(0);
    const [AboutData, setAboutData] = React.useState(null);
    const tabCat = [
        {
            title:'About Company',
            data:AboutData?.aboutUs[0]
        },
        {
            title:'Big Picture',
            data:AboutData?.bigPicture[0]
        },
        {
            title:'Promoters',
            data:AboutData?.promoters[0]
        },
        {
            title:'Product',
            data:AboutData?.product[0]
        },
        {
            title:'Price',
            data:AboutData?.price[0]
        },
        {
            title:'Triggers',
            data:AboutData?.triggers[0]
        },
        {
            title:'Risk',
            data:AboutData?.risk[0]
        },
        {
            title:'Peers',
            data:AboutData?.peers[0]
        },
    ];


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (!companyLoading) {
            let abtData = {
              aboutUs: companyData.aboutUs,
              bigPicture: companyData.bigPicture,
              peers: companyData.Peers,
              promoters: companyData.Promoters,
              price: companyData.price,
              product: companyData.product,
              risk: companyData.risk,
              triggers: companyData.triggers,
            };
            setAboutData(abtData);
          }

    }, [dispatch, companyLoading]);


    useEffect(() => {
        console.log(AboutData, 'loadingData >> ', loadingData)
        if(AboutData !== null){
            var prams = [{ "CompanyID": companyData.CompanyID, "userid": "admin", "Note": null }];
            dispatch(singleCompanyAction(prams));
            console.log('companyData >> ', companyData);
        }
    }, [dispatch, loadingData, companyLoading]);

    
    // useEffect(() => {
      
    // }, [dispatch, loadingData])
    

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {
                        tabCat.length > 0 && tabCat.map((item, i) => {
                            return (
                                <Tab sx={{
                                    fontSize: '0.7rem',
                                }} key={i} label={item.title} {...a11yProps(i)} />
                            )
                        })
                    }
                </Tabs>
            </Box>
            {
                tabCat.length > 0 && tabCat.map((item, i) => {
                    return (
                        <TabPanel key={i} value={value} index={i}>
                            <Button onClick={(e)=>{toggleDrawer('bottom', true)(e);  setOpenForm('UpdateAbout'); setParentGroup(item?.data) }} size="small" sx={{ fontSize:10 }} variant="outlined" >Edit</Button>
                            <Typography variant="subtitle2" dangerouslySetInnerHTML={{__html: item?.data?.Description }} className='docItem' sx={{ textTransform:'capitalize', fontSize: 10, color:'#7a7a7a' }} />
                        </TabPanel>
                    )
                })
            }
        </Box>
    ); 

}

export default SingleCompanyAbout