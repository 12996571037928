import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { CompaniesComponent, InputComponent } from '../../Common'
import { useSelector, useDispatch } from 'react-redux'
import MuiAutoComplete from '../../Common/MuiAutoComplete';
import { ideaType, shortAsc, recommendationArr } from '../../../constants/helpers';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { companyMasterAction } from '../../../redux/actions/userAction';
import { vdrCompanyAction } from '../../../redux/actions/VdrAction';


const Add = (props) => {

    const {
        data: DefaultMasterData, loading:DefaultMasterLoading
    } = useSelector(state=>state.DefaultMasterReducer);
    
    useEffect(() => {
    }, [DefaultMasterLoading])
    let RecommendationArrData = DefaultMasterData?.Recommendation && DefaultMasterData?.Recommendation;
    let IdeaTypeArrData = DefaultMasterData?.IdeaType && DefaultMasterData?.IdeaType;






    const [companyMasterArr, setCompanyMasterArr] = useState([]);
    const [companyTypeArr, setCompanyTypeArr] = useState(IdeaTypeArrData);

    // const recommendationArr = [];
    const [selectedCompanyData, setSelectedCompanyData] = useState(null);

    const [selectedCompanyTypeData, setSelectedCompanyTypeData] = useState(null);

    const [selectedRecommendationData, setSelectedRecommendationData] = useState(null);


    const [UpdateState, setUpdateState] = useState(false);

    const [tags, setTags] = React.useState([])
    const {
        singleCompanyData,
        parentGroup,
        levelType,
        inputType
    } = props;

    // console.log(singleCompanyData)

    const dispatch = useDispatch();
    const { data: companyMaster, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
    const { data: companyData, loading: companyLoading, loadingAdd } = useSelector((state) => state.vdrCompanyReducer);
   

    const { control, handleSubmit, register, reset } = useForm({
        defaultValues: {
            groups_id: (singleCompanyData?.groups_id || parentGroup.group_id || 0),
            groups_level: (singleCompanyData?.groups_level || parentGroup.level_type || 0),
            status: 1,
            recommendation_date: singleCompanyData?.recommendation_date,
            recommendation_price: singleCompanyData?.recommendation_price,
            Inputtype: inputType
        }
    });


    const onSubmit = data => {
        const paramsData = {
            company_id: selectedCompanyData?.value,
            company_name: selectedCompanyData?.title,
            groups_id: data?.groups_id,
            groups_level: data?.groups_level,
            recommendation_date: data?.recommendation_date,
            recommendation_price: data?.recommendation_price,
            company_type: selectedCompanyTypeData?.value,
            recommendation: selectedRecommendationData?.value,
            status: data?.status,
            tags: tags,
            Inputtype: inputType,
        }

        // console.log(paramsData);
        // return false;
        
        dispatch(vdrCompanyAction(paramsData))
        reset(singleCompanyData)

        // if (loadingAdd === false) {
        //     window.location.reload();
        // }

    };

    const selectCompany = () => {
        if (companyMaster.length > 0) {
            var data1 = [];
            companyMaster.map((item) => {
                var d1 = { title: item.CompanyName, value: item.CompanyID };
                data1.push(d1);
            })
            shortAsc(data1)
            setCompanyMasterArr(data1);
        }
    }

    const getUniqueListBy = (arr) => {
        return [...new Map(arr.map(item => [item, item])).values()]
    }

    const setTagsFunction = (tags1) => {
        setTags(getUniqueListBy(tags1))
        // console.log(tags1)
    }



    useEffect(() => {
        if (!companyMasterLoading) {
            selectCompany();
        }
        if (singleCompanyData) {
            setSelectedCompanyData({
                title: singleCompanyData?.company_name,
                value: singleCompanyData?.company_id
            });

            setSelectedCompanyTypeData({
                title: (singleCompanyData?.company_type ? IdeaTypeArrData[singleCompanyData?.company_type].title : IdeaTypeArrData[0].title),
                value: singleCompanyData?.company_type
            });

            setSelectedRecommendationData({
                title: (singleCompanyData?.recommendation ? RecommendationArrData[singleCompanyData?.recommendation].title : RecommendationArrData[0].title),
                value: singleCompanyData?.recommendation
            });

            setTags(singleCompanyData?.tags);
        }
    }, [dispatch, props, companyMasterLoading])

    return (
        <>
            <Box sx={{
                width: '30%',
                margin: "1rem auto"
            }}>
                <Typography variant="h5" fontSize={20} sx={{ margin: '1rem 0' }}>
                    {inputType && inputType ? 'Update' : 'Add'} Company
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <Grid container spacing={1}>
                        <input type="hidden" defaultValue={singleCompanyData?.group_id || parentGroup?.group_id || 0} {...register("groups_id")} />
                        <input type="hidden" defaultValue={singleCompanyData?.level_type || parentGroup?.level_type || 0} {...register("groups_level")} />
                        <input type="hidden" defaultValue={inputType || 0} {...register("Inputtype")} />

                        <Grid item xs={12} >
                            <MuiAutoComplete
                                {...register("company_data")}
                                placeholder="Select Company"
                                className='cInput'
                                readOnly={inputType ? true : false}
                                options={companyMasterArr && companyMasterArr}
                                multiple={false}
                                value={selectedCompanyData}
                                label="Company"
                                onChange={(event, newInputValue) => {
                                    var val1 = [];
                                    setSelectedCompanyData(newInputValue);
                                    // console.log(newInputValue)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <MuiAutoComplete
                                {...register("company_type")}
                                placeholder="Select Idea Type"
                                className='cInput'
                                options={companyTypeArr && companyTypeArr}
                                multiple={false}
                                label="Idea Type"
                                value={selectedCompanyTypeData}
                                onChange={(event, newInputValue) => {
                                    var val1 = [];
                                    setSelectedCompanyTypeData(newInputValue);
                                    // console.log(newInputValue)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <MuiAutoComplete
                                {...register("recommendation")}
                                placeholder="Select Recommendation"
                                className='cInput'
                                options={RecommendationArrData.length && RecommendationArrData}
                                multiple={false}
                                label="Recommendation"
                                value={selectedRecommendationData}
                                onChange={(event, newInputValue) => {
                                    var val1 = [];
                                    setSelectedRecommendationData(newInputValue);
                                    // console.log(newInputValue)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <InputComponent
                                name="recommendation_date"
                                label="Recommendation Date"
                                defaultValue={singleCompanyData?.recommendation_date || null}
                                control={control}
                                size="small"
                                placeholder="Enter Recommendation Date"
                                className='cInput'
                                InputProps={{
                                    type:'date',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <InputComponent
                                name="recommendation_price"
                                label="Recommendation Price"
                                defaultValue={singleCompanyData?.recommendation_price || null}
                                control={control}
                                size="small"
                                placeholder="Enter Recommendation Price"
                                className='cInput'
                            />
                        </Grid>




                        {/* <Grid item xs={12} >
                        <ReactTagInput
                            tags={tags} 
                            placeholder="Enter Tags"
                            maxTags={10}
                            editable={true}
                            readOnly={false}
                            removeOnBackspace={true}
                            onChange={(newTags) => setTagsFunction(newTags)}
                        />
                        </Grid> */}

                        <Grid item xs={12}>
                            {
                                inputType && inputType ?
                                    <>
                                        <Button type="submit" variant="contained">Update</Button>
                                    </> :
                                    <>
                                        <Button type="submit" variant="contained">Submit</Button>
                                    </>
                            }
                        </Grid>
                    </Grid>

                </form>

            </Box>
        </>
    )
}

export default Add