import Layout from './Layout';
import HomePage from './pages/HomePage';
import TickerPage from './pages/TickerPage';
import CompanyDetail from './pages/CompanyDetail';
import CompanyListing from './pages/CompanyListing';
import FavoriteListing from './pages/FavoriteListing';
import SmallCase from './pages/SmallCase';
import CompanyOldDetail from './pages/CompanyOldDetail';
import VideosPage from './pages/VideosPage';
import TOTPage from './pages/tot/index';
import Resources from './pages/resources/index';
import Blogs from './pages/blogs/index';
import VideoPageNew from './pages/videos/index';
import MyListPage from './pages/mylist/index';
import NewsletterPageNew from './pages/newsletter/index';
import BlogPageNew from './pages/newblogs/index';
import ResourcesComponent from './pages/newresources/Cards';

export default{
  HomePage,
  BlogPageNew,
  Blogs,
  CompanyOldDetail,
  ResourcesComponent,
  Resources,
  SmallCase,
  TOTPage,
  TickerPage,
  VideosPage,
  VideoPageNew,
  MyListPage,
  NewsletterPageNew,
  CompanyDetail,
  Layout,
  CompanyListing,
  FavoriteListing,
}