import logo192 from './../assets/images/logo192.png';
import boringamc from './../assets/images/boringAmc.png'

export default {
    logo192, boringamc
};





