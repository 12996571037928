import React, { useEffect, useState } from 'react'

import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { Box, Button, Checkbox } from '@mui/material';
import { NewsletterCommentLikeAction, VideoCommentAction, VideoCommentLikeAction } from '../../../../redux/actions/VdrAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { commentParams, crtUserId } from '../../../../constants/helpers';
import AddCommentComponent from './AddCommentComponent';



const CommentCard = (props) => {
// console.log('props > ', props)
    const {item, replies, ActiveComment, setActiveComment} = props
    const { data: UserLoginData, loading: UserLoginLoading, isAuth, isRedirect } = useSelector(state => state.UserLoginReducer);
    let currentUserId = (UserLoginData?.user_id || crtUserId());
    const fiveMinutes = 300000;
    const timePassed = new Date() - new Date(item?.created_at) > fiveMinutes;
    const canReply = Boolean(currentUserId); 
    const canEdit = currentUserId === item.webuserId; 
    const canDelete = currentUserId === item.webuserId; //&& (replies.length === 0);
    // const canDelete = currentUserId === item.webuserId;
    // const canDelete = currentUserId === item.webuserId && !timePassed;
    const created_at = moment(item?.created_at).format('DD MMM, YYYY');
    const isReplying = ActiveComment && ActiveComment.type === "replying" && ActiveComment.id === item.commentId;
    const isEditing = ActiveComment && ActiveComment.type === "editing" && ActiveComment.id === item.commentId;

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [checked, setChecked] = React.useState(item?.is_user_like);
    const dispatch = useDispatch();
    const {
         data:CommentLikeData,
         loading:CommentLikeLoading,
    } = useSelector(state=>state.VideoCommentLikeReducer)

//   const { data: VideoCommentData, loading: VideoCommentLoading } = useSelector((state) => state.VideoCommentReducer);
  const { data: NewsletterCommentData, loading: NewsletterCommentLoading } = useSelector((state) => state.NewsletterCommentReducer);

//   const handleChange = (event) => {
//     let crt_check = event.target.checked;
//     let like_type = 'like';
//     if(!crt_check){
//       like_type = 'unlike';
//     }
//     setChecked(crt_check);
//     let params = {
//                     type: like_type, //  Blank , like or  unlike
//                     comment_id: item.commentId,
//                     video_id: item.videoId,
//                     webuserId: crtUserId(),
//                     inputType: "0" //0:edit 1:get
//                  }
//     let vid_params = { ...params }
//     dispatch(VideoCommentLikeAction(vid_params));

//     let params0 = {
//         ...commentParams,
//         videoId: item.videoId,
//         comment: '',
//         inputType: "3"
//       }
//     dispatch(VideoCommentAction(params0));

//   };


const handleChange = (event) => {
        
    let crt_check = event.target.checked;
    let like_type = 'like';
    if(!crt_check){
        like_type = 'unlike';
    }
    setChecked(crt_check);
    let params = {
                    type:like_type,
                    user_id: currentUserId,
                    comment_id: item?.commentId,
                 }
    let vid_params = { ...params }
    dispatch(NewsletterCommentLikeAction(vid_params));
    
};


  const deleteComment = (commentId) => {
    // console.log(commentId)
    let params = {
        ...commentParams,
        commentId: commentId,
        videoId: item?.videoId,
        inputType: "2"
      }
      
      console.log('params >>>> ', params)
    //   dispatch(VideoCommentAction(params));

  }


  return (
    <>
        <Box className="comment-box">
            <div className="comment-author-cont">
                <div className="author-detail">
                    <img src={item?.webUserImage} alt={item?.webUserName} />
                    <h3>
                        {item?.webUserName}
                        
                    </h3>
                </div>
                <div className='date_a'>{created_at}</div>
            </div>
            <div className="comment-description">
                <div dangerouslySetInnerHTML={{ 
                    __html: item?.comment
                }}/>
            </div>
            <div>
            <Box style={{ 
                    display:'flex',
                    fontSize:'1rem',
                    alignItems:'center',
                    marginTop:10
                }}
                >
                <Checkbox
                    {...label}
                    onChange={handleChange} 
                    icon={<FavoriteBorder sx={{fontSize: '16px' }} />}
                    checkedIcon={<Favorite sx={{ fontSize: '16px', color: 'red' }} />}
                    checked={checked}
                    sx={{ 
                    padding:'0 .2rem 0 0'
                    }}
                />
                    <div style={{fontSize: '12px' }}>
                        {item?.total_comment_like} Like
                    </div>
                </Box>
            </div>
            <div className='commentAction'>
                { canReply && <Button variant='outlined' size="small" 
                    onClick={()=>{
                        setActiveComment({item:item, id:item.commentId, type:'replying'})
                    }}
                >Reply</Button> }
                
                {/* { canEdit && <Button variant='outlined' size="small" onClick={()=>{
                    setActiveComment({item:item, id:item.commentId, type:'editing'})
                }
                }
                >Edit</Button> }
                
                
                { canDelete && <Button variant='outlined' color="error" size="small" onCLick={()=>deleteComment(item?.commentId)}>Delete</Button> } */}
            </div>
        </Box>
        
        <>
            {
                isReplying && (
                    <AddCommentComponent
                        videoId={item?.videoId}
                        submitLabel='Reply'
                        replyId={item?.commentId}
                        ActiveComment={ActiveComment}
                        setActiveComment={setActiveComment}
                    />
                )
            }
            {
                isEditing && (
                    <Box className='nT'>
                        <AddCommentComponent
                            videoId={item?.videoId}
                            submitLabel='Update'
                            replyId={item?.commentId}
                            ActiveComment={ActiveComment}
                            setActiveComment={setActiveComment}
                        />
                    </Box>
                )
            }
        </>
        {replies && replies.length > 0 && (
            <div className="replies">
                {replies.map((reply) => {
                    let child = NewsletterCommentData?.data.filter(item=> item.parent_id === reply.commentId);
                    
                    return (
                        <>
                            <CommentCard
                                key={reply.commentId}
                                item={reply}
                                replies={child}
                                ActiveComment={ActiveComment}
                                setActiveComment={setActiveComment}
                            />
                        </>
                        )
                    }
                    )
                }       
            </div>
        )}
    </>
  )
}

export default CommentCard