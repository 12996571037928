import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { segmentAssignAction, vdrGroupAction, vdrUpdateGroupAction } from '../../../redux/actions/VdrAction';
import { InputComponent } from '../../Common'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MuiAutoComplete from '../../Common/MuiAutoComplete';
import { shortAsc } from '../../../constants/helpers';


const SegmentAssign = (props) => { 

    const [checked, setChecked] = React.useState(false);
    const [checked1, setChecked1] = React.useState(false);

    
    const [companyMasterArr, setCompanyMasterArr] = useState([]);
    const [selectedCompanyData, setSelectedCompanyData] = useState(null);
    

    const dispatch = useDispatch();
    const { control, handleSubmit, register, reset } = useForm({
        defaultValues: {
            status: 1,
        }
    });
    const { data: SegmentData, loading: SegmentLoading } = useSelector((state) => state.SegmentReducer);
    
    const selectCompany = () => {
        
        if (SegmentData?.Data.length > 0) {
            var data1 = [];
            SegmentData?.Data.map((item) => {
                var d1 = { title: item.segment_label, value: item.segment_id };
                data1.push(d1);
            })
            shortAsc(data1)
            
            setCompanyMasterArr(data1);
        }
    }
 


    const {
        groupData,
        levelType,
        inputType,
        parentGroup
    } = props;

    useEffect(() => {
        // console.log(groupData)

        if(!SegmentLoading){
            selectCompany();
        }

        setChecked(groupData?.enable_number ? true : false);
        setChecked1(groupData?.sticky  ? true : false);
        reset(groupData)
    }, [dispatch, props])

    const onSubmit = (data) => {

        let data1 = {
            "segment_id": selectedCompanyData.value,
            "group_id": parentGroup.group_id,
            "assign_type": true,
        };
        
        dispatch(segmentAssignAction(data1));
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    };

    return (
        <>
            <Box sx={{
                width: '30%',
                margin: "1rem auto"
            }}>

                <Typography variant="h5" fontSize={20} sx={{ margin: '1rem 0' }}>
                    Assign Segment
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                    
                        <Grid item xs={12} >
                            <MuiAutoComplete
                                {...register("company_data")}
                                placeholder="Select"
                                className='cInput'
                                readOnly={inputType ? true : false}
                                options={companyMasterArr && companyMasterArr}
                                multiple={false}
                                value={selectedCompanyData}
                                label="Select Segment"
                                onChange={(event, newInputValue) => {
                                    var val1 = [];
                                    setSelectedCompanyData(newInputValue);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {
                                inputType ?
                                    <>
                                        <Button type="submit" variant="contained">Update</Button>
                                    </> :
                                    <>
                                        <Button type="submit" variant="contained">Submit</Button>
                                    </>

                            }
                        </Grid>
                    </Grid>

                </form>

            </Box>
        </>
    )
}

export default SegmentAssign