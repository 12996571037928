import React, { useEffect, useRef, useState } from 'react'
import { AppBar, Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { AiOutlineFilePdf } from "react-icons/ai";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { dateFormat } from '../../constants/helpers';

import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import $ from 'jquery'
import DeleteIcon from '@mui/icons-material/Delete';



const AnalystRoomCommentContainer = (props) => {

    const { itemData, title, companyData, deleteFunction } = props;

    const shotButton = useRef();

    const [arrowIcon, setArrowIcon] = useState(<RiArrowDownSLine fontSize={25} color="#f37b22" />);
    const [ToggleData, setToggleData] = useState(false);
    const [DivHeight, setDivHeight] = useState(300);
    const [ListData, setListData] = useState(itemData);
    const [name, setName] = useState("");

    const [ActiveBtn, setActiveBtn] = useState(0);

    useEffect(() => {
        setListData(itemData);
    }, [itemData])

    useEffect(() => {

    }, [name])

    useEffect(() => {

    }, [ActiveBtn])

    const [KeyUp, setKeyUp] = useState(false);

    const btnCategory = [
        "Show All",
        "Quarter Update ",
        "Management Meeting",
        "OnePager",
        "Important Source",
        "Rough"
    ];
    const crtRef = React.useRef();
    const crtRef2 = React.useRef();

    const afterDiv = (e, i) => {
        // var dd = document.querySelector('#desc_div'+i);
        // dd.()
        $('#desc_div'+i).slideToggle();
        // console.log(dd, i);
    }

    const sortData = (itemData, type) => {
        let sData;

        

        if (SortType === 'name') {
            if (ActiveBtn) {
                if (ToggleData) {
                    sData = ListData.sort((a, b) => a._CommentDetails.Heading.localeCompare(b._CommentDetails.Heading));
                } else {
                    sData = ListData.sort((a, b) => b._CommentDetails.Heading.localeCompare(a._CommentDetails.Heading));
                }
            } else {
                if (ToggleData) {
                    sData = itemData.sort((a, b) => a._CommentDetails.Heading.localeCompare(b._CommentDetails.Heading));
                } else {
                    sData = itemData.sort((a, b) => b._CommentDetails.Heading.localeCompare(a._CommentDetails.Heading));
                }
            }
        } else {
            if (ActiveBtn) {
                if (ToggleData) {
                    sData = ListData.sort((a, b) => {
                        var a1 = moment(a._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
                        var b1 = moment(b._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
                        var dd = new Date(a1) - new Date(b1);
                        return dd;
                    });
                } else {
                    sData = ListData.sort((a, b) => {
                        var a1 = moment(a._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
                        var b1 = moment(b._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
                        var dd = new Date(b1) - new Date(a1);
                        return dd;
                    });
                }

            } else {
                if (ToggleData) {
                    sData = itemData.sort((a, b) => {
                        var a1 = moment(a._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
                        var b1 = moment(b._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
                        var dd = new Date(a1) - new Date(b1);
                        return dd;
                    });
                } else {
                    sData = itemData.sort((a, b) => {
                        var a1 = moment(a._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
                        var b1 = moment(b._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
                        var dd = new Date(b1) - new Date(a1);
                        return dd;
                    });
                }
            }
        }

        setToggleData(!ToggleData);
    }

    const onUpdateHeight = () => {
        if (DivHeight === 300) {
            setArrowIcon(<RiArrowUpSLine fontSize={25} color="#f37b22" />)
            setDivHeight('auto')
        } else {
            setArrowIcon(<RiArrowDownSLine fontSize={25} color="#f37b22" />)
            setDivHeight(300)
        }

    }

    const handleChange = (event, itemData) => {
        // console.log(itemData);
        let arrNew = [];
        itemData.forEach(function (a) {
            // console.log(btnCategory[ActiveBtn]);
            var fName = a._CommentDetails.Heading.toLowerCase();
            var nVal = event.target.value.toLowerCase();
            if (fName.indexOf(nVal) > -1) {
                if (btnCategory[ActiveBtn] === a.DocumentType) {
                    arrNew.push(a)
                }
                if (ActiveBtn === 0) {
                    arrNew.push(a)
                }
            }
        });
        setListData(arrNew);
        setName(event.target.value);
    };


    const filterByCat = (event, activeStatus, filterFrom) => {
        // console.warn(activeStatus)
        setActiveBtn(activeStatus);
        let lstData = itemData.filter((item) => item.DocumentType == filterFrom);
        if (activeStatus === 0) {
            setListData(itemData);
        } else {
            setListData(lstData);
        }
    };


    const listContainer = useRef();
    const searchContainer = useRef();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [SortType, setSortType] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <Box className="box-container" sx={{
                paddingBottom: 0,
            }}>
                <Box className="header-section">
                    <Box
                        display={'flex'}
                        justifyContent="space-between"
                        alignItems={'center'}
                        sx={{
                            paddingBottom: '0.5rem'
                        }}
                    >
                        <Typography fontSize={16} variant="h6" style={{}}>{title || 'Final Output'}</Typography>
                        <>
                            <IconButton ref={shotButton} size="small"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}

                            // onClick={() => sortData(itemData, (title || 'Final Output'))}
                            >
                                {
                                    ToggleData
                                        ?
                                        <BiSortDown />
                                        :
                                        <BiSortUp />
                                }
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => [sortData(itemData, (title || 'Final Output')), handleClose(), setSortType('date')]}>Sort by Date</MenuItem>
                                <MenuItem onClick={() => [sortData(itemData, (title || 'Final Output')), handleClose(), setSortType('name')]}>Sort by Name</MenuItem>
                            </Menu>
                        </>
                    </Box>
                    <Divider />
                </Box>

                <Box className="list-container"
                    sx={{
                        minHeight: 300,
                        height: DivHeight,
                    }}
                >
                    <Box ref={searchContainer}>
                        {
                            title ?
                                <>
                                    <Box
                                        sx={{
                                            marginTop: 1,
                                        }}
                                    >
                                        {
                                            btnCategory.map((item, index) => {
                                                return (
                                                    <>
                                                        <Button key={index} size="small"
                                                            className={`${ActiveBtn === index ? 'btn_active' : null}`}
                                                            variant='outlined'
                                                            onClick={(e) => filterByCat(e, index, item)}
                                                            sx={{
                                                                marginLeft: 0.5,
                                                                marginTop: 0.5,
                                                                fontSize: '0.6rem',
                                                                textTransform: "capitalize"
                                                            }}>
                                                            {item}
                                                        </Button>
                                                    </>
                                                )
                                            })
                                        }

                                    </Box>
                                </>
                                :
                                null
                        }

                        <Box
                            sx={{
                                marginTop: 2,
                            }}
                        >
                            <TextField
                                onChange={(e) => handleChange(e, (itemData && itemData))}
                                sx={{
                                    width: '99%',
                                    padding: 0
                                }}
                                size="small"
                                label="Search Document"
                                id="fullWidth"
                                value={name}
                                InputProps={{
                                    endAdornment: <SearchIcon sx={{ color: 'gray' }} />,
                                }}
                                InputLabelProps={{ style: { fontSize: 12, padding: 0, margin: 0 } }}
                                inputProps={{
                                    style: { padding: '0.4rem', fontSize: 15 }
                                }}
                            />
                        </Box>
                    </Box>

                    <List ref={listContainer}>
                        {
                            ListData && ListData.length ?
                                ListData.map((items, index) => {
                                    let item = items._CommentDetails;
                                    let fileLink = '';
                                    let fileExtension = '';
                                    fileLink = item.link;
                                    if (fileExtension.toLowerCase() === 'pdf') {
                                        fileLink = `/MyPdfViewer`;
                                    }
                                    item = { ...item };
                                    item.title = companyData?.company_name;
                                    {/* console.table(itemData); */ }


                                    return (
                                        <>
                                            <ListItem disablePadding key={index} className='docContainer'
                                            
                                            secondaryAction={
                                                    <IconButton edge="end" aria-label="delete" onClick={(e)=>deleteFunction(item)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                }
                                                >
                                                
                                                <ListItemText primary={
                                                        <>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography variant="subtitle2" className='docItem' sx={{ textTransform:'capitalize', fontSize: { xs: 9, sm: 9, md: 9, lg: 9, xl: 13 } }}>{item?.Heading ? (item?.Heading).toLowerCase() : null}</Typography>
                                                                <Button ref={crtRef} dName={index} size='small' sx={{ textTransform:'capitalize', fontSize: '0.65rem' }} onClick={(e)=>afterDiv(e, index)}>Read More</Button>
                                                            </div>
                                                            <div id={'desc_div'+index} style={{ display:'none' }} dvName={index}>
                                                                <Typography variant="subtitle2" dangerouslySetInnerHTML={{__html: item?.Discription }} className='docItem' sx={{ textTransform:'capitalize', fontSize: 10, color:'#7a7a7a' }} />
                                                            </div>
                                                        </>
                                                    } secondary={
                                                        <>
                                                            <Typography fontWeight="bold" fontSize={14} style={{}}>{item?.UserName}</Typography>
                                                            <Box display={'flex'}>
                                                                <Typography fontSize={9} style={{}}>{dateFormat(item?.DateTime, 'DD/MMM/YYYY')}</Typography>
                                                                {
                                                                    title ?
                                                                        <>
                                                                            {
                                                                                !ActiveBtn ?
                                                                                    <>
                                                                                        <Typography fontSize={9} fontWeight="bold" sx={{ marginLeft: 0.5 }}>{item?.CommentType}</Typography>
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                            </Box>
                                                        </>
                                                    } />
                                            </ListItem>
                                            {
                                                itemData.length - 1 !== index ?
                                                    <Divider />
                                                    :
                                                    null
                                            }
                                        </>
                                    )
                                })
                                :
                                <>
                                    <Typography variant='subtitle1' fontSize={12} fontWeight="500">
                                        {!name ? 'To Be Updated' : 'Data not Found!'}
                                    </Typography>
                                </>
                        }
                    </List>
                </Box>

                <Box className="footer-section"
                    sx={{

                    }}
                >
                    <Divider />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <IconButton size="small" onClick={() => onUpdateHeight()}>
                            {arrowIcon}
                        </IconButton>
                    </div>
                </Box>

            </Box>
        </>
    )
}

export default AnalystRoomCommentContainer