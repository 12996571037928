import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Store from './redux/Store'
import { GoogleOAuthProvider } from '@react-oauth/google';


// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import '../node_modules/react-modal-video/scss/modal-video.scss'
import { getBaseLink } from './constants/helpers';
import GlobalContextProvider from './context/GlobalContext';

let clientId = ""; 
ReactDOM.render(
    <BrowserRouter basename={getBaseLink()} >
        <GlobalContextProvider>
            <Provider store={Store}>
                <App />
            </Provider>
        </GlobalContextProvider>
    </BrowserRouter>,
    document.getElementById('root')
);

// serviceWorkerRegistration.unregister();
