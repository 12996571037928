import React, { useEffect, useRef, useState } from 'react'
// import { SingleCompanyGrid } from '../components'
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tab, Tabs, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { vdrHomeAction, vdrCompanyAction, vdrFavoriteAction, vdrMediaAction, segmentAction, dataRoomTickerAction } from '../../redux/actions/VdrAction';
import { CustomCarousel, HomeSearch } from '../components';
import { loggedInUser } from '../../constants/helpers';
import { LoadingComponent } from '../../components';
import { Link as SLink } from 'react-scroll'
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion'
import MediaModel from '../../components/SingleCompanyContainer/MediaSection_old/MediaModel';
import { segmentAddJson } from '../../constants/defaultArray';
import moment from 'moment';





const HomePage = () => {

  const dispatch = useDispatch()
  const { data: homeData, loading: homeLoading } = useSelector((state) => state.vdrHomeReducer)
  const { data: companyData, loading: companyLoading, totalRows } = useSelector((state) => state.vdrCompanyReducer)
  const [listingData, setListingData] = useState(null);
  const [listingGroup, setListingGroup] = useState(null);
  const [FavLoading, setFavLoading] = useState(false);


  const navigation = useNavigate();

  const companyDataView = () => {

  }

  const { data: FavoriteCompanyData, loading: FavoriteCompanyLoading, error: FavoriteCompanyError } = useSelector(state => state.FavoriteCompanyReducer);
  const { data: vdrFavoriteData, loading: vdrFavoriteLoading, error: vdrFavoriteError } = useSelector(state => state.vdrFavoriteReducer);


  const { data: vdrMediaData, loading: vdrMediaLoading } = useSelector((state) => state.vdrMediaReducer);
  const { data: SegmentData, loading: SegmentLoading } = useSelector((state) => state.SegmentReducer);

  const {
    loading: loadingDataRoomTicker,
    data:DataRoomTicker
  } = useSelector(state => state.dataRoomTickerReducer);

  


  const [companyMasterArr, setCompanyMasterArr,] = React.useState([]);
  const [VideosArr, setVideosArr] = React.useState([]);

  const [company, setCompany] = React.useState([]);
  const [companyKey, setCompanyKey] = React.useState(false);
  const [cLoading, setCLoading] = React.useState(false);

  const selectCompany = () => {
    if (totalRows > 0) {
      var data1 = [];
      companyData.Data.map((item) => {
        var d1 = { title: item.company_name, name: item.company_name, value: item.company_id };
        data1.push(d1);
      })

      data1.sort(function (a, b) {
        if (a.title < b.title) { return -1; }
        if (a.title > b.title) { return 1; }
        return 0;
      })
      const unique = [...new Map(data1.map(item => [item['value'], item])).values()];
      // console.log('unique >> '+JSON.stringify(data1));
      setCompanyMasterArr(unique);
      setCLoading(true);
    }
  }

const [MarqueeText, setMarqueeText] = useState({})

  // useEffect(() => {
  //   axios.get("https://omkaracapital.in/api/vdr-marquee")
  //   .then(req=>{
  //     setMarqueeText(req.data?.text);
  //   })
  //   .catch(err=>{

  //   })
  // }, [])
  
  useEffect(() => {
    
    if(loadingDataRoomTicker){
      dispatch(dataRoomTickerAction())
    }
    if(!loadingDataRoomTicker){
      let _dataRoomTicker = DataRoomTicker?.data && DataRoomTicker?.data[0];
      setMarqueeText(_dataRoomTicker);
    }
  }, [dispatch, loadingDataRoomTicker])




  useEffect(() => {
    
    //console.log(loggedInUser);
    // console.log('loggedInUser >>> ',JSON.parse(localStorage.getItem('users_leggin'))?.user_id);

    if (SegmentLoading) {
      dispatch(segmentAction(segmentAddJson));
    }

  /*
    if (FavLoading === false) {

      if (vdrFavoriteLoading) {
        dispatch(vdrFavoriteAction({
          "user_id": (loggedInUser?.user_id),
          "Inputtype": 2
        }));
      }

      if (!vdrFavoriteLoading) {
        setFavLoading(true);
        var cmpDataaaaa = [];

        var homeDataData1 = vdrFavoriteData?.Data.map((item) => {
          var company_id = item.company_id;
          if (item.isFavorite && !cmpDataaaaa.includes(company_id)) {
            cmpDataaaaa.push(company_id.toString());
          }
        });
        // console.log('vdrFavoriteDsssssssata >>>>>', vdrFavoriteData?.Data)
        dispatch({
          type: 'FavoriteCompanySuccess',
          payload: cmpDataaaaa
        });

      }


    }
    */


  }, [dispatch]);


  useEffect(() => {
    if (homeLoading) {
      dispatch(vdrHomeAction({ status: 1, user_id: (loggedInUser?.user_id) }))
    }
    if (companyLoading) {
      dispatch(vdrCompanyAction({ status: 1 }))
    }

    if (!homeLoading) {
      setListingData(homeData.Data)
      // const unique = [...new Set(homeData.Data.map(item => item.group_id))]; // [ 'A', 'B'] 
      const unique = [...new Map(homeData.Data.map(item => [item['group_id'], item])).values()]; // [ 'A', 'B'] 
      setListingGroup(unique)
    }
    // console.log(companyLoading);
    if (!companyLoading) {
      selectCompany()
    }
  }, [dispatch, homeLoading, companyLoading]);

  useEffect(() => {

    if (vdrMediaLoading) {
      var videoParams = {
        "userid": 1,
        "videoCode": "",
        "videoId": "",
        "videoType": "",
        "videoTitle": "",
        "videoDescription": "",
        "DocumentType": "Common",
        "isFeatured": true,
        "inputType": 2
      }
      dispatch(vdrMediaAction(videoParams));
    }

    if (!vdrMediaLoading) {
      setVideosArr(vdrMediaData.Data.filter((items) => { if (items.DocumentType === "Common" && items.isFeatured === true) return items }));
    }

  }, [dispatch, vdrMediaLoading]);


  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [anchor, setAnchor] = React.useState('left');
  const [stateDrawer, setStateDrawer] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [openForm, setOpenForm] = React.useState(null);
  const [SideDraw, setSideDraw] = React.useState(true);
  const [parentGroup, setParentGroup] = React.useState(null);
  const toggleDrawer = (anchor, open) => (event) => {

    if (event) {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
    }
    setStateDrawer({ ...stateDrawer, [anchor]: open });
  };



  var group_id;

  if (homeLoading) {

    return (
      <LoadingComponent />
    )
  }

  const motionVariant = {
    open: {
      x: 0,
      opacity: 1,
    },
    close: {
      opacity: 0,
      x: -100,
    },
  }


  const groupViewData = (groupItem, gIndex, segment_id, disabledLink="") => {
    
    if(groupItem.group_id == 4){
      disabledLink = true;
    }

    return (
      <>
      {/* {
        !segment_id && groupItem?.group_name.toLowerCase() == 'exit' ?
          <Divider sx={{ backgroundColor: '#3e3e3e', height: 3 }} />
          :
          null
      } */}

      <Box sx={{
        margin: '20px 0'
      }} key={gIndex} id={`container${gIndex}`}>
        <Box
          sx={{
            display: 'flex',
            padding: '0 0.8rem'
          }}
          justifyContent="space-between"
          alignItems={'center'}
        >
          <Typography variant="h6" >
            {groupItem.group_name}
            {/* {groupItem.group_id} */}
          </Typography>

          {
            groupItem?.company_length > 5
              ?
              <>
                <Button variant="text">
                  See &nbsp;More
                </Button>
              </>
              :
              null
          }
        </Box>

        <CustomCarousel disabledLink={disabledLink} groupId={groupItem.group_id} EnableNumber={groupItem?.enable_number} listingData={listingData} />

      </Box>
      {
       groupItem.sticky ?
          <Divider sx={{ backgroundColor: '#3e3e3e', height: 3 }} />
          :
          null
      }
    </>
    )
  }

  const arr1 = [3,2];
  const arr2 = [];
  const arr3 = [4];
  const arr4 = [49,48];

  const viewData = [];

  return (
    <>

      <Box
        className='headerContainerFront'
      >
        <Typography className='secondary-cst-colr' variant='h1' fontSize={'2.5em'} fontWeight="600"  style={{ zIndex: 1 }} >
          Data Room
        </Typography>

        <HomeSearch  sx={{
          minWidth: '50%',
          backgroundColor: '#fff', zIndex: 2, marginTop: 2, marginBottom: 2,
        }} newOptions={companyMasterArr} />


      </Box>
      <Box >
        <motion.div className='primary-cst-bg'
          animate={{
            left: SideDraw === false ? 0 : '250px',
          }}
          whileHover={{
            // scale: 1.10,
            paddingLeft: '14px',
          }}
          initial={{
            paddingLeft: '0',
          }}
          transition={{
            duration: 0.3
          }}
          style={{
            zIndex: 12,
            position: 'fixed',
            top: '15%',
            left: 0,
            right: 0,
            transform: 'translateY(-50%)',
            borderRadius: '0px 0px 0px 0px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            color: '#fff',
            padding: '0.5rem',
            margin: '0px',
            width: 'fit-content',
            height: '50px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0px 5px 5px 0px',
            cursor: 'pointer',
          }}
          onClick={() => setSideDraw(!SideDraw)} variant="contained"
        >

          {SideDraw == false ? '>' : '<'}
        </motion.div>
        <motion.div
          variants={motionVariant}
          animate={SideDraw ? 'open' : 'close'}
          transition={{
            type: 'just',
            duration: 0.5
          }}
          style={{
            backgroundColor: '#f0f0f0',
            width: SideDraw ? '250px' : 0,
            position: 'fixed',
            top: 0,
            zIndex: 11,
            // height: 'calc(100vh - 60px)',
            height: '100vh',
            padding: 2,
            paddingTop: '70px',
          }}
        >
          {/* <Box >
          </Box> */}
          <Box sx={{
            position: 'relative',
          }}>
            {/*           
          <Button sx={{
              position: 'absolute',
              top: -20,
              right: 10,
              
            }} size="small" onClick={() => setSideDraw(!SideDraw)} variant="outlined">X</Button> */}
            {/* <Typography fontWeight={'bold'} sx={{ paddingLeft:3, marginTop:-5 }}>Company Groups</Typography> */}

            <Box sx={{
              height: '88vh',
              overflowY: 'auto',
            }}>

              <List>
              {
                    listingGroup &&
                    listingGroup.map((groupItem, gIndex) => {
                      // if(groupItem.Segment_id == 0){
                      //   return ;
                      // }
                      return (
                        <>
                          <ListItem disablePadding>
                            <SLink activeClass="active" className={`sidebarBtn`} offset={-70} spy to={`container${gIndex}`} smooth={true} duration={500} >
                              <ListItemButton onClick={() => setValue(gIndex)} className="demoClass">
                                {groupItem.group_name}
                              </ListItemButton>
                            </SLink>
                          </ListItem>
                        </>
                      )
                    })
                  }
                  

              </List>
            </Box>

          </Box>
        </motion.div>
      </Box>

      <Box
        className='bodyContainer'
      > 
{
  MarqueeText && (
      <Box>
        <div className='homeTicker'>
        {/* <div dangerouslySetInnerHTML={{  __html: MarqueeText }} /> */}
        <div>
          <strong>{MarqueeText?.date ? moment(MarqueeText.date).format('Do MMM YYYY')+": " : ""}</strong>&nbsp; <span dangerouslySetInnerHTML={{ __html:MarqueeText?.description }} />
        </div>
          <Link className='' to="/ticker" style={{ 
            textDecoration:'none',
            padding:'0 0.5rem',
            borderRadius:'3px',
            textAlign:'center'
          }}>View All</Link>
        </div>  
      </Box>
  )
}

          {
              listingGroup &&
              listingGroup.map((groupItem, gIndex) => {
                  return groupViewData(groupItem, gIndex);
              })
          }
        <MediaModel />

      </Box>

    </>
  )
}

export default HomePage