import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Typography} from '@mui/material';

export default function SingleCompanyValuation(props) {
    const { valuationTable } = props;
    
    useEffect(() => {
    //   console.log(valuationTable);
    }, [])
    

    const [Headers, setHeaders] = useState([]);

    let columns1 = [];
    let row1 = [];

    console.log('valuationTable.length >>>> ', valuationTable)

    if (valuationTable.length > 0) {
        valuationTable[0]?.Header != null && Object.entries((valuationTable[0]?.Header)).map((item, i) => {
            if (item[0] != '$id') {
                if(i === 1){
                    var key1 = `F_Name`;
                }else{
                    var key1 = `F_Value${i-1}`;
                }
                let dd1 = { id: key1, label: `${item[1]}`, minWidth: 20 };
                columns1 = [...columns1, dd1]
            }
        })
        Object.entries((valuationTable[0]?.Header)).map((item, i) => {
            if (item[0] != '$id') {
                if(i === 1){
                    var key1 = `V_Name`;
                }else{
                    var key1 = `V_Value${i-1}`;
                }
                let dd1 = { id: key1, label: `${item[1]}`, minWidth: 20 };
                columns1 = [...columns1, dd1]
            }
        })
    }


    var FinancialArr = [];
    var ValuationDataArr = [];


    const colorArr = [
        {
            label:'Net Sales',
            color:1
        },
        {
            label:'EBIDTA Margin %',
            color:1
        },
        {
            label:'Cons PAT',
            color:1
        },
        {
            label:'Debt',
            color:2
        },
        {
            label:'ROCE %',
            color:2
        },
        {
            label:'D/E (x)',
            color:2
        },
        {
            label:'Mcap/Sales (x)',
            color:3
        },
        {
            label:'EV/EBITDA (x)',
            color:3
        },
        {
            label:'PE (x)',
            color:3
        },
    ]

    if (valuationTable && valuationTable.length > 0) {
        let FinancialsData = valuationTable[0]?.Financials;

        for (const s_key in FinancialsData) {
            if (s_key != '$id') {
                if (Object.hasOwnProperty.call(FinancialsData, s_key)) {
                    const element2 = FinancialsData[s_key];
                    delete element2.$id;
                    let colorClass = '';
                    colorArr.filter((item)=>{
                        if(item.label == element2.Name){
                            colorClass = `color${item.color}`
                        }
                    })


                    var element11 = {
                        F_Name: {
                            name:element2.Name,
                            color:colorClass
                        },
                        F_Value1: {
                            name:element2.Value1,
                            color:colorClass
                        },
                        F_Value2: {
                            name:element2.Value2,
                            color:colorClass
                        },
                        F_Value3: {
                            name:element2.Value3,
                            color:colorClass
                        },
                        F_Value4: {
                            name:element2.Value4,
                            color:colorClass
                        },
                        F_Value5: {
                            name:element2.Value5,
                            color:colorClass
                        },
                        F_Value6: {
                            name:element2.Value6,
                            color:colorClass
                        },
                        F_Value7: {
                            name:element2.Value7,
                            color:colorClass
                        },
                        F_Value8: {
                            name:element2.Value8,
                            color:colorClass
                        },
                        F_Value9: {
                            name:element2.Value9,
                            color:colorClass
                        }
                    }
                    FinancialArr.push(element11);
                }
            }
        }


        let ValuationsData = valuationTable[0]?.Valuations
        for (const s_key in ValuationsData) {
            if (s_key != '$id') {
                const element1 = ValuationsData[s_key];
                if (Object.hasOwnProperty.call(ValuationsData, s_key)) {
                    delete element1.$id;
                    let colorClass = '';
                    colorArr.filter((item)=>{
                        if(item.label == element1.Name){
                            colorClass = `color${item.color}`
                        }
                    })


                    var element11 = {
                        V_Name: {
                            name:element1.Name,
                            color:colorClass
                        },
                        V_Value1: {
                            name:element1.Value1,
                            color:colorClass
                        },
                        V_Value2: {
                            name:element1.Value2,
                            color:colorClass
                        },
                        V_Value3: {
                            name:element1.Value3,
                            color:colorClass
                        },
                        V_Value4: {
                            name:element1.Value4,
                            color:colorClass
                        },
                        V_Value5: {
                            name:element1.Value5,
                            color:colorClass
                        },
                        V_Value6: {
                            name:element1.Value6,
                            color:colorClass
                        },
                        V_Value7: {
                            name:element1.Value7,
                            color:colorClass
                        },
                        V_Value8: {
                            name:element1.Value8,
                            color:colorClass
                        },
                        V_Value9: {
                            name:element1.Value9,
                            color:colorClass
                        }
                    }
                    ValuationDataArr.push(element11);
                }
            }
        }

    }

    let newRow = [];
    FinancialArr.map((item, a)=>{
        var aa = {...item, ...ValuationDataArr[a]}
        newRow = [...newRow, aa];
    })



    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    // console.log(newRow);

    return (
        <Paper sx={{ width: '100%', boxShadow:'unset' }} >
        {
            valuationTable.length > 0 ?
            <>
            <TableContainer className='cstTable'>
                <Table aria-label="table">
                    <TableHead sx={{ backgroundColor:'#fff9f5', borderTop:'1px solid rgba(224, 224, 224, 1)' }}>
                        <TableRow>
                            <TableCell className={`borderRight`} align="center" colSpan={columns1.length/2}>
                                Financials
                            </TableCell>
                            <TableCell align="center" colSpan={columns1.length/2}>
                                Valuations
                            </TableCell>
                        </TableRow>
                        
                        <TableRow>
                            {columns1 && columns1.length > 0 && columns1.map((column, i0) => (
                                <TableCell
                                 className={`${columns1?.length/2-1 === i0 ? 'borderRight' : null}`}
                                    key={column.id}
                                    align={column.align}
                                    style={{ top: 57, minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {newRow && newRow.length > 0 &&
                            newRow.map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns1.map((column, i0) => {
                                            let colorClass = '';
                                            const value = row[column.id];
                                            
                                            return (
                                                <TableCell className={`${columns1?.length/2-1 === i0 ? 'borderRight' : null} ${value?.color}`} key={column.id} align={column.align}>
                                                    {column.format && typeof value?.name === 'number'
                                                        ? column.format(value?.name)
                                                        : value?.name}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            </>
            :
            <Typography variant="h6" sx={{ padding:1 }} className="noData">Will be updated soon.</Typography>
        }
            

            {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
        </Paper>
    );
}
