import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { InputComponent, SelectCompany, SelectIndustry, SelectSector } from '../Common';
import { Grid, Box, Checkbox, Button, Divider } from '@mui/material';
import { useForm } from 'react-hook-form';
import { valuationDataAction, valuationFilterInputAction, valuationFilters } from '../../redux/actions/userAction';
import { dateAPIAction, PriceActionAction, PriceActionFilterAction, priceActionFilters } from '../../redux/actions/VdrAction';
import PriceIndex from '../Common/PriceIndex';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    // borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#000' }} />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: '2rem',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    // marginLeft: theme.spacing(1),
    margin: 0,
  },
  '& .MuiAccordionSummary-content p': {
    fontSize: '0.85rem',
    fontWeight: '700',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function PriceActionFilter() {
  // const [expanded, setExpanded] = React.useState('panel1');

  const [expanded1, setExpanded1] = React.useState('panel1');
  const [expanded2, setExpanded2] = React.useState(null);
  const [expanded3, setExpanded3] = React.useState(null);
  const [expanded4, setExpanded4] = React.useState(null);
  const [expanded5, setExpanded5] = React.useState(null);
  const [expanded6, setExpanded6] = React.useState(null);




  const { data: P_IndexMaster, loading: P_IndexMasterLoading } = useSelector((state) => state.priceIndexMasterReducer);


  const { data: companyMaster, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
  const { data: sectorMaster, loading: sectorMasterLoading } = useSelector((state) => state.sectorMasterData);
  const { data: industryMaster, loading: industryMasterLoading } = useSelector((state) => state.industryMasterData);
  const { data: turnAroundMaster, loading: turnAroundMasterLoading } = useSelector((state) => state.turnAroundMasterData);


  const [sectors, setSectors] = React.useState([]);
  const [sectorMasterArr, setSectorMasterArr] = useState([]);
  const [P_Index, setP_Index] = React.useState([]);
  const [P_IndexArr, setP_IndexArr] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [company, setCompany] = useState([]);
  const [companyMasterArr, setCompanyMasterArr] = useState([]);
  const [filterArr, setFilterArr] = useState([]);
  const [industryMasterArr, setIndustryMasterArr] = useState([]);
  const [sectorsKey, setSectorsKey] = useState(false);
  const [industryKey, setIndustryKey] = useState(false);
  const [resetButton, setResetButton] = useState(false);
  const [sectorsIds, setSectorsIds] = useState([]);

  const [turnAroundMasterArr, setTurnAroundMasterArr,] = React.useState([]);





  const handleChange = (panel) => (event, newExpanded) => {

    if (panel === 'panel1') {
      setExpanded1(newExpanded ? panel : false);
    }
    if (panel === 'panel2') {
      setExpanded2(newExpanded ? panel : false);
    }
    if (panel === 'panel3') {
      setExpanded3(newExpanded ? panel : false);
    }
    if (panel === 'panel4') {
      setExpanded4(newExpanded ? panel : false);
    }
    if (panel === 'panel5') {
      setExpanded5(newExpanded ? panel : false);
    }
    if (panel === 'panel6') {
      setExpanded6(newExpanded ? panel : false);
    }
  };
  const { control, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);

  const [inputs, setInputs] = useState(false);

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };
  
  const sharePrice = [

    {
      label: 'Market Cap(Cr.)',
      placeholder: '>1',
      name: '',
      type: 'array',
      gridSize: '12',
      children: [
        {
          label: '',
          placeholder: '>500',
          name: 'MarketCapFrom',
          type: 'string',
          gridSize: '6',
        },
        {
          label: '',
          placeholder: '>5000',
          name: 'MarketCapTo',
          type: 'string',
          gridSize: '6',
        },
      ]
    },
    {
      label: 'Change from 52wk High (%)',
      placeholder: '<30',
      name: 'Chg_from_week52_highPer',
      type: 'string',
    },
    {
      label: 'Change from 52wk Low (%)',
      placeholder: '>10',
      name: 'Chg_from_week52_lowPer',
      type: 'string',
    },
    {
      label: 'Change All Time High(%)',
      placeholder: '<10',
      name: 'ChangeAllTimeHigh',
      type: 'string',
    },
    {
      label: 'TTM PE (x)',
      placeholder: '>1',
      type: 'array',
      gridSize: '12',
      children: [
        {
          label: '',
          placeholder: '>1',
          name: 'TTM_PEFrom',
          type: 'string',
          gridSize: '6',
        },
        {
          label: '',
          placeholder: '<20',
          name: 'TTM_PETo',
          type: 'string',
          gridSize: '6',
        },
      ]
    },
    {
      label: 'TTM PBV (x)',
      type: 'array',
      gridSize: '12',
      children: [
        {
          label: '',
          placeholder: '>1',
          name: 'TTM_PBVFrom',
          type: 'string',
          gridSize: '6',
        },
        {
          label: '',
          placeholder: '<20',
          name: 'TTM_PBVTo',
          type: 'string',
          gridSize: '6',
        },
      ]
    },
    // {
    //   label: 'TTM PE (x)',
    //   placeholder: '>1',
    //   name: 'TTM_P_E',
    //   type: 'string',
    // },
    // {
    //   label: 'TM PBV (x)',
    //   placeholder: '>1',
    //   name: 'TTM_P_B',
    //   type: 'string',
    // },
  ];
 
  const { data: dataAPIData, loading: dataAPILoading } = useSelector((state) => state.dateAPIReducer);
  const [DateData, setDateData] = React.useState(null)


  React.useEffect(() => {

    if (dataAPILoading) {
      dispatch(dateAPIAction());
    }
    if (!dataAPILoading) {
      let a1 = dataAPIData?.Data?.[0]?.Price_Action;
      setDateData(a1)
    }
  }, [dispatch, dataAPILoading])



  const applyNow = (data) => {

    var sectorValue = sectors.map((item) => item.value);
    var industryValue = industry.map((item) => item.value);
    var companyValue = company.map((item) => item.value);
    var P_IndexValue = P_Index.map((item) => item.value);
   

    const topLabels =

    {
      "Market_Cap": {
        "label": "Market Cap (Cr.)",
        "value1": (data.MarketCapFrom || ''),
        "value2": (data.MarketCapTo || '')
      },
      "Chg_from_week52_highPer": {
        "label": "Change from 52wk High (%)",
        "value1": (data.Chg_from_week52_highPer || ""),
        "value2": ""
      },
      "Chg_from_week52_lowPer": {
        "label": "Change from 52wk Low(%)",
        "value1": (data.Chg_from_week52_lowPer || ""),
        "value2": ""
      },
      "ChangeAllTimeHigh": {
        "label": "Change All Time High(%)",
        "value1": (data?.ChangeAllTimeHigh || ""),
        "value2": "",
      },
      // "ChangeAllTimeHigh": {
      //   "label": "TTM PE (x)",
      //   "value1": (data?.TTM_P_E || ""),
      //   "value2": "",
      // },
      // "ChangeAllTimeHigh": {
      //   "label": "TM PBV (x)",
      //   "value1": (data?.TTM_P_B || ""),
      //   "value2": "",
      // },
      "TTM_PE": {
        "label": "TTM PE (x)",
        "value1": (data?.TTM_PEFrom || ""),
        "value2": (data?.TTM_PETo || ""),
      },
      "TTM_PBV": {
        "label": "TTM PBV (x)",
        "value1": (data?.TTM_PBVFrom || ""),
        "value2": (data?.TTM_PBVTo || "")
      },
      "sectors": {
        "label": "Sector",
        "value1": (sectorValue || ""),
        "value2": ""
      },
      "industry": {
        "label": "Industry",
        "value1": (industryValue || ""),
        "value2": ""
      },
      "company": {
        "label": "Company",
        "value1": (companyValue || ""),
        "value2": ""
      },
      "index": {
        "label": "Index",
        "value1": (P_IndexValue || ""),
        "value2": ""
      },
      "portfolio": {
        "label": "Portfolio",
        "value1": checked,
        "value2": ""
      }
    };
    // console.log(P_IndexValue);
    data.Index = P_IndexValue;
    data.Sector = sectorValue;
    data.Industry = industryValue;
    data.Company = companyValue;
    data.Market_Cap = [data.MarketCapFrom, data.MarketCapTo];
    data.TTM_P_E = [data.TTM_PEFrom, data.TTM_PETo];
    data.TTM_P_B = [data.TTM_PBVFrom, data.TTM_PBVTo];
    // console.log(topLabels);
    // return ;
    const filterArray = priceActionFilters(topLabels);
    dispatch(PriceActionFilterAction(topLabels));
    dispatch(PriceActionAction(filterArray));


  }






  return (
    <>

      <Box >
        {
          DateData
            ?
            <Typography sx={{ paddingBottom:'0', margin:0, textAlign:'center' }} variant='subtitle2' fontSize={12} textAlign="center">Data Updated <b>{DateData}</b></Typography>
            :
            null
        }
        <Divider />
      </Box>
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Typography sx={{
          marginY: 2,
          fontSize: [14, 18, 24],
          fontWeight: 'bold',
        }} >Filter</Typography>
        <Button className='btn-cst-primary' type='submit' onClick={handleSubmit(applyNow)} variant='contained' size={'small'}>Apply</Button>
      </Box>
      

      <Accordion expanded={expanded1 === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Classification</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={6} >
            <SelectSector
              sectors={sectors}
              sectorMasterArr={sectorMasterArr}
              setSectors={setSectors}
              setSectorMasterArr={setSectorMasterArr}
              sectorsIds={sectorsIds}
              setSectorsIds={setSectorsIds}
              sectorMaster={sectorMaster}
            />
            
            <SelectIndustry
              industry={industry}
              industryMasterArr={industryMasterArr}
              sectorsIds={sectorsIds}
              setIndustry={setIndustry}
              setIndustryMasterArr={setIndustryMasterArr}
              sectorMaster={sectorMaster}
              industryMaster={industryMaster}
            />

            <SelectCompany
              sectors={sectors}
              industry={industry}
              sectorMasterArr={sectorMasterArr}
              setSectors={setSectors}
              sectorsIds={sectorsIds}
              setSectorsIds={setSectorsIds}
              companyMaster={companyMaster}
              companyMasterArr={companyMasterArr}
              company={company}
              setCompany={setCompany}
              setCompanyMasterArr={setCompanyMasterArr}
              companyMasterLoading={companyMasterLoading}
            />


{/* P_Index
P_IndexArr */}

            <PriceIndex
              sectors={P_Index}
              sectorMasterArr={P_IndexArr}
              setSectors={setP_Index}
              setSectorMasterArr={setP_IndexArr}
              sectorsIds={sectorsIds}
              setSectorsIds={setSectorsIds}
              sectorMaster={P_IndexMaster}
            />




            
            <Box
                sx={{ 
                  margin:'0.5rem 0'
                 }}>
              <Checkbox
                sx={{ 
                  padding: '0',
                  margin:'0 0.3rem 0 0'
                 }}
                size='small'
                className={'checkBoxContainer'}
                checked={checked}
                onChange={handleChangeChecked}
                inputProps={{ 'aria-label': 'Portfolio' }}
              />
              <label style={{ fontSize:'12px' }}>Portfolio</label>
            </Box>

          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded2 === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Share Price (TTM)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {
              sharePrice.map((item, index) => {
                if (item.type === 'array') {
                  return (
                    <>
                      <Grid item xs={12} key={index}>
                        <label className='sidebar-label'>{item.label}</label>
                      </Grid>
                      {
                        item?.children.map((child, index0) => {
                          return (
                            <Grid item xs={child.gridSize} key={index0}>
                              <InputComponent
                                name={child.name}
                                label={child.label}
                                control={control}
                                size="small"
                                placeholder={child.placeholder}
                                className='cInput'
                              />
                            </Grid>
                          )
                        })
                      }
                    </>
                  )
                } else {
                  return (
                    <Grid item xs={item.gridSize} key={index}>
                      <InputComponent
                        name={item.name}
                        label={item.label}
                        control={control}
                        size="small"
                        placeholder={item.placeholder}
                        className='cInput'
                      />
                    </Grid>
                  )
                }


              })
            }

          </Grid>
        </AccordionDetails>
      </Accordion>



      {/* <Accordion expanded={expanded3 === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Historical</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {
              HistoricalFilters.map((item, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <InputComponent
                      name="MarketCapFrom"
                      label={item.label}
                      control={control}
                      size="small"
                      placeholder={item.placeholder}
                      className='cInput'
                    />
                  </Grid>
                )

              })
            }

          </Grid>
        </AccordionDetails>
      </Accordion> */}
      {/* <Accordion expanded={expanded4 === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>Balance Sheet</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {
              balanceSheet.map((item, index) => {
                if (item.type === 'array') {
                  return (
                    <>
                      <Grid item xs={12} key={index}>
                        <label className='sidebar-label'>{item.label}</label>
                      </Grid>
                      {
                        item?.children.map((child, index0) => {
                          return (
                            <Grid item xs={child.gridSize} key={index0}>
                              <InputComponent
                                name="MarketCapFrom"
                                label={child.label}
                                control={control}
                                size="small"
                                placeholder={child.placeholder}
                                className='cInput'
                              />
                            </Grid>
                          )
                        })
                      }

                    </>
                  )
                } else {
                  return (
                    <Grid item xs={item.gridSize} key={index}>
                      <InputComponent
                        name="MarketCapFrom"
                        label={item.label}
                        control={control}
                        size="small"
                        placeholder={item.placeholder}
                        className='cInput'
                      />
                    </Grid>
                  )
                }


              })
            }
          </Grid>
        </AccordionDetails>
      </Accordion> */}
      {/* <Accordion expanded={expanded5 === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography>Share Holding Pattern</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Grid container spacing={1}>
            {
              holdingPattern.map((item, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <InputComponent
                      name="MarketCapFrom"
                      label={item.label}
                      control={control}
                      size="small"
                      placeholder={item.placeholder}
                      className='cInput'
                    />
                  </Grid>
                )

              })
            }

          </Grid>
        </AccordionDetails>
      </Accordion> */}
      {/* <Accordion expanded={expanded6 === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography>Conditions</Typography>
        </AccordionSummary>
        <AccordionDetails>

        </AccordionDetails>
      </Accordion> */}
    </>
  );
}
