import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { vdrGroupAction, vdrUpdateGroupAction, vdrUploadImageAction } from '../../../redux/actions/VdrAction';
import { InputComponent } from '../../Common'

import AvatarEditor from 'react-avatar-editor'


import { purple } from '@mui/material/colors';




const Index = (props) => {

    const dispatch = useDispatch();
    const { control, handleSubmit, register, reset } = useForm();


    const { singleCompanyData, toggleDrawer } = props;


    const [companyImage, setCompanyImage] = React.useState(null);
    const [preview, setPreview] = React.useState(null);
    const [editor, setEditor] = React.useState(null);

    const [width, setWidth] = React.useState(200);
    const [height, setHeight] = React.useState(200);

    const [scale, setScale] = React.useState(1.5);
    const handleNewImage = async e => {
        console.log(e.target.files[0]);
        await setCompanyImage(e.target.files[0])
    };
    const handleScale = e => {
        const scale = parseFloat(e.target.value);
        setScale(scale);
    };

    const setEditorRef = editor => {
        if (editor) {
            setEditor(editor);
        }
    };



    const handleSave = data => {

        const img = editor.getImageScaledToCanvas().toDataURL();
        const rect = editor.getCroppingRect();

        setPreview({
            img,
            rect,
            scale: scale,
            width: width,
            height: height,
            // borderRadius: borderRadius
        });
    };


    useEffect(() => {

    }, [dispatch, props])

    const onSubmit = (data) => {
        handleSave();
        let companyName;
        companyName = companyImage.name;
        if (companyName !== '') {
            companyName = companyName.replaceAll(' ', '-');
        }
        const params = {
            "CompanyID": singleCompanyData?.company_id,
            "type": "Logo",
            "FileName": companyName,
            "FileContent": editor.getImageScaledToCanvas().toDataURL()
        }
        // console.log(companyImage.name);
        console.log(params);

        dispatch(vdrUploadImageAction(params));
        toggleDrawer();

    };

    return (
        <>
            <Box sx={{
                width: '30%',
                margin: "1rem auto"
            }}>
                <Typography variant="h5" fontSize={20} sx={{ margin: '1rem 0' }}>
                    Upload Logo
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        {/* <span>group_id</span> */}
                        {/* <input type="hidden" defaultValue={groupData?.group_id || 0} {...register("group_id")} /> */}

                        <Grid item xs={6} >
                            <AvatarEditor
                                ref={setEditorRef}
                                image={companyImage}
                                width={width}
                                height={height}
                                border={20}
                                color={[255, 255, 255, 0.6]} // RGBA
                                scale={scale}
                                rotate={0}
                                className="editor-canvas"
                            />
                        </Grid>

                        <Grid item xs={6}
                            display="flex"
                            justifyContent='space-between'
                            flexDirection={'column'}
                        >
                            <div>
                                Choose File:
                                <input type="file" onChange={handleNewImage} accept=".jpg,.jpeg,.png,.gif" />
                                {
                                    companyImage ?
                                        <>
                                            <br />
                                            <br />
                                            Zoom:
                                            <input
                                                name="scale"
                                                type="range"
                                                onChange={handleScale}
                                                min={1}
                                                max={2}
                                                step="0.01"
                                                defaultValue={scale}
                                                preview={null}
                                            />
                                        </>
                                        :
                                        null
                                }

                            </div>
                            <Button type="submit" variant="contained">Update</Button>
                        </Grid>

                        {/* 
                        {!!preview && (
                            <img
                                src={preview.img}
                                style={{
                                    borderRadius: `${(Math.min(
                                        preview.height,
                                        preview.width
                                    ) +
                                        10) *
                                        (preview.borderRadius / 2 / 100)}px`
                                }}
                                alt="img"
                            />
                        )} */}
                        {/* <Grid item xs={12}>
                            <Button type="submit" variant="contained">Update</Button>
                        </Grid> */}
                        
                    </Grid>

                </form>

            </Box>
        </>
    )
}

export default Index