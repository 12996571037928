import React, { useState } from 'react'
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6


const Editor = (props) => {
  const {TextData, setTextData} = props;

  const handleChange = (value) => {
    setTextData(value)
    // console.log(value);?
  }

  // const modules = {
  //   toolbar: [
  //     [{ 'header': [1, 2, false] }],
  //     ['bold', 'italic', 'blockquote'],
  //     [{'list': 'ordered'}, {'list': 'bullet'}],
  //     ['link'],
  //     [{ 'color': [] }, { 'background': [] }], 
  //     ['clean']
  //   ],
  // };
 
  // const formats = [
  //   'header',
  //   'bold', 'italic', 'blockquote',
  //   'link', 
  //   'list', 'bullet',
  //   'color', 'background', 'code-block',
  //   'clean'
  // ];

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      [{ 'color': [] }, { 'background': [] }], 
      ['clean']
    ],
  };
 
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video',
    'color', 'background', 'code-block',
    'clean'
  ];




  return (
    <>
    <style>{"\
        .ql-container.ql-snow{\
          height: 400px;\
        }\
      "}</style>
      <ReactQuill
        theme={"snow"}
        value={TextData}
        onChange={handleChange} 
        modules={modules}
        formats={formats}
        style={{ 
          height: '450px'
         }}
      />
    </>
  )
}

export default Editor