import { AppBar, Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, { useState,useRef } from 'react';
import { FaSortAlphaDown, FaSortAlphaUp, FaRegEnvelope } from "react-icons/fa";
import { useEffect } from 'react';
import $ from 'jquery';
import moment from 'moment';
import { BiSortDown, BiSortUp } from "react-icons/bi";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import SearchIcon from '@mui/icons-material/Search';
import NotesModal from './NotesModal';
import { crtUserId } from '../../../../constants/helpers';
const FinalOutputComp = (props) => {
  const {
    listdata,
    isCD,
    deleteFunction,
    toggleDrawer, setOpenForm,setinputType,
    ItemEditData, setItemEditData
  } = props;
  console.log('isCD >>> 0', isCD)
  const [listData1, setFinalOutputData] = useState([]);

  
  const [ModalOpen, setModalOpen] = React.useState(false);
  const handleClickOpen = (scrollType="paper") => () => {
    setModalOpen(true);
    
  };

  useEffect(() => {
    setFinalOutputData(listdata)
  }, [props])
  
  // const buttonsArr = [
  //   {
  //     id:1,
  //     label:"All",
  //     title:"All",
  //   },
  //   {
  //     id:2,
  //     label:"Initial Coverage",
  //     title:"Quarter Notes",
  //   },
  //   {
  //     id:3,
  //     label:"Other Updates",
  //     title:"Mgt Meet",
  //   },
  //   {
  //     id:4,
  //     label:"Others",
  //     title:"Others",
  //   },
  // ];
  const buttonsArr = [
    {
      id:1,
      label:"All",
      title:"All",
    },
    {
      id:7,
      label:"Concall Summary",
      title:"Concall Summary",
    },
    {
      id:5,
      label:"Important Source",
      title:"Important Source",
    },
    {
      id:3,
      label:"Management Meeting",
      title:"Management Meeting",
    },
    {
      id:4,
      label:"One Pager",
      title:"One Pager",
    },
    {
      id:2,
      label:"Quarterly Updates",
      title:"Quarterly Updates",
    },
    {
      id:6,
      label:"Rough",
      title:"Rough",
    }
  ];
  
  const filterfinaloutput = (crtTab) => {
   // a ='all'
   // a.label
   let fData=[];
    if(crtTab.label !== 'All'){
      fData = listdata.filter((items) => { if (items._CommentDetails.CommentType === crtTab.label) return items });
     }else{
      fData = listdata.filter(items=>{ if (items._CommentDetails.CommentType !== "") return items });
    }

  
   setFinalOutputData(fData);
}

 const [ActiveBtn, setActiveBtn] = useState(1)
 const chooseType = (item) =>{
   // alert('sasa');
   filterfinaloutput(item)
   setActiveBtn(item.id)
}
const crtRef = React.useRef();
const afterDiv = (e, i) => {
  // var dd = document.querySelector('#desc_div'+i);
  // dd.()
  $('#desc_div'+i).slideToggle();
  // console.log(dd, i);
}


//sort 
const shotButton = useRef();
const [ToggleData, setToggleData] = useState(false);
useEffect(() => {
  setFinalOutputData(listdata);
}, [listdata])


useEffect(() => {

}, [ActiveBtn])
const sortData = (itemData, type) => {
  let sData;
  console.log(itemData,'ammy');
  if (SortType === 'name') {
      if (ActiveBtn) {
          if (ToggleData) {
              sData = listData1.sort((a, b) => a._CommentDetails.Heading.localeCompare(b._CommentDetails.Heading));
          } else {
              sData = listData1.sort((a, b) => b._CommentDetails.Heading.localeCompare(a._CommentDetails.Heading));
          }
      } else {
          if (ToggleData) {
              sData = itemData.sort((a, b) => a._CommentDetails.Heading.localeCompare(b._CommentDetails.Heading));
          } else {
              sData = itemData.sort((a, b) => b._CommentDetails.Heading.localeCompare(a._CommentDetails.Heading));
          }
      }
  } else {
      if (ActiveBtn) {
          if (ToggleData) {
              sData = listData1.sort((a, b) => {
                  var a1 = moment(a._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
                  var b1 = moment(b._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
                  var dd = new Date(a1) - new Date(b1);
                  return dd;
              });
          } else {
              sData = listData1.sort((a, b) => {
                  var a1 = moment(a._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
                  var b1 = moment(b._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
                  var dd = new Date(b1) - new Date(a1);
                  return dd;
              });
          }

      } else {
          if (ToggleData) {
              sData = itemData.sort((a, b) => {
                  var a1 = moment(a._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
                  var b1 = moment(b._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
                  var dd = new Date(a1) - new Date(b1);
                  return dd;
              });
          } else {
              sData = itemData.sort((a, b) => {
                  var a1 = moment(a._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
                  var b1 = moment(b._CommentDetails.DateTime, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
                  var dd = new Date(b1) - new Date(a1);
                  return dd;
              });
          }
      }

  }

  setToggleData(!ToggleData);
  setFinalOutputData(sData);
}
const listContainer = useRef();
const searchContainer = useRef();
const [anchorEl, setAnchorEl] = React.useState(null);
const [SortType, setSortType] = React.useState(null);
const open = Boolean(anchorEl);
const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
};
const handleClose = () => {
    setAnchorEl(null);
};
const [arrowIcon, setArrowIcon] = useState(<RiArrowDownSLine fontSize={25} className="sc_arrow_color" />);
const [DivHeight, setDivHeight] = useState(300);
const onUpdateHeight = () => {
  if (DivHeight === 300) {
      setArrowIcon(<RiArrowUpSLine fontSize={25} className="sc_arrow_color" />)
      setDivHeight('auto')
  } else {
      setArrowIcon(<RiArrowDownSLine fontSize={25} className="sc_arrow_color" />)
      setDivHeight(300)
  }

}

const [
  SelectedItem,
  setSelectedItem
] = useState({});
  return (
    <>


    <NotesModal item={SelectedItem} open={ModalOpen} setOpen={setModalOpen} handleClickOpen={handleClickOpen} />

      <Box className='cst-box'>
          <Box className='c-head-sec0'>
            <Box sx={{ 
              display:'flex',
              justifyContent:'space-between',
              marginBottom:'.5rem'
            }}>
                <Typography sx={{ 
                  fontWeight:'600'
                }}>Notes</Typography>
                <div style={{ 
                  display:'flex',
                  alignItems:'center'
                 }}>
                  {
                    !isCD && (
                        <Button className='text-primary defaultButtonTxt'  onClick={(e)=>{
                        setOpenForm('UploadNote')
                        toggleDrawer('bottom', true)(e)
                    }} size="small" sx={{ 
                          fontSize:'12px',
                          textTransform:'capitalize'
                        }}>+ Add Note</Button>
                    )
                  }

                    {/* <FaSortAlphaUp className='text-primary' /> */}
                    <IconButton ref={shotButton} size="small"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}

                            // onClick={() => sortData(itemData, (title || 'Final Output'))}
                            >
                                {
                                    ToggleData
                                        ?
                                        // <BiSortDown />
                                        <FaSortAlphaDown className='text-primary' />
                                        :
                                        // <BiSortUp />
                                      <FaSortAlphaUp className='text-primary' />
                                }
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => [sortData(listdata, ("Notes" || "Notes")), handleClose(), setSortType('date')]}>Sort by Date</MenuItem>
                                <MenuItem onClick={() => [sortData(listdata, ("Notes" || "Notes")), handleClose(), setSortType('name')]}>Sort by Name</MenuItem>
                            </Menu>


                </div>
            </Box>
            <Box>
                <ul className='final-out-btn'>
                  {
                    buttonsArr.map((item, i)=>(
                      <li key={i}>
                        <button onClick={(e)=>{
                          chooseType(item);
                      }} className={`btn ${ActiveBtn === item.id ? "primary-btn" : "primary-btn-outline"}`}>{item?.title}</button>
                      </li>
                    )) 
                  }
                </ul>
                <Box
                      sx={{
                          marginTop: 2,
                      }}
                  >
                      <TextField
                          onChange={(e) => {}}
                          sx={{
                              width: '99%',
                              padding: 0
                          }}
                          size="small"
                          label="Search Document"
                          id="fullWidth"
                          // value={name}
                          InputProps={{
                              endAdornment: <SearchIcon sx={{ color: 'gray' }} />,
                          }}
                          InputLabelProps={{ style: { fontSize: 12, padding: 0, margin: 0 } }}
                          inputProps={{
                              style: { padding: '0.4rem', fontSize: 15 }
                          }}
                      />
                  </Box>
            </Box>
            
          </Box>
          <Box className='cst-box-body' sx={{
            minHeight: 300,
            height: DivHeight }} >
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {
                listData1?.length === 0 && (
                  <>
                  <ListItem
                    disableGutters
                  >
                    <Typography sx={{ fontSize:'14px' }}>Data Not Found</Typography>
                  </ListItem>
                  </>
                )
              }
            {
              listData1 && listData1.map((value, i) => {
                let item = value._CommentDetails;
                item = { ...item };
                return  (
                <React.Fragment key={i}>
                  <div style={{ display: 'none' }}>
                    item?.UserID: {item?.UserID}
                    <br />
                    crtUserId(): {crtUserId()}
                  </div>
                  <ListItem
                    disableGutters
                    secondaryAction={<>
                    {
                      crtUserId() == 1 || crtUserId() == item?.UserID
                      ?
                      <>
                        <IconButton style={{ right: '30px' }} edge="end" aria-label="edit" onClick={(e)=>{
                          setOpenForm('UpdateNote')
                          setinputType(item)
                          toggleDrawer('bottom', true)(e)
                      }}>
                        <EditIcon />
                      </IconButton>

                      <IconButton style={{ right: '20px' }} edge="end" aria-label="delete" onClick={() => deleteFunction(item)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                      :
                      <>
                      </>
                    }
                    </>}
                  >
                    <Box>
                      <div>
                        <div style={{ 
                          display:'flex',
                          gap:'5px',
                          alignItems:'center',
                        }}>
                          <Typography sx={{ fontSize:'14px', textTransform:'capitalize' }}>{(item?.Heading).toLowerCase()}</Typography>
                          <Typography sx={{ fontSize:'12px', fontWeight:'600', cursor: 'pointer' }} className='text-primary'ref={crtRef} dName={i} onClick={(e)=>{
                            // afterDiv(e, i)
                            setSelectedItem(item)
                            handleClickOpen()(e)
                          }}>Read More</Typography>
                        </div>
                        {/* <div id={'desc_div'+i} style={{ display:'none' }} dvName={i}>
                            <Typography variant="subtitle2" dangerouslySetInnerHTML={{__html: item?.Discription }} className='docItem' sx={{ textTransform:'capitalize', fontSize: 10, color:'#7a7a7a' }} />
                        </div> */}
                      </div>
                      
                      
                
                      <div style={{ 
                        fontSize:'12px',
                       }}>
                        <div><strong>{item?.UserName}</strong></div>
                        <div>{item?.DateTime}</div>
                        <div>{item?.CommentType}</div>
                      </div>
                      
                    </Box>
                    
                  </ListItem>
                  
                  <Divider />
                </React.Fragment>
                )
              }
              )
            }
            </List>
          </Box>
          <Box className="footer-section"
          sx={{

          }}
      >
          <Divider />
          <div
              style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
              }}
          >
              <IconButton size="small" onClick={() => onUpdateHeight()}>
                  {arrowIcon}
              </IconButton>
          </div>
      </Box>
      </Box>
    </>
  )
}

export default FinalOutputComp