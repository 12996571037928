import { AiOutlineInfoCircle } from "react-icons/ai"; 
import { BsFillBarChartFill } from "react-icons/bs"; 
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QuarterlyResultsAction, ResultDocumentNewAction, TLQuarterlyResultsAction } from '../../../../redux/actions/VdrAction';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import InCr from '../InCr';
import { PDF_Icon } from "../../sc_images";
import InfoComponent from "./InfoComponent";
import { QUARTERLY_RESULTS_REQUEST } from "../../../../constants/VdrConstants";

const QuarterlyComp = (props) => {
  const {
    companyId,
    FirstLoad, setFirstLoad,
    setActivePrimaryBtn,
    QuarterlySegmentType,
    setQuarterlySegmentType
  } = props

  const { data: CompanyNoteData, loading: CompanyNoteLoading } = useSelector(state => state.singleCompanyNoteReducer);

  const [TableColumns, setTableColumns] = useState([]);
  const [TableBody, setTableBody] = useState([]);
  const {
    data,
    l_type,
    loading
  } = useSelector(state=>state.QuarterlyResultsReducer)

  const {
    QuarterlyResults:{
      data: QuarterlyResultsData,
      loading: QuarterlyResultsLoading
    }
  } = useSelector(state=>state.TrendlyneDataReducer)

  
  const dispatch = useDispatch();
const [P_Type, setP_Type] = useState('con');
  const callApi = () => {
    
    let pType = 'con';
        setP_Type(pType)
    
    if(QuarterlySegmentType === "Standalone"){
      pType = 'std';
      setP_Type('std')
    }
    // pType = 'std';

    let params =  {
      "CompanyId":companyId,
      "type":pType
    }
    // console.log('params >>>> ', params)
    dispatch(QuarterlyResultsAction(params))
  }


  let compSlug = "INFY";
  if(CompanyNoteData?.Data && CompanyNoteData?.Data.length > 0){
    compSlug = (CompanyNoteData?.Data[0].BSEcode || CompanyNoteData?.Data[0].NSEcode);
  }
  

  useEffect(() => {
    setTableColumns([])
    setTableBody([])
    callApi();
  }, [QuarterlySegmentType])

  useEffect(() => {
    if(CompanyNoteData?.Data && CompanyNoteData?.Data.length > 0){
      // console.log('CompanyNoteData?.Data >>>> ', CompanyNoteData?.Data.length, QuarterlyResultsLoading)
    if(QuarterlyResultsLoading){
      dispatch(TLQuarterlyResultsAction(compSlug))
    } 
    if(!QuarterlyResultsLoading){
      
    }
  } 
    
  }, [QuarterlyResultsLoading, CompanyNoteLoading])

  useEffect(() => {
      if(!loading){
        if(data.Header && data.Header.length > 0 && data?.button_status[P_Type] === true){
          let cols = []
          let firstObj = data.Header[0];
          let a0 = firstObj.row;
          Object.keys(a0).map((item, i)=>{
            if(i > 0){
              let dd = {
                  "id": item,
                  "width": "",
                  "align": "",
                  "bg_color": firstObj?.bg_color,
                  "isItalic": firstObj?.isItalic,
                  "isBold": firstObj?.isBold,
                  "text_color": firstObj?.text_color,
                  "label": a0[item]
                }
              cols.push(dd);
            }
          })
          setTableColumns(cols)
        }else{
          if(FirstLoad){
            if(!loading){
              setFirstLoad(false);
              setActivePrimaryBtn(2)
              setQuarterlySegmentType('Standalone')
            }
          }
        }



        if(data.Data && data.Data.length && data?.button_status[P_Type] === true){
          let dataA = []
          let a00 = data.Data;
          a00.map((item, i)=>{
            let cData = item?.row;
            let childObj = {}
            Object.keys(cData).map((item0, i0)=>{
              if(i0 > 0){
                let data = {
                  "label":cData[item0],
                  "bg_color":item['bg_color'],
                  "isItalic":item['isItalic'],
                  "isBold":item['isBold'],
                  "text_color":item['text_color'],
                };
                childObj = {...childObj, [`Column${i0}`]: data}
              }
            })
            dataA.push(childObj)
          })
          setTableBody(dataA)
          console.log('dataA >>>>> ', dataA)
        }
      }

      // console.log('dataA >>>> loading >>>>> ', loading

  }, [dispatch, loading, data])

  
  const [IsOpen, setIsOpen] = useState(false);
  const [CrtRow, setCrtRow] = useState(false);
  const showChart = (row)=>{
    dispatch({
      type:"Columns_Rows",
      payload:{columns:TableColumns, rows:row}
    })

    // setIsOpen(true);
    // setCrtRow(row);
  }
   

  const [OpenInfo, setOpenInfo] = React.useState(false);
  const [InfoTitle, setInfoTitle] = React.useState("");

  const clickInfo = (e, yearMonth) => {
    setInfoTitle(yearMonth);
    let yearMonthArr = yearMonth.split('-')
    // let pType = 'con';
    //     setP_Type(pType)
    // if(QuarterlySegmentType === "Standalone"){
    //   pType = 'std';
    //   setP_Type('std')
    // }
    
    let params = {
      "CompanyID": companyId,
      "UserId": "12",
      "month": yearMonthArr[0],
      "year": yearMonthArr[1],
      "Type": P_Type // std
  }
    dispatch(ResultDocumentNewAction(params))
    setOpenInfo(true)
  }

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  


  return (
    <>
        <InfoComponent OpenInfo={OpenInfo} setOpenInfo={setOpenInfo} Title={InfoTitle} QuarterlySegmentType={QuarterlySegmentType}/>
        
        <div className="box-body-sec">
          
          

          {
            (loading ) && (
              <CircularProgress />
            )
          }
          
       
          {
            !loading && (
              <>
              
              {
                (TableBody.length > 0 && data?.button_status[P_Type] === true) ?
                <>
                <table>
                  <thead>
                    <tr>
                      {
                        TableColumns.map((item,i)=>{
                          let cStyle = {
                            
                                backgroundColor:item?.bg_color,
                                fontStyle:item?.isItalic ? "italic" : "",
                                fontWeight:item?.isBold ? "500" : "",
                                color:item?.text_color,
                              }
                          return (
                            <td style={cStyle} key={i}>{item.label}</td>
                          )
                        })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                        TableBody.map((row,i)=>{
                          return (
                            <tr>
                              {
                                TableColumns.map((item,i)=>{
                                  let rowData = row[item.id]
                                  let value = rowData?.label
                                  let cStyle = {
                                    backgroundColor:rowData?.bg_color,
                                    fontStyle:rowData?.isItalic ? "italic" : "",
                                    fontWeight:rowData?.isBold ? "500" : "",
                                    color:rowData?.text_color,
                                  }
                                  if(rowData?.isChild === true && i === 0){
                                    cStyle = {...cStyle, paddingLeft:'2rem'}
                                  }
                                  
                                  return (
                                    <td style={cStyle} key={i}>
                                      <div style={{ 
                                        display:'flex',
                                        width:'100%',
                                        justifyContent: (i === 0 ? 'space-between' : 'flex-end'),
                                        alignItems:'center'
                                       }}>
                                      {value || ""}  
                                      <span>
                                        {
                                          i === 0 && 
                                          <IconButton onClick={(e)=>showChart(row)} sx={{ padding:0 }}>
                                            <BsFillBarChartFill size={16} />
                                          </IconButton>
                                        }
                                      </span>
                                      </div>
                                    
                                    </td>
                                  )
                                })
                              }
                            </tr>
                          )
                        })
                      }
                      {/* <tr>
                      {
                        TableColumns.map((item,i)=>{
                          if(i === 0){
                            return (
                              <td>
                                <b>Raw PDF</b>
                              </td>
                            ) 
                          }else{
                            let singleData = [];
                            let $key = item.label;
                            if($key){
                              $key = $key.replace(' ', '-');                             
                              singleData = QuarterlyResultsData[$key]
                              // console.log('singleData >>>> ', singleData)
                            }
                            return (
                              <td>
                                <div style={{ 
                                  display:'flex',
                                  alignItems:'baseline',
                                  justifyContent:'end',
                                  gap:'.5rem'
                                 }}>
                                  {
                                    singleData && singleData.length > 0 && singleData.map((c_data,_i)=>{
                                      return (
                                        <div>
                                          <a href={c_data.url} title={c_data.title} target="_blank" rel="noopener noreferrer">
                                            <img src={PDF_Icon} alt={c_data.title}  className="pdf_icon"/>
                                          </a>
                                        </div>
                                      )
                                    })
                                  }
                                  
                                </div>
                              </td>
                            )
                          }
                          
                        })
                      }
                      </tr> */}
                      <tr>
                      {
                        TableColumns.map((item,i)=>{
                          if(i === 0){
                            return (
                              <td>
                                <b>Analysis</b>
                              </td>
                            ) 
                          }else{
                            let singleData = [];
                            let $key = item.label;
                            if($key){
                              $key = $key.replace(' ', '-');                             
                              // singleData = QuarterlyResultsData[$key]
                              // console.log('singleData >>>> ', singleData)
                            }
                            return (
                              <td>
                                {
                                  $key ?
                                  <>
                                  <div style={{ 
                                    display:'flex',
                                    alignItems:'baseline',
                                    justifyContent:'end',
                                    gap:'.5rem'
                                  }}>
                                    <IconButton onClick={(e)=>clickInfo(e, $key)} sx={{ 
                                      padding:'0'
                                    }}>
                                      <AiOutlineInfoCircle style={{ fontSize:'1rem' }} />
                                    </IconButton>
                                    
                                  </div>
                                  </>
                                  :
                                  <></>

                                }
                                
                              </td>
                            )
                          }
                          
                        })
                      }
                      </tr>
                    
                  </tbody>
                </table>
                {/* <table>
                  <thead>
                    <tr>
                      {
                        TableColumns.map((item,i)=>{
                          return (
                            <td key={i}>{item.label}</td>
                          )
                        })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                        TableBody.map((row,i)=>{
                          return (
                            <tr>
                              {
                                TableColumns.map((item,i)=>{
                                  let value = row[item.id]
                                  return (
                                    <td key={i}>{value}</td>
                                  )
                                })
                              }
                            </tr>
                          )
                        })
                      }
                    
                  </tbody>
                </table> */}
                </>
                :
                <>
                  <div>
                      {/* <Typography>N/A</Typography> */}
                  </div>
                </>

              }
                
              </>
            )
          }
        </div>
    </>
  )
}

export default QuarterlyComp