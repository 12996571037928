// Start VDR Section

export const VDR_GROUPS_REQUEST = "VDR_GROUPS_REQUEST";
export const VDR_GROUPS_SUCCESS = "VDR_GROUPS_SUCCESS";
export const VDR_GROUPS_FAIL = "VDR_GROUPS_FAIL";
export const VDR_COMPANY_REQUEST = "VDR_COMPANY_REQUEST";
export const VDR_COMPANY_SUCCESS = "VDR_COMPANY_SUCCESS";
export const VDR_COMPANY_FAIL = "VDR_COMPANY_FAIL";
export const VDR_LEVEL_REQUEST = "VDR_LEVEL_REQUEST";
export const VDR_LEVEL_SUCCESS = "VDR_LEVEL_SUCCESS";
export const VDRMediaMyFavouriteListFAIL = "VDRMediaMyFavouriteListFAIL";
export const VDRMediaMyFavouriteListREQUEST = "VDRMediaMyFavouriteListREQUEST";
export const VDRMediaMyFavouriteListSUCCESS = "VDRMediaMyFavouriteListSUCCESS";
export const VDR_LEVEL_FAIL = "VDR_LEVEL_FAIL";
export const VDR_HOME_REQUEST = 'VDR_HOME_REQUEST'; 
export const VDR_HOME_SUCCESS = 'VDR_HOME_SUCCESS'; 
export const VDR_HOME_FAIL = 'VDR_HOME_FAIL'; 
export const VDR_HOME_SUCCESS_FILTER = 'VDR_HOME_SUCCESS_FILTER'; 

export const VDR_UPLOAD_IMAGE_REQUEST = 'VDR_UPLOAD_IMAGE_REQUEST';
export const VDR_UPLOAD_IMAGE_SUCCESS = 'VDR_UPLOAD_IMAGE_SUCCESS';
export const VDR_UPLOAD_IMAGE_FAIL = 'VDR_UPLOAD_IMAGE_FAIL';

export const PUBLIC_IP_REQUEST = 'PUBLIC_IP_REQUEST';
export const PUBLIC_IP_SUCCESS = 'PUBLIC_IP_SUCCESS';
export const PUBLIC_IP_FAIL = 'PUBLIC_IP_FAIL';

export const SingleCompanyBalanceSheetREQUEST = 'SingleCompanyBalanceSheetREQUEST';
export const SingleCompanyBalanceSheetSUCCESS = 'SingleCompanyBalanceSheetSUCCESS';
export const SingleCompanyBalanceSheetFAIL = 'SingleCompanyBalanceSheetFAIL';

export const SingleCompanyratiosREQUEST = 'SingleCompanyratiosREQUEST';
export const SingleCompanyratiosSUCCESS = 'SingleCompanyratiosSUCCESS';
export const SingleCompanyratiosFAIL = 'SingleCompanyratiosFAIL';

export const SingleCompanypeersREQUEST = 'SingleCompanypeersREQUEST';
export const SingleCompanypeersSUCCESS = 'SingleCompanypeersSUCCESS';
export const SingleCompanypeersFAIL = 'SingleCompanypeersFAIL';

export const VDR_SINGLE_COMPANY_REQUEST = 'VDR_SINGLE_COMPANY_REQUEST';
export const VDR_SINGLE_COMPANY_SUCCESS = 'VDR_SINGLE_COMPANY_SUCCESS';
export const VDR_SINGLE_COMPANY_FAIL = 'VDR_SINGLE_COMPANY_FAIL';


export const PDF_LINK_REQUEST = 'PDF_LINK_REQUEST';
export const PDF_LINK_SUCCESS = 'PDF_LINK_SUCCESS';


export const MEDIA_MODEL_REQUEST = 'MEDIA_MODEL_REQUEST';
export const MEDIA_MODEL_OPEN = 'MEDIA_MODEL_OPEN';
export const MEDIA_MODEL_CLOSE = 'MEDIA_MODEL_CLOSE';

export const MEDIA_DATA_REQUEST = 'MEDIA_DATA_REQUEST';
export const MEDIA_DATA_SUCCESS = 'MEDIA_DATA_SUCCESS';
export const MEDIA_DATA_FAIL = 'MEDIA_DATA_FAIL';


export const DATA_20_YEARS_REQUEST = 'DATA_20_YEARS_REQUEST';
export const DATA_20_YEARS_SUCCESS = 'DATA_20_YEARS_SUCCESS';
export const DATA_20_YEARS_FAIL = 'DATA_20_YEARS_FAIL';

export const DATA_20_YEARS_QUARTERLY_REQUEST = 'DATA_20_YEARS_QUARTERLY_REQUEST';
export const DATA_20_YEARS_QUARTERLY_SUCCESS = 'DATA_20_YEARS_QUARTERLY_SUCCESS';
export const DATA_20_YEARS_QUARTERLY_FAIL = 'DATA_20_YEARS_QUARTERLY_FAIL';

export const QUARTERLY_REVIEW_REQUEST = 'QUARTERLY_REVIEW_REQUEST';
export const QUARTERLY_REVIEW_SUCCESS = 'QUARTERLY_REVIEW_SUCCESS';
export const QUARTERLY_REVIEW_FAIL = 'QUARTERLY_REVIEW_FAIL';

export const PEER_TO_PEER_REQUEST = 'PEER_TO_PEER_REQUEST';
export const PEER_TO_PEER_SUCCESS = 'PEER_TO_PEER_SUCCESS';
export const PEER_TO_PEER_FAIL = 'PEER_TO_PEER_FAIL';

export const SORT_DATA_REQUEST = 'SORT_DATA_REQUEST';
export const SORT_DATA_SUCCESS = 'SORT_DATA_SUCCESS';
export const SORT_DATA_FAIL = 'SORT_DATA_FAIL';

export const SORT_DATA_UPDATE_REQUEST = 'SORT_DATA_UPDATE_REQUEST';
export const SORT_DATA_UPDATE_SUCCESS = 'SORT_DATA_UPDATE_SUCCESS';
export const SORT_DATA_UPDATE_FAIL = 'SORT_DATA_UPDATE_FAIL';

export const FAVORITE_REQUEST = 'FAVORITE_REQUEST';
export const FAVORITE_SUCCESS = 'FAVORITE_SUCCESS';
export const FAVORITE_FAIL = 'FAVORITE_FAIL';

export const EMPLOYEE_REQUEST = 'EMPLOYEE_REQUEST';
export const EMPLOYEE_SUCCESS = 'EMPLOYEE_SUCCESS';
export const EMPLOYEE_FAIL = 'EMPLOYEE_FAIL';

export const SEGMENT_REQUEST = 'SEGMENT_REQUEST';
export const SEGMENT_SUCCESS = 'SEGMENT_SUCCESS';
export const SEGMENT_FAIL = 'SEGMENT_FAIL';

export const SEGMENT_ASSIGN_REQUEST = 'SEGMENT_ASSIGN_REQUEST';
export const SEGMENT_ASSIGN_SUCCESS = 'SEGMENT_ASSIGN_SUCCESS';
export const SEGMENT_ASSIGN_FAIL = 'SEGMENT_ASSIGN_FAIL';
export const QUARTERLY_REVIEW_RESET = 'QUARTERLY_REVIEW_RESET';

export const QUARTERLY_SEGMENT_REQUEST = 'QUARTERLY_SEGMENT_REQUEST';
export const QUARTERLY_SEGMENT_SUCCESS = 'QUARTERLY_SEGMENT_SUCCESS';
export const QUARTERLY_SEGMENT_RESET = 'QUARTERLY_SEGMENT_RESET';
export const QUARTERLY_SEGMENT_FAIL = 'QUARTERLY_SEGMENT_FAIL';

export const DATAROOM_TICKER_REQUEST = 'DATAROOM_TICKER_REQUEST';
export const DATAROOM_TICKER_SUCCESS = 'DATAROOM_TICKER_SUCCESS';
export const DATAROOM_TICKER_FAIL = 'DATAROOM_TICKER_FAIL';


export const FORUM_LIST_REQUEST = 'FORUM_LIST_REQUEST';
export const FORUM_LIST_SUCCESS = 'FORUM_LIST_SUCCESS';
export const FORUM_LIST_FAIL = 'FORUM_LIST_FAIL';

export const MyBlogListREQUEST = 'MyBlogListREQUEST';
export const MyBlogListSUCCESS = 'MyBlogListSUCCESS';
export const MyBlogListFAIL = 'MyBlogListFAIL';

export const RemoveToMylistREQUEST = 'RemoveToMylistREQUEST';
export const RemoveToMylistSUCCESS = 'RemoveToMylistSUCCESS';
export const RemoveToMylistFAIL = 'RemoveToMylistFAIL';

export const AddToMylistREQUEST = 'AddToMylistREQUEST';
export const AddToMylistSUCCESS = 'AddToMylistSUCCESS';
export const AddToMylistFAIL = 'AddToMylistFAIL';

export const NOTIFICATION_REQUEST = 'NOTIFICATION_REQUEST';
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
export const NOTIFICATION_FAIL = 'NOTIFICATION_FAIL';

export const FORUM_DETAIL_REQUEST = 'FORUM_DETAIL_REQUEST';
export const FORUM_DETAIL_SUCCESS = 'FORUM_DETAIL_SUCCESS';
export const FORUM_DETAIL_FAIL = 'FORUM_DETAIL_FAIL';

export const QUARTERLY_RESULTS_REQUEST = 'QUARTERLY_RESULTS_REQUEST';
export const QUARTERLY_RESULTS_SUCCESS = 'QUARTERLY_RESULTS_SUCCESS';
export const QUARTERLY_RESULTS_FAIL = 'QUARTERLY_RESULTS_FAIL';

export const VDRMediaAddToFavouriteREQUEST = 'VDRMediaAddToFavouriteREQUEST';
export const VDRMediaAddToFavouriteSUCCESS = 'VDRMediaAddToFavouriteSUCCESS';
export const VDRMediaAddToFavouriteFAIL = 'VDRMediaAddToFavouriteFAIL';


export const QUARTERLY_SNAPSHOT_REQUEST = 'QUARTERLY_SNAPSHOT_REQUEST';
export const QUARTERLY_SNAPSHOT_SUCCESS = 'QUARTERLY_SNAPSHOT_SUCCESS';
export const QUARTERLY_SNAPSHOT_FAIL = 'QUARTERLY_SNAPSHOT_FAIL';

export const SINGLE_COMP_PROFIT_LOSS_REQUEST = 'SINGLE_COMP_PROFIT_LOSS_REQUEST';
export const SINGLE_COMP_PROFIT_LOSS_SUCCESS = 'SINGLE_COMP_PROFIT_LOSS_SUCCESS';
export const SINGLE_COMP_PROFIT_LOSS_FAIL = 'SINGLE_COMP_PROFIT_LOSS_FAIL';

export const VIDEO_LIKE_REQUEST = 'VIDEO_LIKE_REQUEST';
export const VIDEO_LIKE_SUCCESS = 'VIDEO_LIKE_SUCCESS';
export const VIDEO_LIKE_FAIL = 'VIDEO_LIKE_FAIL';

export const VIDEO_COMMENT_REQUEST = 'VIDEO_COMMENT_REQUEST';
export const VIDEO_COMMENT_SUCCESS = 'VIDEO_COMMENT_SUCCESS';
export const VIDEO_COMMENT_FAIL = 'VIDEO_COMMENT_FAIL';

export const VIDEO_COMMENT_LIKE_REQUEST = 'VIDEO_COMMENT_LIKE_REQUEST';
export const VIDEO_COMMENT_LIKE_SUCCESS = 'VIDEO_COMMENT_LIKE_SUCCESS';
export const VIDEO_COMMENT_LIKE_FAIL = 'VIDEO_COMMENT_LIKE_FAIL';

export const NEWSLETTER_COMMENT_ADD = "NEWSLETTER_COMMENT_ADD";
export const NEWSLETTER_COMMENT_DELETE = "NEWSLETTER_COMMENT_DELETE";

export const NEWSLETTER_LIKE_REQUEST = "NEWSLETTER_LIKE_REQUEST";
export const NEWSLETTER_LIKE_SUCCESS = "NEWSLETTER_LIKE_SUCCESS";
export const NEWSLETTER_LIKE_FAIL = "NEWSLETTER_LIKE_FAIL";
export const NEWSLETTER_COMMENT_REQUEST = "NEWSLETTER_COMMENT_REQUEST";
export const NEWSLETTER_COMMENT_SUCCESS = "NEWSLETTER_COMMENT_SUCCESS";
export const NEWSLETTER_COMMENT_FAIL = "NEWSLETTER_COMMENT_FAIL";
export const NEWSLETTER_COMMENT_LIKE_REQUEST = "NEWSLETTER_COMMENT_LIKE_REQUEST";
export const NEWSLETTER_COMMENT_LIKE_SUCCESS = "NEWSLETTER_COMMENT_LIKE_SUCCESS";
export const NEWSLETTER_COMMENT_LIKE_FAIL = "NEWSLETTER_COMMENT_LIKE_FAIL";
export const SINGLE_COMP_CASHFLOW_REQUEST = "SINGLE_COMP_CASHFLOW_REQUEST";
export const SINGLE_COMP_CASHFLOW_SUCCESS = "SINGLE_COMP_CASHFLOW_SUCCESS";
export const SINGLE_COMP_CASHFLOW_FAIL = "SINGLE_COMP_CASHFLOW_FAIL";
export const NEWSLETTER_COMMENT_1_LIKE_SUCCESS = "NEWSLETTER_COMMENT_1_LIKE_SUCCESS";
export const SINGLE_NEWSLETTER_LIKE_REQUEST = "SINGLE_NEWSLETTER_LIKE_REQUEST";
export const SINGLE_NEWSLETTER_LIKE_SUCCESS = "SINGLE_NEWSLETTER_LIKE_SUCCESS";
export const SINGLE_NEWSLETTER_LIKE_FAIL = "SINGLE_NEWSLETTER_LIKE_FAIL";

export const PEER_RATIOS_REQUEST = "PEER_RATIOS_REQUEST";
export const PEER_RATIOS_SUCCESS = "PEER_RATIOS_SUCCESS";
export const PEER_RATIOS_FAIL = "PEER_RATIOS_FAIL";

export const PEER_TO_PEER_SC_REQUEST = "PEER_TO_PEER_SC_REQUEST";
export const PEER_TO_PEER_SC_SUCCESS = "PEER_TO_PEER_SC_SUCCESS";
export const PEER_TO_PEER_SC_FAIL = "PEER_TO_PEER_SC_FAIL";

export const REPOSITORY_LIST_RESET = "REPOSITORY_LIST_RESET";
export const REPOSITORY_LIST_REQUEST = "REPOSITORY_LIST_REQUEST";
export const REPOSITORY_LIST_SUCCESS = "REPOSITORY_LIST_SUCCESS";
export const REPOSITORY_LIST_UPDATE = "REPOSITORY_LIST_UPDATE";
export const REPOSITORY_LIST_FAIL = "REPOSITORY_LIST_FAIL";

export const FORUM_TAGS_LIST_REQUEST = "FORUM_TAGS_LIST_REQUEST";
export const FORUM_TAGS_LIST_SUCCESS = "FORUM_TAGS_LIST_SUCCESS";
export const FORUM_TAGS_LIST_FAIL = "FORUM_TAGS_LIST_FAIL";




// End VDR Section