import { Box, Button, Divider, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { FaSortAlphaDown, FaSortAlphaUp, FaRegEnvelope } from "react-icons/fa";
import DeleteIcon from '@mui/icons-material/Delete';
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TLEarningsCallAction, TLInvestorPresentationAction } from '../../../../redux/actions/VdrAction';
const ConcallTransriptsComp = (props) => {
  const {
   
    deleteFunction

  } = props;
  const buttonsArr = [
    {
      id:1,
      label:"All",
    },
    {
      id:2,
      label:"Initial Coverage",
    },
    {
      id:3,
      label:"Quarterly Update",
    },
    {
      id:4,
      label:"Forensic",
    },
    {
      id:5,
      label:"Brief Notes",
    },
    {
      id:6,
      label:"Others",
    },
  ];


const dispatch = useDispatch()
const { data: CompanyNoteData, loading: CompanyNoteLoading } = useSelector(state => state.singleCompanyNoteReducer);

const {
  EarningsCall:{
    data: EarningsCallData,
    loading: EarningsCallLoading
  },
  InvestorPresentation:{
    data: InvestorPresentationData,
    loading: InvestorPresentationLoading
  }
} = useSelector(state=>state.TrendlyneDataReducer)




const [ListData, setListData] = useState([])

  const mergeFun = () => {

    // let present = InvestorPresentationData;
    let present = [];
    // let earning = EarningsCallData.filter(item=> item.postType !== "Investor Presentation" );
    let earning = EarningsCallData //.filter(item=> item.postType !== "Investor Presentation" );
    let earning0 = earning;

    let a0v = earning0 && earning0.length > 0 && earning0.map(item=>{
      item = {...item, newPostType: item?.postType}
      // item['newPostType'] = item?.postType;
      if(item.postType == "Trendlyne Earnings Call" || item.postType == "Earnings Call"){
        // if(((item?.title).toLowerCase()).includes('transcript') || (item?.description.toLowerCase()).includes('transcript')){
          item = {...item, newPostType: 'Earnings Call New'}
        // }
      }
      if((item?.description).includes('Audio/Video')){
        item = {...item, newPostType: 'mp3'}
      }
      if((item?.title).includes('Investor Presentation') && !(item?.title).includes('Intimation')){
        item = {...item, newPostType: 'Investor Presentation New'}
      }
      return item
    })
    earning = a0v || []
    
    // console.log('a0v <<>>>>>', JSON.stringify(a0v))
    
    // Combine the 'present' and 'earning' arrays
    const combinedArray = [...present, ...earning];

    // Create an empty object to store the grouped objects
    const groupedObjects = {};

    // Iterate over the combinedArray
    combinedArray.forEach(obj => {
      const { postType, pubDate, pdfUrl, videoUrl, newPostType, url } = obj;
      const date = new Date(pubDate);
      const monthYear = `${date.getMonth()}-${date.getFullYear()}`;

      if (!groupedObjects[monthYear]) {
        groupedObjects[monthYear] = {
          date,
          pdf1: [],
          pdf2: [],
          video: [],
        };
      }

      let n_pdfUrl = pdfUrl || url;

      // console.log(monthYear, 'pdfUrl pdf1 ??? ', n_pdfUrl)
      if (newPostType === 'Investor Presentation New') {
        groupedObjects[monthYear].pdf1.push(n_pdfUrl);
        // groupedObjects[monthYear].pdf2.push(null);
      }
      // if (newPostType === 'Trendlyne Earnings Call') {
      //   groupedObjects[monthYear].video.push(videoUrl);
      // }
      if (newPostType === 'Earnings Call New') {
        
        // console.log(groupedObjects[monthYear].pdf1.length)
        // groupedObjects[monthYear].pdf1
        // if(groupedObjects[monthYear].pdf1.length === 0){
        //   groupedObjects[monthYear].pdf1.push(null);
        // }
        
        // if(groupedObjects[monthYear].pdf2.length === 0){
        //   groupedObjects[monthYear].video.push(videoUrl);
        //   groupedObjects[monthYear].pdf2.push(n_pdfUrl);
        // }
        groupedObjects[monthYear].video.push(videoUrl);
        groupedObjects[monthYear].pdf2.push(n_pdfUrl);
      }
      
    });

    // Sort the grouped objects by date in descending order
    // console.log('groupedObjects >> ', groupedObjects)
    const sortedObjects = Object.values(groupedObjects)
      .sort((a, b) => b.date - a.date); 

    // Create the final thirdArray by extracting the required keys
    const thirdArray = sortedObjects.map(({ date, pdf1, pdf2, video }) =>{
      // console.log('pdf1, pdf2 >>> ', pdf1, pdf2)
      return ({
        date,
        pdf1: pdf1,
        pdf2: pdf2,
        video: video,
      })
    });

    // console.log('thirdArray 00000 >>>>> ', thirdArray);

    
    let forthArray = [];
    thirdArray.map((item0, i)=>{
      let date = item0?.date;
      let pdf1 = item0?.pdf1;
      let pdf2 = item0?.pdf2;
      let video = item0?.video;
      let a0 = {}
      
      let l_pdf = pdf1;
      let crt_pdf = 'pdf1';

      if(pdf1.length < pdf2.length){
        l_pdf = pdf2;
        crt_pdf = 'pdf2';
      }

      l_pdf.map((item, i0)=>{
        let obj = { 
          date: date,
          pdf1: (pdf1[i0] || ""),
          pdf2: (pdf2[i0] || ""),
          video: (video[i0] || ""),
        }
        if((pdf1[i0] === undefined || pdf1[i0] === "" || pdf1[i0] === null) && (pdf2[i0] === undefined || pdf2[i0] === "" || pdf2[i0] === null)){
          // console.log('both >>> ', pdf1[i0], pdf2[i0])
        }else{
          // forthArray.push(obj); // remove duplicate data
        }
        forthArray.push(obj);
      })


    })
    // console.log('forthArray >>> ', forthArray)
    setListData(forthArray);
    
  
  }
  let cstHeight = 300;
      cstHeight = 340;
  const [arrowIcon, setArrowIcon] = useState(<RiArrowDownSLine fontSize={25} className="sc_arrow_color" />);
  const [DivHeight, setDivHeight] = useState(cstHeight);
  const onUpdateHeight = () => {
    if (DivHeight === cstHeight) {
        setArrowIcon(<RiArrowUpSLine fontSize={25} className="sc_arrow_color" />)
        setDivHeight('auto')
    } else {
        setArrowIcon(<RiArrowDownSLine fontSize={25} className="sc_arrow_color" />)
        setDivHeight(cstHeight)
    }
  
  }
  let compSlug = "INFY";
  if(CompanyNoteData?.Data && CompanyNoteData?.Data.length > 0){
    compSlug = (CompanyNoteData?.Data[0].BSEcode || CompanyNoteData?.Data[0].NSEcode);
  }
  

  useEffect(() => {
    if(CompanyNoteData?.Data && CompanyNoteData?.Data.length > 0){
      if(EarningsCallLoading){
        dispatch(TLEarningsCallAction(compSlug));
        dispatch(TLInvestorPresentationAction(compSlug));
      }
      if(!EarningsCallLoading){
        let newArr = EarningsCallData;
        mergeFun()
      }
    }
  }, [EarningsCallLoading, CompanyNoteLoading])
  
  useEffect(() => {
    if(CompanyNoteData?.Data && CompanyNoteData?.Data.length > 0){
      if(!InvestorPresentationLoading){
        let newArr = InvestorPresentationData;
        mergeFun()
      }
    }
  }, [InvestorPresentationLoading, CompanyNoteLoading])
  
  
  useEffect(() => {
    
  }, [ListData])
  

  

  return (
    <>
      <Box className='cst-box'>
          <Box className='c-head-sec00'>
            <Box sx={{ 
              display:'flex',
              justifyContent:'space-between',
              marginBottom:'.5rem'
            }}>
                <Typography sx={{ 
                  fontWeight:'600'
                }}>Concall Transripts</Typography>
                <div>
                    {/* <FaSortAlphaUp className='text-primary' /> */}
                </div>
            </Box>
            {/* <Box>
              <div><p dangerouslySetInnerHTML={{ __html:"&nbsp;" }} /></div>
              <div
                style={{
                  fontWeight: "600",
                  color: "#767676",
                }}
              >
                <p dangerouslySetInnerHTML={{ __html:"&nbsp;" }} />
              </div>
            </Box> */}
            {/* <Box >
                <div>Filename Format:</div>
                <div
                  style={{
                    fontWeight: "600",
                    color: "#767676",
                  }}
                >
                  IP_FY22_Q4
                </div>
              </Box> */}
            <Box>
                {/* <ul className='final-out-btn'>
                  <li style={{ 
                    visibility:'hidden'
                   }}>
                    <button>0</button>
                  </li>
                  {
                    buttonsArr.map((item, i)=>(
                      <li key={i}>
                        <button style={{  }} onClick={(e)=>{
                          chooseType(item);
                      }} className={`btn ${ActiveBtn === item.id ? "primary-btn" : "primary-btn-outline"}`}>{item?.label}</button>
                      </li>
                    ))
                  }
                </ul> */}

                <Box
                            sx={{
                                marginTop: 2,
                            }}
                        >
                            <TextField
                                // onChange={(e) => handleChange(e, (listData && listData))}
                                sx={{
                                    width: '99%',
                                    padding: 0
                                }}
                                size="small"
                                label="Search Document"
                                id="fullWidth"
                                // value={name}
                                InputProps={{
                                    endAdornment: <SearchIcon sx={{ color: 'gray' }} />,
                                }}
                                InputLabelProps={{ style: { fontSize: 12, padding: 0, margin: 0 } }}
                                inputProps={{
                                    style: { padding: '0.4rem', fontSize: 15 }
                                }}
                            />
                        </Box>
            </Box>
            
          </Box>
          <Box className='cst-box-body' sx={{
            minHeight: cstHeight,
            height: DivHeight}} >
            { ListData && ListData.length > 0 && 
              ListData.map((value, i00) => {

                let title = `_____`;
                let fileLink1 = "";
                let fileExtension1 = "";
                fileLink1 = value.pdf1;
                fileExtension1 = fileLink1.split(".").pop();
                if (fileExtension1.toLowerCase() === "pdf") {
                  // fileLink1 = `/MyPdfViewer`;
                }
                let fileLink2 = "";
                let fileExtension2 = "";
                fileLink2 = value.pdf2;
                fileExtension2 = fileLink2.split(".").pop();
                if (fileExtension2.toLowerCase() === "pdf") {
                  // fileLink2 = `/MyPdfViewer`;
                }


                // console.log('value pdf1 >>> ', value)
                // console.log('value pdf2 >>> ', value.pdf2)
                // if(value.pdf1 === null && value.pdf2 === null){

                // }
                
                  return (
                    <React.Fragment key={i00}>
                      <Box>
                        <Box className='ct-row'>
                          <Typography className='dft-text'  sx={{ 
                            color: 'rgba(96, 111, 123, 0.8) !important'
                            // fontWeight:'400', fontSize: { xs: 10,
                            //                                       sm: 10,
                            //                                       md: 11,
                            //                                       lg: 13,
                            //                                       xl: 14,
                            //                                      },
                                                                }}
                                                                  >{moment(value?.date).format('MMM YYYY')}</Typography>
                          <div  className='ct-btn-group'>
                            <Button 
                            sx={{ 
                              textTransform:'capitalize'
                             }}
                              component={'a'}
                               rel="noopener noreferrer"
                              target="_blank"
                              href={fileLink2}
                              onClick={(e) => {
                                console.table(value);
                                localStorage.setItem(
                                  "PDFViewed",
                                  window.btoa(value.pdf2)
                                );
                                localStorage.setItem(
                                  "itemData1",
                                  JSON.stringify(value)
                                );
                                localStorage.setItem(
                                  "file_type",
                                  title || "Final Output"
                                );
                              }}

                              className={`ct-btn ${value?.pdf2 === '' ? 'ct-btn-disable' : ''}`} size='small' disabled={value?.pdf2 === '' ? true : false} variant='outlined'>Transcript</Button>
                              <Button 
                              sx={{ 
                                textTransform:'capitalize'
                               }}
                                component={'a'}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={value?.video}
                                className={`ct-btn ${value?.video === '' ? 'ct-btn-disable' : ''}`} size='small' disabled={value?.video === '' ? true : false} variant='outlined'>Video</Button>

                            <Button 
                            sx={{ 
                              textTransform:'capitalize'
                             }}
                              component={'a'}
                               rel="noopener noreferrer"
                              target="_blank"
                              href={fileLink1}
                              onClick={(e) => {
                                console.table(value);
                                localStorage.setItem(
                                  "PDFViewed",
                                  window.btoa(value.pdf1)
                                );
                                localStorage.setItem(
                                  "itemData1",
                                  JSON.stringify(value)
                                );
                                localStorage.setItem(
                                  "file_type",
                                  title || "Final Output"
                                );
                              }}

                              className={`ct-btn ${value?.pdf1 === '' ? 'ct-btn-disable' : ''}`} size='small' disabled={value?.pdf1 === '' ? true : false} variant='outlined'>PPT</Button>
                          </div>
                        </Box>
                        {/* <Divider /> */}
                      </Box>
      
                    </React.Fragment>
                  )
              })
            }
            {/* <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {[1, 2, 3].map((value) => (
                <React.Fragment key={value}>
                  <ListItem
                    disableGutters
                  >
                    <Box>
                      <ListItemText primary={`Line item ${value}`} />
                      <div style={{ 
                        display:'flex',
                        fontSize:'12px',
                        gap:'5px'
                       }}>
                        <div><strong>VB</strong></div>
                        <div>31 Mar, 2023 00:00:00</div>
                        <div>Sector Report</div>
                      </div>
                    </Box>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List> */}
          </Box>
          <Box className="footer-section"
          sx={{

          }}
      >
          <Divider />
          <div
              style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
              }}
          >
              <IconButton size="small" onClick={() => onUpdateHeight()}>
                  {arrowIcon}
              </IconButton>
          </div>
      </Box>
      </Box>
    </>
  )
}

export default ConcallTransriptsComp